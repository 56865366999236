import React from 'react'

const ConfirmSubmit = ({ brandProfile }) => {
	let text1 = null
	if (brandProfile.outcomeIncompleteCount > 0) {
		text1 = `${brandProfile.outcomeIncompleteCount} Outcome`
	}

	let text2 = null
	if (brandProfile.opinionIncompleteCount > 0) {
		text2 = `${brandProfile.opinionIncompleteCount} Opinion`
	}

	let text3 = null
	if (brandProfile.scenarioIncompleteCount > 0) {
		text3 = `${brandProfile.scenarioIncompleteCount} Scenario`
	}

	let text4 = null
	if (brandProfile.questionIncompleteCount > 0) {
		text4 = `${brandProfile.questionIncompleteCount} Question`
	}

	let text5 = null
	if (!brandProfile.queriesComplete) {
		text5 = `No queries entered`
	}

	return (
		<>
			You have some incomplete items on your Brand Mentality: <br /> <br />
			{text1 && `${text1}. `}
			{text2 && `${text2}. `}
			{text3 && `${text3}. `}
			{text4 && `${text4}. `}
			{text5 && `${text5}. `}
			<br /> <br />
			If you click 'Submit', we will skip those questions and submit it anyway.
			Would you like to proceed?
		</>
	)
}

export default ConfirmSubmit
