import * as Yup from 'yup'

export const notificationsObjValidation = Yup.array().of(
	Yup.object().shape({
		accountId: Yup.number().required(),
		accountName: Yup.string().required(),
		body: Yup.string().required(),
		createdDate: Yup.string().required(),
		notificationId: Yup.number().required(),
		notificationTypeId: Yup.number().required(),
		notificationTypeName: Yup.string().required(),
		seen: Yup.boolean().required(),

		urls: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().defined(''),
				url: Yup.string().required()
			})
		)
	})
)
