import React from 'react'
import PortalModal from '../../../../../components/PortalModal/PortalModal'
import SightlyButton from '../../../../../components/SightlyButton'
import SightlySelectForm from '../../../../../components/Sightly/SightlySelectForm'
import SightlyInputForm from '../../../../../components/Sightly/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { competitorObjValidation } from '../../../../../schemas/brandProfiles'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import NameSearchField from './NameSearchField'
import { Event } from './EventSection'
import { accentColor } from '../../../../../assets/jss/colorContants'
import DatePickerField from './DatePickerField'
import { userAccountAxios } from '../../../../../axiosInstances'
import { useMatch } from '@tanstack/react-location'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

enum fields {
    'name' = 'name',
    'id' = 'id',
    'twitterProfileUrl' = 'twitterProfileUrl',
    'wikiUrl' = 'wikiUrl',
    'websiteUrl' = 'websiteUrl',
    'relationshipId' = 'relationshipId',
    'date' = 'date'
}

const eventRelationshipOptions = [
    { id: 1, name: 'Event I want to associate with' },
    { id: 2, name: 'Event I want to avoid' }
]

type IProps = {
    item: Event
    open: boolean
    handleClose: () => void
    disabled?: boolean
    type: 'edit' | 'add'
}

const EventModal = ({ item, open, handleClose, disabled, type }: IProps) => {
    const {
        params: { brandProfileId }
    } = useMatch()
    const queryClient = useQueryClient()
    const [isSaving, setIsSaving] = React.useState(false)

    const { register, handleSubmit, reset, control, formState, setValue, getValues, watch } = useForm<Event>({
        defaultValues: item,
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(competitorObjValidation)
    })

    const _handleClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: Partial<Event>) => {
        setIsSaving(true)

        const _args: any = data
        delete _args.id
        delete _args.relationshipName

        _args.day = _args.date ? _args.date.getDate() : null
        _args.month = _args.date ? _args.date.getMonth() + 1 : null
        _args.year = _args.date ? _args.date.getFullYear() : null

        delete _args.date
        try {
            if (type === 'edit') {
                const url = `/brand-profile/${brandProfileId}/events/${item.id}`
                await userAccountAxios.patch(url, _args)
                toast.success('Saved')
            } else {
                const url = `/brand-profile/${brandProfileId}/events`
                await userAccountAxios.post(url, _args)
                toast.success('Added')
                reset()
            }
            queryClient.invalidateQueries(rqKeys.brandProfileEvents(Number(brandProfileId)))
        } catch (err) {
            toast.error('Error')
            setIsSaving(false)
        }
        setIsSaving(false)
    }

    const errors = formState.errors

    React.useEffect(() => {
        if (item && type === 'edit') {
            reset(item)
        }
    }, [item, type])

    const typeInfo = {
        options: eventRelationshipOptions,
        humanName: 'Event'
    }

    const watchName = watch('name')

    return (
        <PortalModal
            hasClose={true}
            handleClose={_handleClose}
            open={open}
            footerButtons={[
                <SightlyButton
                    id="rename"
                    loading={isSaving}
                    disabled={!formState.isValid || !formState.isDirty}
                    text={type === 'edit' ? 'Save' : 'Add'}
                    handleClick={handleSubmit(onSubmit)}
                    type="primary"
                />
            ]}
        >
            <h4 style={{ marginBottom: 30, width: 376 }}>
                {type === 'edit' ? `Edit ${typeInfo?.humanName}` : `Add New ${typeInfo?.humanName}`}
            </h4>

            <div className="form-container">
                <NameSearchField
                    disabled={disabled}
                    handleSetWikiUrl={(val: never) =>
                        setValue(fields.wikiUrl, val, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                    handleSetName={(val: never) =>
                        setValue(fields.name, val, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                    nameValue={watchName}
                    error={errors?.name?.message}
                />

                <SightlyInputForm
                    control={control}
                    error={errors?.wikiUrl?.message}
                    name={fields.wikiUrl}
                    register={register}
                    id="WikipediaLink"
                    label="Wikipedia Link"
                    disabled={disabled}
                    prefix="en.wikipedia.org/wiki/"
                />

                <SightlyInputForm
                    control={control}
                    error={errors?.twitterProfileUrl?.message}
                    name={fields.twitterProfileUrl}
                    register={register}
                    id="twitterProfileUrl"
                    label="Twitter"
                    disabled={disabled}
                    prefix="twitter.com/"
                />

                <SightlyInputForm
                    control={control}
                    error={errors?.websiteUrl?.message}
                    name={fields.websiteUrl}
                    register={register}
                    id="website"
                    label="Website"
                    disabled={disabled}
                />

                <div>
                    <SightlySelectForm
                        label="Relationship"
                        id="relationship"
                        labelKey="name"
                        valueKey="id"
                        name={fields.relationshipId}
                        options={typeInfo?.options}
                        control={control}
                        disabled={disabled}
                    />
                </div>

                <div>
                    <div className="input-label">Date</div>
                    <DatePickerField
                        disabled={disabled}
                        defaultValue={getValues().date || undefined}
                        handleSet={(val: never) => {
                            setValue(fields.date, val, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                        }}
                    />
                </div>

                {type === 'add' && formState.isDirty && (
                    <div
                        onClick={() => reset()}
                        className="sightlyLink"
                        style={{
                            position: 'absolute',
                            bottom: 24,
                            left: 24,
                            color: accentColor
                        }}
                    >
                        Clear
                    </div>
                )}
            </div>

            <style>{`
				.form-container {
					display: grid;
					gap: 24px;				
					grid-template-columns: repeat(auto-fit);			
				}		
			`}</style>
        </PortalModal>
    )
}

export default EventModal
