import React, { ReactNode } from 'react'
import useBoards, { Board } from './useBoards'
import { purpleSecondaryColor, accentColor } from '../../../assets/jss/colorContants'
import { ReactComponent as StarSelected } from '../../../assets/img/star-selected.svg'
import { ReactComponent as StarUnselected } from '../../../assets/img/star-unselected.svg'
import { ReactComponent as AddIcon } from '../../../assets/img/add_20x20.svg'
import { getCalendarDate } from '../../../utils'
import { routes } from '../../../routes'
import DeleteBoardModal from './DeleteBoardModal'
import RenameModal from './RenamingModal'
import Whisper from 'rsuite/lib/Whisper'
import Popover from 'rsuite/lib/Popover'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import Dropdown from 'rsuite/lib/Dropdown'
import Table from 'rsuite/lib/Table'
import MaxBoardsWarning from './MaxBoardsWarning'
import Sidebar from './Sidebar'
import { Panel, Tag, TagGroup } from 'rsuite'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import NoBrandProfiles from '../../../components/NoBrandProfiles'
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import useUser from '../../../services/useUser'
import { location } from '../../../RouteContainer'

let tableBody: HTMLElement

type WhisperProps = {
    children: ReactNode
    boardId: number
    handleDelete: () => void
    handleRename: () => void
    brandProfileId: number | undefined
}

const CustomWhisper = ({ children, handleDelete, handleRename, boardId, brandProfileId }: WhisperProps) => {
    let trigger: any = null
    const navigate = useNavigate<MyLocationGenerics>()
    const handleSelectMenu = (eventKey: any, event: any) => {
        trigger.hide()
    }

    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const currentAccountId = currentAccount?.accountId

    const handleOpen = () => {
        navigate({
            to: routes.app.discover.boards.board.path,
            search: old => ({
                ...old,
                boardIds: [boardId],
                brandProfileId: old?.brandProfileId,
                accountId: currentAccountId,
                timeRange: 1,
                actions: ['Target', 'Monitor', 'Block'],
                searchTerm: '',
                sortBy: 'keywords'
            })
        })
    }

    return (
        <Whisper
            placement="topEnd"
            trigger="click"
            triggerRef={ref => {
                trigger = ref
            }}
            container={() => {
                return tableBody
            }}
            speaker={
                <Popover full>
                    <Dropdown.Menu onSelect={handleSelectMenu}>
                        <Dropdown.Item onClick={handleRename}>Rename</Dropdown.Item>
                        <Dropdown.Item onClick={handleOpen}>Open</Dropdown.Item>
                        <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Popover>
            }
        >
            {children}
        </Whisper>
    )
}

const BoardsCreated = ({ n }: { n: number }) => {
    return (
        <div
            style={{
                fontSize: 12,
                fontWeight: 600,
                color: '#292929',
                textAlign: 'left'
            }}
        >
            {n}/10 Boards Created
            <div
                style={{
                    backgroundColor: '#f8f8f8',
                    borderRadius: 12,
                    width: 232,
                    height: 12,
                    marginTop: 8
                }}
            >
                <div
                    style={{
                        width: (n / 10) * 232,
                        backgroundColor: '#1BD317',
                        height: 12,
                        borderRadius: 12
                    }}
                />
            </div>
        </div>
    )
}

const Dashboard = () => {
    const navigate = useNavigate<MyLocationGenerics>()
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const { data: brandProfiles, currentBrandProfile } = useBrandProfiles({
        submittedOnly: true
    })

    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const [showDeleteAlert, setShowDeleteAlert] = React.useState(false)
    const [showRenameModal, setShowRenameModal] = React.useState(false)
    const [renamingBoardId, setRenamingBoardId] = React.useState<number | null>(null)

    const [deletingBoardId, setDeletingBoardId] = React.useState<number | null>(null)
    const { boards, handleEditStarred, boardsIsFetching, boardsFetched, handleDelete, handleRename } = useBoards()

    React.useLayoutEffect(() => {
        if (boardsFetched && boards && boards.length < 1 && !boardsIsFetching) {
            navigate({
                to: routes.app.discover.boards.welcome.path,
                search: old => ({
                    ...old
                })
            })
        }
    }, [boards, boardsFetched, boardsIsFetching])

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: search => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
    }, [currentBrandProfile, location.current.search])

    if (brandProfiles?.length === 0) {
        return <NoBrandProfiles />
    }

    return (
        <div className="gridContainer">
            <Sidebar
                handleBrandProfileChange={(brandProfileId: number) => {
                    navigate({
                        search: prev => ({
                            ...prev,
                            brandProfileId
                        })
                    })
                }}
                brandProfileId={brandProfileId}
                handleSelectBoard={() => {}}
                boards={[]}
                selectedBoards={[]}
                hideNewBoardButton={boards && boards.length > 9}
            />

            <div className="main">
                {boardsFetched && boards && boards.length > 9 ? (
                    <MaxBoardsWarning />
                ) : (
                    <>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 24,
                                lineHeight: '32px',
                                color: '#333D47',
                                marginTop: 52
                            }}
                        >
                            Welcome back {user?.firstName}!
                        </div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 20,
                                lineHeight: '32px',
                                color: '#333D47',
                                marginTop: 39
                            }}
                        >
                            All Boards
                        </div>
                    </>
                )}

                <div className="">
                    <div
                        style={{
                            marginTop: 39,
                            marginBottom: 39,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {boards && boards.length > 9 ? (
                            <div></div>
                        ) : (
                            <>
                                <AddIcon
                                    style={{ cursor: 'pointer' }}
                                    fill={purpleSecondaryColor}
                                    onClick={() =>
                                        navigate({
                                            to: routes.app.discover.boards.welcome.path,
                                            search: old => {
                                                return {
                                                    accountId: old?.accountId,
                                                    brandProfileId: old?.brandProfileId
                                                }
                                            }
                                        })
                                    }
                                />
                                <div
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 16,
                                        color: 'black',
                                        marginLeft: 21,
                                        marginRight: 21
                                    }}
                                >
                                    New Board
                                </div>
                            </>
                        )}

                        <BoardsCreated n={boards?.length ? boards.length : 0} />
                    </div>
                    {boards &&
                        boards.map((board: Board) => {
                            return (
                                <div key={board.id}>
                                    <Panel
                                        shaded
                                        bordered
                                        header={
                                            <div
                                                className="sightlyLink"
                                                onClick={() => {
                                                    navigate({
                                                        to: routes.app.discover.boards.board.path,
                                                        search: old => ({
                                                            ...old,
                                                            boardIds: [board.id],
                                                            brandProfileId: old?.brandProfileId,
                                                            accountId: old?.accountId,
                                                            timeRange: 1,
                                                            actions: ['Target', 'Monitor', 'Block'],
                                                            searchTerm: '',
                                                            sortBy: 'keywords'
                                                        })
                                                    })
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                    fontWeight: 600,
                                                    fontSize: 16,
                                                    color: 'black'
                                                }}
                                            >
                                                {board.name}
                                            </div>
                                        }
                                    >
                                        <div className="board-container">
                                            <div className="name">
                                                <div
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: 12,
                                                        color: '#8F8F8F',
                                                        marginTop: 8
                                                    }}
                                                >
                                                    {board.updatedByName && (
                                                        <div>
                                                            Modified by {board.updatedByName},{' '}
                                                            {getCalendarDate(board.updatedDate)}
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: 12,
                                                        color: '#8F8F8F',
                                                        marginTop: 8
                                                    }}
                                                >
                                                    {board.createdByName && <div>Created by {board.createdByName}</div>}
                                                </div>
                                            </div>
                                            <div className="categories">
                                                <Panel>
                                                    <div
                                                        style={{
                                                            fontWeight: 500,
                                                            fontSize: 12,
                                                            color: '#8F8F8F',
                                                            marginTop: 8
                                                        }}
                                                    >
                                                        Categories
                                                    </div>
                                                    <TagGroup>
                                                        {board.selectedItems.map(item => (
                                                            <Tag key={item}>{item}</Tag>
                                                        ))}
                                                    </TagGroup>
                                                </Panel>
                                            </div>
                                            <div className="starred">
                                                {board.starred ? (
                                                    <StarSelected
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() =>
                                                            handleEditStarred({
                                                                id: board.id,
                                                                starred: false
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    <StarUnselected
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() =>
                                                            handleEditStarred({
                                                                id: board.id,
                                                                starred: true
                                                            })
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="more">
                                                <CustomWhisper
                                                    handleRename={() => {
                                                        setShowRenameModal(true)
                                                        setRenamingBoardId(board?.id)
                                                    }}
                                                    handleDelete={() => {
                                                        setShowDeleteAlert(true)
                                                        setDeletingBoardId(board?.id)
                                                    }}
                                                    boardId={board?.id}
                                                    brandProfileId={brandProfileId}
                                                >
                                                    <IconButton
                                                        size="lg"
                                                        appearance="default"
                                                        icon={<Icon icon="more" color={accentColor} />}
                                                    />
                                                </CustomWhisper>
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            )
                        })}
                </div>

                <DeleteBoardModal
                    handleCancel={() => setShowDeleteAlert(false)}
                    handleClose={() => setShowDeleteAlert(false)}
                    handleDelete={() => {
                        if (!deletingBoardId) return
                        handleDelete(deletingBoardId)
                        setShowDeleteAlert(false)
                    }}
                    open={showDeleteAlert}
                />

                <RenameModal
                    key={renamingBoardId}
                    handleCancel={() => setShowRenameModal(false)}
                    handleClose={() => setShowRenameModal(false)}
                    handleRename={(val: string) => {
                        if (!renamingBoardId) return
                        handleRename({ id: renamingBoardId, name: val })
                        setShowRenameModal(false)
                    }}
                    open={showRenameModal}
                    name={boards?.filter(b => b.id === renamingBoardId)[0]?.name || ''}
                />
            </div>
            <style>{`
				.gridContainer {
					display: grid;
					height: 100%;	
					grid-template-columns: 265px 1fr;	
					grid-template-rows:  1fr 64px;
					grid-template-areas:
						"sidebar main"
						"sidebar main"		
				}		
				.main {
					grid-area: main;
					padding: 25px 10%;
					overflow-y: auto;
				}  
                .panel {      					
                    background-color: #F8F8F8;
                    border-radius: 6;
                    margin-top: 7;             
                }

                .board-container {
					display: grid;
                    align-items:center;
					height: 100%;	
                    grid-auto-flow: row;	
					grid-template-columns: 30% 60% 5% 5%;	
					grid-template-rows:  1fr;
					grid-template-areas:
						"name categories starred more"		
				}		
				.name {
					grid-area: name;
					padding: 12px;				
				}  
                .categories {      					
                   grid-area: categories  
                   padding: 12px;	
                }    
                .starred {
                    grid-area: starred  
                    padding: 12px;	
                }
                .more {
                    grid-area: more  
                    padding: 12px;	
                }
      `}</style>
        </div>
    )
}

export default Dashboard
