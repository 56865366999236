import { SET_TRENDS_IS_LOADING } from '../../action-types/discover/trends'

export function trendsIsLoading(state = false, action) {
    switch (action.type) {
        case SET_TRENDS_IS_LOADING:
            return action.trendsIsLoading
        default:
            return state
    }
}
