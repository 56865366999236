import React from 'react'
import Container from 'rsuite/lib/Container'
import useWindowDimensions from '../useWindowDimensions'
import HorizontalBar from '../components/Navbars/Horizontal/Navbar.js'
import VerticalBar from '../components/Navbars/Vertical/Navbar'

export function Layout({ children }) {
    const windowDims = useWindowDimensions()
    return (
        <div id="layoutContainer" style={{ display: 'flex', flexDirection: 'row', flex: 'auto' }}>
            <VerticalBar />

            <Container>
                <HorizontalBar />

                <div
                    style={{
                        flex: '1 1 auto',
                        height: windowDims.height - 56,
                        overflowY: 'auto',
                        overflowX: 'auto'
                    }}
                >
                    {children}
                </div>
            </Container>
        </div>
    )
}

export default Layout
