import React from 'react'
import Button from 'rsuite/lib/Button'
import ButtonToolbar from 'rsuite/lib/ButtonToolbar'
import Table from 'rsuite/lib/Table'
import {
    fetchAdminBrandScenarios,
    fetchAdminBrandScenarioLabels,
    updateScenario
} from '../../../redux/actions/admin/scenarios'
import { connect } from 'react-redux'
import Loader from 'rsuite/lib/Loader'
import { routes } from '../../../routes'
import InputPicker from 'rsuite/lib/InputPicker'
import { archivedStatuses } from '../../Engage/Lists/constants.js'
import Icon from 'rsuite/lib/Icon'
import IconButton from 'rsuite/lib/IconButton'
import { useMatch, useNavigate } from '@tanstack/react-location'

const mapStateToProps = state => {
    return {
        scenariosIsLoading: state.admin.scenariosIsLoading,
        adminScenarios: state.admin.scenarios,
        scenarioLabels: state.admin.scenarioLabels
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAdminBrandScenarios: () => dispatch(fetchAdminBrandScenarios()),
        fetchAdminBrandScenarioLabels: () => dispatch(fetchAdminBrandScenarioLabels()),
        updateScenario: (scenarioId, scenario) => dispatch(updateScenario(scenarioId, scenario))
    }
}

function Scenarios(props) {
    const navigate = useNavigate()
    const [archiveFilter, setArchiveFilter] = React.useState(null)
    const { fetchAdminBrandScenarios, fetchAdminBrandScenarioLabels, adminScenarios } = props
    let [scenariosFetched, setScenariosFetched] = React.useState(false)
    React.useEffect(() => {
        if (!scenariosFetched) {
            fetchAdminBrandScenarios()
            fetchAdminBrandScenarioLabels()
            setScenariosFetched(true)
        }
    }, [fetchAdminBrandScenarios, fetchAdminBrandScenarioLabels, scenariosFetched, setScenariosFetched])

    const handleCreateScenarioClick = () => {
        let url = routes.admin.scenarios.create.path
        navigate({ to: url })
    }

    const handleConfigureLabelsClick = () => {
        let url = routes.admin.scenarios.labels.path
        navigate({ to: url })
    }

    const handleConfigureTypesClick = () => {
        let url = routes.admin.scenarios.types.path
        navigate({ to: url })
    }

    const filteredScenarios = React.useMemo(() => {
        if (archiveFilter === null) {
            return adminScenarios
        }
        if (archiveFilter === 1) {
            return adminScenarios.filter(s => s.archived)
        }
        if (archiveFilter === 2) {
            return adminScenarios.filter(s => !s.archived)
        }

        return adminScenarios
    }, [adminScenarios, archiveFilter])

    return (
        <div style={{ padding: 24 }}>
            {adminScenarios && adminScenarios.length > 0 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 'auto',
                        gap: 24
                    }}
                >
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleCreateScenarioClick}>
                            Create Scenario
                        </Button>
                        <Button appearance="primary" onClick={handleConfigureLabelsClick}>
                            Configure Labels
                        </Button>
                        <Button appearance="primary" onClick={handleConfigureTypesClick}>
                            Configure Scenario Types
                        </Button>
                    </ButtonToolbar>

                    <InputPicker
                        size={'sm'}
                        id="archivedStatusId"
                        label="Archived"
                        placeholder="Archived Status"
                        labelKey="archivedStatusName"
                        valueKey="archivedStatusId"
                        data={archivedStatuses}
                        onChange={val => {
                            setArchiveFilter(val)
                        }}
                        style={{ width: 200 }}
                    />

                    <Table virtualized autoHeight data={filteredScenarios || []}>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>Scenario Name</Table.HeaderCell>
                            <Table.Cell dataKey="scenarioName" />
                        </Table.Column>
                        <Table.Column>
                            <Table.HeaderCell>Archived</Table.HeaderCell>
                            <Table.Cell>
                                {scenario => {
                                    return <div>{scenario.archived ? 'True' : 'False'}</div>
                                }}
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column width={200} fixed>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.Cell>
                                {scenario => {
                                    return (
                                        <IconButton
                                            onClick={() =>
                                                navigate({
                                                    to: routes.admin.scenarios.path + '/edit/' + scenario.scenarioId
                                                })
                                            }
                                            icon={<Icon icon="edit" />}
                                            appearance="ghost"
                                            size="xs"
                                            id="editScenarioButton"
                                        >
                                            Click to Edit
                                        </IconButton>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            ) : props.scenariosIsLoading ? (
                <Loader center vertical />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                        height: 'calc(100vh - 200px)',
                        color: 'white'
                    }}
                >
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleCreateScenarioClick}>
                            Create Scenario
                        </Button>
                        <Button appearance="primary" onClick={handleConfigureLabelsClick}>
                            Configure Labels
                        </Button>
                        <Button appearance="primary" onClick={handleConfigureTypesClick}>
                            Configure Scenario Types
                        </Button>
                    </ButtonToolbar>
                </div>
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenarios)
