/*eslint-disable no-restricted-globals*/
import React from 'react'
import AccountDropdown from '../../components/AccountDropdown'
import Loader from 'rsuite/lib/Loader'
import * as Yup from 'yup'
import { accountTypes } from '../../staticData/data'
import SightlyButton from '../../components/SightlyButton'
import SightlyInputForm from '../../components/Sightly/SightlyInputForm'
import SightlySelect from '../../components/Sightly/SightlySelect'
import { Account } from '../../classes/account'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SightlySelectForm from '../../components/Sightly/SightlySelectForm'
import Input from 'rsuite/lib/Input'
import { permissionView } from '../../utils'
import PortalModal from '../../components/PortalModal/PortalModal'
import { useMatch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../classes/utils/utils'
import { api } from '../../api/api'
import useUser from '../../services/useUser'

const schemaValidation = Yup.object().shape({
    accountName: Yup.string()
        .required('Required')
        .min(2, 'Must be greater than 1 character')
        .max(50, 'Must be less than 50 characters'),
    contactName: Yup.string()
        .required('Required')
        .min(2, 'Must be greater than 1 character')
        .max(50, 'Must be less than 50 characters'),
    contactEmail: Yup.string()
        .required('Required')
        .email('Invalid email'),
    accountTypeId: Yup.number().required()
})

function AccountComponent() {
    const { accounts, deleteAccount, createAccount, updateAccount, user, currentAccount, perms, userCan } = useUser()

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<Account>({
        defaultValues: currentAccount,
        resolver: yupResolver(schemaValidation)
    })

    React.useEffect(() => {
        reset(currentAccount)
    }, [currentAccount])

    const onSubmit = async (account: Account) => {
        updateAccount(account)
    }

    const [open, setOpen] = React.useState(false)
    const [name, setName] = React.useState('')
    const [accountType, setAccountType] = React.useState(accountTypes[0].accountTypeId)

    const handleCreateChild = async (current: Account) => {
        setOpen(false)

        let levelId = current.accountLevelId + 1
        if (levelId > 3) {
            levelId = 3
        }
        let childAccount = {
            accountName: name,
            accountTypeId: accountType,
            accountLevelId: levelId,
            accountMargin: 0,
            contactName: 'placeholder',
            contactEmail: 'placeholder@placeholder.com',
            parentAccountId: current.accountId
        }
        createAccount(childAccount)
    }

    const handleDeleteAccount = async (current: Account) => {
        if (current.accountName === 'Sightly') {
            alert('You cannot delete the top level Sightly account')
            return
        }
        if (confirm('Are you sure you want to delete this account?')) {
            deleteAccount(current.accountId)
        }
    }

    let current = currentAccount

    let accountEditDisabled = React.useMemo(() => {
        if (!accounts || accounts.length < 1 || !current) {
            return true
        }

        let topLevelAccounts = accounts.map((account: Account) => account.accountId)
        if (
            (topLevelAccounts.includes(current.accountId) && !userCan(perms.ASSIGNED_ACCOUNT_UPDATE)) ||
            !userCan(perms.ACCOUNT_UPDATE) ||
            current.accountId === 1
        ) {
            return true
        } else {
            return false
        }
    }, [current, accounts])

    if (current === undefined) {
        return <Loader center vertical />
    }
    return (
        <div className="gridContainer">
            <AccountDropdown searchable={true} />
            <PortalModal
                open={open}
                handleClose={() => setOpen(false)}
                footerButtons={[
                    <SightlyButton
                        id="cancelCreateAccountSubmit"
                        text="Cancel"
                        type="secondary"
                        handleClick={() => setOpen(false)}
                    />,
                    <SightlyButton
                        id="createAccountSubmit"
                        text="Submit"
                        disabled={name.length < 2}
                        handleClick={() => (current ? handleCreateChild(current) : undefined)}
                    />
                ]}
            >
                <div>
                    <h4>Create a new Account under {current.accountName}</h4>

                    <div style={{ padding: 30, width: 400 }}>
                        <div className="input-label">Account Name</div>
                        <Input id="accountNameInput" onChange={(e: string) => setName(e)} value={name} />
                        <div style={{ height: 24 }} />
                        <SightlySelect
                            label="Account Type"
                            id="accountTypeInput"
                            labelKey="accountTypeName"
                            valueKey="accountTypeId"
                            placeholder="Account Type"
                            width={340}
                            options={accountTypes}
                            onChange={(id: number) => setAccountType(id)}
                            value={accountType}
                        />
                    </div>
                </div>
            </PortalModal>
            {userCan(perms.ACCOUNT_CREATE) && (
                <>
                    <SightlyButton
                        id="createChildAccountShowModalButton"
                        handleClick={() => setOpen(true)}
                        text="Create Child Account"
                    />
                    {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_CREATE}</div>}
                </>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="gridContainer">
                    <SightlyInputForm
                        error={errors?.accountName?.message}
                        name="accountName"
                        register={register}
                        id="accountName"
                        label="Account Name"
                        disabled={accountEditDisabled}
                        control={control}
                    />

                    <SightlyInputForm
                        error={errors?.parentAccountName?.message}
                        name="parentAccountName"
                        register={register}
                        id="parentAccountName"
                        label="Parent Account Name"
                        disabled
                        placeholder="-"
                        control={control}
                    />

                    <SightlyInputForm
                        error={errors?.contactName?.message}
                        name="contactName"
                        register={register}
                        id="contactName"
                        label="Contact Name"
                        disabled={accountEditDisabled}
                        control={control}
                    />

                    <SightlyInputForm
                        error={errors?.contactEmail?.message}
                        name="contactEmail"
                        register={register}
                        id="contactEmail"
                        label="Contact Email"
                        disabled={accountEditDisabled}
                        control={control}
                    />

                    <SightlySelectForm
                        label="Account Type"
                        id="eventRelationship"
                        labelKey="accountTypeName"
                        valueKey="accountTypeId"
                        name={'accountTypeId'}
                        options={accountTypes}
                        control={control}
                        disabled={accountEditDisabled}
                    />
                </div>
            </form>

            <div style={{ display: 'flex' }}>
                {current.accountName === 'Sightly' ||
                current.accountId === 1 ||
                (current.children && current.children.length > 0) ? null : (
                    <>
                        {userCan(perms.ACCOUNT_DELETE) && (
                            <>
                                <SightlyButton
                                    id="deleteAccountButton"
                                    text="Delete"
                                    type="cancel"
                                    handleClick={() => current && handleDeleteAccount(current)}
                                />
                                {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_DELETE}</div>}
                            </>
                        )}
                    </>
                )}

                <div style={{ width: 8 }} />

                {userCan(perms.ACCOUNT_UPDATE) && (
                    <>
                        <SightlyButton id="submitButton" handleClick={() => handleSubmit(onSubmit)()} text="Submit" />
                        {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_UPDATE}</div>}
                    </>
                )}
            </div>

            <style>{`
				.gridContainer {
					display: grid;
					grid-template-columns: repeat(1, 500px);
					grid-row-gap: 16px;
					justify-content: center;
				
				}		
			`}</style>
        </div>
    )
}

export default AccountComponent
