import React from 'react'
import { patchBrandProfileKeywords } from '../../../../../redux/actions/brandProfiles'
import { connect } from 'react-redux'
import Panel from 'rsuite/lib/Panel'
import Divider from 'rsuite/lib/Divider'
import ControlLabel from 'rsuite/lib/ControlLabel'
import CreatableTagPicker from '../../../../../components/CreatableTagPicker'
import useBrandProfileKeywords from '../../../../../services/brandProfile/useBrandProfileKeywords'
import { useMatch, useSearch } from '@tanstack/react-location'
import useUser from '../../../../../services/useUser'

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        patchBrandProfileKeywords: o => dispatch(patchBrandProfileKeywords(o))
    }
}

function Keywords(props) {
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    const {
        params: { brandProfileId }
    } = useMatch()
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const { data: keywords, isFetched } = useBrandProfileKeywords(brandProfileId)

    const [compKeywordsTarget, setCompKeywordsTarget] = React.useState([])
    const [compKeywordsBlock, setCompKeywordsBlock] = React.useState([])
    React.useEffect(() => {
        if (!isFetched) {
            return
        }
        setCompKeywordsTarget(keywords?.filter(k => k.action === 'target'))
        setCompKeywordsBlock(keywords?.filter(k => k.action === 'block'))
    }, [keywords])

    const handleChange = ({ newKeywords, action }) => {
        if (!keywords) {
            return
        }

        if (action === 'target') {
            const newFormatted = newKeywords.map(k => {
                return { action: 'target', keyword: k }
            })

            props.patchBrandProfileKeywords({
                keywords: compKeywordsBlock.concat(newFormatted),
                brandProfileId
            })

            setCompKeywordsTarget(newFormatted)
        }

        if (action === 'block') {
            const newFormattedB = newKeywords.map(k => {
                return { action: 'block', keyword: k }
            })

            props.patchBrandProfileKeywords({
                keywords: compKeywordsTarget.concat(newFormattedB),
                brandProfileId
            })

            setCompKeywordsBlock(newFormattedB)
        }
    }

    const items = React.useMemo(() => {
        let _targeted = []
        let _blocked = []

        if (keywords) {
            keywords.forEach(kw => {
                if (kw.action === 'target') {
                    _targeted.push(kw.keyword)
                }
                if (kw.action === 'block') {
                    _blocked.push(kw.keyword)
                }
            })
        }

        return {
            targeted: _targeted,
            blocked: _blocked
        }
    }, [keywords])

    return (
        <Panel header={<div className="sightlyPanelHeader">Keywords</div>}>
            <ControlLabel>Keywords to target</ControlLabel>
            <CreatableTagPicker
                disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                backgroundColor="#4CAF50"
                textColor="white"
                items={items.targeted}
                saveChanges={arr =>
                    handleChange({
                        newKeywords: arr,
                        action: 'target'
                    })
                }
            />
            <Divider />

            <ControlLabel>Keywords to block</ControlLabel>
            <CreatableTagPicker
                disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                backgroundColor="#FF0000"
                textColor="white"
                items={items.blocked}
                saveChanges={arr =>
                    handleChange({
                        newKeywords: arr,
                        action: 'block'
                    })
                }
            />
        </Panel>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Keywords)
