import React from 'react'
import SightlyButton from '../../../../components/SightlyButton'

const NavButton = ({ text, handleClick }: { text: string; handleClick: Function }) => {
    return (
        <div
            className="unselectable"
            onClick={() => handleClick()}
            style={{
                cursor: 'pointer',
                width: 96,
                height: 40,
                textAlign: 'center',
                backgroundColor: 'white',
                border: '1px solid #D4D9D9',
                boxSizing: 'border-box',
                borderRadius: 3,
                fontWeight: 600,
                fontSize: 14,
                lineHeight: '22px',
                paddingTop: 9,
                marginRight: 8,
                color: '#696969'
            }}
        >
            {text}
        </div>
    )
}

interface ModalFooterProps {
    active: any
    handleFooterNav: any
    trendScenarios: any
    hasUnsavedChanges: any
    patchingScenarios: any
    handlePatchTrendScenarios: any
}

const ModalFooter = ({
    active,
    handleFooterNav,
    trendScenarios,
    hasUnsavedChanges,
    patchingScenarios,
    handlePatchTrendScenarios
}: ModalFooterProps) => {
    return (
        <div
            style={{
                display: 'flex',
                paddingTop: 12,
                paddingLeft: 24,
                paddingRight: 24,
                background: 'rgba(255, 255, 255, 0.88)',
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                position: 'absolute',
                height: 64,
                left: 0,
                right: 0,
                bottom: 0,
                boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.1)'
            }}
        >
            <div style={{ display: 'flex', width: '100%' }}>
                <NavButton text="Prev" handleClick={() => handleFooterNav('prev')} />
                <NavButton text="Next" handleClick={() => handleFooterNav('next')} />
            </div>
            {active === '0' && (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'right'
                    }}
                >
                    {trendScenarios.length < 1 && hasUnsavedChanges && (
                        <div className="sightly-informational-font">
                            Please add at least one Scenario to save your changes
                        </div>
                    )}

                    <SightlyButton
                        disabled={trendScenarios.length < 1 || !hasUnsavedChanges}
                        loading={patchingScenarios}
                        id="confirmEditButton"
                        handleClick={() => handlePatchTrendScenarios()}
                        text="Update Moment"
                    />
                </div>
            )}
        </div>
    )
}

export default ModalFooter
