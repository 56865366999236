import React from 'react'
import useWindowDimensions from '../../useWindowDimensions'
import useEmbedUrls from '../../services/useEmbedUrls'
import { useMatch } from '@tanstack/react-location'
import useUser from '../../services/useUser'

const Measure = () => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const currentAccountId = currentAccount.accountId
    const { data: embedUrls } = useEmbedUrls(currentAccountId)

    const dims = useWindowDimensions()

    return (
        <div style={{ padding: 24 }}>
            <h4 style={{ marginBottom: 24 }}>Overview</h4>
            <iframe
                style={{
                    border: 0,
                    width: '100%',
                    height: dims.height - 56 - 48 - 24 - 24 - 24
                }}
                src={embedUrls?.performanceDashboardEmbedUrl}
                title={'Overview'}
            >
                <p>Your browser does not support iframes.</p>
            </iframe>
        </div>
    )
}

export default Measure
