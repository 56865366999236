import React from 'react'
import { connect } from 'react-redux'
import { fetchTargetviewInsertionOrders } from '../../../../../redux/actions/ThirdParty/targetview'
import {
    fetchBrandProfileTargetviewInsertionOrders,
    patchBrandProfileTargetviewInsertionOrders
} from '../../../../../redux/actions/brandProfiles'
import Panel from 'rsuite/lib/Panel'
import SightlyCheckbox from '../../../../../components/SightlyCheckbox'
import SightlySearchBar from '../../../../../components/SightlySearchBar'
import debounce from 'just-debounce-it'
import Tag from 'rsuite/lib/Tag'
import TagGroup from 'rsuite/lib/TagGroup'
import { brandPinkColor } from '../../../../../assets/jss/colorContants'
import Divider from 'rsuite/lib/Divider'
import Loader from 'rsuite/lib/Loader'
import { useMatch, useSearch } from '@tanstack/react-location'

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit,
        targetviewInsertionOrders: state.thirdParty.targetviewInsertionOrders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTargetviewInsertionOrders: () => dispatch(fetchTargetviewInsertionOrders()),
        fetchBrandProfileTargetviewInsertionOrders: brandProfileId =>
            dispatch(fetchBrandProfileTargetviewInsertionOrders(brandProfileId)),

        patchBrandProfileTargetviewInsertionOrders: o => dispatch(patchBrandProfileTargetviewInsertionOrders(o))
    }
}

const RowCat = props => {
    //	const dispatch = useDispatch()
    let io = props.io

    return (
        <tr className="catRow">
            <td className="catCell" style={{ width: 400 }}>
                <SightlyCheckbox
                    disabled={props.viewOnly}
                    defaultChecked={props?.selectedIos?.includes(io.InsertionOrderId)}
                    handleChange={checked => {
                        setTimeout(() => {
                            props.handleChange(io.InsertionOrderId, checked)
                        }, 100)
                    }}
                />
            </td>

            <td className="catCell" style={{ width: 300 }}>
                {io.InsertionOrderId}
            </td>
            <td className="catCell">{io.OrderName}</td>
            <td className="catCell">{io.CampaignType}</td>
            <td className="catCell">{io.AdvertiserName}</td>
            <td className="catCell">{io.AccountName}</td>
        </tr>
    )
}

function Targetview(props) {
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false

    const [fetched, setFetched] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState('')

    const { targetviewInsertionOrders, brandProfile } = props
    const _ios = React.useMemo(() => {
        if (!targetviewInsertionOrders) {
            return []
        }
        const _searchTerm = String(searchTerm).toLowerCase()
        return targetviewInsertionOrders.filter(
            io =>
                io?.AccountName?.toLowerCase().includes(_searchTerm) ||
                String(io.InsertionOrderId)
                    .toLowerCase()
                    .includes(_searchTerm) ||
                io?.OrderName.toLowerCase().includes(_searchTerm) ||
                io?.CampaignType.toLowerCase().includes(_searchTerm) ||
                io?.AdvertiserName.toLowerCase().includes(_searchTerm)
        )
    }, [targetviewInsertionOrders, searchTerm])

    const [selectedIos, setSelectedIos] = React.useState([])
    React.useEffect(() => {
        if (!fetched) {
            props.fetchTargetviewInsertionOrders()
            props.fetchBrandProfileTargetviewInsertionOrders(brandProfileId)
            setFetched(true)
        }
    }, [])

    React.useEffect(() => {
        setSelectedIos(brandProfile.ios)
    }, [brandProfile])

    React.useEffect(() => {
        return () => {
            setFetched(false)
            setSelectedIos([])
        }
    }, [])

    const handleSearch = debounce(val => {
        setSearchTerm(val)
    }, 700)

    const handleChange = (ioId, checked) => {
        if (checked && selectedIos.includes(ioId)) {
            return
        }

        let finalIos = []
        if (checked) {
            finalIos = [...selectedIos, ioId]
        } else {
            finalIos = [...selectedIos].filter(_io => _io !== ioId)
        }

        setSelectedIos(finalIos)

        const args = {
            brandProfileId: brandProfileId,
            ios: finalIos
        }
        props.patchBrandProfileTargetviewInsertionOrders(args)
    }

    const tagIos = React.useMemo(() => {
        if (!selectedIos) {
            return []
        }
        if (!targetviewInsertionOrders) {
            return []
        }
        return (
            targetviewInsertionOrders &&
            targetviewInsertionOrders.filter(io => selectedIos.includes(io.InsertionOrderId))
        )
    }, [selectedIos, targetviewInsertionOrders])

    if (!targetviewInsertionOrders) {
        return <Loader center vertical />
    }

    return (
        <Panel header={<div className="sightlyPanelHeader">Linked Targetview Insertion Orders</div>}>
            <TagGroup
                style={{
                    marginTop: 16,
                    marginBottom: 16
                }}
                id={'tagGroup'}
            >
                {tagIos &&
                    tagIos.map((item, index) => (
                        <Tag
                            style={{
                                backgroundColor: brandPinkColor,
                                color: 'white'
                            }}
                            key={index}
                            closable={!viewOnly}
                            onClose={() => {
                                handleChange(item.InsertionOrderId, false)
                            }}
                        >
                            {item.InsertionOrderId} - {item.OrderName}
                        </Tag>
                    ))}
            </TagGroup>

            <Divider />

            <SightlySearchBar placeholder="Search any field..." handleChange={handleSearch} />

            <div
                style={{
                    marginTop: 24
                }}
            >
                <table>
                    <thead>
                        <tr>
                            <th className="catHeader">Linked</th>
                            <th className="catHeader">IO ID</th>
                            <th className="catHeader">Order Name</th>
                            <th className="catHeader">Campaign Type</th>
                            <th className="catHeader">Advertiser Name</th>
                            <th className="catHeader">Account Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_ios &&
                            _ios.map((io, index) => {
                                return (
                                    <RowCat
                                        io={io}
                                        key={index}
                                        handleChange={handleChange}
                                        selectedIos={selectedIos}
                                        brandProfile={props.brandProfile}
                                        viewOnly={viewOnly}
                                    />
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </Panel>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Targetview)
