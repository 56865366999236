import React, { ReactNode } from 'react'
import ReactDom from 'react-dom'
import ClickAwayListener from 'react-click-away-listener'
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'

type IProps = {
	open: boolean
	children: ReactNode
	footerButtons: ReactNode[]
	handleClose: () => void
	hasClose?: boolean
	cannotClose?: boolean
	centerFooterButtons?: boolean
}

const PortalModal = ({
	open,
	children,
	footerButtons,
	handleClose,
	hasClose,
	cannotClose,
	centerFooterButtons
}: IProps) => {
	if (!open) {
		return null
	}
	const modalRoot = document.getElementById('modalPortal') as HTMLElement

	return ReactDom.createPortal(
		<div className='wrapper'>
			<ClickAwayListener onClickAway={() => {
				if (cannotClose) return
				handleClose()
			}}>
				<div className='grid-container'>
					{hasClose && (
						<CloseIcon
							data-testid='closeButton'
							id='closeButton'
							onClick={handleClose}
							style={{
								cursor: 'pointer',
								position: 'absolute',
								right: 24,
								top: 24
							}}
						/>
					)}
					<div className='main-portal'>{children}</div>
					<div className='footer-portal'>
						{footerButtons.map((MyButton, index) => {
							return <div key={index}>{MyButton}</div>
						})}
					</div>
				</div>
			</ClickAwayListener>
			<style>{` 
          .wrapper {
            position: fixed;
				    bottom: 0;
				    left: 0;
				    right: 0;
            top: 0;
            background-color: rgb(0, 0, 0, .7);
            z-index: 1000;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .grid-container {
						position: relative;
            grid-template-rows: 1fr 64px;
            background-color: white;
            border-radius: 12px;
            grid-template-areas:
              "main"
              "footer"		         
          }
          .main-portal {
            grid-area: main;
            padding: 24px;
          }
          .footer-portal {
            grid-area: footer;
            height: 64px;
						display: flex;
						gap: 8px;
						width: 100%;
            justify-content: ${centerFooterButtons ? 'center' : 'flex-end'};
            align-items: center;
            padding-right:  ${centerFooterButtons ? '0px' : '24px'};
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        `}</style>
		</div>,
		modalRoot
	)
}

export default PortalModal
