import {
	SET_ADMIN_IAB_CATEGORIES,
	SET_ADMIN_IAB_CATEGORIES_LOADING
} from '../../action-types/admin/iabCategories'

export function adminIabCategories(state = [], action) {
	switch (action.type) {
		case SET_ADMIN_IAB_CATEGORIES:
			return action.adminIabCategories
		default:
			return state
	}
}

export function adminIabCategoriesLoading(state = false, action) {
	switch (action.type) {
		case SET_ADMIN_IAB_CATEGORIES_LOADING:
			return action.adminIabCategoriesLoading
		default:
			return state
	}
}
