import React from 'react'
import { joinClasses } from '../utils'

export const SightlyDropdownItem = ({
	value,
	label,
	handleClick,
	isSelected,
	backgroundColor,
	minWidth,
	disabled,
	id
}) => {
	const _backgroundColor = React.useMemo(() => {
		if (isSelected) return '#c0ecfe'
		if (backgroundColor) return backgroundColor
		return undefined
	}, [isSelected])
	return (
		<div
			id={id}
			className={'sightlyDropdownItem'}
			key={value}
			onClick={() => (disabled ? null : handleClick())}
			style={{
				minWidth: minWidth,
				backgroundColor: _backgroundColor,
				color: '#333D47',
				zIndex: 999,
				height: 40,
				borderRadius: 3,
				cursor: disabled ? 'not-allowed' : 'pointer',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				boxShadow: '0px 0px 0px 1px white inset'
			}}
		>
			<div
				className={joinClasses('unselectable', 'maxOneLine')}
				style={{
					fontWeight: 600,
					paddingLeft: 16
				}}
			>
				{label}
			</div>
		</div>
	)
}
