import React, { useState } from 'react'
import { connect } from 'react-redux'
import { login } from '../redux/actions/auth.js'
import svgLogo from '../assets/img/sightly-logo.svg'
import Form from 'rsuite/lib/Form'
import FormGroup from 'rsuite/lib/FormGroup'
import ControlLabel from 'rsuite/lib/ControlLabel'
import SightlyButton from '../components/SightlyButton'
import Input from 'rsuite/lib/Input'
import toast from 'react-hot-toast'
import { Link, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../classes/utils/utils.js'

const mapStateToProps = (state: any) => {
    return {
        authToken: state.authToken,
        isLoggedIn: state.isLoggedIn,
        alert: state.alert,
        loggingIn: state.loggingIn
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        login: (credentials: any) => dispatch(login(credentials))
    }
}

function Login(props: any) {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')

    const postLogin = () => {
        if (userName.length < 1 || password.length < 1) {
            toast.error('Email and password must be entered. Please try again.')
            return
        }
        let credentials = {
            username: userName,
            password: password
        }
        props.login(credentials)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            postLogin()
        }
    }

    const { redirect } = useSearch<MyLocationGenerics>()
    if (props.isLoggedIn) {
        const toUrl = redirect || '/app/settings/account'
        window.location.href = toUrl
    }

    return (
        <div style={{ width: 400, margin: 'auto' }}>
            <Form>
                <FormGroup style={{ textAlign: 'center' }}>
                    <img
                        src={svgLogo}
                        alt="logo"
                        style={{
                            height: '130px',
                            width: '130px',
                            verticalAlign: 'middle',
                            border: '0',
                            margin: '90px 0px 60px 0px'
                        }}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <Input
                        id="email-address"
                        type="email"
                        value={userName}
                        onChange={e => setUserName(e)}
                        onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Password</ControlLabel>
                    <Input
                        //		labelText='Password'

                        id="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e)}
                        onKeyPress={(e: any) => handleKeyPress(e)}
                        autoComplete="current-password"
                        //	handleClear={() => setPassword('')}
                    />
                </FormGroup>

                <FormGroup>
                    <SightlyButton
                        id="signInButton"
                        block
                        text="Sign In"
                        handleClick={postLogin}
                        disabled={props.loggingIn || props.isLoggedIn}
                        loading={props.loggingIn || props.isLoggedIn}
                    />
                </FormGroup>

                <FormGroup>
                    <Link to="/resetPassword">Forgot password</Link>
                </FormGroup>
            </Form>
        </div>
    )
}

const MyLogin = connect(mapStateToProps, mapDispatchToProps)(Login)

export default MyLogin
