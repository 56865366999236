import React from 'react'
import debounce from 'just-debounce-it'
import { userAccountAxios } from '../../../../../axiosInstances'
import toast from 'react-hot-toast'

export type AylienItem = {
	id: number
	hasChildren: boolean
	children?: AylienItem[]
	name: string
	responseId: 1 | 2 | null
	taxonomyCode: string
	taxonomyLevel: number
}

export enum aylienTypes {
	'news' = 'news',
	'industries' = 'industries'
}

export type HandleActionClickProps = {
	id: number
	responseId: 1 | 2 | null
}

type apiProps = {
	brandProfileId: number
	aylienType: aylienTypes
	parentId?: number
	searchTerm?: string | null
	filter?: string[]
	sort: boolean
}

const getBpAylienItems = async (args: apiProps) => {
	if (typeof args.searchTerm === 'string') {
		args.parentId = undefined
	}
	const url = `/brand-profile/${args.brandProfileId}/aylien-${args.aylienType}`
	const { data } = await userAccountAxios.post<AylienItem[]>(url, {
		parentId: args.parentId,
		searchTerm: args.searchTerm,
		filter: args.filter,
		sort: args.sort ? 'asc' : 'desc'
	})

	return data
}

interface PatchProps {
	id: number
	responseId: 1 | 2 | null
	aylienType: aylienTypes
	brandProfileId: number
}

const patchBpAylienItems = async (args: PatchProps) => {
	const payload = [{ id: args.id, responseId: args.responseId }]

	const url = `/brand-profile/${args.brandProfileId}/aylien-${args.aylienType}`
	await userAccountAxios.patch(url, payload)
	toast.success('Changes saved')
}

export default function useAylien(
	brandProfileId: number | undefined,
	aylienType: aylienTypes
) {
	const [items, setItems] = React.useState<AylienItem[]>([])
	const [filter, setFilter] = React.useState<string[]>([])
	const [searchTerm, setSearchTerm] = React.useState('')
	const [sort, setSort] = React.useState(true)

	React.useEffect(() => {
		if (!brandProfileId) {
			return
		}
		const proc = async () => {
			const args = {
				brandProfileId,
				aylienType,
				filter,
				sort,
				searchTerm
			}
			const n = await getBpAylienItems(args)
			formatChildren(n)
			setItems(n)
		}

		proc()
	}, [brandProfileId, aylienType, sort, filter])

	const formatChildren = (items: AylienItem[]) => {
		for (const item of items) {
			if (item.hasChildren && !item.children) {
				item.children = []
			}
		}
	}

	const appendChildren = (
		tree: AylienItem[],
		children: AylienItem[],
		id: number
	) => {
		for (const item of tree) {
			if (item.id === id) {
				item.children = children
				return
			}
			if (item.children && item.children.length > 0) {
				appendChildren(item.children, children, id)
			}
		}
	}

	const populateChildren = async (n: AylienItem) => {
		if (!brandProfileId) {
			return
		}

		const args = {
			brandProfileId,
			aylienType,
			parentId: n.id,
			sort
		}

		const data = await getBpAylienItems(args)
		formatChildren(data)
		const myPrev = JSON.parse(JSON.stringify(items))
		appendChildren(myPrev, data, n.id)
		setItems(myPrev)
	}

	const handleSearch = debounce((text: string) => {
		handleSearchExecute(text)
	}, 300)

	const handleSearchExecute = async (text: string) => {
		if (!brandProfileId) {
			return
		}

		setSearchTerm(text)

		const args = {
			brandProfileId,
			aylienType,
			searchTerm: text,
			filter,
			sort
		}

		const n = await getBpAylienItems(args)
		formatChildren(n)
		setItems(n)
	}

	const markItem = (
		tree: AylienItem[],
		id: number,
		responseId: 1 | 2 | null
	) => {
		for (const item of tree) {
			if (item.id === id) {
				item.responseId = responseId
				return
			}

			if (item.children && item.children.length > 0) {
				markItem(item.children, id, responseId)
			}
		}
	}

	const handleActionClick = ({ id, responseId }: HandleActionClickProps) => {
		if (!brandProfileId) {
			return
		}
		setItems((prev: AylienItem[]) => {
			markItem(prev, id, responseId)
			return prev
		})
		const args = {
			brandProfileId,
			id,
			responseId,
			aylienType
		}
		patchBpAylienItems(args)
	}

	const handleFilterChange = async (val: string[]) => {
		setFilter(val)
	}

	const handleSortChange = (val: boolean) => {
		setSort(val)
	}

	return {
		items,
		populateChildren,
		handleSearch,
		handleActionClick,
		handleFilterChange,
		handleSortChange,
		searchTerm,
		sort
	}
}
