import React from 'react'
import TrendArticleComponent from './TrendArticle'
import { TrendArticle, Trend } from '../../../../classes/trend'
import Skeleton from 'react-loading-skeleton'

interface TrendArticlesProps {
	compTrend: Trend
	trendArticlesLoading: boolean
	trendArticles: TrendArticle[]
}

const TrendArticles = ({
	compTrend,
	trendArticlesLoading,
	trendArticles
}: TrendArticlesProps) => {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			}}
		>
			{compTrend?.articleCount > 5 && (
				<div
					style={{
						height: 28,
						//	width: 'fit-content',
						paddingRight: 8,
						borderRadius: 3,
						backgroundColor: '#F2FAFD',
						paddingTop: 3,
						paddingLeft: 8,
						fontWeight: 500,
						fontSize: 14,
						lineHeight: '22px',
						marginTop: 24
					}}
				>
					Showing 5 of {compTrend?.articleCount} articles. To see all articles,
					please download the moment.
				</div>
			)}

			<div
				id='articlesTab'
				style={{
					overflowY: 'auto',
					marginTop: 24,
					textAlign: 'left'
				}}
			>
				{trendArticlesLoading ? (
					<div style={{ width: 664 }}>
						<Skeleton count={8} />
						<div style={{ height: 24 }} />
						<Skeleton count={4} />
						<div style={{ height: 24 }} />
						<Skeleton count={4} />
					</div>
				) : (
					<>
						{trendArticles &&
							trendArticles.slice(0, 5).map((article, index) => {
								return (
									<TrendArticleComponent
										key={index + article.url}
										article={article}
										isFirst={index === 0}
									/>
								)
							})}
					</>
				)}
			</div>
		</div>
	)
}

export default TrendArticles
