import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { socialsObjValidation } from '../../../../../schemas/brandProfiles'
import Table from 'rsuite/lib/Table'
import SightlyButton from '../../../../../components/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '../../../../../assets/jss/colorContants'
import { CustomWhisper } from '../../../../../components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import SocialModal from './SocialModal'
import Panel from 'rsuite/lib/Panel'
import { userAccountAxios } from '../../../../../axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useUser from '../../../../../services/useUser'
import { TableColumnJs } from '../../../../../utils'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

export type Social = {
    id: number
    name?: string
    description: string
    wikiUrl: string
    relationshipId: number
    relationshipName?: string
    causeIsNotListed: boolean
    aylienId: number | null
}

const getSocials = async (brandProfileId: number): Promise<Social[]> => {
    let url = `/brand-profile/${brandProfileId}/socialAndEnvironmental`

    const result = await userAccountAxios.get(url)

    if (result.status === 200) {
        if (result.data.length > 0) {
            socialsObjValidation.validate(result.data).catch(function(err) {
                console.error(err)
            })
        }
    }

    const tranformedData = result.data.map((i: any) => {
        if (i.aylienNewsId) {
            return {
                ...i,
                aylienId: i.aylienNewsId
            }
        } else if (i.aylienIndustriesId) {
            return {
                ...i,
                aylienId: i.aylienIndustriesId
            }
        } else {
            return i
        }
    })

    return tranformedData
}

const SocialSection = () => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const queryClient = useQueryClient()

    const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
    const viewOnly = viewOnlyParam || false

    const { data: items, isLoading, isFetched } = useQuery(
        ['social', brandProfileId],
        () => getSocials(Number(brandProfileId)),
        { enabled: !!brandProfileId, refetchOnWindowFocus: false }
    )

    const [editing, setEditing] = React.useState(false)
    const [editingId, setEditingId] = React.useState<number | null>(null)
    const [adding, setAdding] = React.useState(false)

    const editingCompetitor = React.useMemo(() => {
        if (!editingId || !isFetched || !items || !editing) return null

        const item = items.filter((c: Social) => c.id === editingId)[0]

        if (!item) return null
        return item
    }, [editingId, editing, items])

    const handleDelete = async (id: number) => {
        const url = `/brand-profile/${brandProfileId}/socialAndEnvironmental/${id}`
        try {
            await userAccountAxios.delete(url)
            toast.success('Deleted')
        } catch (err) {
            toast.error('Error')
        }
        queryClient.invalidateQueries(rqKeys.brandProfileSocial(Number(brandProfileId)))
    }

    return (
        <Panel header={<div className="sightlyPanelHeader">Social</div>}>
            {!!editingCompetitor && (
                <SocialModal
                    key="edit"
                    type="edit"
                    open={editing}
                    handleClose={() => {
                        setEditing(false)
                        setEditingId(null)
                    }}
                    item={editingCompetitor}
                />
            )}

            <SocialModal
                key="add"
                type="add"
                open={adding}
                handleClose={() => setAdding(false)}
                item={{
                    id: 456456546456456564,
                    name: '',
                    description: '',
                    aylienId: null,
                    wikiUrl: '',
                    relationshipId: 1,
                    causeIsNotListed: false
                }}
            />

            <div className="descriptionText"></div>
            {!viewOnly && items && items.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <SightlyButton
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE)}
                        handleClick={() => setAdding(true)}
                        id="addMorebutton"
                        text="Create New"
                    />
                </div>
            )}

            {isFetched && items && items.length < 1 ? (
                <SightlyButton
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                    handleClick={() => setAdding(true)}
                    id="addMorebutton"
                    type="secondary"
                    text="Add +"
                />
            ) : (
                isFetched && (
                    <Table loading={isLoading} autoHeight data={items}>
                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell style={{ display: 'flex', alignItems: 'center' }} dataKey="relationshipName" />
                        </TableColumnJs>

                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell>
                                {(item: Social) => {
                                    return <>{item.name}</>
                                }}
                            </Table.Cell>
                        </TableColumnJs>

                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Wiki</Table.HeaderCell>
                            <Table.Cell dataKey="wikiUrl" />
                        </TableColumnJs>

                        {!viewOnly && (
                            <TableColumnJs flexGrow={undefined}>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                    {(item: Social) => {
                                        return (
                                            <CustomWhisper
                                                dropdownItems={[
                                                    <Dropdown.Item
                                                        key={1}
                                                        onClick={() => {
                                                            setEditingId(item.id)
                                                            setEditing(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>,
                                                    <Dropdown.Item key={2} onClick={() => handleDelete(item.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                ]}
                                            >
                                                <IconButton
                                                    size="lg"
                                                    appearance="subtle"
                                                    icon={<Icon icon="more" color={accentColor} />}
                                                />
                                            </CustomWhisper>
                                        )
                                    }}
                                </Table.Cell>
                            </TableColumnJs>
                        )}
                    </Table>
                )
            )}
        </Panel>
    )
}

export default SocialSection
