import axios from 'axios'
import { SET_TARGETVIEW_INSERTION_ORDERS } from '../../action-types/ThirdParty/targetview'
import { targetviewIosObjValidation } from '../../../schemas/brandProfiles'
import toast from 'react-hot-toast'

const targetviewUrl = 'https://api-genesis-production-4kr2ass6nq-uw.a.run.app'

export function setTargetviewInsertionOrders(targetviewInsertionOrders) {
	return {
		type: SET_TARGETVIEW_INSERTION_ORDERS,
		targetviewInsertionOrders
	}
}

//handle unable to authenticate
//handle fetchIo errors

export function fetchTargetviewInsertionOrders() {
	return async (dispatch) => {
		const loginCredentials = {
			password: process.env.REACT_APP_TARGETVIEW_PASSWORD,
			username: 'authuser@sightly.com'
		}

		let url = targetviewUrl + `/api/v1/authenticate`

		const authRequest = axios.create({
			headers: {
				'Content-Type': 'application/json',
				'Acess-Control-Allow-Origin': '*',
				Accept: 'application/json'
			}
		})
		let result
		try {
			result = await authRequest.post(url, loginCredentials)
		} catch {
			dispatch(setTargetviewInsertionOrders([]))
			toast.error('Could not access Targetview API to fetch IOs', {
				duration: 7000
			})
			return
		}

		if (result.status === 200) {
			const iosUrl = `${targetviewUrl}/v2/account/00000000-0000-0000-0000-000000000001/insertionOrder`
			const getAllInsertionOrdersRequest = axios.create({
				headers: {
					'Content-Type': 'application/json',
					'Acess-Control-Allow-Origin': '*',
					Authorization: `${result.data.jwt}`,
					Accept: 'application/json'
				}
			})

			const ios = await getAllInsertionOrdersRequest.get(iosUrl)
			if (ios.status === 200) {
				targetviewIosObjValidation
					.validate(ios.data.slice(0, 3)) //just test first 3 so that it validates faster
					.catch(function (err) {
						console.log(err.name, err.errors)
						console.error(
							'We received different data than expected while fetching targetview IOs. See console for more details'
						)
					})
				dispatch(setTargetviewInsertionOrders(ios.data))
			} else {
				dispatch(setTargetviewInsertionOrders([]))
				toast.error('Could not access Targetview API to fetch IOs')
			}
		} else {
			dispatch(setTargetviewInsertionOrders([]))
			toast.error('Could not access Targetview API to fetch IOs')
		}
	}
}
