import React from 'react'
import { ReactComponent as NoNotifications } from '../assets/img/noNotifications.svg'
import SightlyButton from './SightlyButton'
import useBrandProfiles from '../services/brandProfile/useBrandProfiles'
import useUser from '../services/useUser'

const NoBrandProfiles = () => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const { createBrandProfile, isLoading } = useBrandProfiles({ submittedOnly: false })
    console.log('loading', isLoading)
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%'
            }}
        >
            <div style={{ width: 500, justifyContent: 'center', textAlign: 'center' }}>
                <NoNotifications />
                <h4 style={{ marginBottom: 48 }}>
                    We currently have no brand profiles submitted and processed for this account.
                </h4>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <SightlyButton id="createNewProfile" text="Create New Profile" handleClick={createBrandProfile} />
                </div>
            </div>
        </div>
    )
}

export default NoBrandProfiles
