import React from 'react'
import { accentColor, purpleSecondaryColor } from '../assets/jss/colorContants'
import { joinClasses } from '../utils'
import Loader from 'rsuite/lib/Loader'

interface IProps {
    id: string
    handleClick: () => void
    text: string
    disabled?: boolean
    loading?: boolean
    type?: 'primary' | 'secondary' | 'purple' | 'cancel'
    block?: boolean
    width?: number
    isRectangle?: boolean
}

const disabledColor = 'rgba(211, 211, 211, 1)'

const SightlyButton = ({
    id,
    handleClick,
    text,
    disabled,
    loading,
    type = 'primary',
    block,
    width,
    isRectangle
}: IProps) => {
    const _handleClick = () => {
        if (disabled || loading) {
            return
        }
        handleClick()
    }
    const _backgroundColor = React.useMemo(() => {
        if (type === 'secondary') return 'white'
        if (type === 'cancel') return 'red'
        if (type === 'purple') return purpleSecondaryColor
        if (disabled && type === 'primary') return disabledColor
        if (loading && type === 'primary') return disabledColor
        return accentColor
    }, [disabled, type, loading])

    return (
        <div
            id={id}
            className={type === 'primary' && !disabled ? joinClasses('unselectable', 'primaryButton') : 'unselectable'}
            style={{
                width: width ? width : undefined,
                display: block ? 'block' : 'inline-block',
                border: type === 'secondary' ? '1px solid #26AAE1' : undefined,
                cursor: disabled || loading ? 'not-allowed' : 'pointer',
                backgroundColor: _backgroundColor,
                height: 40,
                fontWeight: 600,
                borderRadius: isRectangle ? 3 : 40,
                color: type === 'secondary' ? accentColor : '#FFF',
                textAlign: 'center',
                paddingLeft: 24,
                paddingRight: 24,
                opacity: disabled && type === 'secondary' ? 0.3 : 1
            }}
            onClick={_handleClick}
        >
            <div style={{ display: 'flex', height: 40, justifyContent: 'center', position: 'relative' }}>
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate3d(-50%, -50%, 0)'
                        }}
                    >
                        <Loader />
                    </div>
                )}

                <div
                    style={{
                        alignSelf: 'center',
                        color: loading ? disabledColor : type === 'secondary' ? accentColor : 'white'
                    }}
                >
                    {text}
                </div>
            </div>
        </div>
    )
}

export default SightlyButton
