export const youtubeCategories = [
	{ categoryId: '32', categoryName: 'Action/Adventure' },
	{ categoryId: '31', categoryName: 'Anime/Animation' },
	{ categoryId: '2', categoryName: 'Autos & Vehicles' },
	{ categoryId: '33', categoryName: 'Classics' },
	{ categoryId: '23', categoryName: 'Comedy' },
	{ categoryId: '35', categoryName: 'Documentary' },
	{ categoryId: '36', categoryName: 'Drama' },
	{ categoryId: '27', categoryName: 'Education' },
	{ categoryId: '24', categoryName: 'Entertainment' },
	{ categoryId: '37', categoryName: 'Family' },
	{ categoryId: '1', categoryName: 'Film & Animation' },
	{ categoryId: '38', categoryName: 'Foreign' },
	{ categoryId: '20', categoryName: 'Gaming' },
	{ categoryId: '39', categoryName: 'Horror' },
	{ categoryId: '26', categoryName: 'Howto & Style' },
	{ categoryId: '30', categoryName: 'Movies' },
	{ categoryId: '10', categoryName: 'Music' },
	{ categoryId: '25', categoryName: 'News & Politics' },
	{ categoryId: '29', categoryName: 'Nonprofits & Activism' },
	{ categoryId: '22', categoryName: 'People & Blogs' },
	{ categoryId: '15', categoryName: 'Pets & Animals' },
	{ categoryId: '40', categoryName: 'Sci-Fi/Fantasy' },
	{ categoryId: '28', categoryName: 'Science & Technology' },
	{ categoryId: '18', categoryName: 'Short Movies' },
	{ categoryId: '42', categoryName: 'Shorts' },
	{ categoryId: '43', categoryName: 'Shows' },
	{ categoryId: '17', categoryName: 'Sports' },
	{ categoryId: '41', categoryName: 'Thriller' },
	{ categoryId: '44', categoryName: 'Trailers' },
	{ categoryId: '19', categoryName: 'Travel & Events' },
	{ categoryId: '21', categoryName: 'Videoblogging' }
]

export const countriesOptions = [
	{ countryCode: 'AF', countryName: 'Afghanistan' },
	{ countryCode: 'AL', countryName: 'Albania' },
	{ countryCode: 'DZ', countryName: 'Algeria' },
	{ countryCode: 'AS', countryName: 'American Samoa' },
	{ countryCode: 'AD', countryName: 'Andorra' },
	{ countryCode: 'AO', countryName: 'Angola' },
	{ countryCode: 'AI', countryName: 'Anguilla' },
	{ countryCode: 'AQ', countryName: 'Antarctica' },
	{ countryCode: 'AG', countryName: 'Antigua and Barbuda' },
	{ countryCode: 'AR', countryName: 'Argentina' },
	{ countryCode: 'AM', countryName: 'Armenia' },
	{ countryCode: 'AW', countryName: 'Aruba' },
	{ countryCode: 'AU', countryName: 'Australia' },
	{ countryCode: 'AT', countryName: 'Austria' },
	{ countryCode: 'AZ', countryName: 'Azerbaijan' },
	{ countryCode: 'BS', countryName: 'Bahamas' },
	{ countryCode: 'BH', countryName: 'Bahrain' },
	{ countryCode: 'BD', countryName: 'Bangladesh' },
	{ countryCode: 'BB', countryName: 'Barbados' },
	{ countryCode: 'BY', countryName: 'Belarus' },
	{ countryCode: 'BE', countryName: 'Belgium' },
	{ countryCode: 'BZ', countryName: 'Belize' },
	{ countryCode: 'BJ', countryName: 'Benin' },
	{ countryCode: 'BM', countryName: 'Bermuda' },
	{ countryCode: 'BT', countryName: 'Bhutan' },
	{ countryCode: 'BO', countryName: 'Bolivia' },
	{ countryCode: 'BA', countryName: 'Bosnia and Herzegovina' },
	{ countryCode: 'BW', countryName: 'Botswana' },
	{ countryCode: 'BV', countryName: 'Bouvet Island' },
	{ countryCode: 'BR', countryName: 'Brazil' },
	{ countryCode: 'IO', countryName: 'British Indian Ocean Territory' },
	{ countryCode: 'VG', countryName: 'British Virgin Islands' },
	{ countryCode: 'BN', countryName: 'Brunei' },
	{ countryCode: 'BG', countryName: 'Bulgaria' },
	{ countryCode: 'BF', countryName: 'Burkina Faso' },
	{ countryCode: 'BI', countryName: 'Burundi' },
	{ countryCode: 'KH', countryName: 'Cambodia' },
	{ countryCode: 'CM', countryName: 'Cameroon' },
	{ countryCode: 'CA', countryName: 'Canada' },
	{ countryCode: 'CV', countryName: 'Cape Verde' },
	{ countryCode: 'KY', countryName: 'Cayman Islands' },
	{ countryCode: 'CF', countryName: 'Central African Republic' },
	{ countryCode: 'TD', countryName: 'Chad' },
	{ countryCode: 'CL', countryName: 'Chile' },
	{ countryCode: 'CN', countryName: 'China' },
	{ countryCode: 'CX', countryName: 'Christmas Island' },
	{ countryCode: 'CC', countryName: 'Cocos [Keeling] Islands' },
	{ countryCode: 'CO', countryName: 'Colombia' },
	{ countryCode: 'KM', countryName: 'Comoros' },
	{ countryCode: 'CD', countryName: 'Congo [DRC]' },
	{ countryCode: 'CG', countryName: 'Congo [Republic]' },
	{ countryCode: 'CK', countryName: 'Cook Islands' },
	{ countryCode: 'CR', countryName: 'Costa Rica' },
	{ countryCode: 'HR', countryName: 'Croatia' },
	{ countryCode: 'CU', countryName: 'Cuba' },
	{ countryCode: 'CY', countryName: 'Cyprus' },
	{ countryCode: 'CZ', countryName: 'Czech Republic' },
	{ countryCode: 'CI', countryName: "Côte d'Ivoire" },
	{ countryCode: 'DK', countryName: 'Denmark' },
	{ countryCode: 'DJ', countryName: 'Djibouti' },
	{ countryCode: 'DM', countryName: 'Dominica' },
	{ countryCode: 'DO', countryName: 'Dominican Republic' },
	{ countryCode: 'EC', countryName: 'Ecuador' },
	{ countryCode: 'EG', countryName: 'Egypt' },
	{ countryCode: 'SV', countryName: 'El Salvador' },
	{ countryCode: 'GQ', countryName: 'Equatorial Guinea' },
	{ countryCode: 'ER', countryName: 'Eritrea' },
	{ countryCode: 'EE', countryName: 'Estonia' },
	{ countryCode: 'ET', countryName: 'Ethiopia' },
	{ countryCode: 'FK', countryName: 'Falkland Islands [Islas Malvinas]' },
	{ countryCode: 'FO', countryName: 'Faroe Islands' },
	{ countryCode: 'FJ', countryName: 'Fiji' },
	{ countryCode: 'FI', countryName: 'Finland' },
	{ countryCode: 'FR', countryName: 'France' },
	{ countryCode: 'GF', countryName: 'French Guiana' },
	{ countryCode: 'PF', countryName: 'French Polynesia' },
	{ countryCode: 'TF', countryName: 'French Southern Territories' },
	{ countryCode: 'GA', countryName: 'Gabon' },
	{ countryCode: 'GM', countryName: 'Gambia' },
	{ countryCode: 'GZ', countryName: 'Gaza Strip' },
	{ countryCode: 'GE', countryName: 'Georgia' },
	{ countryCode: 'DE', countryName: 'Germany' },
	{ countryCode: 'GH', countryName: 'Ghana' },
	{ countryCode: 'GI', countryName: 'Gibraltar' },
	{ countryCode: 'GR', countryName: 'Greece' },
	{ countryCode: 'GL', countryName: 'Greenland' },
	{ countryCode: 'GD', countryName: 'Grenada' },
	{ countryCode: 'GP', countryName: 'Guadeloupe' },
	{ countryCode: 'GU', countryName: 'Guam' },
	{ countryCode: 'GT', countryName: 'Guatemala' },
	{ countryCode: 'GG', countryName: 'Guernsey' },
	{ countryCode: 'GN', countryName: 'Guinea' },
	{ countryCode: 'GW', countryName: 'Guinea-Bissau' },
	{ countryCode: 'GY', countryName: 'Guyana' },
	{ countryCode: 'HT', countryName: 'Haiti' },
	{ countryCode: 'HM', countryName: 'Heard Island and McDonald Islands' },
	{ countryCode: 'HN', countryName: 'Honduras' },
	{ countryCode: 'HK', countryName: 'Hong Kong' },
	{ countryCode: 'HU', countryName: 'Hungary' },
	{ countryCode: 'IS', countryName: 'Iceland' },
	{ countryCode: 'IN', countryName: 'India' },
	{ countryCode: 'ID', countryName: 'Indonesia' },
	{ countryCode: 'IR', countryName: 'Iran' },
	{ countryCode: 'IQ', countryName: 'Iraq' },
	{ countryCode: 'IE', countryName: 'Ireland' },
	{ countryCode: 'IM', countryName: 'Isle of Man' },
	{ countryCode: 'IL', countryName: 'Israel' },
	{ countryCode: 'IT', countryName: 'Italy' },
	{ countryCode: 'JM', countryName: 'Jamaica' },
	{ countryCode: 'JP', countryName: 'Japan' },
	{ countryCode: 'JE', countryName: 'Jersey' },
	{ countryCode: 'JO', countryName: 'Jordan' },
	{ countryCode: 'KZ', countryName: 'Kazakhstan' },
	{ countryCode: 'KE', countryName: 'Kenya' },
	{ countryCode: 'KI', countryName: 'Kiribati' },
	{ countryCode: 'XK', countryName: 'Kosovo' },
	{ countryCode: 'KW', countryName: 'Kuwait' },
	{ countryCode: 'KG', countryName: 'Kyrgyzstan' },
	{ countryCode: 'LA', countryName: 'Laos' },
	{ countryCode: 'LV', countryName: 'Latvia' },
	{ countryCode: 'LB', countryName: 'Lebanon' },
	{ countryCode: 'LS', countryName: 'Lesotho' },
	{ countryCode: 'LR', countryName: 'Liberia' },
	{ countryCode: 'LY', countryName: 'Libya' },
	{ countryCode: 'LI', countryName: 'Liechtenstein' },
	{ countryCode: 'LT', countryName: 'Lithuania' },
	{ countryCode: 'LU', countryName: 'Luxembourg' },
	{ countryCode: 'MO', countryName: 'Macau' },
	{ countryCode: 'MK', countryName: 'Macedonia [FYROM]' },
	{ countryCode: 'MG', countryName: 'Madagascar' },
	{ countryCode: 'MW', countryName: 'Malawi' },
	{ countryCode: 'MY', countryName: 'Malaysia' },
	{ countryCode: 'MV', countryName: 'Maldives' },
	{ countryCode: 'ML', countryName: 'Mali' },
	{ countryCode: 'MT', countryName: 'Malta' },
	{ countryCode: 'MH', countryName: 'Marshall Islands' },
	{ countryCode: 'MQ', countryName: 'Martinique' },
	{ countryCode: 'MR', countryName: 'Mauritania' },
	{ countryCode: 'MU', countryName: 'Mauritius' },
	{ countryCode: 'YT', countryName: 'Mayotte' },
	{ countryCode: 'MX', countryName: 'Mexico' },
	{ countryCode: 'FM', countryName: 'Micronesia' },
	{ countryCode: 'MD', countryName: 'Moldova' },
	{ countryCode: 'MC', countryName: 'Monaco' },
	{ countryCode: 'MN', countryName: 'Mongolia' },
	{ countryCode: 'ME', countryName: 'Montenegro' },
	{ countryCode: 'MS', countryName: 'Montserrat' },
	{ countryCode: 'MA', countryName: 'Morocco' },
	{ countryCode: 'MZ', countryName: 'Mozambique' },
	{ countryCode: 'MM', countryName: 'Myanmar [Burma]' },
	{ countryCode: 'NA', countryName: 'Namibia' },
	{ countryCode: 'NR', countryName: 'Nauru' },
	{ countryCode: 'NP', countryName: 'Nepal' },
	{ countryCode: 'NL', countryName: 'Netherlands' },
	{ countryCode: 'AN', countryName: 'Netherlands Antilles' },
	{ countryCode: 'NC', countryName: 'New Caledonia' },
	{ countryCode: 'NZ', countryName: 'New Zealand' },
	{ countryCode: 'NI', countryName: 'Nicaragua' },
	{ countryCode: 'NE', countryName: 'Niger' },
	{ countryCode: 'NG', countryName: 'Nigeria' },
	{ countryCode: 'NU', countryName: 'Niue' },
	{ countryCode: 'NF', countryName: 'Norfolk Island' },
	{ countryCode: 'KP', countryName: 'North Korea' },
	{ countryCode: 'MP', countryName: 'Northern Mariana Islands' },
	{ countryCode: 'NO', countryName: 'Norway' },
	{ countryCode: 'OM', countryName: 'Oman' },
	{ countryCode: 'PK', countryName: 'Pakistan' },
	{ countryCode: 'PW', countryName: 'Palau' },
	{ countryCode: 'PS', countryName: 'Palestinian Territories' },
	{ countryCode: 'PA', countryName: 'Panama' },
	{ countryCode: 'PG', countryName: 'Papua New Guinea' },
	{ countryCode: 'PY', countryName: 'Paraguay' },
	{ countryCode: 'PE', countryName: 'Peru' },
	{ countryCode: 'PH', countryName: 'Philippines' },
	{ countryCode: 'PN', countryName: 'Pitcairn Islands' },
	{ countryCode: 'PL', countryName: 'Poland' },
	{ countryCode: 'PT', countryName: 'Portugal' },
	{ countryCode: 'PR', countryName: 'Puerto Rico' },
	{ countryCode: 'QA', countryName: 'Qatar' },
	{ countryCode: 'RO', countryName: 'Romania' },
	{ countryCode: 'RU', countryName: 'Russia' },
	{ countryCode: 'RW', countryName: 'Rwanda' },
	{ countryCode: 'RE', countryName: 'Réunion' },
	{ countryCode: 'SH', countryName: 'Saint Helena' },
	{ countryCode: 'KN', countryName: 'Saint Kitts and Nevis' },
	{ countryCode: 'LC', countryName: 'Saint Lucia' },
	{ countryCode: 'PM', countryName: 'Saint Pierre and Miquelon' },
	{ countryCode: 'VC', countryName: 'Saint Vincent and the Grenadines' },
	{ countryCode: 'WS', countryName: 'Samoa' },
	{ countryCode: 'SM', countryName: 'San Marino' },
	{ countryCode: 'SA', countryName: 'Saudi Arabia' },
	{ countryCode: 'SN', countryName: 'Senegal' },
	{ countryCode: 'RS', countryName: 'Serbia' },
	{ countryCode: 'SC', countryName: 'Seychelles' },
	{ countryCode: 'SL', countryName: 'Sierra Leone' },
	{ countryCode: 'SG', countryName: 'Singapore' },
	{ countryCode: 'SK', countryName: 'Slovakia' },
	{ countryCode: 'SI', countryName: 'Slovenia' },
	{ countryCode: 'SB', countryName: 'Solomon Islands' },
	{ countryCode: 'SO', countryName: 'Somalia' },
	{ countryCode: 'ZA', countryName: 'South Africa' },
	{
		countryCode: 'GS',
		countryName: 'South Georgia and the South Sandwich Islands'
	},
	{ countryCode: 'KR', countryName: 'South Korea' },
	{ countryCode: 'ES', countryName: 'Spain' },
	{ countryCode: 'LK', countryName: 'Sri Lanka' },
	{ countryCode: 'SD', countryName: 'Sudan' },
	{ countryCode: 'SR', countryName: 'Suriname' },
	{ countryCode: 'SJ', countryName: 'Svalbard and Jan Mayen' },
	{ countryCode: 'SZ', countryName: 'Swaziland' },
	{ countryCode: 'SE', countryName: 'Sweden' },
	{ countryCode: 'CH', countryName: 'Switzerland' },
	{ countryCode: 'SY', countryName: 'Syria' },
	{ countryCode: 'ST', countryName: 'São Tomé and Príncipe' },
	{ countryCode: 'TW', countryName: 'Taiwan' },
	{ countryCode: 'TJ', countryName: 'Tajikistan' },
	{ countryCode: 'TZ', countryName: 'Tanzania' },
	{ countryCode: 'TH', countryName: 'Thailand' },
	{ countryCode: 'TL', countryName: 'Timor-Leste' },
	{ countryCode: 'TG', countryName: 'Togo' },
	{ countryCode: 'TK', countryName: 'Tokelau' },
	{ countryCode: 'TO', countryName: 'Tonga' },
	{ countryCode: 'TT', countryName: 'Trinidad and Tobago' },
	{ countryCode: 'TN', countryName: 'Tunisia' },
	{ countryCode: 'TR', countryName: 'Turkey' },
	{ countryCode: 'TM', countryName: 'Turkmenistan' },
	{ countryCode: 'TC', countryName: 'Turks and Caicos Islands' },
	{ countryCode: 'TV', countryName: 'Tuvalu' },
	{ countryCode: 'UM', countryName: 'U.S. Minor Outlying Islands' },
	{ countryCode: 'VI', countryName: 'U.S. Virgin Islands' },
	{ countryCode: 'UG', countryName: 'Uganda' },
	{ countryCode: 'UA', countryName: 'Ukraine' },
	{ countryCode: 'AE', countryName: 'United Arab Emirates' },
	{ countryCode: 'GB', countryName: 'United Kingdom' },
	{ countryCode: 'US', countryName: 'United States' },
	{ countryCode: 'UY', countryName: 'Uruguay' },
	{ countryCode: 'UZ', countryName: 'Uzbekistan' },
	{ countryCode: 'VU', countryName: 'Vanuatu' },
	{ countryCode: 'VA', countryName: 'Vatican City' },
	{ countryCode: 'VE', countryName: 'Venezuela' },
	{ countryCode: 'VN', countryName: 'Vietnam' },
	{ countryCode: 'WF', countryName: 'Wallis and Futuna' },
	{ countryCode: 'EH', countryName: 'Western Sahara' },
	{ countryCode: 'YE', countryName: 'Yemen' },
	{ countryCode: 'ZM', countryName: 'Zambia' },
	{ countryCode: 'ZW', countryName: 'Zimbabwe' }
]

export const lidLanguagesOptions = [
	{ languageCode: 'en_mix', languageName: 'English and Other Languages' },
	{ languageCode: 'af', languageName: 'Afrikaans' },
	{ languageCode: 'als', languageName: 'Tosk Albanian' },
	{ languageCode: 'am', languageName: 'Amharic' },
	{ languageCode: 'an', languageName: 'Aragonese' },
	{ languageCode: 'ar', languageName: 'Arabic' },
	{ languageCode: 'arz', languageName: 'Egyptian Arabic' },
	{ languageCode: 'as', languageName: 'Assamese' },
	{ languageCode: 'ast', languageName: 'Asturian' },
	{ languageCode: 'av', languageName: 'Avaric' },
	{ languageCode: 'az', languageName: 'Azerbaijani' },
	{ languageCode: 'azb', languageName: 'South Azerbaijani' },
	{ languageCode: 'ba', languageName: 'Bashkir' },
	{ languageCode: 'bar', languageName: 'Bavarian' },
	{ languageCode: 'bcl', languageName: 'Central Bikol' },
	{ languageCode: 'be', languageName: 'Belarusian' },
	{ languageCode: 'bg', languageName: 'Bulgarian' },
	{ languageCode: 'bh', languageName: 'Bihari' },
	{ languageCode: 'bn', languageName: 'Bengali' },
	{ languageCode: 'bo', languageName: 'Tibetan' },
	{ languageCode: 'bpy', languageName: 'Bishnupriya' },
	{ languageCode: 'br', languageName: 'Breton' },
	{ languageCode: 'bs', languageName: 'Bosnian' },
	{ languageCode: 'bxr', languageName: 'Russia Buriat' },
	{ languageCode: 'ca', languageName: 'Catalan' },
	{ languageCode: 'cbk', languageName: 'Chavacano' },
	{ languageCode: 'ce', languageName: 'Chechen' },
	{ languageCode: 'ceb', languageName: 'Cebuano' },
	{ languageCode: 'ckb', languageName: 'Central Kurdish' },
	{ languageCode: 'co', languageName: 'Corsican' },
	{ languageCode: 'cs', languageName: 'Czech' },
	{ languageCode: 'cv', languageName: 'Chuvash' },
	{ languageCode: 'cy', languageName: 'Welsh' },
	{ languageCode: 'da', languageName: 'Danish' },
	{ languageCode: 'de', languageName: 'German' },
	{ languageCode: 'diq', languageName: 'Dimli' },
	{ languageCode: 'dsb', languageName: 'Lower Sorbian' },
	{ languageCode: 'dty', languageName: 'Dotyali' },
	{ languageCode: 'dv', languageName: 'Divehi' },
	{ languageCode: 'el', languageName: 'Modern Greek' },
	{ languageCode: 'eml', languageName: 'Emilian-Romagnol' },
	{ languageCode: 'en', languageName: 'English' },
	{ languageCode: 'eo', languageName: 'Esperanto' },
	{ languageCode: 'es', languageName: 'Spanish' },
	{ languageCode: 'et', languageName: 'Estonian' },
	{ languageCode: 'eu', languageName: 'Basque' },
	{ languageCode: 'fa', languageName: 'Farsi' },
	{ languageCode: 'fi', languageName: 'Finnish' },
	{ languageCode: 'fr', languageName: 'French' },
	{ languageCode: 'frr', languageName: 'Northern Frisian' },
	{ languageCode: 'fy', languageName: 'Western Frisian' },
	{ languageCode: 'ga', languageName: 'Irish' },
	{ languageCode: 'gd', languageName: 'Scottish Gaelic' },
	{ languageCode: 'gl', languageName: 'Galician' },
	{ languageCode: 'gn', languageName: 'Guarani' },
	{ languageCode: 'gom', languageName: 'Goan Konkani' },
	{ languageCode: 'gu', languageName: 'Gujarati' },
	{ languageCode: 'gv', languageName: 'Manx' },
	{ languageCode: 'he', languageName: 'Hebrew' },
	{ languageCode: 'hi', languageName: 'Hindi' },
	{ languageCode: 'hif', languageName: 'Fiji Hindi' },
	{ languageCode: 'hr', languageName: 'Croatian' },
	{ languageCode: 'hsb', languageName: 'Upper Sorbian' },
	{ languageCode: 'ht', languageName: 'Haitian' },
	{ languageCode: 'hu', languageName: 'Hungarian' },
	{ languageCode: 'hy', languageName: 'Armenian' },
	{ languageCode: 'ia', languageName: 'Interlingua' },
	{ languageCode: 'id', languageName: 'Indonesian' },
	{ languageCode: 'ie', languageName: 'Interlingue' },
	{ languageCode: 'ilo', languageName: 'Iloko' },
	{ languageCode: 'io', languageName: 'Ido' },
	{ languageCode: 'is', languageName: 'Icelandic' },
	{ languageCode: 'it', languageName: 'Italian' },
	{ languageCode: 'ja', languageName: 'Japanese' },
	{ languageCode: 'jbo', languageName: 'Lojban' },
	{ languageCode: 'jv', languageName: 'Javanese' },
	{ languageCode: 'ka', languageName: 'Georgian' },
	{ languageCode: 'kk', languageName: 'Kazakh' },
	{ languageCode: 'km', languageName: 'Central Khmer' },
	{ languageCode: 'kn', languageName: 'Kannada' },
	{ languageCode: 'ko', languageName: 'Korean' },
	{ languageCode: 'krc', languageName: 'Karachay-Balkar' },
	{ languageCode: 'ku', languageName: 'Kurdish' },
	{ languageCode: 'kv', languageName: 'Komi' },
	{ languageCode: 'kw', languageName: 'Cornish' },
	{ languageCode: 'ky', languageName: 'Kyrgyz' },
	{ languageCode: 'la', languageName: 'Latin' },
	{ languageCode: 'lb', languageName: 'Luxembourgish' },
	{ languageCode: 'lez', languageName: 'Lezghian' },
	{ languageCode: 'li', languageName: 'Limburgian' },
	{ languageCode: 'lmo', languageName: 'Lombard' },
	{ languageCode: 'lo', languageName: 'Lao' },
	{ languageCode: 'lrc', languageName: 'Northern Luri' },
	{ languageCode: 'lt', languageName: 'Lithuanian' },
	{ languageCode: 'lv', languageName: 'Latvian' },
	{ languageCode: 'mai', languageName: 'Maithili' },
	{ languageCode: 'mg', languageName: 'Malagasy' },
	{ languageCode: 'mhr', languageName: 'Eastern Mari' },
	{ languageCode: 'min', languageName: 'Minangkabau' },
	{ languageCode: 'mk', languageName: 'Macedonian' },
	{ languageCode: 'ml', languageName: 'Malayam' },
	{ languageCode: 'mn', languageName: 'Mongolian' },
	{ languageCode: 'mr', languageName: 'Marathi' },
	{ languageCode: 'mrj', languageName: 'Western Mari' },
	{ languageCode: 'ms', languageName: 'Malay' },
	{ languageCode: 'mt', languageName: 'Maltese' },
	{ languageCode: 'mwl', languageName: 'Mirandese' },
	{ languageCode: 'my', languageName: 'Burmese' },
	{ languageCode: 'myv', languageName: 'Erzya' },
	{ languageCode: 'mzn', languageName: 'Mazandarani' },
	{ languageCode: 'nah', languageName: 'Nahuatl' },
	{ languageCode: 'nap', languageName: 'Neapolitan' },
	{ languageCode: 'nds', languageName: 'Saxon' },
	{ languageCode: 'ne', languageName: 'Nepali' },
	{ languageCode: 'new', languageName: 'Nepal Bhasa' },
	{ languageCode: 'nl', languageName: 'Dutch' },
	{ languageCode: 'nn', languageName: 'Norwegian Nynorsk' },
	{ languageCode: 'no', languageName: 'Norwegian' },
	{ languageCode: 'oc', languageName: 'Occitan' },
	{ languageCode: 'or', languageName: 'Oriya' },
	{ languageCode: 'os', languageName: 'Ossetian' },
	{ languageCode: 'pa', languageName: 'Punjabi' },
	{ languageCode: 'pam', languageName: 'Pampanga' },
	{ languageCode: 'pfl', languageName: 'Pfaelzisch' },
	{ languageCode: 'pl', languageName: 'Polish' },
	{ languageCode: 'pms', languageName: 'Piemontese' },
	{ languageCode: 'pnb', languageName: 'Western Panjabi' },
	{ languageCode: 'ps', languageName: 'Pashto' },
	{ languageCode: 'pt', languageName: 'Portuguese' },
	{ languageCode: 'qu', languageName: 'Quechua' },
	{ languageCode: 'rm', languageName: 'Romansh' },
	{ languageCode: 'ro', languageName: 'Romanian' },
	{ languageCode: 'ru', languageName: 'Russian' },
	{ languageCode: 'rue', languageName: 'Rusyn' },
	{ languageCode: 'sa', languageName: 'Sanskrit' },
	{ languageCode: 'sah', languageName: 'Yakut' },
	{ languageCode: 'sc', languageName: 'Sardinian' },
	{ languageCode: 'scn', languageName: 'Sicilian' },
	{ languageCode: 'sco', languageName: 'Scots' },
	{ languageCode: 'sd', languageName: 'Sindhi' },
	{ languageCode: 'sh', languageName: 'Serbo-Croatian' },
	{ languageCode: 'si', languageName: 'Sinhalese' },
	{ languageCode: 'sk', languageName: 'Slovak' },
	{ languageCode: 'sl', languageName: 'Slovenian' },
	{ languageCode: 'so', languageName: 'Somali' },
	{ languageCode: 'sq', languageName: 'Albanian' },
	{ languageCode: 'sr', languageName: 'Serbian' },
	{ languageCode: 'su', languageName: 'Sundanese' },
	{ languageCode: 'sv', languageName: 'Swedish' },
	{ languageCode: 'sw', languageName: 'Swahili' },
	{ languageCode: 'ta', languageName: 'Tamil' },
	{ languageCode: 'te', languageName: 'Telugu' },
	{ languageCode: 'tg', languageName: 'Tajik' },
	{ languageCode: 'th', languageName: 'Thai' },
	{ languageCode: 'tk', languageName: 'Turkmen' },
	{ languageCode: 'tl', languageName: 'Tagalog' },
	{ languageCode: 'tr', languageName: 'Turkish' },
	{ languageCode: 'tt', languageName: 'Tatar' },
	{ languageCode: 'tyv', languageName: 'Tuvinian' },
	{ languageCode: 'ug', languageName: 'Uighur' },
	{ languageCode: 'uk', languageName: 'Ukranian' },
	{ languageCode: 'ur', languageName: 'Urdu' },
	{ languageCode: 'uz', languageName: 'Uzbek' },
	{ languageCode: 'vec', languageName: 'Venetian' },
	{ languageCode: 'vep', languageName: 'Veps' },
	{ languageCode: 'vi', languageName: 'Vietnamese' },
	{ languageCode: 'vls', languageName: 'Vlaams' },
	{ languageCode: 'vo', languageName: 'Volapük' },
	{ languageCode: 'wa', languageName: 'Walloon' },
	{ languageCode: 'war', languageName: 'Waray' },
	{ languageCode: 'wuu', languageName: 'Wu Chinese' },
	{ languageCode: 'xal', languageName: 'Kalmyk' },
	{ languageCode: 'xmf', languageName: 'Mingrelian' },
	{ languageCode: 'yi', languageName: 'Yiddish' },
	{ languageCode: 'yo', languageName: 'Yoruba' },
	{ languageCode: 'yue', languageName: 'Yue Chinese' },
	{ languageCode: 'zh', languageName: 'Chinese' }
]

export const accountTypes = [
	{ accountTypeId: 1, accountTypeName: 'Brand' },
	{ accountTypeId: 2, accountTypeName: 'Agency' },
	{ accountTypeId: 3, accountTypeName: 'Reseller' },
	{ accountTypeId: 4, accountTypeName: 'Publisher' },
	{ accountTypeId: 5, accountTypeName: 'Researcher' }
]
