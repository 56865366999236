import React from 'react'
import Tabs from '../../../components/Tabs/Tabs'
import Input from 'rsuite/lib/Input'
import useBoardCreator, { ItemTypes } from './useBoardCreator'
import Footer from './Footer'
import { routes } from '../../../routes'
import useBoards, { Board } from './useBoards'
import MaxBoardsWarning from './MaxBoardsWarning'
import PillsGroup from './PillsGroup'
import Sidebar from './Sidebar'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import { Navigate, useMatch, useNavigate } from '@tanstack/react-location'
import { Toggle, Tooltip, Whisper } from 'rsuite'
import { accentColor, purpleSecondaryColor } from '../../../assets/jss/colorContants'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import useUser from '../../../services/useUser'
import { location } from '../../../RouteContainer'

const _tabs = ['Queries', 'News Verticals', 'Industry Verticals']

const Custom = () => {
    const [active, setActive] = React.useState<string>('0')
    const { currentBrandProfile } = useBrandProfiles({
        submittedOnly: true
    })
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const MAX_ALLOWED_CUSTOM_ITEMS = user?.userType === 'Internal' ? 10 : 3
    const brandProfileId = currentBrandProfile?.brandProfileId

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: search => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
    }, [currentBrandProfile, location.current.search])

    const boardsObj = useBoards()

    const boardCreator = useBoardCreator({
        brandProfileId,
        boardType: 'custom'
    })

    const navigate = useNavigate()

    if (boardsObj && boardsObj.boardsFetched && boardsObj?.boards && boardsObj?.boards?.length > 9) {
        return <Navigate to={routes.app.discover.boards.dashboard.path} />
    }

    return (
        <div className="gridContainer">
            <Sidebar
                handleBrandProfileChange={(brandProfileId: number) => {
                    navigate({
                        search: prev => ({
                            ...prev,
                            brandProfileId
                        })
                    })
                }}
                brandProfileId={brandProfileId}
                handleSelectBoard={() => {}}
                boards={[]}
                selectedBoards={[]}
            />

            <div className="main">
                <div className="primaryContent">
                    <div className="title">Custom Board</div>
                    <div className="name">
                        <Input
                            id="name"
                            onChange={(val: string) => boardCreator.setName(val)}
                            placeholder="Enter a name for this board here"
                        />
                    </div>

                    <div className="subtitle">News to follow</div>
                    <div className="description">
                        {`Select up to ${MAX_ALLOWED_CUSTOM_ITEMS} items to create a board. ${
                            !boardCreator.isOr
                                ? `By selecting more than one
                        topic, your board will only display moments when the topics intersect in the news`
                                : ''
                        }`}
                    </div>
                    {user?.userType === 'Internal' && (
                        <>
                            <Toggle
                                onChange={boardCreator.setIsOr}
                                size="xs"
                                defaultChecked={boardCreator.isOr}
                                checkedChildren="Or"
                                unCheckedChildren="And"
                                style={{
                                    marginRight: 8,
                                    backgroundColor: boardCreator.isOr ? accentColor : purpleSecondaryColor
                                }}
                            />

                            <Whisper
                                delayShow={300}
                                delayHide={0}
                                placement="topStart"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                                            <div>
                                                OR: Includes articles that match any of your search terms; i.e Fashion
                                                <strong> OR</strong> Nike.
                                            </div>
                                            <div>
                                                AND: Includes articles that match all of your search terms; i.e. only
                                                articles which mention both Fashion <strong> AND</strong> Nike
                                            </div>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <Icon icon="question-circle" />
                            </Whisper>
                        </>
                    )}

                    <Tabs
                        active={active}
                        onChange={(active: string) => {
                            setActive(active)
                        }}
                    >
                        {_tabs.map((_tab, index) => {
                            return <div key={index}>{_tab}</div>
                        })}
                    </Tabs>

                    <InputGroup inside style={{ marginTop: 16, maxWidth: 559, marginBottom: 24 }}>
                        <InputGroup.Button>
                            <Icon icon="search" style={{ color: '#7F868D' }} />
                        </InputGroup.Button>
                        <Input
                            style={{ borderRadius: 3 }}
                            id="search"
                            onChange={boardCreator.setSearchTerm}
                            placeholder="Search"
                        />
                    </InputGroup>

                    {active === '0' && (
                        <PillsGroup
                            boardType="custom"
                            MAX_ALLOWED_CUSTOM_ITEMS={MAX_ALLOWED_CUSTOM_ITEMS}
                            data={boardCreator.queries}
                            count={boardCreator.queriesCount}
                            handleSelectItem={boardCreator.handleSelectItem}
                            selectedCount={boardCreator.selectedCount}
                            itemType={ItemTypes.queries}
                            searchTerm={boardCreator.searchTerm}
                            WarningMessage={
                                <div style={{ marginBottom: 24 }}>
                                    Please{' '}
                                    <a
                                        href={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/brands`}
                                    >
                                        return to your Brand Profile Queries section
                                    </a>{' '}
                                    and add some Queries in order to receive topic recommendations.{' '}
                                </div>
                            }
                        />
                    )}

                    {active === '1' && (
                        <PillsGroup
                            boardType="custom"
                            MAX_ALLOWED_CUSTOM_ITEMS={MAX_ALLOWED_CUSTOM_ITEMS}
                            data={boardCreator.aylienNews}
                            count={boardCreator.newsTargetedCount}
                            handleSelectItem={boardCreator.handleSelectItem}
                            selectedCount={boardCreator.selectedCount}
                            itemType={ItemTypes.aylienNews}
                            searchTerm={boardCreator.searchTerm}
                            WarningMessage={
                                <div style={{ marginBottom: 24 }}>
                                    Please{' '}
                                    <a
                                        href={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/opinions`}
                                    >
                                        return to your Brand Profile Opinions section
                                    </a>{' '}
                                    and designate some opinions which your Brand agrees with in order to receive topic
                                    recommendations.{' '}
                                </div>
                            }
                        />
                    )}

                    {active === '2' && (
                        <PillsGroup
                            boardType="custom"
                            MAX_ALLOWED_CUSTOM_ITEMS={MAX_ALLOWED_CUSTOM_ITEMS}
                            data={boardCreator.aylienIndustries}
                            count={boardCreator.industriesTargetedCount}
                            handleSelectItem={boardCreator.handleSelectItem}
                            selectedCount={boardCreator.selectedCount}
                            itemType={ItemTypes.aylienIndustries}
                            searchTerm={boardCreator.searchTerm}
                            WarningMessage={
                                <div>
                                    Please{' '}
                                    <a
                                        href={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/opinions`}
                                    >
                                        return to your Brand Profile Opinions section
                                    </a>{' '}
                                    and designate some opinions which your Brand agrees with in order to receive topic
                                    recommendations.{' '}
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
            <Footer
                isCreating={boardCreator.isCreating}
                handleCreate={() => boardCreator.createBoard()}
                disabled={boardCreator.selectedCount < 1}
            />

            <style>{`
  
	
	.gridContainer {
		display: grid;
		 height: 100%;	
		grid-template-columns: 265px 1fr;	
		grid-template-rows:  1fr 64px;
		grid-template-areas:
			"sidebar main"
			"sidebar footer"		
	}		

	.main {
		grid-area: main;
		padding: 25px 38px;
		overflow-y: auto;
		justify-content: center;
		display: flex; 
	 
	}  

	.primaryContent {
		width: 500px;
	}
	
  .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #333D47;
    margin-bottom: 18px;
  }
  .name {
    width: 559px;
  }
  .subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 26px;
    margin-bottom: 16px;
  }
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    max-width: 790px;
    margin-bottom: 16px;
  }
  
`}</style>
        </div>
    )
}

export default Custom
