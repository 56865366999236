import React from 'react'
import { fetchVideosService, patchListKeywordsService } from '../../../../services/lists_ts'
import VideoComponent from './components/VideoComponent'
import { patchListSensitivityService, saveVersionFilterService, fetchVersionService } from '../../../../services/lists'
import config from '../../../../config'
import debounce from 'just-debounce-it'
import ListFrame from './components/ListFrame'
import { Version } from '../../../../classes/version'
import { Video } from '../../../../classes/video'
import { useMatch, useNavigate } from '@tanstack/react-location'
import { location } from '../../../../RouteContainer'

interface Sort {
    sortColumn: string
    sortType: string
}

interface FetchVideoParams {
    versionId: string
    pageNumber: number
    sort: Sort
    searchTerm: string
}

const VideoListBuilder = () => {
    const navigate = useNavigate()
    const {
        params: { versionId }
    } = useMatch()
    const isFromViewClick = location.current.pathname.includes('view')

    const [version, setVersion] = React.useState<Version>({
        versionId: null,
        videos: [],
        channels: [],
        smartListId: 1,
        smartListName: '',
        brandProfileName: '',
        createdDate: '',
        updatedDate: '',
        uploaded: false,
        stats: {
            channelCount: 0,
            itemCount: 0,
            videoCount: 0
        },
        objectiveName: ''
    })
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [currentSort, setCurrentSort] = React.useState<Sort>({
        sortColumn: 'views',
        sortType: 'desc'
    })
    const [hasNextPage, setHasNextPage] = React.useState<boolean>(true)
    const [currentPage, setCurrentPage] = React.useState<number>(1)
    const [contentIsLoading, setContentIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        if (!versionId) {
            return
        }

        async function handlePageLoad() {
            const _versionResult = await fetchVersionService(versionId)
            const _videos = await fetchInitialVideos()

            if (_videos.length < config.listbuilderIncrement) {
                setHasNextPage(false)
            } else {
                setHasNextPage(true)
            }

            setVersion(() => {
                const _version: Version = {
                    ..._versionResult,
                    videos: _videos
                }
                return _version
            })
            setContentIsLoading(false)
        }
        handlePageLoad()
    }, [])

    const handleBottomReached = async () => {
        setContentIsLoading(true)
        let params = {
            versionId: versionId,
            pageNumber: currentPage + 1,
            sort: currentSort,
            searchTerm
        }
        const videos = await fetchVideosService(params)
        setVersion(prev => {
            return {
                ...prev,
                videos: prev.videos ? prev.videos.concat(...videos) : videos
            }
        })

        setCurrentPage(prev => prev + 1)

        if (videos.length < config.listbuilderIncrement) {
            setHasNextPage(false)
        } else {
            setHasNextPage(true)
        }

        setContentIsLoading(false)
    }

    const fetchInitialVideos = async () => {
        const params: FetchVideoParams = {
            versionId,
            pageNumber: currentPage,
            sort: currentSort,
            searchTerm
        }
        return await fetchVideosService(params)
    }

    const handleSensitivityChange = async (val: 1 | -0.3 | -7 | -99999) => {
        setContentIsLoading(true)
        setHasNextPage(true)
        setCurrentPage(1)
        setVersion(prev => {
            return {
                ...prev,
                videos: []
            }
        })

        const res = await patchListSensitivityService({
            sensitivity: val,
            smartListId: version.smartListId
        })

        if (res.status !== 200) {
            console.error('error patching keywords')
            return
        }

        const params: FetchVideoParams = {
            versionId,
            pageNumber: 1,
            sort: currentSort,
            searchTerm
        }
        const _videos: Video[] = await fetchVideosService(params)
        handleSetVideos(_videos)

        setContentIsLoading(false)
    }

    const handleSetVideos: Function = async (_videos: Video[]) => {
        setVersion(prev => {
            return {
                ...prev,
                videos: _videos
            }
        })

        if (_videos.length < config.listbuilderIncrement) {
            setHasNextPage(false)
        } else {
            setHasNextPage(true)
        }
    }

    const handleBlockedKeywordsExecute = async (arr: string[]) => {
        setContentIsLoading(true)

        setVersion(prev => {
            return {
                ...prev,
                videos: []
            }
        })
        const res = await patchListKeywordsService({
            blockedKeywords: arr,
            smartListId: version.smartListId
        })

        if (res.status !== 200) {
            console.error('error patching keywords')
            return
        }

        const params: FetchVideoParams = {
            versionId,
            pageNumber: currentPage,
            sort: currentSort,
            searchTerm
        }
        const _videos: Video[] = await fetchVideosService(params)
        handleSetVideos(_videos)
        setContentIsLoading(false)
    }

    const handleBlockedKeywords = React.useCallback(debounce(handleBlockedKeywordsExecute, 1), [version])

    const handleFiltersSaved = async (args: { filters: object; versionId: number }) => {
        setContentIsLoading(true)
        setVersion(prev => {
            return {
                ...prev,
                videos: []
            }
        })

        const res = await saveVersionFilterService(args)
        if (res.status === 200) {
            const params: FetchVideoParams = {
                versionId,
                pageNumber: currentPage,
                sort: currentSort,
                searchTerm
            }
            const _videos: Video[] = await fetchVideosService(params)
            handleSetVideos(_videos)
            setContentIsLoading(false)
        }
    }

    const handleChannelsToggle = async () => {
        navigate({
            to: `/app/engage/lists/channelListBuilder/${versionId}/${
                location.current.pathname.includes('view') ? 'view' : 'edit'
            }`
        })
    }

    const setCurrentVideosSort = async (val: 'views' | 'subscribers' | 'videos') => {
        const sortObj = { sortColumn: val, sortType: 'desc' }
        setContentIsLoading(true)
        setCurrentSort(sortObj)
        setCurrentPage(1)
        setVersion(prev => {
            return {
                ...prev,
                videos: []
            }
        })

        let params = {
            versionId,
            pageNumber: 1,
            sort: sortObj,
            searchTerm
        }

        const videos = await fetchVideosService(params)

        handleSetVideos(videos)
        setContentIsLoading(false)
    }

    const handleVideosSearch = debounce((value: string) => {
        handleVideosSearchExecute(value)
    }, 500)

    const handleVideosSearchExecute = async (_searchTerm: string) => {
        setContentIsLoading(true)
        setSearchTerm(_searchTerm)
        setCurrentPage(1)
        setHasNextPage(true)
        setVersion(prev => {
            return {
                ...prev,
                videos: []
            }
        })

        let params = {
            versionId,
            pageNumber: 1,
            sort: currentSort,
            searchTerm: _searchTerm
        }

        const videos = await fetchVideosService(params)
        handleSetVideos(videos)
        setContentIsLoading(false)
    }

    return (
        <ListFrame
            isVideo={true}
            isFromViewClick={isFromViewClick}
            version={version}
            handleBlockedKeywords={handleBlockedKeywords}
            handleSensitivityChange={handleSensitivityChange}
            handleFiltersSaved={handleFiltersSaved}
            handleChannelsToggle={handleChannelsToggle}
            contentIsLoading={contentIsLoading}
            children={version.videos.map(video => {
                return (
                    <div key={video.id}>
                        <VideoComponent isFromViewClick={isFromViewClick} video={video} />

                        <div
                            style={{
                                marginTop: 4,
                                marginBottom: 4,
                                height: 1,
                                backgroundColor: '#F5F5F5'
                            }}
                        />
                    </div>
                )
            })}
            handleSearch={handleVideosSearch}
            setCurrentSort={setCurrentVideosSort}
            handleBottomReached={handleBottomReached}
            hasNextPage={hasNextPage}
            sortOptions={[
                { id: 'views', label: 'Sort by View Count' },
                { id: 'likes', label: 'Sort by Like Count' },
                { id: 'comments', label: 'Sort by Comment Count' }
            ]}
        />
    )
}

export default VideoListBuilder
