import React from 'react'
import { ReactComponent as VideoIcon } from '../../../assets/img/video_28x28.svg'
import { ReactComponent as CloseIcon8x8 } from '../../../assets/img/closeIcon8x8.svg'
import SightlyButton from '../../../components/SightlyButton'
import SightlyAvatar from '../../../components/SightlyAvatar'
import { BrandProfile } from '../../../classes/brandProfile'
import { Trend } from '../../../classes/trend'
import { Account } from '../../../classes/account'
import TextTruncate from 'react-text-truncate'
import PortalModal from '../../../components/PortalModal/PortalModal'
import { api } from '../../../api/api'
import { Link } from '@tanstack/react-location'
import { routes } from '../../../routes'
import { WarningModal } from '../../../components/WarningModal'
import Loader from 'react-loader-spinner'
import { accentColor } from '../../../assets/jss/colorContants'

const IoPill = ({ name, removeIo }: { name: string; removeIo: Function }) => {
    return (
        <div
            style={{
                height: 24,
                backgroundColor: '#F0F3F5',
                borderRadius: 3,
                fontWeight: 600,
                fontSize: 12,
                lineHeight: '20px',
                marginBottom: 10,
                maxWidth: 145,
                paddingTop: 2,
                paddingLeft: 8,
                paddingRight: 8,
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <div className="unselectable" style={{ width: 115 }}>
                <TextTruncate text={name} />
            </div>

            <CloseIcon8x8
                onClick={() => removeIo()}
                fill="#292929"
                style={{ marginLeft: 4, alignSelf: 'center', cursor: 'pointer' }}
            />
        </div>
    )
}

const BlockPlacementsModal = ({
    open,
    setOpen,
    trends,
    accountName,
    handleBlock,
    brandProfileId
}: {
    open: boolean
    setOpen: Function
    trends: Trend[]
    accountName: Account['accountName']
    handleBlock: Function
    brandProfileId: number
}) => {
    const totalVideos = React.useMemo(() => {
        if (!trends) return 0
        return trends.reduce((n, { videoCount }) => n + videoCount, 0)
    }, [trends])

    const [bpIos, setBpIos] = React.useState<BrandProfile['ios']>([])
    const [compIos, setCompIos] = React.useState<BrandProfile['ios']>([])
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        if (!brandProfileId) return
        setIsLoading(true)
        const handlePageLoad = async () => {
            const ios = await api.brandProfile.ios.get(brandProfileId)
            if (open) {
                setCompIos(ios)
                setBpIos(ios)
            }
            setIsLoading(false)
        }
        handlePageLoad()
    }, [brandProfileId, open])

    const removeIo = (ioId: number) => {
        setCompIos(prev => {
            return prev.filter(i => i.ioId !== ioId)
        })
    }

    const handleBlockClick = () => {
        const args = {
            trendIds: trends.map(t => {
                return t.trendId
            }),
            ioIds: compIos.map(i => {
                return i.ioId
            })
        }

        handleBlock(args)
        setOpen(false)
    }

    if (bpIos && bpIos.length < 1 && !isLoading) {
        return (
            <WarningModal open={open} setIsOpen={setOpen}>
                <div style={{ width: 500 }}>
                    <div>Error: Please link IO to Brand Profile to block implementations</div>
                    <Link to={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/targetview`}>
                        Update Here
                    </Link>
                </div>
            </WarningModal>
        )
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <PortalModal
                footerButtons={[
                    <SightlyButton
                        disabled={compIos.length < 1}
                        id="submitClickTrendButton"
                        text="Confirm"
                        handleClick={() => handleBlockClick()}
                    />
                ]}
                open={open && !isLoading}
                handleClose={() => setOpen(false)}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 24
                    }}
                >
                    <div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 24,
                                lineHeight: '32px',
                                color: '#333D47',
                                width: '70vw'
                            }}
                        >
                            Confirm Blocked Placements
                        </div>
                        <div
                            style={{
                                marginTop: 8,
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: '24px',
                                color: '#292929'
                            }}
                        >
                            Please confirm the below placement(s) to be blocked for the appropriate insertion order(s).
                        </div>

                        <div>
                            <div
                                style={{
                                    marginTop: 24,
                                    display: 'flex',
                                    height: 35
                                }}
                            >
                                <SightlyAvatar size="small" type="blue" text={accountName} />
                                <div
                                    style={{
                                        marginLeft: 16,
                                        fontWeight: 600,
                                        fontSize: 16,
                                        lineHeight: '24px',
                                        color: '#333D47',
                                        alignSelf: 'center'
                                    }}
                                >
                                    {accountName}
                                </div>

                                <VideoIcon
                                    style={{
                                        alignSelf: 'center',
                                        marginLeft: 16,
                                        marginRight: 10.5
                                    }}
                                />

                                <div
                                    style={{
                                        fontWeight: 500,
                                        lineHeight: '35px',
                                        fontSize: 16,
                                        color: '#696969',
                                        alignSelf: 'center'
                                    }}
                                >
                                    {`${totalVideos} Placements`}
                                </div>
                            </div>

                            <div style={{ height: 62 }} />
                        </div>

                        <div id="bodyContainer" style={{ padding: 24, display: 'flex' }}>
                            <div id="insertionOrdersColumn" style={{ height: 450, overflowY: 'auto' }}>
                                <div
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 18,
                                        lineHeight: '24px',
                                        color: '#333D47',
                                        marginBottom: 20
                                    }}
                                >
                                    Insertion Orders
                                </div>

                                {compIos &&
                                    compIos.map(io => {
                                        return (
                                            <IoPill removeIo={() => removeIo(io.ioId)} key={io.ioId} name={io.ioName} />
                                        )
                                    })}
                            </div>

                            <div id="momentsColumn" style={{ maxHeight: 450, overflowY: 'auto', marginLeft: 71 }}>
                                <div
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 18,
                                        lineHeight: '24px',
                                        color: '#333D47',
                                        marginBottom: 20
                                    }}
                                >
                                    Moments
                                </div>

                                {trends &&
                                    trends.map(trend => {
                                        return (
                                            <div
                                                key={trend.trendId}
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: 14,
                                                    lineHeight: '22px',
                                                    marginBottom: 16,
                                                    color: '#2E4052'
                                                }}
                                            >
                                                <div style={{ width: 538 }}>
                                                    <TextTruncate text={trend.trendName} />
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </PortalModal>
        </div>
    )
}

export default BlockPlacementsModal
