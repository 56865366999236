import React from 'react'
import SightlyButton from '../../../components/SightlyButton'
import { routes } from '../../../routes'
import { useNavigate } from '@tanstack/react-location'
type IProps = {
    handleCreate: () => void
    isCreating: boolean
    disabled: boolean
}
const Footer = ({ handleCreate, isCreating, disabled }: IProps) => {
    const navigate = useNavigate()
    return (
        <div className="footer">
            <SightlyButton
                type="secondary"
                isRectangle={true}
                id="cancel"
                text="Cancel"
                handleClick={() =>
                    navigate({
                        to: routes.app.discover.boards.dashboard.path,
                        search: prev => {
                            return {
                                ...prev
                            }
                        }
                    })
                }
            />

            <SightlyButton
                disabled={disabled}
                loading={isCreating}
                type="primary"
                isRectangle={true}
                id="create"
                text="Create Board"
                handleClick={handleCreate}
            />
            <style>{`
  
  .footer {
    grid-area: footer;
    background-color: #FFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 72px;
    gap: 18px;
  }
  
`}</style>
        </div>
    )
}

export default Footer
