import { typeOneSections } from './views/BrandProfiles/BrandProfile/components/Queries/constants'

type MomentsProps = {
    boardIds: number[]
    brandProfileId: number | undefined
    sortBy: string
    timeRange: number
    actions: string[] | undefined
    searchTerm: string
    aylienIndustries: string[]
    aylienNews: string[]
    page: number
}
type TaxonomiesProps = {
    brandProfileId: number | undefined
    timeRange: number
    boardIds: number[]
    actions: string[] | undefined
}

export const rqKeys = {
    userKey: (userId: number) => ['useUserKey', userId] as const,
    boardsKey: (brandProfileId: number | undefined) => ['boards', brandProfileId] as const,
    apiVersionKey: () => ['versionNumber'] as const,
    unseenNotificationCountKey: () => ['unseenNotificationCount'] as const,
    embedUrlsKey: (accountId: number | undefined) => ['embedUrls', accountId] as const,
    listsKey: (accountId: number) => ['lists', accountId] as const,
    rolesKey: () => ['rolesPermissionsQuery'] as const,
    usersKeys: (accountId: number | undefined) => ['users', accountId] as const,
    brandProfileStatusKey: (brandProfileId: number) => ['brandProfileStatus', brandProfileId] as const,
    brandProfileKeywordsKey: (brandProfileId: number) => ['brandProfileKeywords', brandProfileId] as const,
    brandProfileScenarios: (brandProfileId: number) => ['scenarios', brandProfileId] as const,
    brandProfileQuestions: (brandProfileId: number) => ['bpQuestions', brandProfileId] as const,
    brandProfileOpinions: (brandProfileId: number) => ['opinions', brandProfileId] as const,
    brandProfileEvents: (brandProfileId: number) => ['events', brandProfileId] as const,
    brandProfileSocial: (brandProfileId: number) => ['social', brandProfileId] as const,
    brandProfileBoards: (brandProfileId: number | undefined) => ['boards', brandProfileId] as const,
    brandProfileQuery: (queryType: typeOneSections, brandProfileId: number) => [queryType, brandProfileId] as const,
    aylienIndBasicInfo: () => ['aylienIndustriesBasicInfo'] as const,
    wikiOptions: (wikiSearchTerm: string) => ['wikiOptions', wikiSearchTerm] as const,
    socialAylien: () => ['aylienItems'] as const,
    opinionTypesKey: (excludeArchived: boolean | undefined) => ['opinionTypes', excludeArchived] as const,
    brandProfilesKey: (accountId: number | undefined) => ['brandProfiles', accountId] as const,
    opinionQueryKey: (opinionId: string) => ['adminOpinion', opinionId] as const,
    adminOpinions: () => ['adminOpinions'] as const,
    adminTopics: () => ['fetchAdminTopics'] as const,
    adminIabCats: () => ['fetchIabCategories'] as const,
    adminAylienNews: () => ['fetchAylienNewsAdmin'] as const,
    adminAylienInd: () => ['fetchAylienIndustryAdmin'] as const,
    adminPermissionRoles: () => ['adminPermissionRoles'] as const,
    scenario: (scenarioId: number) => ['adminscenario', scenarioId] as const,
    scenarioDataSegments: () => ['dataSegments'] as const,
    scenarioLabels: () => ['scenariolabels'] as const,
    scenarioTypes: () => ['scenarioTypes'] as const,
    isOrdered: (brandProfileId: number | undefined) => ['isOrdered', brandProfileId] as const,
    trends: (
        brandProfileId: number | undefined,
        date: string | undefined,
        hour: number | undefined,
        sortBy: string,
        isDemo: boolean
    ) => ['trends', brandProfileId, date, hour, sortBy, isDemo] as const,
    brandProfileIos: (brandProfileId: number | undefined) => ['brandProfileIos', brandProfileId] as const,
    moments: ({
        boardIds,
        brandProfileId,
        sortBy,
        timeRange,
        actions,
        searchTerm,
        aylienIndustries,
        aylienNews,
        page
    }: MomentsProps) =>
        [
            'moments',
            boardIds,
            brandProfileId,
            sortBy,
            timeRange,
            actions,
            searchTerm,
            aylienIndustries,
            aylienNews,
            page
        ] as const,
    taxonomies: ({ brandProfileId, timeRange, boardIds, actions }: TaxonomiesProps) =>
        ['taxonomies', brandProfileId, timeRange, boardIds, actions] as const
    // all: ['todos'] as const,
    // lists: () => [...todoKeys.all, 'list'] as const,
    // list: (filters: string) => [...todoKeys.lists(), { filters }] as const,
    // details: () => [...todoKeys.all, 'detail'] as const,
    // detail: (id: number) => [...todoKeys.details(), id] as const,
}
