import { Navigate, Outlet, useSearch } from '@tanstack/react-location'
import React from 'react'
import { connect } from 'react-redux'
import { setAuthToken } from '../redux/actions/auth.js'
import useThirdPartyInitializer from '../useInitializeThirdParty.js'
import Layout from './Layout.js'

const mapStateToProps = state => {
    return {
        authToken: state.authToken
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAuthToken: token => dispatch(setAuthToken(token))
    }
}

function isValidToken(token) {
    if (token) return true
    return false
}

function PrivateRoute({ children, ...rest }) {
    useThirdPartyInitializer()
    const search = useSearch()
    var authToken = rest.authToken

    if (!authToken) {
        authToken = localStorage.getItem('token')
        if (authToken) {
            rest.setAuthToken(authToken)
        }
    }

    if (isValidToken(authToken)) {
        return (
            <Layout>
                <Outlet />
            </Layout>
        )
    }
    if (window.location.pathname !== '/login') {
        search.redirect = window.location.pathname + window.location.search + window.location.hash
        delete search.brandProfileId
    }

    return <Navigate to="/login" search={search} />
}

const AuthRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)

export default AuthRoute
