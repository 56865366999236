import React from 'react'
import { ReactComponent as ThumbsDown } from '../assets/img/thumbs-down-solid.svg'
import { ReactComponent as ThumbsDownOutline } from '../assets/img/thumbs-down-regular.svg'
import toast from 'react-hot-toast'

const FeedbackButton = ({ disagree, text, handleClick }) => {
	const [componentDisagree, setComponentDisagree] = React.useState(null)

	React.useEffect(() => {
		setComponentDisagree(disagree)
	}, [disagree])

	const handleButtonClick = () => {
		handleClick(componentDisagree ? null : 'disagree')
		setComponentDisagree((prev) => !prev)

		toast.success('Thanks for the feedback!', { duration: 1000 })
	}

	return (
		<div
			style={{ display: 'flex', cursor: 'pointer' }}
			onClick={handleButtonClick}
		>
			<div
				style={{
					fontWeight: 500,
					fontSize: 12,
					lineHeight: '20px',
					color: '#333D47',
					marginRight: 10
				}}
			>
				{text}
			</div>

			{componentDisagree ? (
				<ThumbsDown style={{ height: 16, marginTop: 3 }} />
			) : (
				<ThumbsDownOutline style={{ height: 16, marginTop: 3 }} />
			)}
		</div>
	)
}

export default FeedbackButton
