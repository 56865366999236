/*eslint-disable no-restricted-globals*/
import React from 'react'
import Button from 'rsuite/lib/Button'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { createScenario, fetchAdminBrandScenarioLabels } from '../../../redux/actions/admin/scenarios'
import useScenarioTypes from './useScenarioTypes'
import { CheckTreePicker, Input, SelectPicker } from 'rsuite'
import { joinClasses } from '../../../utils'

const mapStateToProps = state => {
    return {
        scenarioSaving: state.admin.scenarioSaving,
        scenarioLabels: state.admin.scenarioLabels,
        scenariosLabelsIsLoading: state.admin.scenariosLabelsIsLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createScenario: scenario => dispatch(createScenario(scenario)),
        fetchAdminBrandScenarioLabels: text => dispatch(fetchAdminBrandScenarioLabels(text))
    }
}

const schemaValidation = Yup.object().shape({
    scenarioName: Yup.string()
        .required('Required')
        .min(2, 'Must be greater than 1 character')
        .max(120, 'Must be less than 120 characters'),
    scenarioTypeIds: Yup.array()
        .of(Yup.number())
        .required('Required')
        .min(1, 'Must select one'),
    labelId: Yup.number().required('Required')
})

function Scenario(props) {
    const { fetchAdminBrandScenarioLabels, scenarioLabels } = props

    let [scenarioTypesFetched, setScenarioTypesFetched] = React.useState(false)
    React.useEffect(() => {
        if (!scenarioTypesFetched) {
            fetchAdminBrandScenarioLabels()
            setScenarioTypesFetched(true)
        }
    }, [fetchAdminBrandScenarioLabels, scenarioTypesFetched, setScenarioTypesFetched])

    const scenarioTypesObj = useScenarioTypes({ excludeArchived: true })

    let filteredScenarioLabels = scenarioLabels.filter(labels => !labels.archived)

    const { handleSubmit, formState, setValue } = useForm({
        defaultValues: {
            scenarioTypeIds: [],
            scenarioName: '',
            labelId: null
        },
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(schemaValidation)
    })

    const onSubmit = async data => {
        let scenario = {
            scenarioName: data.scenarioName,
            labelId: data.labelId,
            scenarioTypeIds: data.scenarioTypeIds
        }
        props.createScenario(scenario)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 500 }}>
                <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                    Scenario
                </div>
                <Input
                    componentClass={'textarea'}
                    rows={2}
                    id="scenarioName"
                    disabled={false}
                    onChange={v => {
                        setValue('scenarioName', v, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }}
                />
                <div style={{ color: 'red' }}>
                    {formState?.errors?.scenarioName && formState?.errors?.scenarioName.message}
                </div>

                <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                    Scenario Types
                </div>
                <CheckTreePicker
                    preventOverflow
                    data={scenarioTypesObj.scenarioTypes}
                    labelKey="typeName"
                    valueKey="typeId"
                    onChange={v => {
                        setValue('scenarioTypeIds', v, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }}
                    block
                />
                <div style={{ color: 'red' }}>
                    {formState?.errors?.scenarioTypeIds && formState?.errors?.scenarioTypeIds.message}
                </div>

                <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                    Scenario Label
                </div>
                <SelectPicker
                    id={'scenarioLabel'}
                    block
                    cleanable={false}
                    data={filteredScenarioLabels}
                    labelKey="labelName"
                    valueKey="labelId"
                    placeholder="Scenario Label"
                    onChange={v => {
                        setValue('labelId', v, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }}
                />
                <div style={{ color: 'red' }}>{formState?.errors?.labelId && formState?.errors?.labelId.message}</div>

                <Button
                    style={{ marginTop: 24 }}
                    loading={props.scenarioSaving}
                    disabled={!formState.isValid || props.scenarioSaving}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenario)
