/*eslint-disable no-restricted-globals*/
import React from 'react'
import Button from 'rsuite/lib/Button'
import { connect } from 'react-redux'
import { withFormik, Form } from 'formik'
import FormikInput from '../../../components/CustomInput/FormikInput'
import * as Yup from 'yup'
import { createEvergreenChannel } from '../../../redux/actions/admin/evergreenChannels'
import { iabCategoriesFilter } from '../../../staticData/iabCategories'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'

const mapStateToProps = (state) => {
	return {
		evergreenChannelSaving: state.admin.evergreenChannelSaving
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		createEvergreenChannel: (evergreenChannel) =>
			dispatch(createEvergreenChannel(evergreenChannel))
	}
}

const schemaValidation = Yup.object().shape({
	channelId: Yup.string().required('Required').length(24),
	iabCategoryIds: Yup.array().of(Yup.string())
})

function EvergreenChannel(props) {
	const { isValid, dirty, values } = props

	return (
		<div style={{ width: 500, margin: 'auto' }}>
			<Form>
				<FormikInput
					name='channelId'
					formikValue={values.channelId}
					labelText='Channel Id'
					id='channelId'
				/>
				<div style={{ height: 20 }} />
				<CheckTreePicker
					preventOverflow
					size={'xs'}
					defaultExpandAll={false}
					data={iabCategoriesFilter}
					labelKey={'name'}
					valueKey={'id'}
					onChange={(v) => (values.iabCategoryIds = v)}
					cascade={true}
					block
				/>

				<div style={{ height: 20 }} />

				<Button
					loading={props.evergreenChannelSaving}
					disabled={!isValid || !dirty || props.evergreenChannelSaving}
					type='submit'>
					Save
				</Button>
			</Form>
		</div>
	)
}

const FormikForm = withFormik({
	mapPropsToValues: (props) => {
		return {
			channelId: '',
			iabCategoryIds: []
		}
	},
	enableReinitialize: true,
	validateOnMount: true,
	validateOnChange: true,
	validateOnBlur: true,
	validationSchema: schemaValidation,
	handleSubmit: (values, { props }) => {
		let channel = {
			channelId: values.channelId,
			iabCategoryIds: values.iabCategoryIds
		}
		props.createEvergreenChannel(channel)
	}
})(EvergreenChannel)

export default connect(mapStateToProps, mapDispatchToProps)(FormikForm)
