import { SET_TARGETVIEW_INSERTION_ORDERS } from '../../action-types/ThirdParty/targetview'

export function targetviewInsertionOrders(state = null, action) {
	switch (action.type) {
		case SET_TARGETVIEW_INSERTION_ORDERS:
			return action.targetviewInsertionOrders
		default:
			return state
	}
}
