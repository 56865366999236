import React from 'react'
import Button from 'rsuite/lib/Button'
import Icon from 'rsuite/lib/Icon'
import IconButton from 'rsuite/lib/IconButton'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import { filteredRolesPermissionsInfo } from './userUtils'
import RolesInfoFullScreen from './RolesInfoFullScreen.js'
import Input from 'rsuite/lib/Input'
import FormGroup from 'rsuite/lib/FormGroup'
import { isValidEmail } from '../../validations'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import SelectPicker from 'rsuite/lib/SelectPicker'
import Skeleton from 'react-loading-skeleton'
import { joinClasses } from '../../utils'
import useRoles from '../../services/useRoles'
import { useMatch } from '@tanstack/react-location'
import useUser from '../../services/useUser'
import { api } from '../../api/api'
import { LoadingPage } from '../../components/LoadingPage'

export function EditUser(props) {
    const { data: roles } = useRoles()
    const {
        params: { userId }
    } = useMatch()

    const { accounts, user: userProfile, currentAccount, perms, userCan } = useUser()

    const [loading, setLoading] = React.useState(false)
    const [openDialog, setOpenDialog] = React.useState(false)

    const [user, setUser] = React.useState({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        accounts: [],
        roleId: null
    })

    const [userFetchError, setUserFetchError] = React.useState(false)

    React.useEffect(() => {
        const proc = async () => {
            setUserFetchError(false)
            setLoading(true)
            try {
                const _user = await api.user.get(userId)
                _user.accounts = _user.accounts.map(a => a.accountId)
                setUser(_user)
                setLoading(false)
            } catch (err) {
                setUserFetchError(true)
            }
        }

        proc()
    }, [])

    const handleSaveClick = () => {
        const _user = JSON.parse(JSON.stringify(user))
        _user.userType = user.email.toLowerCase().includes('@sightly.com') ? 'Internal' : 'External'
        api.user.updateUserData(_user)
        let accounts = []
        for (const account of user.accounts) {
            accounts.push({ accountId: account })
        }
        api.user.updateAccounts(_user.userId, accounts)
    }

    const handleDialog = value => {
        setOpenDialog(value)
    }

    const isClean = React.useMemo(() => {
        if (user?.firstName?.length < 1) {
            return false
        }
        if (!isValidEmail(user?.email)) {
            return false
        }
        if (user?.lastName?.length < 1) {
            return false
        }
        if (user?.accounts?.length < 1) {
            return false
        }
        if (user?.roleId?.length < 1) {
            return false
        }
        if (user?.company?.length < 1) {
            return false
        }

        return true
    }, [user])

    const handleChange = (property, value) => {
        switch (property) {
            case 'company':
                setUser(prevState => {
                    let newState = {
                        ...prevState,
                        company: value
                    }
                    return newState
                })
                break

            case 'firstName':
                setUser(prevState => {
                    let newState = {
                        ...prevState,
                        firstName: value
                    }
                    return newState
                })
                break

            case 'lastName':
                setUser(prevState => {
                    let newState = {
                        ...prevState,
                        lastName: value
                    }
                    return newState
                })
                break

            case 'email':
                setUser(prevState => {
                    let newState = {
                        ...prevState,
                        email: value
                    }
                    return newState
                })
                break

            case 'roleId':
                setUser(prevState => {
                    let newState = {
                        ...prevState,
                        roleId: value
                    }
                    return newState
                })
                break

            case 'accounts':
                setUser(prevState => {
                    let newState = {
                        ...prevState,
                        accounts: value
                    }
                    return newState
                })
                break

            default:
                break
        }
    }

    const roleOptions = React.useMemo(() => {
        if (!roles || !userProfile) return []

        if (!user?.email.toLowerCase().includes('@sightly.com')) {
            return roles.filter(role => role.userType === 'External')
        }

        if (userProfile.userType === 'External') {
            return roles.filter(role => role.userType === 'External')
        }

        if (userProfile.roleId === 1) {
            return roles
        }

        return roles.filter(role => role.roleId != 1 && role.userType === 'Internal')
    }, [roles, userProfile, user?.email])

    if (userFetchError) {
        return <LoadingPage message="This user is not available" />
    }

    return (
        <>
            <div
                style={{
                    width: 500,
                    margin: 'auto'
                }}
            >
                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Company
                    </div>
                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled={!userCan(perms.USER_UPDATE)}
                            value={user.company}
                            onChange={val => handleChange('company', val)}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Email
                    </div>

                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input disabled value={user?.email} onChange={val => handleChange('email', val)} />
                    )}
                </FormGroup>

                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        First Name
                    </div>
                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled={!userCan(perms.USER_UPDATE)}
                            value={user?.firstName}
                            onChange={val => handleChange('firstName', val)}
                        />
                    )}
                </FormGroup>
                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Last Name
                    </div>
                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled={!userCan(perms.USER_UPDATE)}
                            value={user?.lastName}
                            onChange={val => handleChange('lastName', val)}
                        />
                    )}
                </FormGroup>

                <FormGroup style={{ marginTop: 27 }}>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Account
                    </div>

                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <CheckTreePicker
                            block
                            disabledItemValues={user?.email?.toLowerCase().includes('@sightly.com') ? [] : [1]}
                            disabled={!userCan(perms.USER_UPDATE)}
                            virtualized={true}
                            defaultExpandAll
                            value={user.accounts}
                            data={accounts}
                            labelKey={'accountName'}
                            valueKey={'accountId'}
                            onChange={val => handleChange('accounts', val)}
                            cascade={true}
                            preventOverflow={true}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Role
                    </div>
                    <div
                        style={{
                            position: 'relative'
                        }}
                    >
                        {loading || roleOptions.length < 1 ? (
                            <Skeleton height={36} />
                        ) : (
                            <SelectPicker
                                disabled={!userCan(perms.USER_UPDATE)}
                                id={'role'}
                                labelKey={'roleNameExtended'}
                                valueKey={'roleId'}
                                placeholder={'Role'}
                                value={user?.roleId}
                                data={roleOptions}
                                renderMenuItem={(label, item) => {
                                    return <div>{label}</div>
                                }}
                                onChange={val => handleChange('roleId', val)}
                                cleanable={false}
                                style={{ width: '100%' }}
                                preventOverflow={true}
                            />
                        )}

                        <div style={{ position: 'absolute', right: -60, top: -10 }}>
                            <Whisper
                                delayShow={700}
                                delayHide={0}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>More about Roles/Permissions</Tooltip>}
                            >
                                <IconButton
                                    icon={<Icon icon="info" />}
                                    circle
                                    size="md"
                                    appearance="ghost"
                                    onClick={() => {
                                        handleDialog(true)
                                    }}
                                    style={{ margin: '10px' }}
                                />
                            </Whisper>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup style={{ marginTop: 27 }}>
                    <Button disabled={!isClean} onClick={handleSaveClick}>
                        Save
                    </Button>
                </FormGroup>
            </div>

            <RolesInfoFullScreen
                show={openDialog}
                title="Roles and Permissions"
                handleDialog={value => {
                    handleDialog(value)
                }}
                data={filteredRolesPermissionsInfo(props.userProfile && props.userProfile.userType, roles)}
                userType={props.userProfile && props.userProfile.userType}
            />
        </>
    )
}

export default EditUser
