import React from 'react'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import Input from 'rsuite/lib/Input'
import useBoardCreator, { ItemTypes } from './useBoardCreator'
import Footer from './Footer'
import { routes } from '../../../routes'
import PillsGroup from './PillsGroup'
import Sidebar from './Sidebar'
import useBoards from './useBoards'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import { Navigate, useNavigate } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'

const General = () => {
    const { currentBrandProfile } = useBrandProfiles({
        submittedOnly: true
    })
    const brandProfileId = currentBrandProfile?.brandProfileId

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: search => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
    }, [currentBrandProfile, location.current.search])

    const boardCreator = useBoardCreator({
        brandProfileId,
        boardType: 'general'
    })

    const boardsObj = useBoards()
    const navigate = useNavigate()

    if (boardsObj && boardsObj.boardsFetched && boardsObj?.boards && boardsObj?.boards?.length > 9) {
        return <Navigate to={routes.app.discover.boards.dashboard.path} />
    }

    return (
        <div className="gridContainer">
            <Sidebar
                handleBrandProfileChange={(brandProfileId: number) => {
                    navigate({
                        search: prev => ({
                            ...prev,
                            brandProfileId
                        })
                    })
                }}
                brandProfileId={brandProfileId}
                handleSelectBoard={() => {}}
                boards={[]}
                selectedBoards={[]}
            />

            <div className="main">
                <div className="primaryContent">
                    <div className="title">General Board</div>
                    <div className="name">
                        <Input
                            id="name"
                            onChange={(val: string) => boardCreator.setName(val)}
                            placeholder="Enter a name for this board here"
                        />
                    </div>

                    <div className="subtitle">News to follow</div>
                    <div className="description">
                        Select <strong>one vertical/industry</strong> to follow
                    </div>

                    <InputGroup inside style={{ marginTop: 16, maxWidth: 559, marginBottom: 24 }}>
                        <InputGroup.Button>
                            <Icon icon="search" style={{ color: '#7F868D' }} />
                        </InputGroup.Button>
                        <Input
                            style={{ borderRadius: 3 }}
                            id="search"
                            onChange={boardCreator.setSearchTerm}
                            placeholder="Search"
                        />
                    </InputGroup>

                    <PillsGroup
                        boardType="general"
                        data={boardCreator.aggregates}
                        count={
                            boardCreator.industriesTargetedCount +
                            boardCreator.newsTargetedCount +
                            boardCreator.aggregateTargetedCount
                        }
                        handleSelectItem={boardCreator.handleSelectItem}
                        selectedCount={boardCreator.selectedCount}
                        itemType={ItemTypes.aggregate}
                        searchTerm={''}
                        WarningMessage={<></>}
                    />

                    {boardCreator.newsTargetedCount > 0 && (
                        <>
                            <PillsGroup
                                boardType="general"
                                data={boardCreator.aylienNews}
                                count={
                                    boardCreator.industriesTargetedCount +
                                    boardCreator.newsTargetedCount +
                                    boardCreator.aggregateTargetedCount
                                }
                                handleSelectItem={boardCreator.handleSelectItem}
                                selectedCount={boardCreator.selectedCount}
                                itemType={ItemTypes.aylienNews}
                                searchTerm={boardCreator.searchTerm}
                                WarningMessage={<></>}
                            />
                        </>
                    )}

                    {boardCreator.industriesTargetedCount > 0 && (
                        <PillsGroup
                            boardType="general"
                            data={boardCreator.aylienIndustries}
                            count={
                                boardCreator.industriesTargetedCount +
                                boardCreator.newsTargetedCount +
                                boardCreator.aggregateTargetedCount
                            }
                            handleSelectItem={boardCreator.handleSelectItem}
                            selectedCount={boardCreator.selectedCount}
                            itemType={ItemTypes.aylienIndustries}
                            searchTerm={boardCreator.searchTerm}
                            WarningMessage={<></>}
                        />
                    )}

                    {boardCreator.industriesTargetedCount + boardCreator.newsTargetedCount < 1 && (
                        <div>
                            Please{' '}
                            <a
                                href={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/opinions`}
                            >
                                return to your Brand Profile Opinions section
                            </a>{' '}
                            and designate some opinions which your Brand agrees with in order to receive topic
                            recommendations.{' '}
                        </div>
                    )}
                </div>
            </div>
            <Footer
                isCreating={boardCreator.isCreating}
                handleCreate={() => boardCreator.createBoard()}
                disabled={boardCreator.selectedCount < 1}
            />

            <style>{`

.gridContainer {
	display: grid;
	 height: 100%;	
	grid-template-columns: 265px 1fr;	
	grid-template-rows:  1fr 64px;
	grid-template-areas:
		"sidebar main"
		"sidebar footer"		
}		

.main {
	grid-area: main;
	padding: 25px 38px;
	overflow-y: auto;
	justify-content: center;
	display: flex; 
 
}  

.primaryContent {
	width: 500px;
}

  .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #333D47;
    margin-bottom: 18px;
  }
  .name {
    width: 559px;
  }
  .subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 26px;
    margin-bottom: 16px;
  }
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    max-width: 790px;
    margin-bottom: 16px;
  }
  
`}</style>
        </div>
    )
}

export default General
