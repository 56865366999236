import React from 'react'
import { TrendVideo } from '../../../../classes/trend'
import ytLogo from '../../../../assets/img/ytLogo.png'
import FeedbackButton from '../../../../components/FeedbackButton'
import { bgColor } from '../../../../assets/jss/colorContants'

interface iProps {
	video: TrendVideo
	handleVideoFeedback: Function
}

const openInNewTab = (url: string) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const TrendVideoComponent = ({ video, handleVideoFeedback }: iProps) => {
	const _title = React.useMemo(() => {
		if (video?.videoTitle?.length > 99) {
			return video?.videoTitle?.substring(0, 98) + ' ...'
		}
		return video?.videoTitle
	}, [video])
	return (
		<div
			style={{
				width: 540,
				height: 129,
				borderBottom: '1px solid rgb(212, 217, 217, 0.5)',
				marginBottom: 7,
				position: 'relative',
				textAlign: 'left'
			}}
		>
			<div style={{ display: 'flex' }}>
				<div
					style={{
						marginRight: 28,
						width: 100,
						height: 75,
						alignItems: 'center',
						display: 'flex'
					}}
				>
					<div
						onClick={() =>
							openInNewTab(
								`https://www.youtube.com/embed/${video.videoId}?autoplay=1`
							)
						}
						style={{ position: 'relative', cursor: 'pointer' }}
					>
						<img
							style={{ width: 75 }}
							src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
						/>
						<div style={{ position: 'absolute', top: 22, left: 30 }}>
							<img style={{ width: 42 }} src={ytLogo} />
						</div>
					</div>
				</div>

				<div
					style={{
						width: 400,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center'
					}}
				>
					<div
						onClick={() =>
							openInNewTab(`https://www.youtube.com/watch?v=${video.videoId}`)
						}
						className='sightlyLink'
						style={{
							flex: 1,
							fontWeight: 600,
							fontSize: 16,
							lineHeight: '24px',
							color: '#333D47'
						}}
					>
						{_title}
					</div>

					<div
						onClick={() =>
							openInNewTab(`https://www.youtube.com/channel/${video.channelId}`)
						}
						className='sightlyLink'
						style={{
							flex: 1,
							fontWeight: 600,
							fontSize: 12,
							lineHeight: '20px',
							color: '#6e8399',
							width: 400,

							marginTop: 4
						}}
					>
						{video.channelTitle}
					</div>

					<div
						style={{
							flex: 1,
							fontWeight: 600,
							fontSize: 12,
							lineHeight: '20px',
							color: '#5F7284',
							width: 400,

							marginTop: 4
						}}
					></div>
				</div>

				<div
					style={{
						position: 'absolute',
						bottom: 8,
						left: 0,
						display: 'flex'
					}}
				>
					<div style={{ marginRight: 16 }}>
						<FeedbackButton
							text='Video not related to moment'
							disagree={video.feedback.videoRelatedToTrend}
							handleClick={(feedback: TrendVideo['feedback']) =>
								handleVideoFeedback('videoRelatedToTrend', feedback, video)
							}
						/>
					</div>

					<div style={{ marginRight: 16 }}>
						<FeedbackButton
							text='Language not English'
							disagree={video.feedback.languageIsEnglish}
							handleClick={(feedback: TrendVideo['feedback']) =>
								handleVideoFeedback('languageIsEnglish', feedback, video)
							}
						/>
					</div>
					<FeedbackButton
						text='Date relevancy is invalid'
						disagree={video.feedback.dateRelevancyIsInvalid}
						handleClick={(feedback: TrendVideo['feedback']) =>
							handleVideoFeedback('dateRelevancyIsInvalid', feedback, video)
						}
					/>
				</div>
			</div>
		</div>
	)
}

export default TrendVideoComponent
