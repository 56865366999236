import React from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Trend, TrendVideo } from '../../../classes/trend'
import { listBuilderAxios } from '../../../axiosInstances'
import { useNavigate } from '@tanstack/react-location'
import useUser from '../../../services/useUser'
import { rqKeys } from '../../../ReactQueryKeyFactory'

type GetMomentsProps = {
    brandProfileId: number | undefined
    boardIds: number[] | undefined
    sortBy: string
    timeRange: number | undefined
    actions: string[] | undefined
    searchTerm: string
    aylienNews: string[]
    aylienIndustries: string[]
    page: number
    signal: AbortSignal | undefined // React query gives us this to use as axios cancel signal, to prevent duplicate calls
}

type GetMomentsResultProps = {
    data: Trend[]
    metaData: {
        totalResultCount: number
        page: number
        requestedPageSize: number
    }
}

const getMoments = async ({
    brandProfileId,
    boardIds,
    sortBy,
    timeRange,
    actions,
    searchTerm,
    aylienIndustries,
    aylienNews,
    page,
    signal //axios cancel
}: GetMomentsProps): Promise<GetMomentsResultProps | undefined> => {
    console.log('requestingPage:', page)
    if (typeof brandProfileId === undefined) {
        return Promise.reject(new Error('undefined brandprofileId'))
    }
    if (typeof boardIds === undefined) {
        return Promise.reject(new Error('undefined boardIds'))
    }
    if (typeof timeRange === undefined) {
        return Promise.reject(new Error('undefined timeRange'))
    }
    if (typeof actions === undefined) {
        return Promise.reject(new Error('undefined actions'))
    }
    const url = `/brand-profile/${brandProfileId}/moments`

    const res = await listBuilderAxios.post(
        url,
        {
            boardIds,
            timeRange,
            actions,
            size: 20,
            page,
            sortBy,
            searchTerm,
            aylienNews,
            aylienIndustries
        },
        {
            signal
        }
    )

    return {
        data: res.data.data,
        metaData: res.data.metaData
    }
}

type IProps = {
    brandProfileId: number | undefined
    boardIds: number[]
    timeRange: number
    searchTerm: string
    sortBy: string
    actions: string[] | undefined
}

export const useMoments = ({ brandProfileId, boardIds, timeRange, searchTerm, sortBy, actions }: IProps) => {
    const [selectedTrends, setSelectedTrends] = React.useState<Trend[]>([])
    const [videos, setVideos] = React.useState<Trend['videos']>([])
    const [aylienNews, setAylienNews] = React.useState<string[]>([])
    const [aylienIndustries, setAylienIndustries] = React.useState<string[]>([])

    const selectedItems = React.useMemo(() => {
        return selectedTrends.map((trend: Trend) => trend.trendId)
    }, [selectedTrends])

    const [page, setPage] = React.useState(1)

    const queryKey = rqKeys.moments({
        boardIds,
        brandProfileId,
        sortBy,
        timeRange,
        actions,
        searchTerm,
        aylienIndustries,
        aylienNews,
        page
    })

    interface AylienTaxonomy {
        id: number
        taxonomyPath: string
        taxonomyCode: string
    }

    type Taxonomies = {
        aylienIndustries: AylienTaxonomy[]
        aylienNews: AylienTaxonomy[]
    }

    const getTaxonomies = async (signal: AbortSignal | undefined) => {
        const url = `/brand-profile/${brandProfileId}/moments/aylien-taxonomies`
        const res = await listBuilderAxios.post<Taxonomies>(
            url,
            {
                timeRange,
                boardIds,
                actions,
                searchTerm
            },
            {
                signal
            }
        )
        return res.data
    }

    const taxonomies = useQuery(
        rqKeys.taxonomies({ brandProfileId, timeRange, boardIds, actions }),
        ({ signal }) => getTaxonomies(signal),
        {
            onSettled: () => {
                setAylienNews([])
                setAylienIndustries([])
            },
            enabled: !!timeRange && !!brandProfileId && !!boardIds && boardIds.length > 0,
            refetchOnWindowFocus: false
        }
    )

    const queryClient = useQueryClient()

    const tempObj = useQuery(
        queryKey,
        ({ signal }) =>
            getMoments({
                brandProfileId,
                boardIds,
                sortBy,
                timeRange,
                actions,
                searchTerm,
                aylienIndustries,
                aylienNews,
                page,
                signal
            }),
        {
            refetchOnWindowFocus: false,
            enabled:
                brandProfileId !== undefined &&
                !!brandProfileId &&
                !!boardIds &&
                boardIds.length > 0 &&
                actions &&
                actions.length > 0 &&
                !!timeRange
        }
    )

    const moments = tempObj?.data?.data
    const momentsIsLoading = tempObj?.isLoading
    const momentsIsFetching = tempObj?.isFetching
    const totalResultCount = tempObj?.data?.metaData.totalResultCount || 0
    const metaData = tempObj?.data?.metaData || {
        totalResultCount: 0,
        requestedPageSize: 0,
        page: 0
    }
    const hasNextPage = metaData ? metaData?.totalResultCount > metaData?.page * metaData?.requestedPageSize : false

    type SaveActionEditProps = {
        trendId: string
        brandProfileId: number
        action: string
    }

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const addMetaData = (moments: Trend[]) => {
        return {
            data: moments,
            metaData: metaData
        }
    }

    const saveActionEdit = useMutation(
        ({ trendId, brandProfileId, action }: SaveActionEditProps) => {
            const url = `/brand-profile/${brandProfileId}/moments/${trendId}/action`
            return listBuilderAxios.post(url, { action })
        },
        {
            onMutate: async ({ trendId, brandProfileId, action }: SaveActionEditProps) => {
                queryClient.setQueryData(queryKey, (momentsResult: GetMomentsResultProps | undefined) => {
                    const old = momentsResult?.data
                    if (!old) return addMetaData([])
                    const newTrends = old.map(t => {
                        if (t.trendId === trendId) {
                            return {
                                ...t,
                                editedFromAction: t.action,
                                action: action,
                                editedBy: `${user?.firstName} ${user?.lastName}`,
                                editedDateTime: new Date().toJSON()
                            }
                        }
                        return t
                    })
                    return addMetaData(newTrends)
                })

                setSelectedTrends((prev: Trend[]) => {
                    return prev.map((t: Trend) => {
                        return {
                            ...t,
                            action: t.trendId === trendId ? action : t.action
                        }
                    })
                })
            }
        }
    )

    const changeTrendsToBlocked = useMutation(
        ({ trendIds }: { trendIds: string[] }) => {
            return Promise.resolve()
        },
        {
            onMutate: async ({ trendIds }: { trendIds: string[] }) => {
                queryClient.setQueryData(queryKey, (momentsResult: GetMomentsResultProps | undefined) => {
                    const old = momentsResult?.data
                    if (!old) return addMetaData([])
                    const newTrends = old.map(t => {
                        if (trendIds.includes(t.trendId)) {
                            t.gAdsBlocked = true
                        }
                        return t
                    })
                    return addMetaData(newTrends)
                })
            }
        }
    )

    const selectTrend = useMutation(
        (trend: Trend) => {
            return Promise.resolve()
        },
        {
            onMutate: async (trend: Trend) => {
                setSelectedTrends((prev: Trend[]) => {
                    return selectedItems.includes(trend.trendId)
                        ? prev.filter((i: Trend) => i.trendId !== trend.trendId)
                        : prev.concat(trend)
                })
            }
        }
    )

    const selectAllOnPage = useMutation(
        () => {
            return Promise.resolve(true)
        },
        {
            onMutate: async (isSelected: boolean) => {
                if (isSelected) {
                    const currentTrends = moments?.map((i: Trend) => i.trendId) || []
                    setSelectedTrends((prev: Trend[]) => {
                        const final = prev
                            .filter((t: Trend) => !currentTrends.includes(t.trendId))
                            .concat(moments || [])
                        return final
                    })
                } else {
                    setSelectedTrends([])
                }
            }
        }
    )

    const navigate = useNavigate()

    const recActionFeedback = useMutation(
        (args: { trendId: string; feedback: string; brandProfileId: number }) => {
            let url = `/brand-profile/${brandProfileId}/moments/${args.trendId}/feedback-recommended-action`
            return listBuilderAxios.post(url, {
                feedback: args.feedback
            })
        },
        {
            onMutate: async (args: { trendId: string; feedback: string; brandProfileId: number }) => {
                queryClient.setQueryData(queryKey, (momentsResult: GetMomentsResultProps | undefined) => {
                    const old = momentsResult?.data
                    if (!old) return addMetaData([])

                    const newTrends = old.map((t: Trend) => {
                        if (t.trendId === args.trendId) {
                            return {
                                ...t,
                                recommendedActionFeedback: args.feedback
                            }
                        }
                        return t
                    })
                    return addMetaData(newTrends)
                })
            }
        }
    )

    const questionFeedback = (args: {
        trendId: string
        feedback: string
        question: string
        brandProfileId: number
    }) => {
        let url = `/brand-profile/${brandProfileId}/moments/${args.trendId}/feedback-question`
        return listBuilderAxios.post(url, {
            feedback: args.feedback,
            actionJustificationQuestion: args.question
        })
    }

    interface FeedbackServiceBody {
        brandProfileId: number
        videoId: string
        trendId: string
        videoRelatedToTrend?: string | null
        languageIsEnglish?: string | null
        dateRelevancyIsInvalid?: string | null
    }

    const videoFeedback = (args: {
        type: string
        brandProfileId: number
        video: TrendVideo
        trendId: string
        feedback: string
    }) => {
        const _videos = JSON.parse(JSON.stringify(videos))

        let _body: FeedbackServiceBody = {
            brandProfileId: args.brandProfileId,
            videoId: args.video.videoId,
            trendId: args.trendId
        }

        for (const video of _videos) {
            if (video.videoId === args.video.videoId) {
                if (args.type === 'videoRelatedToTrend') {
                    video.feedback.videoRelatedToTrend = args.feedback
                    _body.videoRelatedToTrend = args.feedback
                }
                if (args.type === 'languageIsEnglish') {
                    video.feedback.languageIsEnglish = args.feedback
                    _body.languageIsEnglish = args.feedback
                }
                if (args.type === 'dateRelevancyIsInvalid') {
                    video.feedback.dateRelevancyIsInvalid = args.feedback
                    _body.dateRelevancyIsInvalid = args.feedback
                }
            }
        }
        setVideos(_videos)

        let url = `/brand-profile/${brandProfileId}/moments/${args.trendId}/feedback-video`
        const _args = (({ trendId, brandProfileId, ...o }) => o)(_body) //removes trendId and brandProfileId from args

        return listBuilderAxios.post(url, { ..._args })
    }

    const keywordsFeedback = useMutation(
        (args: { trendId: string; feedback: string; brandProfileId: number }) => {
            const url = `/brand-profile/${brandProfileId}/moments/${args.trendId}/feedback-keywords`
            return listBuilderAxios.post(url, {
                feedback: args.feedback
            })
        },
        {
            onMutate: async (args: { trendId: string; feedback: string }) => {
                queryClient.setQueryData(queryKey, (momentsResult: GetMomentsResultProps | undefined) => {
                    const old = momentsResult?.data
                    if (!old) return addMetaData([])
                    const newTrends = old.map((t: Trend) => {
                        if (t.trendId === args.trendId) {
                            return { ...t, keywordsFeedback: args.feedback }
                        }
                        return t
                    })
                    return addMetaData(newTrends)
                })
            }
        }
    )

    interface UpdateProps {
        trendId: string
        recommendedAction: string
    }

    const updateTrendScenarios: any = useMutation(
        ({ trendId, recommendedAction }: UpdateProps) => {
            return Promise.resolve()
        },
        {
            onMutate: async ({ trendId, recommendedAction }: UpdateProps) => {
                queryClient.setQueryData(queryKey, (momentsResult: GetMomentsResultProps | undefined) => {
                    const old = momentsResult?.data
                    if (!old) return addMetaData([])
                    const newTrends = old.map((t: Trend) => {
                        if (t.trendId === trendId) {
                            return {
                                ...t,
                                action: recommendedAction
                            }
                        }
                        return t
                    })
                    return addMetaData(newTrends)
                })
            }
        }
    )

    React.useEffect(() => {
        selectAllOnPage.mutate(false)
        setPage(1)
        setSelectedTrends([])
    }, [actions, timeRange, aylienNews, aylienIndustries, searchTerm])

    React.useEffect(() => {
        setPage(1)
    }, [sortBy])

    return {
        moments: moments,
        handleSaveActionEdit: saveActionEdit.mutate,
        handleSelectTrend: selectTrend.mutate,
        handleSelectAllOnPage: selectAllOnPage.mutate,
        momentsIsLoading: momentsIsLoading || momentsIsFetching || taxonomies.isLoading,
        sortBy,
        setSortBy: (val: string) => {
            navigate({
                search: prev => {
                    return {
                        ...prev,
                        sortBy: val
                    }
                }
            })
        },
        setActions: (val: string[]) => {
            navigate({
                search: prev => {
                    return {
                        ...prev,
                        actions: val
                    }
                }
            })
        },
        selectedItems,
        postTrendRecActionFeedback: recActionFeedback.mutate,
        postTrendQuestionFeedback: questionFeedback,
        postTrendVideoFeedback: videoFeedback,
        postTrendKeywordsFeedback: keywordsFeedback.mutate,
        videos,
        setVideos,
        updateTrendScenarios: updateTrendScenarios.mutate,
        aylienNewsOptions: taxonomies?.data?.aylienNews || [],
        aylienIndustriesOptions: taxonomies?.data?.aylienIndustries || [],
        setAylienNews,
        setAylienIndustries,
        aylienNews,
        aylienIndustries,
        changeTrendsToBlocked: changeTrendsToBlocked.mutate,
        setPage,
        page,
        totalResultCount,
        hasNextPage,
        requestedPageSize: metaData?.requestedPageSize || 0,
        selectedTrends
    }
}
