import React from 'react'
import { routes } from '../../../routes'
import Icon from 'rsuite/lib/Icon'
import IconButton from 'rsuite/lib/IconButton'
import Whisper from 'rsuite/lib/Whisper'
import Dropdown from 'rsuite/lib/Dropdown'
import Popover from 'rsuite/lib/Popover'
import orderBy from 'lodash/orderBy'
import DownloadModal from './components/DownloadModal'
import { objectives, archivedStatuses, targetTypes } from './constants'
import InputPicker from 'rsuite/lib/InputPicker'
import Table from 'rsuite/lib/Table'
import useWindowDimensions from '../../../useWindowDimensions'
import SightlyButton from '../../../components/SightlyButton'
import { cloneListVersionService, downloadExcelListService } from '../../../services/lists'
import toast from 'react-hot-toast'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import useLists from '../../../services/useLists'
import NoBrandProfiles from '../../../components/NoBrandProfiles'
import { useMatch, useNavigate } from '@tanstack/react-location'
import useUser from '../../../services/useUser'

function Lists(props) {
    const { data: brandProfiles, isFetched: brandProfilesFetched } = useBrandProfiles({
        submittedOnly: true
    })

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const currentAccountId = currentAccount?.accountId
    const { data: lists, isLoading, isFetching, archiveList } = useLists(currentAccountId, brandProfiles)
    const navigate = useNavigate()
    const windowDims = useWindowDimensions()
    const [showDownloadModal, setShowDownloadModal] = React.useState(false)
    const [downloadingVersionId, setDownloadingVersionId] = React.useState(null)
    const [isPostingList, setIsPostingList] = React.useState(false)
    const [isPostingListVersionId, setIsPostingListVersionId] = React.useState(null)

    const [currentSort, setCurrentSort] = React.useState({
        sortColumn: 'brandName',
        sortType: 'desc'
    })
    const [filterState, setFilterState] = React.useState({
        objectiveId: null,
        brandProfileId: null,
        targetTypeId: null,
        smartListId: null,
        activeStatusId: 1,
        archivedStatusId: 2
    })

    const handleCreateNewList = () => {
        navigate({
            to: routes.app.engage.lists.createList.path,
            from: 'lists'
        })
    }

    const handleUploadList = () => {
        navigate({
            to: routes.app.engage.lists.uploadList.path,
            from: 'lists'
        })
    }

    const handleEditClick = async item => {
        setIsPostingList(true)
        setIsPostingListVersionId(item.versionId)
        let params = {
            versionId: item.versionId,
            smartListName: item.smartListName,
            brandProfileName: item.brandProfileName
        }
        let result = await cloneListVersionService(params)

        if (!result.versionId) {
            toast.error('Error cloning SmartList')
            return
        }

        setIsPostingList(false)
        setIsPostingListVersionId(null)

        if (item.uploaded) {
            navigate({ to: `/app/engage/lists/videoListBuilder/${result.versionId}/edit` })
        } else {
            if (result.isVideo) {
                navigate({ to: `/app/engage/lists/videoListBuilder/${result.versionId}/edit` })
            } else {
                navigate({ to: `/app/engage/lists/channelListBuilder/${result.versionId}/edit` })
            }
        }
    }

    const handleViewClick = item => {
        if (item.uploaded) {
            navigate({ to: `/app/engage/lists/videoListBuilder/${item.versionId}/view` })
        } else {
            if (item.isVideo) {
                navigate({ to: `/app/engage/lists/videoListBuilder/${item.versionId}/view` })
            } else {
                navigate({ to: `/app/engage/lists/channelListBuilder/${item.versionId}/view` })
            }
        }
    }

    const smartLists = React.useMemo(() => {
        if (!lists) {
            return []
        }
        let _smartLists = []
        let currentIds = []

        for (const list of lists) {
            if (!currentIds.includes(list.smartListId)) {
                _smartLists.push(list)
                currentIds.push(list.smartListId)
            }
        }
        return _smartLists
    }, [lists])

    const handleFilter = list => {
        if (filterState.objectiveId && list.objectiveId != filterState.objectiveId) {
            return false
        }

        if (filterState.targetTypeId && list.targetTypeId != filterState.targetTypeId) {
            return false
        }

        if (filterState.brandProfileId && list.brandProfileId != filterState.brandProfileId) {
            return false
        }

        if (filterState.smartListId && list.smartListId != filterState.smartListId) {
            return false
        }

        if (filterState.activeStatusId && list.active != (filterState.activeStatusId === 1 ? true : false)) {
            return false
        }

        if (filterState.archivedStatusId && list.archived != (filterState.archivedStatusId === 1 ? true : false)) {
            return false
        }

        return true
    }

    const visibleLists = React.useMemo(() => {
        if (!lists) {
            return []
        }
        let filtered = lists.filter(list => handleFilter(list))
        let { sortColumn, sortType } = currentSort
        let sorted = orderBy(
            filtered,
            [
                item => {
                    if (typeof item[sortColumn] === 'string') {
                        return item[sortColumn].toLowerCase()
                    } else {
                        return item[sortColumn]
                    }
                }
            ],
            [sortType]
        )

        return sorted
    }, [lists, currentSort, filterState])

    const ActionCell = ({ rowData, dataKey, customProps, isPostingList, isPostingListVersionId, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group" style={{ align: 'center', padding: 5 }}>
                <CustomWhisper rowData={rowData}>
                    <IconButton
                        appearance="subtle"
                        icon={<Icon icon="more" />}
                        loading={
                            (customProps.isDownloadingExcel &&
                                customProps.isDownloadingExcelVersionId === rowData.versionId) ||
                            (isPostingList && isPostingListVersionId === rowData.versionId)
                        }
                    />
                </CustomWhisper>
            </Table.Cell>
        )
    }

    const handleDownloadClick = async version => {
        if (version.uploaded) {
            let payload = {
                versionId: version.versionId,
                smartListName: version.smartListName
            }
            downloadExcelListService(payload)
        } else {
            setDownloadingVersionId(version.versionId)
            setShowDownloadModal(true)
        }
    }

    const Menu = props => {
        return (
            <Dropdown.Menu onSelect={props.onSelect}>
                <Dropdown.Item
                    id="download"
                    disabled={
                        !userCan(perms.SMARTLIST_DOWNLOAD) || (!props.rowData.hasEnrichedData && props.rowData.uploaded)
                    }
                    onClick={e => {
                        handleDownloadClick(props.rowData)
                    }}
                >
                    Download
                </Dropdown.Item>
                {!props.rowData.archived && (
                    <Dropdown.Item
                        id="archive"
                        disabled={!userCan(perms.SMARTLIST_ARCHIVE)}
                        eventKey={7}
                        onClick={() => {
                            const payload = {
                                smartListId: props.rowData.smartListId,
                                archive: true
                            }
                            archiveList(payload)
                        }}
                    >
                        Archive
                    </Dropdown.Item>
                )}

                {props.rowData.archived && (
                    <Dropdown.Item
                        id="unarchive"
                        disabled={!userCan(perms.SMARTLIST_ARCHIVE)}
                        onClick={() => {
                            const payload = {
                                smartListId: props.rowData.smartListId,
                                archive: false
                            }
                            archiveList(payload)
                        }}
                    >
                        Unarchive
                    </Dropdown.Item>
                )}

                <Dropdown.Item
                    id="edit"
                    disabled={
                        !userCan(perms.SMARTLIST_EDIT) ||
                        !userCan(perms.SMARTLIST_READ) ||
                        (!props.rowData.hasEnrichedData && props.rowData.uploaded)
                    }
                    eventKey={7}
                    onClick={() => {
                        handleEditClick(props.rowData)
                    }}
                >
                    Edit
                </Dropdown.Item>

                <Dropdown.Item
                    id="view"
                    disabled={
                        !userCan(perms.SMARTLIST_READ) || (!props.rowData.hasEnrichedData && props.rowData.uploaded)
                    }
                    onClick={() => {
                        handleViewClick(props.rowData)
                    }}
                >
                    View
                </Dropdown.Item>
            </Dropdown.Menu>
        )
    }

    const MenuPopover = ({ onSelect, rowData, ...rest }) => (
        <Popover {...rest} full>
            <Menu onSelect={onSelect} rowData={rowData} />
        </Popover>
    )

    let tableBody

    class CustomWhisper extends React.Component {
        constructor(props) {
            super(props)
            this.handleSelectMenu = this.handleSelectMenu.bind(this)
        }
        handleSelectMenu(eventKey, event) {
            this.trigger.hide()
        }
        render() {
            return (
                <Whisper
                    placement="topEnd"
                    trigger="click"
                    triggerRef={ref => {
                        this.trigger = ref
                    }}
                    container={() => {
                        return tableBody
                    }}
                    speaker={<MenuPopover rowData={this.props.rowData} onSelect={this.handleSelectMenu} />}
                >
                    {this.props.children}
                </Whisper>
            )
        }
    }

    if (brandProfiles?.length < 1 && brandProfilesFetched) {
        return <NoBrandProfiles />
    }

    return (
        <div style={{ padding: 24 }}>
            <DownloadModal
                show={showDownloadModal}
                setShow={() => setShowDownloadModal(false)}
                versionId={downloadingVersionId}
            />

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                {userCan(perms.SMARTLIST_CREATE) && (
                    <SightlyButton id="smartListCreateButton" handleClick={handleCreateNewList} text="Build" />
                )}

                <div style={{ width: 8 }}></div>
                {userCan(perms.SMARTLIST_CREATE_UPLOAD) && (
                    <SightlyButton id="smartListUploadButton" handleClick={handleUploadList} text="Upload" />
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 24,
                    marginBottom: 24
                }}
            >
                <InputPicker
                    size={'sm'}
                    id="brandProfileId"
                    label="Brand Profile"
                    placeholder="Filter by Brand Profile"
                    labelKey="brandName"
                    valueKey="brandProfileId"
                    data={brandProfiles}
                    value={filterState.brandProfileId}
                    onChange={val =>
                        setFilterState(prevState => {
                            return {
                                ...prevState,
                                brandProfileId: val
                            }
                        })
                    }
                />

                <InputPicker
                    size={'sm'}
                    id="smartListId"
                    label="SmartList"
                    placeholder="Select a SmartList"
                    labelKey="smartListName"
                    valueKey="smartListId"
                    data={smartLists}
                    value={filterState.smartListId}
                    onChange={val =>
                        setFilterState(prevState => {
                            return {
                                ...prevState,
                                smartListId: val
                            }
                        })
                    }
                />

                <InputPicker
                    size={'sm'}
                    id="objectiveId"
                    label="Objective"
                    placeholder="Filter by Objective"
                    labelKey="objectiveName"
                    valueKey="objectiveId"
                    data={objectives}
                    value={filterState.objectiveId}
                    onChange={val =>
                        setFilterState(prevState => {
                            return {
                                ...prevState,
                                objectiveId: val
                            }
                        })
                    }
                />

                <InputPicker
                    size={'sm'}
                    id="targetTypeId"
                    label="SmartList Type"
                    placeholder="Filter by SmartList Type"
                    labelKey="targetTypeName"
                    valueKey="targetTypeId"
                    data={targetTypes}
                    value={filterState.targetTypeId}
                    onChange={val =>
                        setFilterState(prevState => {
                            return {
                                ...prevState,
                                targetTypeId: val
                            }
                        })
                    }
                />

                <InputPicker
                    size={'sm'}
                    id="archivedStatusId"
                    label="Archived"
                    placeholder="Select a status"
                    labelKey="archivedStatusName"
                    valueKey="archivedStatusId"
                    data={archivedStatuses}
                    value={filterState.archivedStatusId}
                    onChange={val =>
                        setFilterState(prevState => {
                            return {
                                ...prevState,
                                archivedStatusId: val
                            }
                        })
                    }
                />
            </div>
            <Table
                renderEmpty={() => {
                    return <div></div>
                }}
                loading={isLoading || isFetching}
                height={windowDims.height - 300}
                affixHeader={0}
                data={visibleLists}
                sortColumn={currentSort.sortColumn}
                sortType={currentSort.sortType}
                onSortColumn={(sortColumn, sortType) => {
                    setCurrentSort({ sortColumn, sortType })
                }}
            >
                <Table.Column width={80} sortable>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="versionId" />
                </Table.Column>

                <Table.Column flexGrow={1} sortable>
                    <Table.HeaderCell>SmartList</Table.HeaderCell>
                    <Table.Cell dataKey="smartListName" />
                </Table.Column>

                <Table.Column flexGrow={1} sortable>
                    <Table.HeaderCell>Brand Profile</Table.HeaderCell>
                    <Table.Cell dataKey="brandProfileName" />
                </Table.Column>
                <Table.Column flexGrow={1} sortable>
                    <Table.HeaderCell>Objective</Table.HeaderCell>
                    <Table.Cell dataKey="objectiveName" />
                </Table.Column>
                <Table.Column flexGrow={1} sortable>
                    <Table.HeaderCell>Content</Table.HeaderCell>
                    <Table.Cell dataKey="content" />
                </Table.Column>
                <Table.Column flexGrow={1} sortable>
                    <Table.HeaderCell>SmartList Type</Table.HeaderCell>
                    <Table.Cell dataKey="targetType" />
                </Table.Column>

                <Table.Column sortable flexGrow={1}>
                    <Table.HeaderCell>Enrichment Status</Table.HeaderCell>
                    <Table.Cell dataKey="enrichmentStatus" />
                </Table.Column>

                <Table.Column width={65}>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell
                        customProps={props}
                        isPostingList={isPostingList}
                        isPostingListVersionId={isPostingListVersionId}
                    />
                </Table.Column>
            </Table>
        </div>
    )
}

export default Lists
