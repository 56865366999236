import {
	SET_ADMIN_SCENARIOS_IS_LOADING,
	SET_ADMIN_BRAND_SCENARIOS,
	SET_SCENARIO_LABELS,
	SET_SCENARIO_LABELS_IS_LOADING,
	SET_ADD_SCENARIO,
	SET_UPDATED_SCENARIO,
	SET_SCENARIO_SAVING,
	SET_ADMIN_LABELS,
	SET_LABELS_IS_LOADING,
	SET_LABEL_ARCHIVING,
	SET_LABEL_TO_ARCHIVE,
	SET_ADD_LABEL,
	SET_LABEL_SAVING,
	SET_LABEL_TO_CREATE
} from '../../action-types/admin/scenarios'

export function scenarios(state = [], action) {
	switch (action.type) {
		case SET_ADMIN_BRAND_SCENARIOS:
			return action.scenarios

		case SET_ADD_SCENARIO:
			let stateData = []
			if (state && state.length > 0) {
				stateData = JSON.parse(JSON.stringify(state))
			}
			stateData.push(action.scenario)

			return stateData
		case SET_UPDATED_SCENARIO:
			let updatedState = [
				...state.map((scenario) =>
					scenario.scenarioId === action.scenario.scenarioId
						? action.scenario
						: scenario
				)
			]
			return updatedState
		default:
			return state
	}
}

export function scenarioLabels(state = [], action) {
	switch (action.type) {
		case SET_SCENARIO_LABELS:
			return action.scenarioLabels
		default:
			return state
	}
}

export function scenariosIsLoading(state = true, action) {
	switch (action.type) {
		case SET_ADMIN_SCENARIOS_IS_LOADING:
			return action.adminScenariosIsLoading
		default:
			return state
	}
}

export function scenariosLabelsIsLoading(state = false, action) {
	switch (action.type) {
		case SET_SCENARIO_LABELS_IS_LOADING:
			return action.scenariosLabelsIsLoading
		default:
			return state
	}
}

export function scenarioSaving(state = false, action) {
	switch (action.type) {
		case SET_SCENARIO_SAVING:
			return action.scenarioSaving
		default:
			return state
	}
}

export function labels(state = [], action) {
	switch (action.type) {
		case SET_ADMIN_LABELS:
			return action.labels
		case SET_LABEL_TO_ARCHIVE:
			let newState = [
				...state.map((label) =>
					label.labelId === action.labelId
						? { ...label, archived: true }
						: label
				)
			]
			return newState
		case SET_ADD_LABEL:
			let stateData = []
			if (state && state.length > 0) {
				stateData = JSON.parse(JSON.stringify(state))
			}
			stateData.push(action.label)

			return stateData
		default:
			return state
	}
}

export function labelArchiving(state = '', action) {
	switch (action.type) {
		case SET_LABEL_ARCHIVING:
			return action.labelArchiving
		default:
			return state
	}
}

export function labelsIsLoading(state = false, action) {
	switch (action.type) {
		case SET_LABELS_IS_LOADING:
			return action.labelsIsLoading
		default:
			return state
	}
}

export function labelSaving(state = false, action) {
	switch (action.type) {
		case SET_LABEL_SAVING:
			return action.labelSaving
		default:
			return state
	}
}

export function initLabelAdd(state = false, action) {
	switch (action.type) {
		case SET_LABEL_TO_CREATE:
			return action.initLabelAdd
		default:
			return state
	}
}
