import React from 'react'
import Button from 'rsuite/lib/Button'
import Table from 'rsuite/lib/Table'
import Input from 'rsuite/lib/Input'
import Checkbox from 'rsuite/lib/Checkbox'
import Panel from 'rsuite/lib/Panel'
import toast from 'react-hot-toast'
import useRoles from '../../../services/useRoles'

function Roles(props) {
    const { data: roles, archiveRole, createRole } = useRoles()
    const [newRoleName, setNewRoleName] = React.useState('')
    const [internal, setInternal] = React.useState(false)
    const [description, setDescription] = React.useState('')

    const ActionCell = ({ rowData, dataKey, ...cellprops }) => {
        return (
            <Table.Cell {...cellprops} style={{ padding: 1 }}>
                <Button
                    disabled={rowData.roleId === 1}
                    appearance="link"
                    onClick={() => {
                        archiveRole(rowData.roleId)
                    }}
                >
                    Archive
                </Button>
            </Table.Cell>
        )
    }

    const createRoleClick = () => {
        if (newRoleName.length < 1) {
            toast.error('Role name must be defined')
            return
        }
        if (description.length < 1) {
            toast.error('Role description must be defined')
            return
        }
        createRole({ internal, newRoleName, description })
    }

    return (
        <div style={{ padding: 24 }}>
            <div>
                <Panel header="Create a new role" bordered>
                    <Input
                        style={{ marginBottom: 8 }}
                        value={newRoleName}
                        onChange={val => setNewRoleName(val)}
                        placeholder="Enter role name here..."
                    />
                    <Input
                        value={description}
                        onChange={val => setDescription(val)}
                        placeholder="Enter role description here..."
                    />
                    <Checkbox value={internal} onChange={(na, bool) => setInternal(bool)}>
                        Internal Role
                    </Checkbox>
                    <Button onClick={createRoleClick}>Create Role</Button>
                </Panel>

                <Panel header="Roles" bordered style={{ marginTop: 8 }}>
                    <Table
                        renderEmpty={() => {
                            return <p style={{ textAlign: 'center', marginTop: 50 }}>fetching data...</p>
                        }}
                        virtualized
                        affixHeader={0}
                        autoHeight
                        rowHeight={100}
                        loading={roles?.length < 1}
                        data={roles}
                        shouldUpdateScroll={false}
                    >
                        <Table.Column verticalAlign={'middle'} width={50}>
                            <Table.HeaderCell>Role Id</Table.HeaderCell>
                            <Table.Cell dataKey="roleId" style={{ color: 'grey' }} />
                        </Table.Column>
                        <Table.Column verticalAlign={'middle'} width={180}>
                            <Table.HeaderCell>Role Name</Table.HeaderCell>
                            <Table.Cell dataKey="roleName" style={{ color: 'grey' }} />
                        </Table.Column>
                        <Table.Column verticalAlign={'middle'} width={200}>
                            <Table.HeaderCell>Role Description</Table.HeaderCell>
                            <Table.Cell dataKey="roleDescription" style={{ color: 'grey' }} />
                        </Table.Column>
                        <Table.Column verticalAlign={'middle'}>
                            <Table.HeaderCell>User Type</Table.HeaderCell>
                            <Table.Cell dataKey="userType" style={{ color: 'grey' }} />
                        </Table.Column>
                        <Table.Column verticalAlign={'middle'} width={80}>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>
                </Panel>
            </div>
        </div>
    )
}

export default Roles
