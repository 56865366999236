import React from 'react'
import {
    fetchAdminIabCategories,
    mapIabCategoryToKeywords,
    disregardIabCategoryLeafNode
} from '../../../redux/actions/admin/iabCategories'
import { connect, useDispatch } from 'react-redux'
import CreatableTagPickerArchivable from '../../../components/CreatableTagPickerArchivable'
import Checkbox from 'rsuite/lib/Checkbox'
import Loader from 'rsuite/lib/Loader'

const mapStateToProps = state => {
    return {
        adminIabCategories: state.admin.adminIabCategories,
        adminIabCategoriesLoading: state.admin.adminIabCategoriesLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAdminIabCategories: () => dispatch(fetchAdminIabCategories()),
        disregardIabCategoryLeafNode: (iabCategoryId, checked, index) =>
            dispatch(disregardIabCategoryLeafNode(iabCategoryId, checked, index)),
        mapIabCategoryToKeywords: (iabCategoryId, keywords) =>
            dispatch(mapIabCategoryToKeywords(iabCategoryId, keywords))
    }
}

const RowCat = props => {
    const dispatch = useDispatch()
    let _cat = props.cat
    const [compCat, setCompCat] = React.useState({})
    const [processed, setProcessed] = React.useState(false)
    React.useState(() => {
        if (!processed) {
            setCompCat(_cat)
            setProcessed(true)
        }
    }, [_cat])

    return (
        <tr className="catRow">
            <td>{compCat.iabCategoryId}</td>
            <td>{compCat.iabCategoryName}</td>
            <td>
                <Checkbox
                    disabled={compCat?.keywords?.filter(k => !k.archived).length < 1}
                    checked={compCat.disregardLeafNode}
                    onChange={(e, checked) => {
                        setCompCat(prevCat => {
                            return {
                                ...prevCat,
                                disregardLeafNode: checked
                            }
                        })
                        dispatch(disregardIabCategoryLeafNode(compCat.iabCategoryId, checked))
                    }}
                />
            </td>
            <td style={{ width: 700 }}>
                <CreatableTagPickerArchivable
                    id="iabCategoriesAdminPicker"
                    margin={0}
                    disregardLeafNode={compCat.disregardLeafNode}
                    backgroundColor="#F7F7FA"
                    textColor="black"
                    items={compCat.keywords || []}
                    saveChanges={keywords => {
                        setCompCat(prevCat => {
                            return {
                                ...prevCat,
                                keywords
                            }
                        })
                        dispatch(mapIabCategoryToKeywords(compCat.iabCategoryId, keywords))
                    }}
                />
            </td>
        </tr>
    )
}

function IabCategories(props) {
    const { fetchAdminIabCategories } = props
    let [fetched, setFetched] = React.useState(false)
    React.useEffect(() => {
        if (!fetched) {
            fetchAdminIabCategories()
            setFetched(true)
        }
    }, [])

    if (!props.adminIabCategories || props.adminIabCategories.length < 1) {
        return <Loader vertical center />
    }

    return (
        <div style={{ padding: 24 }}>
            <table>
                <thead>
                    <tr>
                        <th className="catHeader">IAB Category ID</th>
                        <th className="catHeader">IAB Category Name</th>
                        <th className="catHeader">Remove Original IAB Term</th>
                        <th className="catHeader">Sightly Search Terms</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.adminIabCategories.map((cat, index) => {
                        return <RowCat cat={cat} key={index} />
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(IabCategories)
