import React from 'react'
import Button from 'rsuite/lib/Button'
import ButtonToolbar from 'rsuite/lib/ButtonToolbar'
import Table from 'rsuite/lib/Table'
import Loader from 'react-loader-spinner'
import { routes } from '../../../routes'
import { accentColor } from '../../../assets/jss/colorContants.js'
import { useQuery } from '@tanstack/react-query'
import { brandOpinionObjValidation } from '../../../schemas/schemas'
import Icon from 'rsuite/lib/Icon'
import IconButton from 'rsuite/lib/IconButton'
import InputPicker from 'rsuite/lib/InputPicker'
import { archivedStatuses } from '../../Engage/Lists/constants'
import { userAccountAxios } from '../../../axiosInstances'
import { useNavigate } from '@tanstack/react-location'
import { rqKeys } from '../../../ReactQueryKeyFactory'

const getAdminOpinions = async () => {
    let url = `/opinions`

    try {
        const result = await userAccountAxios.get(url)
        if (result.status === 200) {
            let opinions = result.data

            brandOpinionObjValidation.validate(opinions.slice(0, 5)).catch(function(err) {
                console.log(err.name, err.errors)
                console.error('We received different API data than expected, see the console log for more details.')
            })
            return opinions
        }
    } catch (error) {
        console.error(error)
    }
}

function Opinions(props) {
    const navigate = useNavigate()
    const [archiveFilter, setArchiveFilter] = React.useState(null)
    const { data: opinions, isLoading } = useQuery(rqKeys.adminOpinions(), () => getAdminOpinions())

    const userHeaders = ['Opinion', 'Archived', '']

    const handleCreateOpinionClick = () => {
        let url = routes.admin.opinions.create.path
        navigate({ to: url })
    }

    const handleConfigureTypesClick = () => {
        let url = routes.admin.opinions.types.path
        navigate({ to: url })
    }

    const filteredOpinions = React.useMemo(() => {
        if (archiveFilter === null) {
            return opinions
        }
        if (archiveFilter === 1) {
            return opinions.filter(s => s.archived)
        }
        if (archiveFilter === 2) {
            return opinions.filter(s => !s.archived)
        }

        return opinions
    }, [opinions, archiveFilter])

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center'
                }}
            >
                <Loader type="Circles" color={accentColor} />
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 24 }}>
            <div>
                <h4 style={{ marginBottom: 24 }}>Opinions</h4>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleCreateOpinionClick}>
                        Create Opinion
                    </Button>
                    <Button appearance="primary" onClick={handleConfigureTypesClick}>
                        Configure Opinion Types
                    </Button>
                </ButtonToolbar>
                <div
                    style={{
                        overflowX: 'auto',
                        width: '80vw',
                        height: '80vh',
                        marginTop: 36
                    }}
                >
                    <InputPicker
                        size={'sm'}
                        id="archivedStatusId"
                        label="Archived"
                        placeholder="Filter archived status..."
                        labelKey="archivedStatusName"
                        valueKey="archivedStatusId"
                        data={archivedStatuses}
                        onChange={val => {
                            setArchiveFilter(val)
                        }}
                        style={{ marginBottom: 4, width: 200 }}
                    />
                    <div>
                        <Table virtualized autoHeight data={filteredOpinions || []}>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>Question</Table.HeaderCell>
                                <Table.Cell dataKey="question" />
                            </Table.Column>
                            <Table.Column>
                                <Table.HeaderCell>Archived</Table.HeaderCell>
                                <Table.Cell>
                                    {opinion => {
                                        return <div>{opinion.archived ? 'True' : 'False'}</div>
                                    }}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column width={200} fixed>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.Cell>
                                    {opinion => {
                                        return (
                                            <IconButton
                                                onClick={() =>
                                                    navigate({
                                                        to: routes.admin.opinions.path + '/edit/' + opinion.opinionId
                                                    })
                                                }
                                                icon={<Icon icon="edit" />}
                                                appearance="ghost"
                                                size="xs"
                                                id="editOpinionButton"
                                            >
                                                Click to Edit
                                            </IconButton>
                                        )
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Opinions
