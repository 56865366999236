import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { userAccountAxios } from '../axiosInstances'
import { rolesAndPermissionsObjValidation } from '../schemas/schemas'
import { rqKeys } from '../ReactQueryKeyFactory'

const archiveRole = async (roleId: number) => {
    const url = `/role/${roleId}/flagDeleted`

    const res = await userAccountAxios
        .patch(url)
        .then(response => {
            if (response.status === 200) {
                toast.success('Role archived!')
            }
        })
        .catch(error => {
            console.error(error)
        })
    return res
}

const createRole = async ({ internal, newRoleName, description }: any) => {
    const url = `/role`

    return await userAccountAxios
        .post(url, {
            userType: internal ? 'Internal' : 'External',
            roleName: newRoleName,
            roleDescription: description
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Role added!')
            }
        })
        .catch(error => {
            console.error(error)
        })
}

const getRoles = async () => {
    const url = `/role?permissions=true`
    const result = await userAccountAxios.get(url)

    rolesAndPermissionsObjValidation.validate(result.data).catch(function(err) {
        console.log(err.name, err.errors)
        console.error('Could not validate roles Permissions data')
    })

    formatRoles(result)
    return result.data
}

const formatRoles = (data: any) => {
    for (const role of data.data) {
        role.roleNameExtended = `${role.roleName} (${role.userType})`
    }
}

const useRoles = () => {
    const QUERY_KEY = rqKeys.rolesKey()
    const rolesObj = useQuery(QUERY_KEY, getRoles)
    const queryClient = useQueryClient()

    const archiveRoleMutation = useMutation(archiveRole, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        }
    })

    const createRoleMutation = useMutation(createRole, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        }
    })

    return {
        ...rolesObj,
        archiveRole: archiveRoleMutation.mutate,
        createRole: createRoleMutation.mutate
    }
}

export default useRoles
