import React from 'react'

interface iProps {
	leftCheckedByDefault: boolean
	leftText: string
	rightText: string
	onChange: Function
}

const SightlyToggle: Function = ({
	leftCheckedByDefault,
	leftText,
	rightText,
	onChange
}: iProps) => {
	const [left, setLeft] = React.useState(leftCheckedByDefault)

	const handleClick = (_left: boolean) => {
		if (left === _left) {
			return
		}

		setLeft(_left)
		onChange(!_left)
	}

	return (
		<div
			style={{
				height: 40,
				display: 'flex',
				borderRadius: 4,
				padding: 1,
				backgroundColor: 'rgba(0, 0, 0, 0.06)'
			}}
		>
			<div
				onClick={() => handleClick(true)}
				style={{
					borderRadius: 3,
					cursor: 'pointer',
					padding: '8px 16px 8px 16px',
					fontWeight: 600,
					fontSize: 14,
					lineHeight: '22px',
					color: '#333D47',
					height: 38,
					alignSelf: 'center',
					backgroundColor: left ? 'white' : undefined
				}}
			>
				{leftText}
			</div>
			<div
				onClick={() => handleClick(false)}
				style={{
					borderRadius: 3,
					cursor: 'pointer',
					padding: '8px 16px 8px 16px',
					fontWeight: 600,
					fontSize: 14,
					lineHeight: '22px',
					color: '#333D47',
					backgroundColor: left ? undefined : 'white'
				}}
			>
				{rightText}
			</div>
		</div>
	)
}

export default SightlyToggle
