import toast from 'react-hot-toast'
import { listBuilderAxios, userAccountAxios } from '../axiosInstances'
import { Account } from '../classes/account'
import { TargetviewIo } from '../classes/brandProfile'
import { TrendsLatestBatchType } from '../classes/trend'
import { User } from '../classes/user'
import { basicInfoObjValidation, questionsObjValidation, targetviewIos } from '../schemas/brandProfiles'
import { userLoginValidation, userObjValidation } from '../schemas/schemas'
import { trendsLatestBatchObjValidation } from '../schemas/trends'
import { logError } from '../utils'

export const api = {
    user: {
        get: async (userId: number) => {
            const url = `/user/${userId}?permissions=true`
            const { data } = await userAccountAxios.get<User>(url).catch(err => {
                return Promise.reject(err)
            })

            userObjValidation.validate(data).catch(function(err: object) {
                logError(err)
            })

            return data
        },
        updateAccounts: async (userId: number, accounts: Account[]) => {
            if (accounts.length < 1) {
                alert('User not saved. Each user must have at least one account assigned to them.')
                return
            }
            const url = `/user/${userId}/accounts`
            return await userAccountAxios.patch(url, accounts)
        },
        updateUserData: async (user: Partial<User>) => {
            const userId = user.userId
            const url = `/user/${userId}`

            let myUser: Partial<User> = {
                userId: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                company: user.company,
                email: user.email,
                userType: user.userType,
                roleId: user.roleId,
                accounts: []
            }

            userLoginValidation.validate(myUser).catch(function(err) {
                logError(err)
                alert('Could not validate new user')
            })
            delete myUser.accounts
            const result = await userAccountAxios.patch(url, myUser)
            if (result.status === 200) {
                toast.success('User saved!')
            }
        }
    },
    account: {
        update: async (account: Account) => {
            const url = `/account/${account.accountId}`

            const args = {
                accountId: account.accountId,
                accountName: account.accountName,
                contactName: account.contactName,
                contactEmail: account.contactEmail,
                accountTypeId: account.accountTypeId,
                accountMargin: account.accountMargin
            }

            return await userAccountAxios.patch(url, args)
        },
        create: async (account: Partial<Account>) => {
            const url = `/account`
            return await userAccountAxios.post(url, account)
        },
        delete: async (accountId: number | undefined) => {
            if (typeof accountId === undefined) return
            const url = `/account/${accountId}`
            return await userAccountAxios.delete(url)
        }
    },
    trends: {
        getLatestBatch: async () => {
            const url = `/trends/latest-batch`
            const result = await listBuilderAxios.get<TrendsLatestBatchType>(url)
            if (result && result.status === 200) {
                trendsLatestBatchObjValidation.validate(result.data).catch(function(err) {
                    logError(err)
                    console.error(
                        ' we received different data from the api than expected while fetching latest batch, see console log for more details'
                    )
                })
            }
            return result.data
        }
    },
    brandProfile: {
        create: async (accountId: number | undefined) => {
            if (typeof accountId === undefined) {
                throw new Error('accountId must be defined')
            }
            const url = `/brand-profile`
            const brandProfile = {
                accountId,
                brandName: 'UNTITLED_BRAND_PROFILE'
            }
            const result = await userAccountAxios.post<any>(url, brandProfile)
            if (result.status === 200) {
                result.data.aylienIndustryId = null
                basicInfoObjValidation.validate(result.data).catch(function(err) {
                    logError(err)
                    console.error(
                        ' we received different data from the api than expected after creating a  brand profile, see console log for more details'
                    )
                })
            }
            return result.data
        },
        clone: async (brandProfileId: number | undefined) => {
            if (typeof brandProfileId === undefined) {
                throw new Error('brandProfileId must be defined')
            }
            const url = `/brand-profile/${brandProfileId}/clone`
            const result = await userAccountAxios.post(url)
            if (result.status === 200) {
                basicInfoObjValidation.validate(result.data[0]).catch(function(err) {
                    logError(err)
                    console.error(
                        'we received different data from the api than expected after cloning a  brand profile, see console log for more details'
                    )
                })
            }
            return JSON.parse(JSON.stringify(result.data[0]))
        },
        ios: {
            get: async (brandProfileId: number) => {
                const url = `/brand-profile/${brandProfileId}/ios`

                const result = await userAccountAxios.get<TargetviewIo[]>(url)

                if (result.status === 200) {
                    targetviewIos.validate(result.data).catch(function(err) {
                        logError(err)
                        console.error(
                            'we received different data from the api than expected while fetching brand profile ios, see console log for more details'
                        )
                    })
                }
                return result.data || []
            }
        },
        questions: {
            get: async (brandProfileId: number) => {
                let url = `/brand-profile/${brandProfileId}/questions`

                const result = await userAccountAxios.get(url)

                questionsObjValidation.validate(result.data.slice(0, 3)).catch(function(err) {
                    console.log(err.name, err.errors)
                    console.error(
                        'we received different data from the api than expected while fetching brand profile questions, see console log for more details'
                    )
                })

                return result.data
            }
        }
    }
}
