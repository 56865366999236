import React from 'react'
import { joinClasses } from '../../utils'
import Skeleton from 'react-loading-skeleton'
import InputPicker from 'rsuite/lib/InputPicker'

type valueType = string | number | null

interface IProps {
	label?: string
	showLabelStar?: boolean
	options: any
	value: valueType
	placeholder?: string
	width?: number
	labelKey: string
	valueKey: string
	onChange: Function
	disabled?: boolean
	id: string
	loading?: boolean
	searchable?: boolean
}

const SightlySelect = ({
	label,
	showLabelStar,
	options,
	value,
	placeholder,
	width,
	labelKey,
	valueKey,
	onChange,
	disabled,
	id,
	loading,
	searchable
}: IProps) => {
	return (
		<div
			style={{
				position: 'relative',
				width: width ? width : undefined,
				opacity: disabled ? 0.5 : 1,
				borderRadius: 3
			}}>
			{label && (
				<div
					className={
						showLabelStar
							? joinClasses('unselectable', 'isRequired', 'input-label')
							: 'unselectable input-label'
					}>
					{label}
				</div>
			)}

			{loading ? (
				<Skeleton height={34} />
			) : (
				<InputPicker
					preventOverflow
					disabled={disabled}
					cleanable={false}
					id={id}
					labelKey={labelKey}
					valueKey={valueKey}
					placeholder={placeholder}
					block
					data={options}
					value={value}
					onSelect={(val) => onChange(val)}
					style={{ borderRadius: 3 }}
					searchable={searchable || undefined}
				/>
			)}
		</div>
	)
}

export default SightlySelect
