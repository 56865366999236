import Loader from 'react-loader-spinner'
import React from 'react'
export const LoadingPage = ({ message }: { message: string }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%'
            }}
        >
            {message}
        </div>
    )
}
