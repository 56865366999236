import React from 'react'
import SightlyButton from '../../../components/SightlyButton'
import { routes } from '../../../routes'
import Sidebar from './Sidebar'
import useBoards from './useBoards'
import MaxBoardsWarning from './MaxBoardsWarning'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import { Navigate, useMatch, useNavigate } from '@tanstack/react-location'
import NoBrandProfiles from '../../../components/NoBrandProfiles'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import useUser from '../../../services/useUser'
import { location } from '../../../RouteContainer'

const Welcome = () => {
    const navigate = useNavigate<MyLocationGenerics>()
    const {
        currentBrandProfile,

        data: brandProfiles
    } = useBrandProfiles({
        submittedOnly: true
    })
    const brandProfileId = currentBrandProfile?.brandProfileId
    const boardsObj = useBoards()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: search => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
    }, [currentBrandProfile, location.current.search])

    if (brandProfiles?.length === 0) {
        return <NoBrandProfiles />
    } //TODO: hoist NoBrandProifles checker onto /discover parent route and remove from children

    if (boardsObj && boardsObj.boardsFetched && boardsObj?.boards && boardsObj?.boards?.length > 9) {
        return <Navigate to={routes.app.discover.boards.dashboard.path} />
    }

    return (
        <div className="gridContainer">
            <Sidebar
                handleBrandProfileChange={(brandProfileId: number) => {
                    navigate({
                        search: prev => ({
                            ...prev,
                            brandProfileId
                        })
                    })
                }}
                selectedBoards={[]}
                brandProfileId={brandProfileId}
                handleSelectBoard={() => {}}
                boards={[]}
                showBackToDashboards={
                    boardsObj?.boards &&
                    boardsObj.boards.length > 0 &&
                    boardsObj.boardsFetched &&
                    !boardsObj.boardsIsFetching
                }
            />

            <div className="main">
                {user?.firstName && (
                    <>
                        <div className="title">Welcome to the Anticipation Tool {user?.firstName}!</div>
                        <div className="subTitle">Build your first board tailored to the news you want to see</div>

                        <div className="boardHolder">
                            <div className="box" style={{ backgroundColor: '#353D46' }}>
                                <div className="boxTitle">Custom</div>
                                <div className="boxDescription">
                                    Customize your own board by selecting up to {user?.userType === 'Internal' ? 10 : 3}{' '}
                                    categories you’re interested in.
                                </div>
                                <div className="buttonContainer">
                                    <SightlyButton
                                        disabled={boardsObj && boardsObj.boards && boardsObj.boards.length > 9}
                                        type="purple"
                                        text="Create Board"
                                        id="createCustom"
                                        handleClick={() =>
                                            navigate({
                                                to: routes.app.discover.boards.custom.path,
                                                search: old => {
                                                    return { ...old }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ width: 36 }} />
                            <div className="box" style={{ backgroundColor: '#1DA1F2' }}>
                                <div className="boxTitle">General</div>
                                <div className="boxDescription">
                                    Vertical boards include general news in specific industries{' '}
                                </div>
                                <div className="buttonContainer">
                                    <SightlyButton
                                        disabled={boardsObj && boardsObj.boards && boardsObj.boards.length > 9}
                                        text="Create Board"
                                        id="createGeneral"
                                        type="secondary"
                                        handleClick={() =>
                                            navigate({
                                                to: routes.app.discover.boards.general.path,
                                                search: old => {
                                                    return { ...old }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <style>{`

.gridContainer {
	display: grid;
	height: 100%;	
	grid-template-columns: 265px 1fr;	
	grid-template-rows:  1fr 64px;
	grid-template-areas:
		"sidebar main"
		"sidebar main"		
}		
.main {
	grid-area: main;

	overflow-y: auto;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: center;
}  


        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 52px;
          justify-content: center;
        }
        .title {
          font-weight: 600;
          font-size: 24px;
          color: #333D47
        }
        .subTitle {
          font-weight: 500;
          font-size: 16px;
          color: #333D47;
          margin-top: 8px;
        }
        .boardHolder {
          display:flex;
          margin-top: 45px;
        }
        .box {
          height: 260px;
          width: 250px;
          border-radius: 24px;
          display: flex;
          
          flex-direction: column;
          align-items: center;
          color: white;
          padding-top: 43px;
        }
        .boxTitle {
          font-size: 18px;
          line-height: 22px;
          font-weight: 700;
        }
        .boxDescription {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          margin-top: 17px;
          text-align: center;
          width: 218px;
          height: 76px;
        }
      
      `}</style>
        </div>
    )
}

export default Welcome
