import { SET_TRENDS_IS_LOADING } from '../../action-types/discover/trends'
import toast from 'react-hot-toast'
import { listBuilderAxios } from '../../../axiosInstances'

export function postTrendEdit(args) {
    try {
        let url = `/brand-profile/${args.brandProfileId}/trends/${args.trendId}/action`
        return async (dispatch, getState) => {
            const result = await listBuilderAxios.post(url, { action: args.action })
            if (result.status === 200) {
                toast.success('Changes saved')
            } else {
                toast.error('Oops! Something went wrong')
            }
        }
    } catch (err) {
        toast.error('Oops! Something went wrong')
    }
}

export function setTrendsIsLoading(trendsIsLoading) {
    return {
        type: SET_TRENDS_IS_LOADING,
        trendsIsLoading
    }
}
