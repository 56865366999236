import React from 'react'
import { accentColor } from '../../../assets/jss/colorContants'
import { joinClasses, permissionView } from '../../../utils'
import Animation from 'rsuite/lib/Animation'
import Icon from 'rsuite/lib/Icon'
import { useNavigate } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'

interface Step {
    name: string
    humanName: string
    canView: boolean
    active: boolean
    children: Step[]
    permissionNeeded?: string
    showLock?: boolean
}

interface IProps {
    steps: Step[]
    activateStep: (name: string) => void
    activeStep: string
}

const BrandProfileSteps = ({ steps, activateStep, activeStep }: IProps) => {
    const navigate = useNavigate()
    const handleChildClick = (name: string) => {
        let newUrl = location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/')) + `/${name}`
        navigate({
            to: newUrl,
            search: prev => {
                return {
                    ...prev
                }
            }
        })
    }

    return (
        <div>
            {steps.map((step: Step, index: number) => {
                let customActiveStep = activeStep
                if (activeStep === 'opinions' || activeStep === 'scenarios') {
                    customActiveStep = 'brandPositioning'
                }
                if (
                    activeStep === 'brands' ||
                    activeStep === 'influencers' ||
                    activeStep === 'events' ||
                    activeStep === 'social' ||
                    activeStep === 'philanthropic' ||
                    activeStep === 'questions'
                ) {
                    customActiveStep = 'queries'
                }
                if (
                    activeStep === 'iabCategories' ||
                    activeStep === 'topics' ||
                    activeStep === 'news' ||
                    activeStep === 'industries' ||
                    activeStep === 'keywords'
                ) {
                    customActiveStep = 'keywordsGroup'
                }
                const isActive = customActiveStep === step.name
                return (
                    <div style={{ marginBottom: 16 }} key={index}>
                        <div
                            onClick={() => activateStep(step.name)}
                            style={{ display: 'flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }}
                        >
                            <div className={joinClasses('numberCircle', isActive && 'numberCircleActive')}>
                                {index + 1}
                            </div>
                            <div className={joinClasses('stepName', isActive && 'stepNameActive')}>
                                {step.humanName}
                                {permissionView && step.permissionNeeded && (
                                    <div style={{ color: 'red' }}>{step.permissionNeeded}</div>
                                )}
                            </div>
                            {step.showLock && (
                                <div>
                                    {' '}
                                    <Icon style={{ color: '#a3a3a3' }} size="lg" icon="lock" />
                                </div>
                            )}
                        </div>

                        <Animation.Collapse in={customActiveStep === step.name && !!step.children}>
                            {(props: any, ref: any) => (
                                <div {...props} ref={ref}>
                                    {step.children &&
                                        step.children.map((child: Step) => {
                                            return (
                                                <div
                                                    onClick={() => handleChildClick(child.name)}
                                                    key={child.name}
                                                    className={joinClasses(
                                                        'childStep',
                                                        activeStep === child.name && 'childStepActive'
                                                    )}
                                                    style={{
                                                        marginLeft: 36 + 16,
                                                        marginBottom: 8,
                                                        fontSize: 14,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <>
                                                        {child.humanName}
                                                        {permissionView && child.permissionNeeded && (
                                                            <div style={{ color: 'red' }}>{child.permissionNeeded}</div>
                                                        )}
                                                    </>
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                        </Animation.Collapse>
                    </div>
                )
            })}

            <style>
                {' '}
                {`
      .stepName {
        font-weight: 600;
        font-size: 16px;
        color: #a3a3a3; 
        margin-right: 16px;
      }
      
      .stepNameActive {
        color: #3d3d3d;
      }
      .childStep {
        font-weight: 500;
        color: #3d3d3d;
        opacity: 50%;

      }
      .childStepActive {
        font-weight: 600;
        color: #3d3d3d;
        opacity: 100%;
      }
      .numberCircle {
        font-size: 16px;
        width: 36px;
        height: 36px;
        color: #a3a3a3;
        font-weight: 700;
        border: 1px solid #a3a3a3;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 45px;
        margin-right: 16px;
      }
      .numberCircleActive {
        color: ${accentColor};
        border: 2px solid ${accentColor};
      }
`}
            </style>
        </div>
    )
}

export default BrandProfileSteps
