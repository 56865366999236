import { patchVersionDataService } from '../services/lists'

export class Channel {
	constructor(
		public actionId: number | null,
		readonly id: string,
		readonly name: string,
		readonly thumbnail: string,
		readonly description: string,
		readonly topTargetCategories: string[],
		readonly topMonitorCategories: string[],
		readonly topBlockCategories: string[],
		readonly videoCount: number,
		readonly views: number,
		readonly subscribers: number,
		readonly latestVideoUploadDate: string,
		readonly lastTimeActionWasChanged: string,
		readonly targetPercentage: number,
		readonly blockPercentage: number,
		readonly monitorPercentage: number,
		readonly versionId: number
	) {}

	public changeAction(_actionId: number): Channel {
		let args = {
			versionId: this.versionId,
			data: { actionId: _actionId, id: this.id }
		}

		patchVersionDataService(args)

		return new Channel(
			_actionId,
			this.id,
			this.name,
			this.thumbnail,
			this.description,
			this.topTargetCategories,
			this.topMonitorCategories,
			this.topBlockCategories,
			this.videoCount,
			this.views,
			this.subscribers,
			this.latestVideoUploadDate,
			this.lastTimeActionWasChanged,
			this.targetPercentage,
			this.blockPercentage,
			this.monitorPercentage,
			this.versionId
		)
	}
}
