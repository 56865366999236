import React from 'react'
import { LoadingPage } from '../components/LoadingPage'
import useUser from '../services/useUser'
import NeedPermissionPage from '../views/NeedPermissionPage'

const ProtectedRoute = ({ component: Component, permissionsNeeded }) => {
    const { accounts, user, currentAccount, perms, userCan, isLoading } = useUser()

    const canSee = React.useMemo(() => {
        if (!permissionsNeeded || permissionsNeeded.length < 1) {
            return true
        }
        for (const neededPerm of permissionsNeeded) {
            if (!userCan(neededPerm)) {
                return false
            }
        }
        return true
    }, [userCan, perms, permissionsNeeded])
    if (isLoading) {
        return <LoadingPage message="Loading User Data..." />
    }

    if (canSee) {
        return <Component />
    }
    return (
        <>
            {permissionsNeeded ? (
                <NeedPermissionPage permissionNeeded={JSON.stringify(permissionsNeeded)} />
            ) : (
                <div>Error, you do not have permission to view this page</div>
            )}
        </>
    )
}
export default ProtectedRoute
