import React, { useState } from 'react'
import Button from 'rsuite/lib/Button'
import Form from 'rsuite/lib/Form'
import FormGroup from 'rsuite/lib/FormGroup'
import FormControl from 'rsuite/lib/FormControl'
import ControlLabel from 'rsuite/lib/ControlLabel'
import HelpBlock from 'rsuite/lib/HelpBlock'
import Schema from 'rsuite/lib/Schema'
import Table from 'rsuite/lib/Table'
import {
	fetchAdminLabels,
	createLabel,
	archiveLabel,
	setInitLabelAdd
} from '../../../redux/actions/admin/scenarios'
import { connect } from 'react-redux'
import Loader from 'rsuite/lib/Loader'
import PortalModal from '../../../components/PortalModal/PortalModal'

const mapStateToProps = (state) => {
	return {
		labelsIsLoading: state.admin.labelsIsLoading,
		initLabelAdd: state.admin.initLabelAdd,
		labelSaving: state.admin.labelSaving,
		labelArchiving: state.admin.labelArchiving,
		adminLabels: state.admin.labels
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAdminLabels: () => dispatch(fetchAdminLabels()),
		createLabel: (label) => dispatch(createLabel(label)),
		archiveLabel: (labelId) => dispatch(archiveLabel(labelId)),
		setInitLabelAdd: (bool) => dispatch(setInitLabelAdd(bool))
	}
}

function Labels(props) {
	let form

	const [formValues, setFormValues] = useState()

	const { StringType } = Schema.Types
	const model = Schema.Model({
		labelName: StringType().isRequired('Label Name is required.')
	})

	const ActionCell = ({ rowData, dataKey, ...props }) => {
		return (
			<Table.Cell {...props} style={{ padding: 1 }}>
				{!rowData.archived && (
					<Button
						appearance='link'
						loading={props.labelArchiving === rowData.labelId}
						onClick={() => {
							handleArchiveLabelClick(rowData.labelId)
						}}>
						Archive
					</Button>
				)}
			</Table.Cell>
		)
	}

	const handleSubmit = () => {
		if (!form.check()) {
			console.error('Form Error')
			return
		} else {
			props.createLabel(formValues)
		}
	}

	const { fetchAdminLabels, adminLabels } = props
	let [labelsFetched, setLabelsFetched] = React.useState(false)
	React.useEffect(() => {
		if (!labelsFetched) {
			fetchAdminLabels()
			setLabelsFetched(true)
		}
	}, [fetchAdminLabels, labelsFetched, setLabelsFetched])

	const handleArchiveLabelClick = (labelId) => {
		props.archiveLabel(labelId)
	}

	return (
		<div>
			{adminLabels && adminLabels.length > 0 ? (
				<div>
					<Button
						appearance='primary'
						onClick={() => props.setInitLabelAdd(true)}>
						Create Label
					</Button>

					<PortalModal
						open={props.initLabelAdd}
						handleClose={() => props.setInitLabelAdd(false)}
						footerButtons={[
							<Button
								loading={props.labelSaving}
								onClick={() => handleSubmit()}
								appearance='primary'>
								Save
							</Button>,
							<Button
								onClick={() => props.setInitLabelAdd(false)}
								appearance='subtle'>
								Cancel
							</Button>
						]}>
						<div style={{ width: 500 }}>
							<Form
								fluid
								ref={(ref) => (form = ref)}
								model={model}
								onChange={(formValue) => {
									setFormValues(formValue)
								}}>
								<h4 style={{ marginBottom: 24 }}>Add Label</h4>

								<div>
									<FormGroup>
										<ControlLabel>Label Name</ControlLabel>
										<FormControl name='labelName' />
										<HelpBlock>Required</HelpBlock>
									</FormGroup>
								</div>
							</Form>
						</div>
					</PortalModal>

					<Table autoHeight data={adminLabels} shouldUpdateScroll={false}>
						<Table.Column verticalAlign={'middle'} flexGrow={1}>
							<Table.HeaderCell>Label Name</Table.HeaderCell>
							<Table.Cell dataKey='labelName' style={{ color: 'grey' }} />
						</Table.Column>
						<Table.Column verticalAlign={'middle'}>
							<Table.HeaderCell>Archived</Table.HeaderCell>
							<Table.Cell style={{ color: 'grey' }}>
								{(rowData) => {
									return rowData.archived ? 'True' : 'False'
								}}
							</Table.Cell>
						</Table.Column>
						<Table.Column verticalAlign={'middle'}>
							<Table.HeaderCell>Actions</Table.HeaderCell>
							<ActionCell />
						</Table.Column>
					</Table>
				</div>
			) : props.labelsIsLoading ? (
				<Loader center vertical />
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'calc(100vh - 200px)',
						color: 'white'
					}}>
					<Button
						appearance='primary'
						onClick={() => props.setInitLabelAdd(true)}>
						Create Label
					</Button>
				</div>
			)}
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Labels)
