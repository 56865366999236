export const neutralColor = '#fff' // process.env.REACT_APP_NEUTRAL
export const neutralLightColor = '#2f2f2f'
export const neutralExtraLightColor = '#565656'
export const accentColor = '#26AAE1'
export const sightlyBlue = '#26AAE1'
export const brandPinkColor = '#EC3876'
export const brandSuccessColor = '#5aa83e'
export const purpleSecondaryColor = '#5B32EF'
export const neutralExtraExtraLightColor = '#7e7e7e'
export const labelColor = 'black' // '#384955'
export const bgColor = '#F0F3F5'
export const dividerColor = '#f2f2f5'
export const sightlyBlack = '#353D46'
