interface Keyword {
    keyword: string
    rank: number
}

export interface TrendArticle {
    images: string[]
    description: string
    siteName: string
    source: string
    title: string
    url: string
}

export interface TrendVideo {
    channelId: string
    channelTitle: string
    trendId: string
    videoId: string
    videoTitle: string
    feedback: {
        videoRelatedToTrend: boolean
        languageIsEnglish: boolean
        dateRelevancyIsInvalid: boolean
    }
}

export const ScenarioResponseEnum = {
    target: {
        id: 3,
        label: 'Target'
    },
    block: {
        id: 1,
        label: 'Block'
    },
    monitor: {
        id: 2,
        label: 'Monitor'
    },
    dontmonitor: {
        id: 4,
        label: `Don't Monitor`
    }
}

export const getScenarioResponse = (scenarioResponseId: number) => {
    switch (scenarioResponseId) {
        case 1:
            return 'Block'
            break

        case 2:
            return 'Monitor'
            break

        case 3:
            return 'Target'
            break

        case 4:
            return `Don't Monitor`
            break

        default:
            return 'Other'
            break
    }
}

export interface TrendScenario {
    scenarioId: number
    scenarioName: string
    scenarioResponseId: 1 | 2 | 3 | 4
    feedback?: string | null
}

export class Trend {
    constructor(
        readonly demo: boolean,
        readonly brandProfileId: number,
        readonly articleCount: number,
        readonly vertical: string,
        readonly videoCount: number,
        readonly feedback: string,
        public action: string,
        public editedBy: string,
        public editedDateTime: string,
        public editedFromAction: string,
        readonly originalRecommendedAction: string,
        readonly keywords: Keyword[],
        readonly keywordCount: number,
        readonly keywordsFeedback: string,
        readonly recommendedActionFeedback: string,
        readonly trendName: string,
        readonly trendId: string,
        public selected: boolean,
        public articles: TrendArticle[],
        public videos: TrendVideo[],
        public gAdsBlocked: boolean
    ) {}
}

export type TrendsLatestBatchType = {
    date: string
    hour: number
}
