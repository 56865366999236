import React from 'react'
import { ReactComponent as AddIcon } from '../../../../assets/img/add_20x20.svg'
import TrendScenarioEditComponent from './TrendScenarioEditComponent'
import Skeleton from 'react-loading-skeleton'
import { TrendScenario } from '../../../../classes/trend'
import TrendScenarioComponent from './TrendScenarioComponent'
import { accentColor } from '../../../../assets/jss/colorContants'
import { NoneApplyId } from '../../../../services/trends_ts'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'

interface TrendScenariosProps {
    trendScenariosLoading: boolean
    trendScenarios: TrendScenario[]
    handleFeedbackQuestion: any
    handleDeleteTrendScenario: any
    adding: boolean
    handleAddTrendScenario: any
    setAdding: any
    scenarios: any
}

const TrendScenarios = ({
    trendScenariosLoading,
    trendScenarios,
    handleFeedbackQuestion,
    handleDeleteTrendScenario,
    adding,
    handleAddTrendScenario,
    setAdding,
    scenarios
}: TrendScenariosProps) => {
    const _visibleScenarioOptions = React.useMemo(() => {
        const trendScenarioIds = trendScenarios.map((_s: TrendScenario) => {
            return _s.scenarioId
        })

        let final = scenarios.filter((_scen: TrendScenario) => {
            return !trendScenarioIds.includes(_scen.scenarioId)
        })

        if (trendScenarios.length > 0) {
            //remove none apply option
            final = final.filter((t: TrendScenario) => t.scenarioId !== NoneApplyId)
        }

        return final
    }, [trendScenarios, scenarios])

    const hasNoneApply = React.useMemo(() => {
        if (!trendScenarios || trendScenarios.length !== 1) {
            return false
        }
        const noneApply = trendScenarios.filter((scen: TrendScenario) => scen.scenarioId === NoneApplyId)
        if (noneApply && noneApply.length > 0) {
            return true
        }
        return false
    }, [trendScenarios])

    const hasNoScenarioOptions = _visibleScenarioOptions.length === 0 || hasNoneApply

    return (
        <div
            style={{
                marginTop: 30,
                marginLeft: 24,
                marginRight: 24
            }}
        >
            <div
                style={{
                    height: 32,
                    backgroundColor: '#F0F3F5',
                    borderRadius: '8px 8px 0px 0px',
                    color: '#333D47',
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: '24px',
                    display: 'flex',
                    paddingTop: 4
                }}
            >
                <div
                    style={{
                        width: 686,
                        paddingLeft: 12,
                        textAlign: 'center'
                    }}
                >
                    Brand Profile Scenario
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        paddingLeft: 53
                    }}
                >
                    Response
                </div>
            </div>

            <div
                style={{
                    overflowY: 'auto',
                    textAlign: 'left'
                }}
            >
                {trendScenariosLoading ? (
                    <div
                        style={{
                            padding: 10,
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center'
                        }}
                    >
                        <div style={{ width: 550 }}>
                            <Skeleton count={4} />
                            <div style={{ height: 24 }} />
                            <Skeleton count={4} />
                            <div style={{ height: 24 }} />
                            <Skeleton count={4} />
                        </div>
                    </div>
                ) : (
                    <div>
                        {trendScenarios.length > 0 &&
                            trendScenarios.slice(0, 5).map((_scenario, index) => {
                                return (
                                    <TrendScenarioComponent
                                        key={_scenario.scenarioId}
                                        scenario={_scenario}
                                        index={index + 1}
                                        handleFeedbackQuestion={handleFeedbackQuestion}
                                        handleDeleteTrendScenario={() =>
                                            handleDeleteTrendScenario(_scenario.scenarioId)
                                        }
                                    />
                                )
                            })}

                        {adding ? (
                            <TrendScenarioEditComponent
                                scenarios={_visibleScenarioOptions}
                                index={trendScenarios.length + 1}
                                handleAddTrendScenario={handleAddTrendScenario}
                            />
                        ) : (
                            <>
                                <Whisper
                                    delayShow={100}
                                    delayHide={0}
                                    placement="topStart"
                                    trigger={hasNoScenarioOptions || trendScenarios.length === 5 ? 'hover' : 'none'}
                                    speaker={
                                        <Tooltip>
                                            {trendScenarios.length === 5
                                                ? 'You cannot have more than 5.'
                                                : 'This Brand Profile has no Scenarios linked to it'}
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginLeft: 10,
                                            marginTop: 24,
                                            cursor:
                                                hasNoScenarioOptions || trendScenarios.length === 5
                                                    ? 'not-allowed'
                                                    : 'pointer'
                                        }}
                                        onClick={() =>
                                            hasNoScenarioOptions || trendScenarios.length === 5
                                                ? undefined
                                                : setAdding(true)
                                        }
                                    >
                                        <div
                                            style={{
                                                alignSelf: 'center',
                                                fontWeight: 500,
                                                fontSize: 14,
                                                lineHeight: '18px',
                                                color: '#8F8F8F',
                                                marginBottom: 2
                                            }}
                                        >
                                            Add another relevant scenario (Up to 5 Scenarios)
                                        </div>
                                        <AddIcon
                                            fill={accentColor}
                                            style={{
                                                marginLeft: 8
                                            }}
                                        />
                                    </div>
                                </Whisper>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TrendScenarios
