import React from 'react'
import { joinClasses } from '../../utils'
import Skeleton from 'react-loading-skeleton'
import InputPicker from 'rsuite/lib/InputPicker'
import { Controller } from 'react-hook-form'

interface IProps {
	label?: string
	showLabelStar?: boolean
	options: any
	placeholder?: string
	width?: number
	labelKey: string
	valueKey: string
	maxHeight?: number
	backgroundColor?: string
	disabled?: boolean
	id: string
	hasBorder?: boolean
	loading?: boolean
	name: string
	control: any
}

const SightlySelectForm = ({
	label,
	showLabelStar,
	options,
	name,
	placeholder,
	width,
	labelKey,
	valueKey,
	disabled,
	id,
	loading,
	control
}: IProps) => {
	return (
		<div
			style={{
				textAlign: 'left',
				position: 'relative',
				width: width ? width : undefined
			}}
		>
			{label && (
				<div
					className={
						showLabelStar
							? joinClasses('unselectable', 'isRequired')
							: 'unselectable'
					}
					style={{
						marginBottom: 8,
						fontWeight: 500,
						fontSize: 16,
						lineHeight: '16px',
						color: '#696969'
					}}
				>
					{label}
				</div>
			)}

			{loading ? (
				<Skeleton height={34} width={width} />
			) : (
					<Controller
						name={name}
						control={control}
						rules={{ required: true }}
						render={({ field }) => (
							<InputPicker
								{...field}
								disabled={disabled}
								cleanable={false}
								id={id}
								labelKey={labelKey}
								valueKey={valueKey}
								placeholder={placeholder}
								block
								data={options}
								preventOverflow
							/>
						)}
					/>
				)}
		</div>
	)
}

export default SightlySelectForm
