import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../axiosInstances'
import { rqKeys } from '../../ReactQueryKeyFactory'

interface IProps {
    excludeArchived?: boolean
}

const fetchAdminOpinionTypes = async ({ excludeArchived }: IProps) => {
    const url = excludeArchived ? `/opinions/types?archived=false` : `/opinions/types`
    const result = await userAccountAxios.get(url)
    return result.data
}

interface ArchiveProps {
    typeId: number
}
const archiveOpinionType = async ({ typeId }: ArchiveProps) => {
    const url = `/opinions/types/${typeId}`
    return await userAccountAxios.delete(url)
}

const createOpinionType = async (type: any) => {
    const url = `/opinions/types`
    await userAccountAxios.post(url, type)
}

const useBrandProfileOpinionTypes = ({ excludeArchived }: IProps) => {
    const queryClient = useQueryClient()
    const { data: opinionTypes, isLoading: opinionTypesIsLoading } = useQuery(
        rqKeys.opinionTypesKey(excludeArchived),
        () => fetchAdminOpinionTypes({ excludeArchived })
    )

    const archiveOpinionTypeMutation = useMutation(archiveOpinionType, {
        onMutate: async (args: ArchiveProps) => {
            queryClient.setQueryData(rqKeys.opinionTypesKey(excludeArchived), (old: any) => {
                if (!old) return []
                return old
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(rqKeys.opinionTypesKey(excludeArchived))
            toast.success('Success')
        }
    })

    const createOpinionTypeMutation = useMutation(createOpinionType, {
        onMutate: async () => {
            queryClient.setQueryData(rqKeys.opinionTypesKey(excludeArchived), (old: any) => {
                if (!old) return []
                return old
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries(rqKeys.opinionTypesKey(excludeArchived))
        }
    })

    return {
        opinionTypes,
        archiveOpinionType: archiveOpinionTypeMutation.mutate,
        createOpinionType: createOpinionTypeMutation.mutate,
        opinionTypesIsLoading
    }
}

export default useBrandProfileOpinionTypes
