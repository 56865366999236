import React from 'react'
import { ReactComponent as CloseIcon8x8 } from '../../assets/img/closeIcon8x8.svg'
import toast from 'react-hot-toast'
import { isValidEmail } from '../../validations'

const SightlyTagInput = (props) => {
	const [tags, setTags] = React.useState(props.tags)
	const removeTags = (indexToRemove) => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)])
	}
	const addTags = (event) => {
		if (event.target.value !== '') {
			if (!props.inputIsValid(event.target.value)) {
				toast.error('Must be a Sightly email address')
				return
			}
			setTags([...tags, event.target.value])
			event.target.value = ''
		}
	}

	const handleChange = (e) => {
		const val = e.target.value
		if (!val) {
			return
		}
		if (!val.toLowerCase().includes('@sightly.com')) {
			return
		}
		if (isValidEmail(val)) {
			addTags(e)
		}
	}

	React.useEffect(() => {
		if (tags) {
			props.setSelectedTags(tags)
		}
	}, [tags])
	return (
		<div className='tags-input'>
			<ul id='sightlyTags'>
				{tags.map((tag, index) => (
					<li key={index} className='sightlyTag'>
						<span className='tag-title'>{tag}</span>
						<CloseIcon8x8
							style={{ marginLeft: 8, marginTop: 8 }}
							onClick={() => removeTags(index)}
						/>
					</li>
				))}
			</ul>
			<input
				className='myInput'
				type='text'
				onKeyUp={(event) => (event.key === 'Enter' ? addTags(event) : null)}
				onChange={(e) => handleChange(e)}
				placeholder='Enter Sightly emails here'
			/>
		</div>
	)
}

export default SightlyTagInput
