import PortalModal from './PortalModal/PortalModal'
import { ReactComponent as WarningIcon } from '../assets/img/warning-large.svg'
import React, { ReactNode } from 'react'

type IProps = {
    open: boolean
    setIsOpen: Function
    children: ReactNode
}
export const WarningModal = ({ open, setIsOpen, children }: IProps) => {
    return (
        <PortalModal hasClose open={open} handleClose={() => setIsOpen(false)} footerButtons={[]}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 24,
                    alignItems: 'center'
                }}
            >
                <div>
                    <WarningIcon style={{ height: 48 }} />
                </div>
                {children}
            </div>
        </PortalModal>
    )
}
