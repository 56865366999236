import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { competitorsObjValidation } from '../../../../../schemas/brandProfiles'
import Table from 'rsuite/lib/Table'
import SightlyButton from '../../../../../components/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '../../../../../assets/jss/colorContants'
import { CustomWhisper } from '../../../../../components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import Panel from 'rsuite/lib/Panel'
import EventModal from './EventModal'
import { userAccountAxios } from '../../../../../axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useUser from '../../../../../services/useUser'
import { TableColumnJs } from '../../../../../utils'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

export type Event = {
    id: number
    name: string
    twitterProfileUrl: string
    websiteUrl?: string
    wikiUrl: string
    relationshipId: number
    relationshipName?: string
    date?: Date | null
}

const getItems = async (brandProfileId: number) => {
    let url = `/brand-profile/${brandProfileId}/events`

    const result = await userAccountAxios.get<Event[]>(url)

    if (result.status === 200) {
        if (result.data.length > 0) {
            competitorsObjValidation.validate(result.data).catch(function(err) {
                console.error(err)
            })
        }
    }

    let _res = result.data

    _res = result.data.map(i => {
        const _i: any = i
        if (!_i.day || !_i.month || !_i.year) {
            return i
        }
        const d = new Date(_i.year, _i.month - 1, _i.day, 0, 0, 0, 0)

        const newItem: any = {
            ...i,
            date: d
        }

        delete newItem.day
        delete newItem.month
        delete newItem.year
        return newItem
    })

    return _res
}

const QueryTypeOneSection = () => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const queryClient = useQueryClient()

    const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
    const viewOnly = viewOnlyParam || false

    const { data: items, isLoading, isFetched } = useQuery(
        rqKeys.brandProfileEvents(Number(brandProfileId)),
        () => getItems(Number(brandProfileId)),
        { enabled: !!brandProfileId, refetchOnWindowFocus: false }
    )

    const [editing, setEditing] = React.useState(false)
    const [editingId, setEditingId] = React.useState<number | null>(null)

    const [adding, setAdding] = React.useState(false)

    const editingCompetitor = React.useMemo(() => {
        if (!editingId || !isFetched || !items || !editing) return null

        const item = items.filter((c: Event) => c.id === editingId)[0]

        if (!item) return null
        return item
    }, [editingId, editing, items])

    const handleDelete = async (id: number) => {
        const url = `/brand-profile/${brandProfileId}/events/${id}`
        try {
            await userAccountAxios.delete(url)
            toast.success('Deleted')
        } catch (err) {
            toast.error('Error')
        }
        queryClient.invalidateQueries(rqKeys.brandProfileEvents(Number(brandProfileId)))
    }

    return (
        <Panel header={<div className="sightlyPanelHeader">Events</div>}>
            {!!editingCompetitor && (
                <EventModal
                    key="edit"
                    type="edit"
                    open={editing}
                    handleClose={() => {
                        setEditing(false)
                        setEditingId(null)
                    }}
                    item={editingCompetitor}
                />
            )}

            <EventModal
                key="add"
                type="add"
                open={adding}
                handleClose={() => setAdding(false)}
                item={{
                    id: 456456546456456564,
                    name: '',
                    wikiUrl: '',
                    twitterProfileUrl: '',
                    websiteUrl: '',
                    relationshipId: 1,
                    date: null
                }}
            />

            <div className="descriptionText">{`Please enter any events which are relevant to your brand. Ex: NBA
			All-star Game, New York Fashion Week, etc.`}</div>
            {!viewOnly && items && items.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <SightlyButton
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE)}
                        handleClick={() => setAdding(true)}
                        id="addMorebutton"
                        text="Create New"
                    />
                </div>
            )}

            {isFetched && items && items.length < 1 ? (
                <SightlyButton
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                    handleClick={() => setAdding(true)}
                    id="addMorebutton"
                    type="secondary"
                    text="Add +"
                />
            ) : (
                isFetched && (
                    <Table loading={isLoading} autoHeight data={items}>
                        <TableColumnJs flexGrow={undefined}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell style={{ display: 'flex', alignItems: 'center' }} dataKey="relationshipName" />
                        </TableColumnJs>
                        {/* <Table.Column flexGrow={1}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell style={{ display: 'flex', alignItems: 'center' }} dataKey="relationshipName" />
                        </Table.Column> */}

                        <TableColumnJs flexGrow={undefined}>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell>
                                {(item: Event) => {
                                    return <>{item.name}</>
                                }}
                            </Table.Cell>
                        </TableColumnJs>

                        <TableColumnJs flexGrow={undefined}>
                            <Table.HeaderCell>Twitter</Table.HeaderCell>
                            <Table.Cell dataKey="twitterProfileUrl" />
                        </TableColumnJs>

                        <TableColumnJs flexGrow={undefined}>
                            <Table.HeaderCell>Website</Table.HeaderCell>
                            <Table.Cell dataKey="websiteUrl" />
                        </TableColumnJs>

                        <TableColumnJs flexGrow={undefined}>
                            <Table.HeaderCell>Wiki</Table.HeaderCell>
                            <Table.Cell dataKey="wikiUrl" />
                        </TableColumnJs>

                        {!viewOnly && (
                            <TableColumnJs flexGrow={undefined}>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                    {(item: Event) => {
                                        return (
                                            <CustomWhisper
                                                dropdownItems={[
                                                    <Dropdown.Item
                                                        key={1}
                                                        onClick={() => {
                                                            setEditingId(item.id)
                                                            setEditing(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>,
                                                    <Dropdown.Item key={2} onClick={() => handleDelete(item.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                ]}
                                            >
                                                <IconButton
                                                    size="lg"
                                                    appearance="subtle"
                                                    icon={<Icon icon="more" color={accentColor} />}
                                                />
                                            </CustomWhisper>
                                        )
                                    }}
                                </Table.Cell>
                            </TableColumnJs>
                        )}
                    </Table>
                )
            )}
            <style>{`
				.descriptionText {
					color: #a3a3a3;
					font-size: 14px;
					margin-top: 24px;
					margin-bottom: 24px;
					font-weight: 500;									
				}		
			`}</style>
        </Panel>
    )
}

export default QueryTypeOneSection
