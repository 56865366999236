import React from 'react'
import debounce from 'just-debounce-it'
import RadioGroup from 'rsuite/lib/RadioGroup'
import { connect } from 'react-redux'
import Radio from 'rsuite/lib/Radio'
import { neutralColor } from '../../../../../assets/jss/colorContants'
import ControlLabel from 'rsuite/lib/ControlLabel'
import Input from 'rsuite/lib/Input'
import InputNumber from 'rsuite/lib/InputNumber'
import FormGroup from 'rsuite/lib/FormGroup'
import Form from 'rsuite/lib/Form'
import HelpBlock from 'rsuite/lib/HelpBlock'
import Divider from 'rsuite/lib/Divider'
import { Metric, MetricOption } from './Metric'
import { useMatch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useUser from '../../../../../services/useUser'

const mapStateToProps = (state: any) => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

function Outcome(props: any) {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    let _outcome = props.outcome
    const getMetricType = (metricId: number | string) => {
        let metric = props.outcomeMetricOptions.filter((metric: MetricOption) => metric.id === metricId)
        return metric[0]?.type
    }

    const handleDebouncedChange = debounce((property: string, text: number | string) => {
        handleChange(property, text)
    }, 500)

    const handleOutcomeNameChange = debounce((text: string) => {
        handleChange('outcomeName', text)
    }, 500)

    const handleChange = (field: string, value: number | string) => {
        let _outcomeCopy = JSON.parse(JSON.stringify(_outcome))
        _outcomeCopy[field] = value

        if (
            field === 'primaryMetricDescription' ||
            field === 'secondaryMetricDescription' ||
            field === 'tertiaryMetricDescription'
        ) {
            _outcomeCopy.primaryMetricId = null
            _outcomeCopy.secondaryMetricId = null
            _outcomeCopy.tertiaryMetricId = null
        }

        if (field === 'overallGoal') {
            _outcomeCopy.primaryMetricId = null
            _outcomeCopy.secondaryMetricId = null
            _outcomeCopy.tertiaryMetricId = null

            _outcomeCopy.primaryMetricDescription = ''
            _outcomeCopy.secondaryMetricDescription = ''
            _outcomeCopy.tertiaryMetricDescription = ''
            _outcomeCopy.primaryMetricValue = null
            _outcomeCopy.secondaryMetricValue = null
            _outcomeCopy.tertiaryMetricValue = null
        }

        //if metric is being changed and not custom then find type and change that too
        if (
            (field === 'primaryMetricId' && !_outcomeCopy.primaryMetricIsCustom) ||
            (field === 'secondaryMetricId' && !_outcomeCopy.secondaryMetricIsCustom) ||
            (field === 'tertiaryMetricId' && !_outcomeCopy.tertiaryMetricIsCustom)
        ) {
            if (field === 'primaryMetricId') {
                _outcomeCopy.primaryMetricValueType = getMetricType(value)
            } else if (field === 'secondaryMetricId') {
                _outcomeCopy.secondaryMetricValueType = getMetricType(value)
            } else if (field === 'tertiaryMetricId') {
                _outcomeCopy.tertiaryMetricValueType = getMetricType(value)
            }
        }

        props.handlePatch(JSON.parse(JSON.stringify(_outcomeCopy)))
    }

    let cos = props.brandProfile.outcomes
    let totalPercentage = React.useMemo(() => {
        if (!cos) {
            return 0
        }
        let total = 0
        for (const b of cos) {
            total = total + Number(b.budgetPercentage)
        }
        return total
    }, [cos])

    return (
        <div>
            <Form fluid>
                {_outcome.outcomeName !== null && (
                    <FormGroup>
                        <ControlLabel className={'isRequired'}>Outcome Name</ControlLabel>
                        <Input
                            defaultValue={_outcome.outcomeName}
                            onChange={val => {
                                if (val.length < 51) {
                                    handleOutcomeNameChange(val)
                                }
                            }}
                            placeholder="Outcome name"
                            disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <ControlLabel className={'isRequired'}>Budget %</ControlLabel>

                    <InputNumber
                        style={{ width: 200 }}
                        defaultValue={_outcome.budgetPercentage}
                        onChange={val => {
                            if (val === '') {
                                val = 0
                            }
                            handleDebouncedChange('budgetPercentage', val)
                        }}
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                    />
                    {totalPercentage > 100 ? (
                        <HelpBlock>
                            The maximum budget you can enter across all outcomes is 100%. The value you entered exceeds
                            that amount
                        </HelpBlock>
                    ) : (
                        <HelpBlock>
                            {_outcome.budgetPercentage < 0.1
                                ? 'The minimum value is 0.1%'
                                : _outcome.budgetPercentage > 100
                                ? 'You must input a value between 0.1% and 100%'
                                : ' '}
                        </HelpBlock>
                    )}
                </FormGroup>

                <RadioGroup
                    style={{ marginBottom: 30 }}
                    value={_outcome.overallGoal}
                    onChange={value => {
                        handleChange('overallGoal', value)
                    }}
                >
                    <p>What is your overall goal for this outcome?</p>
                    <Radio
                        value="brandingAndAwareness"
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                    >
                        Branding & Awareness
                    </Radio>
                    <Radio
                        value="reachAndMediaEfficiency"
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                    >
                        Reach & Media Efficiency
                    </Radio>
                    <Radio value="conversions" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                        Conversions
                    </Radio>
                    <Radio value="unsure" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                        Unsure
                    </Radio>
                </RadioGroup>

                {_outcome.overallGoal && (
                    <>
                        <Divider />

                        <Metric
                            metricIdProperty={'primaryMetricId'}
                            outcomeMetricOptions={props.outcomeMetricOptions}
                            index={props.index}
                            metricIsCustomProperty={'primaryMetricIsCustom'}
                            metricValueProperty="primaryMetricValue"
                            metricValueTypeProperty="primaryMetricValueType"
                            outcome={_outcome}
                            label="Primary Metric (required)"
                            isCustom={_outcome?.primaryMetricIsCustom}
                            name="primaryMetricDescription"
                            weight={_outcome?.primaryMetricValue ? _outcome.primaryMetricValue : 0}
                            handleChange={handleChange}
                            handleDebouncedChange={handleDebouncedChange}
                            disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                        />

                        <Divider />

                        <Metric
                            outcomeMetricOptions={props.outcomeMetricOptions}
                            metricIdProperty={'secondaryMetricId'}
                            metricIsCustomProperty={'secondaryMetricIsCustom'}
                            metricValueProperty="secondaryMetricValue"
                            metricValueTypeProperty="secondaryMetricValueType"
                            outcome={_outcome}
                            label="Secondary Metric"
                            isCustom={_outcome?.secondaryMetricIsCustom}
                            name="secondaryMetricDescription"
                            weight={_outcome?.secondaryMetricValue ? _outcome?.secondaryMetricValue : 0}
                            handleChange={handleChange}
                            handleDebouncedChange={handleDebouncedChange}
                            disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                        />

                        <Divider />

                        <Metric
                            outcomeMetricOptions={props.outcomeMetricOptions}
                            metricIdProperty={'tertiaryMetricId'}
                            metricIsCustomProperty={'tertiaryMetricIsCustom'}
                            metricValueProperty="tertiaryMetricValue"
                            metricValueTypeProperty="tertiaryMetricValueType"
                            outcome={_outcome}
                            label="Tertiary Metric"
                            isCustom={_outcome?.tertiaryMetricIsCustom}
                            name="tertiaryMetricDescription"
                            weight={_outcome?.tertiaryMetricValue ? _outcome?.tertiaryMetricValue : 0}
                            handleChange={handleChange}
                            handleDebouncedChange={handleDebouncedChange}
                            disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                        />
                    </>
                )}
            </Form>

            <div style={{ color: neutralColor, height: 30 }} />

            <RadioGroup
                style={{ marginBottom: 30 }}
                value={_outcome.mentalityOrSuitability}
                onChange={value => {
                    handleChange('mentalityOrSuitability', value)
                }}
            >
                <p>
                    In the event that Brand Mentality and Brand Suitability are at odds, which one do you prioritize for
                    this outcome?
                </p>
                <Radio value="mentality" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                    Brand Mentality
                </Radio>
                <Radio value="suitability" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                    Brand Suitability
                </Radio>
                <Radio value="unsure" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                    Unsure
                </Radio>
            </RadioGroup>

            <p className={'input-label'} style={{ marginTop: 30 }}>
                Notes (optional)
            </p>
            <Input
                defaultValue={_outcome.notes}
                onChange={val => handleDebouncedChange('notes', val)}
                componentClass="textarea"
                rows={3}
                placeholder="Notes..."
                disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(Outcome)
