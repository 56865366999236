import React from 'react'
import Question from './Question'
import { connect } from 'react-redux'
import Panel from 'rsuite/lib/Panel'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { userAccountAxios } from '../../../../../axiosInstances'
import { questionsObjValidation } from '../../../../../schemas/brandProfiles'
import toast from 'react-hot-toast'
import { ReactLocation, useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'
import { api } from '../../../../../api/api'

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

export const patchBrandProfileQuestions = async data => {
    let brandProfileId = data.brandProfileId
    let questions = data.questions

    const url = `/brand-profile/${brandProfileId}/questions`

    const result = await userAccountAxios.patch(url, questions)

    return result.data
}

function Questions(props) {
    const queryClient = useQueryClient()
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    const QUERY_KEY = rqKeys.brandProfileQuestions(brandProfileId)
    const { data: questions } = useQuery(QUERY_KEY, () => api.brandProfile.questions.get(brandProfileId), {
        enabled: !!brandProfileId
    })

    const handleQuestionResponse = async ({ questionId, response, keywords }) => {
        let data = {
            questionId: questionId,
            responseText: response,
            keywords
        }

        let params = {
            questions: [data],
            brandProfileId: props.brandProfile.brandProfileId
        }

        return await patchBrandProfileQuestions(params)
    }

    const handleResponseMutation = useMutation(handleQuestionResponse, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        },
        onSuccess: () => {
            toast.success('Changes saved')
        }
    })

    return (
        <Panel>
            <div>
                {questions &&
                    questions.length > 0 &&
                    questions.map((question, index) => {
                        return (
                            <Question
                                viewOnly={viewOnly}
                                key={index}
                                handleQuestionResponse={handleResponseMutation.mutate}
                                question={question}
                            />
                        )
                    })}
            </div>
        </Panel>
    )
}

export default connect(mapStateToProps, null)(Questions)
