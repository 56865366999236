import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../../axiosInstances'
import { rqKeys } from '../../../ReactQueryKeyFactory'

const SCENARIO_TYPES_RQ_KEY = rqKeys.scenarioTypes()

interface IProps {
    excludeArchived?: boolean
}

const fetchAdminScenarioTypes = async ({ excludeArchived }: IProps) => {
    const url = excludeArchived ? `/scenarios/types?archived=false` : `/scenarios/types`
    const result = await userAccountAxios.get(url)
    return result.data
}

interface ArchiveProps {
    typeId: number
}
const archiveScenarioType = async ({ typeId }: ArchiveProps) => {
    const url = `/scenarios/types/${typeId}`
    return await userAccountAxios.delete(url)
}

const createScenarioType = async (type: any) => {
    const url = `/scenarios/types`
    await userAccountAxios.post(url, type)
}

const useScenarioTypes = ({ excludeArchived }: IProps) => {
    const queryClient = useQueryClient()
    const { data: scenarioTypes, isLoading: scenarioTypesIsLoading } = useQuery(
        [SCENARIO_TYPES_RQ_KEY, excludeArchived],
        () => fetchAdminScenarioTypes({ excludeArchived })
    )

    const archiveScenarioTypeMutation = useMutation(archiveScenarioType, {
        onMutate: async (args: ArchiveProps) => {
            queryClient.setQueryData(SCENARIO_TYPES_RQ_KEY, (old: any) => {
                if (!old) return []
                return old
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(SCENARIO_TYPES_RQ_KEY)
            toast.success('Success')
        }
    })

    const createScenarioTypeMutation = useMutation(createScenarioType, {
        onMutate: async () => {
            queryClient.setQueryData(SCENARIO_TYPES_RQ_KEY, (old: any) => {
                if (!old) return []
                return old
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries(SCENARIO_TYPES_RQ_KEY)
        }
    })

    return {
        scenarioTypes,
        archiveScenarioType: archiveScenarioTypeMutation.mutate,
        createScenarioType: createScenarioTypeMutation.mutate,
        scenarioTypesIsLoading
    }
}

export default useScenarioTypes
