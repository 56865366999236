import React from 'react'
import ControlLabel from 'rsuite/lib/ControlLabel'
import Input from 'rsuite/lib/Input'
import InputNumber from 'rsuite/lib/InputNumber'
import FormGroup from 'rsuite/lib/FormGroup'
import Checkbox from 'rsuite/lib/Checkbox'
import SelectPicker from 'rsuite/lib/SelectPicker'

interface Outcome {
	[key: string]: any
	overallGoal: string
	budgetPercentage: number
	mentalityOrSuitability: string
	notes: string
	outcomeId: number
	outcomeName: string
	primaryMetricIsCustom: boolean
	primaryMetricValue: number
	primaryMetricValueType: string
	response: string
	secondaryMetricIsCustom: boolean
	secondaryMetricValue: null
	secondaryMetricValueType: string
	tertiaryMetricIsCustom: boolean
	tertiaryMetricValue: null
	tertiaryMetricValueType: string
}

interface MetricType {
	value: string
	label: string
}

export interface MetricOption {
	id: number
	metric: string
	overallGoal: string
	type: string
}

type MetricProps = {
	outcome: Outcome
	label: string
	isCustom: boolean
	disabled: boolean
	handleChange: Function
	metricIsCustomProperty: string
	index?: number
	name: string
	handleDebouncedChange: Function
	metricValueTypeProperty: string
	metricValueProperty: string
	metricIdProperty: string
	weight: string
	outcomeMetricOptions: Array<MetricOption>
}

const metricTypes: Array<MetricType> = [
	{ value: 'dollar', label: '$' },
	{ value: 'percentage', label: '%' },
	{ value: 'whole', label: 'Number' }
]

export const Metric = ({
	outcome,
	label,
	isCustom,
	disabled,
	handleChange,
	metricIsCustomProperty,
	index,
	name,
	handleDebouncedChange,
	metricValueTypeProperty,
	metricValueProperty,
	weight,
	outcomeMetricOptions,
	metricIdProperty
}: MetricProps) => {
	const _metricOptions = React.useMemo(() => {

		if (!outcomeMetricOptions) return []
		if (outcome.overallGoal === 'unsure') return outcomeMetricOptions
		if (outcome.overallGoal === 'brandingAndAwareness')
			return outcomeMetricOptions.filter(
				(option) => option.overallGoal === 'Branding & Awareness'
			)
		if (outcome.overallGoal === 'reachAndMediaEfficiency')
			return outcomeMetricOptions.filter(
				(option) => option.overallGoal === 'Reach & Media Efficiency'
			)
		if (outcome.overallGoal === 'conversions')
			return outcomeMetricOptions.filter(
				(option) => option.overallGoal === 'Conversions'
			)
		return []
	}, [outcomeMetricOptions, outcome])
	return (
		<>
			<FormGroup>
				<ControlLabel>{label}</ControlLabel>
				<Checkbox
					size='xs'
					checked={isCustom}
					disabled={disabled}
					onChange={(na, bool) => {
						handleChange(metricIsCustomProperty, bool)
					}}
				>
					{!disabled || isCustom ? 'Custom' : ''}
				</Checkbox>
			</FormGroup>

			<FormGroup>
				<ControlLabel className={index === 0 ? 'isRequired' : ''}>
					Metric Description
				</ControlLabel>
				{isCustom ? (
					<Input
						defaultValue={outcome[name]}
						onChange={(val) => handleDebouncedChange(name, val)}
						disabled={disabled}
					/>
				) : (
						<SelectPicker
							labelKey={'metric'}
							valueKey={'id'}
							groupBy='overallGoal'
							data={_metricOptions}
							value={outcome && outcome[metricIdProperty]}
							renderMenuItem={(label) => {
								return <div>{label}</div>
							}}
							onChange={(val) => handleChange(metricIdProperty, val)}
							disabled={disabled}
							cleanable={false}
							block
							preventOverflow={true}
							searchable={false}
						/>
					)}
			</FormGroup>

			<FormGroup>
				<ControlLabel>Type</ControlLabel>
				<SelectPicker
					block
					labelKey={'label'}
					valueKey={'value'}
					placeholder={'Metric Type'}
					data={metricTypes}
					value={outcome && outcome[metricValueTypeProperty]}
					renderMenuItem={(label) => {
						return <div>{label}</div>
					}}
					onChange={(val) => handleChange(metricValueTypeProperty, val)}
					disabled={disabled || !isCustom}
					cleanable={false}
					preventOverflow={true}
					searchable={false}
				/>
			</FormGroup>

			<FormGroup>
				<ControlLabel>Benchmark (optional)</ControlLabel>
				<InputNumber
					defaultValue={weight}
					onChange={(val) => {
						handleDebouncedChange(metricValueProperty, val)
					}}
					disabled={disabled}
				/>
			</FormGroup>
		</>
	)
}
