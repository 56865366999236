import React from 'react'
import Skeleton from 'react-loading-skeleton'
import TrendVideoComponent from './TrendVideo'
import { TrendVideo, Trend } from '../../../../classes/trend'

interface TrendVideosProps {
	trendVideos: TrendVideo[]
	compTrend: Trend
	trendVideosLoading: boolean
	handleVideoFeedback: any
}
const TrendVideos = ({
	trendVideos,
	compTrend,
	trendVideosLoading,
	handleVideoFeedback
}: TrendVideosProps) => {
	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
		>
			<div>
				{compTrend?.videoCount === 0 && (
					<div
						style={{
							height: 28,
							paddingRight: 8,
							borderRadius: 3,
							backgroundColor: '#F2FAFD',
							paddingTop: 3,
							paddingLeft: 8,
							fontWeight: 500,
							fontSize: 14,
							lineHeight: '22px',

							marginTop: 24
						}}
					>
						Currently we have no Videos for this Moment.
					</div>
				)}
				{compTrend?.videoCount > 20 && (
					<div
						style={{
							height: 28,
							width: 'fit-content',
							paddingRight: 8,
							borderRadius: 3,
							backgroundColor: '#F2FAFD',
							paddingTop: 3,
							paddingLeft: 8,
							fontWeight: 500,
							fontSize: 14,
							lineHeight: '22px',

							marginTop: 24
						}}
					>
						Showing 20 of {compTrend?.videoCount} videos. To see all videos,
						please download the moment.
					</div>
				)}

				<div
					id='videosTab'
					style={{
						overflowY: 'auto',
						marginTop: 24,
						textAlign: 'left'
					}}
				>
					{trendVideosLoading ? (
						<div style={{ width: 540 }}>
							<Skeleton count={6} />
							<div style={{ height: 24 }} />
							<Skeleton count={6} />
							<div style={{ height: 24 }} />
							<Skeleton count={6} />
						</div>
					) : (
						<>
							{trendVideos &&
								trendVideos.slice(0, 20).map((video, index) => {
									return (
										<TrendVideoComponent
											key={index + video.videoId}
											video={video}
											handleVideoFeedback={handleVideoFeedback}
										/>
									)
								})}
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default TrendVideos
