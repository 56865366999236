import React from 'react'
import { routes } from '../../../routes'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import { userAccountAxios } from '../../../axiosInstances'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import { rqKeys } from '../../../ReactQueryKeyFactory'

export type ItemDetail = {
    id: number
    name: string
    selected?: boolean
    targeted?: boolean
    alwaysDisabled?: boolean
}

export type Item = {
    name: string
    items: ItemDetail[]
}

export type HandleSelectProps = {
    itemType: ItemTypes
    name: string
    id: number
}

type IProps = {
    brandProfileId: number | undefined
    boardType: 'custom' | 'general'
}

export enum ItemTypes {
    'aylienNews' = 'aylienNews',
    'aylienIndustries' = 'aylienIndustries',
    'queries' = 'queries',
    'aggregate' = 'aggregate'
}

const getSelectedItems = (items: Item[]) => {
    let selectedItems = []
    for (const item of items) {
        for (const _el of item.items) {
            if (_el.selected === true) {
                selectedItems.push(_el.id)
            }
        }
    }
    return selectedItems
}

const getSelectedQuery = (item: Item) => {
    if (!item) return []
    let selectedItems = []
    for (const _item of item.items) {
        if (_item.selected === true) {
            selectedItems.push(_item.id)
        }
    }
    return selectedItems
}

const itemsValidation = Yup.array().of(
    Yup.object().shape({
        name: Yup.string().required(),
        items: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(),
                id: Yup.number().required()
            })
        )
    })
)

const useAylienBoardCreator = ({ boardType }: IProps) => {
    const navigate = useNavigate()
    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const queryClient = useQueryClient()
    const [queries, setQueries] = React.useState<Item[]>([])
    const [aylienNews, setAylienNews] = React.useState<Item[]>([])
    const [aylienIndustries, setAylienIndustries] = React.useState<Item[]>([])
    const [aggregates, setAggregates] = React.useState<Item[]>([])
    const [name, setName] = React.useState('')
    const [searchTerm, setSearchTerm] = React.useState('')
    const [isCreating, setIsCreating] = React.useState(false)
    const [isOr, setIsOr] = React.useState(true)

    React.useEffect(() => {
        if (!brandProfileId) return
        const fetchBrandProfileQueries = async () => {
            const url = `/brand-profile/${brandProfileId}/queries`
            const bpQueries = await userAccountAxios.get<Item[]>(url)
            itemsValidation.validate(bpQueries.data).catch(function(err: object) {
                console.error(err)
            })
            bpQueries.data.map(data => {
                return data.items.map(item => {
                    item.targeted = true
                })
            })
            setQueries(bpQueries.data)
        }
        const fetchBrandProfileAylienNews = async () => {
            const url = `/brand-profile/${brandProfileId}/board-aylien-news?boardType=${boardType}`

            if (boardType === 'general') {
                const _aylienNews = await userAccountAxios.get<ItemDetail[]>(url)
                const _formatted = [
                    {
                        name: 'News',
                        items: _aylienNews.data
                    }
                ]

                setAylienNews(_formatted)
            } else {
                const aylienNews_ = await userAccountAxios.get<Item[]>(url)

                itemsValidation.validate(aylienNews_.data).catch(function(err: object) {
                    console.error(err)
                })

                setAylienNews(aylienNews_.data)
            }
        }
        const fetchBrandProfileAylienIndustries = async () => {
            const url = `/brand-profile/${brandProfileId}/board-aylien-industries?boardType=${boardType}`

            if (boardType === 'general') {
                const _aylienIndustries = await userAccountAxios.get<ItemDetail[]>(url)
                const _formatted = [
                    {
                        name: 'Industries',
                        items: _aylienIndustries.data
                    }
                ]

                setAylienIndustries(_formatted)
            } else {
                const _aylienIndustries = await userAccountAxios.get<Item[]>(url)

                itemsValidation.validate(_aylienIndustries.data).catch(function(err: object) {
                    console.error(err)
                })

                setAylienIndustries(_aylienIndustries.data)
            }
        }
        const fetchBrandProfileAggregates = async () => {
            const url = `/brand-profile/${brandProfileId}/board/default-options`
            const res = await userAccountAxios.get(url)
            const _formatted = [
                {
                    name: 'Suggested Boards',
                    items: [
                        {
                            id: 1111111111,
                            name: 'Industry',
                            alwaysDisabled: !res.data.hasIndustry,
                            targeted: true
                        },
                        {
                            id: 2222222222,
                            name: 'Competitors',
                            alwaysDisabled: !res.data.hasCompetitors,
                            targeted: true
                        },
                        {
                            id: 3333333333,
                            name: 'Influencers',
                            alwaysDisabled: !res.data.hasInfluencers,
                            targeted: true
                        }
                    ]
                }
            ]
            setAggregates(_formatted)
        }

        fetchBrandProfileQueries()
        fetchBrandProfileAylienNews()
        fetchBrandProfileAylienIndustries()
        fetchBrandProfileAggregates()
    }, [brandProfileId])

    const createBoard = async () => {
        setIsCreating(true)
        const url = `/brand-profile/${brandProfileId}/board`
        const _board: Partial<any> = {
            boardType,
            name: name,
            starred: false,
            aylienNewsIds: getSelectedItems(aylienNews),
            aylienIndustryIds: getSelectedItems(aylienIndustries),
            queries: {
                competitors: getSelectedQuery(queries.filter(q => q.name === 'competitors')[0]),
                influencers: getSelectedQuery(queries.filter(q => q.name === 'influencers')[0]),
                events: getSelectedQuery(queries.filter(q => q.name === 'events')[0]),
                philanthropic: getSelectedQuery(queries.filter(q => q.name === 'philanthropic')[0]),
                socialAndEnvironmental: getSelectedQuery(queries.filter(q => q.name === 'socialAndEnvironmental')[0])
            }
        }
        if (boardType === 'general') {
            delete _board.queries
        }

        if (boardType === 'custom') {
            _board.operator = isOr ? 'or' : 'and'
        }

        if (getSelectedItems(aggregates).length > 0) {
            _board.defaultType = aggregates[0].items.filter((i: ItemDetail) => i.selected)[0].name.toLowerCase()
        }

        const newBoard = await userAccountAxios.post(url, _board)
        queryClient.invalidateQueries(rqKeys.brandProfileBoards(brandProfileId))
        setIsCreating(false)
        navigate({
            to: routes.app.discover.boards.dashboard.path,
            search(prev?) {
                return {
                    ...prev
                }
            }
        })
    }

    const handleSelectItem = ({ itemType, name, id }: HandleSelectProps) => {
        if (itemType === ItemTypes.aylienIndustries) {
            setAylienIndustries(prev => {
                return prev.map(item => {
                    if (item.name === name) {
                        for (const el of item.items) {
                            if (el.id === id) {
                                el.selected = !el.selected
                            }
                        }
                    }
                    return item
                })
            })
            return
        }

        if (itemType === ItemTypes.aylienNews) {
            setAylienNews(prev => {
                return prev.map(item => {
                    if (item.name === name) {
                        for (const el of item.items) {
                            if (el.id === id) {
                                el.selected = !el.selected
                            }
                        }
                    }
                    return item
                })
            })
            return
        }

        if (itemType === ItemTypes.queries) {
            setQueries(prev => {
                return prev.map(item => {
                    if (item.name === name) {
                        for (const el of item.items) {
                            if (el.id === id) {
                                el.selected = !el.selected
                            }
                        }
                    }
                    return item
                })
            })
        }

        if (itemType === ItemTypes.aggregate) {
            setAggregates(prev => {
                return prev.map(item => {
                    if (item.name === name) {
                        for (const el of item.items) {
                            if (el.id === id) {
                                el.selected = !el.selected
                            }
                        }
                    }
                    return item
                })
            })
        }
    }

    const selectedCount = React.useMemo(() => {
        let selectedItems = 0
        for (const query of queries) {
            for (const item of query.items) {
                if (item.selected === true) {
                    selectedItems++
                }
            }
        }
        for (const news of aylienNews) {
            for (const item of news.items) {
                if (item.selected === true) {
                    selectedItems++
                }
            }
        }
        for (const ind of aylienIndustries) {
            for (const item of ind.items) {
                if (item.selected === true) {
                    selectedItems++
                }
            }
        }
        for (const a of aggregates) {
            for (const item of a.items) {
                if (item.selected === true) {
                    selectedItems++
                }
            }
        }

        return selectedItems
    }, [queries, aylienIndustries, aylienNews, aggregates, boardType])

    const searchedQueries = React.useMemo(() => {
        if (!searchTerm) {
            return queries
        }
        return queries.map(q => {
            return {
                name: q.name,
                items: q.items.filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
            }
        })
    }, [searchTerm, queries])

    const searchedAylienNews = React.useMemo(() => {
        if (!searchTerm) {
            return aylienNews
        }
        return aylienNews.map(q => {
            return {
                name: q.name,
                items: q.items.filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
            }
        })
    }, [searchTerm, aylienNews])

    const searchedAylienIndustries = React.useMemo(() => {
        if (!searchTerm) {
            return aylienIndustries
        }
        return aylienIndustries.map(q => {
            return {
                name: q.name,
                items: q.items.filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
            }
        })
    }, [searchTerm, aylienIndustries])

    const searchedAggregates = React.useMemo(() => {
        if (!searchTerm) {
            return aggregates
        }
        return aggregates.map(q => {
            return {
                name: q.name,
                items: q.items.filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
            }
        })
    }, [searchTerm, aggregates])

    const queriesCount = React.useMemo(() => {
        let _count = 0
        for (const query of queries) {
            for (const item of query.items) {
                _count++
            }
        }
        return _count
    }, [queries])

    const industriesTargetedCount = React.useMemo(() => {
        let _count = 0
        for (const group of aylienIndustries) {
            for (const item of group.items) {
                if (item.targeted) {
                    _count++
                }
            }
        }
        return _count
    }, [aylienIndustries])

    const newsTargetedCount = React.useMemo(() => {
        let _count = 0
        for (const group of aylienNews) {
            for (const item of group.items) {
                if (item.targeted) {
                    _count++
                }
            }
        }
        return _count
    }, [aylienNews])

    const aggregateTargetedCount = React.useMemo(() => {
        let _count = 0
        for (const group of aggregates) {
            for (const item of group.items) {
                if (item.targeted) {
                    _count++
                }
            }
        }
        return _count
    }, [aggregates])

    return {
        queries: searchedQueries,
        aylienIndustries: searchedAylienIndustries,
        aylienNews: searchedAylienNews,
        aggregates: searchedAggregates,
        name,
        setName,
        createBoard,
        isCreating,
        handleSelectItem,
        setSearchTerm,
        searchTerm,
        selectedCount,
        queriesCount,
        industriesTargetedCount,
        newsTargetedCount,
        aggregateTargetedCount,
        isOr,
        setIsOr
    }
}

export default useAylienBoardCreator
