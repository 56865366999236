import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { brandProfilesObjValidation } from '../../schemas/brandProfiles.js'
import { BrandProfile } from '../../classes/brandProfile.js'
import { userAccountAxios } from '../../axiosInstances.js'
import React from 'react'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../classes/utils/utils.js'
import { api } from '../../api/api'
import useUser from '../useUser'
import { location } from '../../RouteContainer'
import { rqKeys } from '../../ReactQueryKeyFactory'

async function getRegularBrandProfiles(accountId: number | undefined) {
    if (accountId === undefined) return []
    const url = `/account/${accountId}/brand-profiles`
    const { data } = await userAccountAxios.get<BrandProfile[]>(url)
    brandProfilesObjValidation.validate(data.slice(0, 2)).catch(function(err: object) {
        console.log(err)
        console.error(
            ' we received different data from the api than expected while fetching all brand profiles, see console log for more details'
        )
    })
    return data.filter((p: BrandProfile) => !p.archived)
}

type IProps = {
    submittedOnly: boolean
}

export default function useBrandProfiles(args: IProps) {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const navigate = useNavigate()

    const queryClient = useQueryClient()

    const QUERY_KEY = rqKeys.brandProfilesKey(currentAccount?.accountId)
    const brandProfilesObj = useQuery(QUERY_KEY, () => getRegularBrandProfiles(currentAccount?.accountId), {
        enabled: !!currentAccount?.accountId,
        select: (bps: BrandProfile[]) =>
            args.submittedOnly === true ? bps.filter((bp: BrandProfile) => bp.submitted) : bps
    })

    const createBrandProfileMutation = useMutation(() => api.brandProfile.create(currentAccount?.accountId), {
        onSuccess: data => {
            if (!data || !data.brandProfileId) {
                throw new Error('brand profile creation result must contain brandProfileId')
            }
            queryClient.invalidateQueries(QUERY_KEY)
            const url = `/app/settings/brandProfiles/brandProfile/${data.brandProfileId}/brandInformation`
            navigate({ to: url })
        }
    })

    const cloneBrandProfileMutation = useMutation(
        ({ brandProfileId }: { brandProfileId: number }) => api.brandProfile.clone(brandProfileId),
        {
            onSuccess: data => {
                if (!data || !data.brandProfileId) {
                    throw new Error('brand profile clone result must contain brandProfileId')
                }
                const brandProfileId = data?.brandProfileId
                queryClient.invalidateQueries(QUERY_KEY)
                const url = `/app/settings/brandProfiles/brandProfile/${brandProfileId}/brandInformation`
                navigate({ to: url })
            }
        }
    )

    const brandProfiles = brandProfilesObj.data
    const { brandProfileId: urlParamBrandProfileId } = useSearch<MyLocationGenerics>()

    const currentBrandProfile = React.useMemo(() => {
        if (!brandProfiles || brandProfiles.length < 1) {
            return null
        }
        if (urlParamBrandProfileId) {
            const urlParamsBrandProfile = brandProfiles.filter(
                (p: BrandProfile) => p.brandProfileId == Number(urlParamBrandProfileId)
            )
            if (!urlParamsBrandProfile || urlParamsBrandProfile === undefined || urlParamsBrandProfile.length < 1) {
                return brandProfiles[0]
            }

            return urlParamsBrandProfile[0]
        } else {
            return brandProfiles[0]
        }
    }, [brandProfiles, urlParamBrandProfileId, location.current.search])

    return {
        ...brandProfilesObj,
        currentBrandProfile,
        createBrandProfile: createBrandProfileMutation.mutate,
        cloneBrandProfile: cloneBrandProfileMutation
    }
}
