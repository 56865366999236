import {
    HAS_BRAND_PROFILES,
    SET_BRAND_PROFILE_LOADING,
    SET_BRAND_PROFILE_SAVING,
    SET_BRAND_PROFILE_SAVED,
    SCENARIOS_IS_LOADING,
    SET_BRAND_PROFILE_UNDER_EDIT,
    SET_BRAND_PROFILE_IAB_CATEGORIES,
    SET_BRAND_PROFILE_TOPICS,
    SET_BRAND_PROFILE_BASIC_INFO,
    SET_BRAND_PROFILE_IOS,
    SET_IS_DOWNLOADING_BRAND_PROFILE,
    SET_DOWNLOADING_BRAND_PROFILE_ID,
    SET_BRAND_PROFILE_SUBMITTING,
    SET_BRAND_PROFILE_SUBMITTED,
    SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING,
    SET_BRAND_PROFILE_TOPICS_LOADING,
    SET_BRAND_PROFILE_OUTCOMES,
    ADD_OUTCOME,
    SET_OUTCOME_METRIC_OPTIONS
} from '../action-types/brandProfiles'
import { initialOutcome } from '../../views/BrandProfiles/BrandProfile/components/Outcomes/constants'

export function brandProfileSubmitting(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_SUBMITTING:
            return action.brandProfileSubmitting
        default:
            return state
    }
}

export function brandProfileUnderEdit(
    state = {
        submitted: null,
        brandName: '',
        websiteUrl: '',
        aylienIndustryId: null,
        twitterProfileUrl: '',
        brandProfileId: ''
    },
    action
) {
    switch (action.type) {
        case SET_BRAND_PROFILE_UNDER_EDIT:
            return action.brandProfileUnderEdit
        case SET_BRAND_PROFILE_BASIC_INFO:
            return {
                ...state,
                brandName: action.basicInfo.brandName,
                aylienIndustryId: action.basicInfo.aylienIndustryId,
                websiteUrl: action.basicInfo.websiteUrl,
                twitterProfileUrl: action.basicInfo.twitterProfileUrl
            }

        case SET_BRAND_PROFILE_OUTCOMES:
            return {
                ...state,
                outcomes: action.outcomes
            }

        case SET_BRAND_PROFILE_IAB_CATEGORIES:
            return {
                ...state,
                iabCategories: action.iabCategories
            }
        case SET_BRAND_PROFILE_TOPICS:
            return {
                ...state,
                topics: action.topics
            }

        case SET_BRAND_PROFILE_IOS:
            return {
                ...state,
                ios: action.ios
            }

        case ADD_OUTCOME:
            let originalState = JSON.parse(JSON.stringify(state))
            let newOutcome = {
                ...initialOutcome,
                outcomeId: Math.random() * 100,
                budgetPercentage: 0
            }
            originalState.outcomes.push(newOutcome)
            return originalState

        default:
            return state
    }
}

export function brandProfileSaving(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_SAVING:
            return action.brandProfileSaving
        default:
            return state
    }
}
export function brandProfileSaved(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_SAVED:
            return action.brandProfileSaved
        default:
            return state
    }
}

export function outcomeMetricOptions(state = [], action) {
    switch (action.type) {
        case SET_OUTCOME_METRIC_OPTIONS:
            return action.outcomeMetricOptions
        default:
            return state
    }
}

export function brandProfileSubmitted(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_SUBMITTED:
            return action.brandProfileSubmitted
        default:
            return state
    }
}

export function brandProfileLoading(state = true, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_LOADING:
            return action.brandProfileLoading
        default:
            return state
    }
}

export function hasBrandProfiles(state = true, action) {
    switch (action.type) {
        case HAS_BRAND_PROFILES:
            return action.hasBrandProfiles
        default:
            return state
    }
}

export function brandProfileIabCategoriesLoading(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING:
            return action.iabCategoriesLoading
        default:
            return state
    }
}

export function brandProfileTopicsLoading(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_TOPICS_LOADING:
            return action.topicsLoading
        default:
            return state
    }
}

export function scenariosIsLoading(state = true, action) {
    switch (action.type) {
        case SCENARIOS_IS_LOADING:
            return action.scenariosIsLoading
        default:
            return state
    }
}

export function isDownloadingBrandProfile(state = false, action) {
    switch (action.type) {
        case SET_IS_DOWNLOADING_BRAND_PROFILE:
            return action.isDownloadingBrandProfile
        default:
            return state
    }
}

export function downloadingBrandProfileId(state = null, action) {
    switch (action.type) {
        case SET_DOWNLOADING_BRAND_PROFILE_ID:
            return action.downloadingBrandProfileId
        default:
            return state
    }
}
