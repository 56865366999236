import { useQuery } from '@tanstack/react-query'
import { usersObjVal } from '../schemas/schemas.js'
import { User } from '../classes/user.js'
import { userAccountAxios } from '../axiosInstances.js'
import useUser from './useUser'
import { rqKeys } from '../ReactQueryKeyFactory'

const getUsers = async (currentAccountId: number | undefined) => {
    const url = `/account/${currentAccountId}/users`
    const { data } = await userAccountAxios.get<User[]>(url)

    usersObjVal.validate(data).catch(function(err: object) {
        console.error('received diff data than expected getting users')
    })

    return data
}

export default function useUsers() {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    return useQuery(rqKeys.usersKeys(currentAccount?.accountId), () => getUsers(currentAccount?.accountId), {
        enabled: !!currentAccount?.accountId
    })
}
