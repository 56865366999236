import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import SightlyAvatar from '../../components/SightlyAvatar'
import SightlyButton from '../../components/SightlyButton'
import toast from 'react-hot-toast'
import Input from 'rsuite/lib/Input'
import Checkbox from 'rsuite/lib/Checkbox'
import { permissionView } from '../../utils.js'
import useUser from '../../services/useUser'
import { api } from '../../api/api'

const mapStateToProps = state => {
    return {
        token: state.authToken
    }
}

const schemaValidation = Yup.object().shape({
    firstName: Yup.string()
        .required('Required')
        .min(2, 'First Name must be greater than 1 character')
        .max(50, 'First Name must be less than 50 characters'),
    lastName: Yup.string()
        .required('Required')
        .min(2, 'Last Name must be greater than 1 character')
        .max(50, 'Last Name must be less than 50 characters'),
    company: Yup.string()
        .required('Required')
        .min(2, 'Company must be greater than 1 character')
        .max(50, 'Company must be less than 50 characters'),
    email: Yup.string()
        .required('Required')
        .email('Invalid email')
})

function UserProfile(props) {
    const [compUser, setCompUser] = React.useState({
        company: '',
        email: '',
        firstName: '',
        lastName: ''
    })

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    React.useEffect(() => {
        if (user) setCompUser(user)
    }, [user])

    const handleViewPermissionsModeClick = (e, checked) => {
        if (checked) {
            localStorage.setItem('permissionView', '1')
        } else {
            localStorage.removeItem('permissionView')
        }
        window.location.reload()
    }

    const handleSubmit = () => {
        const vals = {
            company: compUser.company,
            firstName: compUser.firstName,
            lastName: compUser.lastName,
            email: compUser.email
        }

        schemaValidation
            .validate(vals)
            .catch(function(err) {
                toast.error(err.errors)
                return
            })
            .then(function(valid) {
                if (valid) {
                    const args = {
                        userId: compUser.userId,
                        company: compUser.company,
                        firstName: compUser.firstName,
                        lastName: compUser.lastName,
                        email: compUser.email,
                        userType: compUser.userType
                    }

                    api.user.updateUserData(args)
                }
            })
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                justify: 'center',
                alignContent: 'center',
                paddingTop: 78,
                position: 'absolute',
                top: 56,
                left: 80,
                right: 0,
                bottom: 0
            }}
        >
            {userCan(perms.ADMIN_READ) && (
                <Checkbox
                    checked={permissionView}
                    onChange={handleViewPermissionsModeClick}
                    style={{ position: 'absolute', bottom: 48, right: 48 }}
                >
                    View permissions mode
                </Checkbox>
            )}
            <div
                style={{
                    position: 'relative',
                    width: 526
                }}
            >
                <div
                    style={{
                        width: 526,
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 54
                    }}
                >
                    <SightlyAvatar text={user?.firstName + ' ' + user?.lastName} type="blue" size="xl" />
                </div>

                <div style={{ display: 'flex', marginBottom: 24 }}>
                    <div style={{ width: 251, marginRight: 24 }}>
                        <div className="input-label">First Name</div>
                        <Input
                            id="firstNameInput"
                            value={compUser.firstName}
                            onChange={val =>
                                setCompUser(prev => {
                                    return { ...prev, firstName: val }
                                })
                            }
                        />
                    </div>

                    <div style={{ width: 251 }}>
                        <div className="input-label">Last Name</div>
                        <Input
                            id="lastNameInput"
                            value={compUser.lastName}
                            onChange={val =>
                                setCompUser(prev => {
                                    return { ...prev, lastName: val }
                                })
                            }
                        />
                    </div>
                </div>

                <div style={{ width: 526, marginBottom: 24 }}>
                    <div className="input-label">Email</div>
                    <Input
                        id="emailInput"
                        disabled={true}
                        value={compUser.email}
                        onChange={val =>
                            setCompUser(prev => {
                                return { ...prev, email: val }
                            })
                        }
                    />
                </div>

                <div style={{ width: 526, marginBottom: 24 }}>
                    <div className="input-label">Company</div>
                    <Input
                        id="companyInput"
                        value={compUser.company}
                        onChange={val =>
                            setCompUser(prev => {
                                return { ...prev, company: val }
                            })
                        }
                    />
                </div>

                <div style={{ width: 116, display: 'flex', justifyContent: 'left' }}>
                    <SightlyButton id="saveUserProfileButton" handleClick={handleSubmit} text="Save" />
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(UserProfile)
