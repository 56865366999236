import React from 'react'
import { fetchEvergreenChannels, archiveEvergreenChannel } from '../../../redux/actions/admin/evergreenChannels'
import { connect } from 'react-redux'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import Button from 'rsuite/lib/Button'
import Loader from 'rsuite/lib/Loader'
import TablePagination from 'rsuite/lib/Table/TablePagination'
import Table from 'rsuite/lib/Table'
import ButtonToolbar from 'rsuite/lib/ButtonToolbar'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import { routes } from '../../../routes'
import { iabCategoriesFilter } from '../../../staticData/iabCategories'
import { useNavigate } from '@tanstack/react-location'

const mapStateToProps = state => {
    return {
        evergreenChannels: state.admin.evergreenChannels,
        evergreenChannelCount: state.admin.evergreenChannelCount,
        evergreenChannelsLoading: state.admin.evergreenChannelsLoading,
        evergreenChannelsHasNextPage: state.admin.evergreenChannelsHasNextPage,
        currentEvergreenChannelsPage: state.admin.currentEvergreenChannelsPage,
        evergreenChannelIabCategories: state.admin.evergreenChannelIabCategories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchEvergreenChannels: params => dispatch(fetchEvergreenChannels(params)),
        archiveEvergreenChannel: channelId => dispatch(archiveEvergreenChannel(channelId))
    }
}

function EvergreenChannels(props) {
    const navigate = useNavigate()
    const {
        fetchEvergreenChannels,
        evergreenChannels,
        evergreenChannelCount,
        evergreenChannelsLoading,
        evergreenChannelsHasNextPage,
        currentEvergreenChannelsPage,
        evergreenChannelIabCategories
    } = props

    let [fetched, setFetched] = React.useState(false)

    React.useEffect(() => {
        if (!fetched) {
            let params = {
                iabCategories: evergreenChannelIabCategories,
                page: currentEvergreenChannelsPage || 1,
                limit: 50
            }
            fetchEvergreenChannels(params)
            setFetched(true)
        }
    }, [])

    if (evergreenChannelsLoading) {
        return <Loader speed="fast" content={'Loading...'} vertical center />
    }

    const channelHeaders = [
        'Channel ID',
        'Title',
        'Description',
        'Iab Categories',
        'Link to YT page',
        'Latest Upload Date',
        'Total Views',
        'Total Subscribers',
        'Action'
    ]

    const fetchEvergreenChannelNextPage = page => {
        let params = {
            iabCategories: evergreenChannelIabCategories,
            page: page,
            limit: 50
        }
        setFetched(false)
        fetchEvergreenChannels(params)
        setFetched(true)
    }

    const handleArchiveEvergreenChannelClick = channelId => {
        props.archiveEvergreenChannel(channelId)
    }

    const handleCreateChannelClick = () => {
        let url = routes.admin.evergreenChannels.create.path
        navigate({ to: url })
    }

    const handleFilterChange = value => {
        let params = {
            iabCategories: value,
            page: 1,
            limit: 50
        }
        setFetched(false)
        fetchEvergreenChannels(params)
        setFetched(true)
    }

    return (
        <div style={{ margin: 'auto', overflow: 'auto', padding: 24 }}>
            <ButtonToolbar>
                <Button appearance="primary" onClick={handleCreateChannelClick}>
                    Add Channel
                </Button>
            </ButtonToolbar>

            <br />
            <CheckTreePicker
                preventOverflow
                size={'xs'}
                defaultExpandAll={false}
                value={evergreenChannelIabCategories}
                data={iabCategoriesFilter}
                labelKey={'name'}
                valueKey={'id'}
                onChange={handleFilterChange}
                cascade={true}
                block
            />

            <div
                style={{
                    height: 20,
                    textAlign: 'center',
                    margin: '10px auto',
                    fontWeight: 'bold'
                }}
            >
                {evergreenChannelCount} channels found
            </div>

            <Table defaultExpandAllRows autoHeight data={evergreenChannels || []} shouldUpdateScroll={false}>
                <Table.Column resizable>
                    <Table.HeaderCell>Channel ID</Table.HeaderCell>
                    <Table.Cell dataKey="channelId" />
                </Table.Column>
                <Table.Column resizable>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.Cell dataKey="title" />
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.Cell style={{ color: 'grey' }}>
                        {channel => {
                            return (
                                <Whisper
                                    delayShow={700}
                                    delayHide={0}
                                    trigger="hover"
                                    placement={'right'}
                                    speaker={<Tooltip>{channel.description}</Tooltip>}
                                >
                                    <div className={'lbTableFont'}>
                                        {channel.description
                                            ? `${channel.description.substring(0, 100)}...`
                                            : channel.description}
                                    </div>
                                </Whisper>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Iab Categories</Table.HeaderCell>
                    <Table.Cell>
                        {channel => {
                            return <div>{channel.iabCategories.join(', ')}</div>
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Link to YT page</Table.HeaderCell>
                    <Table.Cell>
                        {channel => {
                            return (
                                <div>
                                    <a href={channel.ytLink}>{channel.ytLink}</a>
                                </div>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Last Upload Date</Table.HeaderCell>
                    <Table.Cell dataKey="lastUploadDate" />
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Total Views</Table.HeaderCell>
                    <Table.Cell dataKey="totalViews" />
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Total Subscribers</Table.HeaderCell>
                    <Table.Cell dataKey="totalSubscribers" />
                </Table.Column>

                <Table.Column resizable>
                    <Table.HeaderCell>Archive</Table.HeaderCell>
                    <Table.Cell style={{ color: 'grey' }}>
                        {channel => {
                            return (
                                <>
                                    {!channel.archived && (
                                        <Button
                                            appearance="link"
                                            loading={props.evergreenChannelArchiving === channel.channelId}
                                            onClick={() => {
                                                handleArchiveEvergreenChannelClick(channel.channelId)
                                            }}
                                        >
                                            Archive
                                        </Button>
                                    )}
                                </>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>

            <TablePagination
                lengthMenu={[
                    {
                        value: 50,
                        label: 50
                    }
                ]}
                last={false}
                next={evergreenChannelsHasNextPage}
                renderTotal={() => {
                    return <div>Page {currentEvergreenChannelsPage}</div>
                }}
                activePage={currentEvergreenChannelsPage}
                displayLength={50}
                onChangePage={fetchEvergreenChannelNextPage}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EvergreenChannels)
