import React from 'react'
import { connect } from 'react-redux'
import TrendModal from './components/TrendModal'
import { postTrendEdit } from '../../redux/actions/discover/trends'

import SightlySelect from '../../components/Sightly/SightlySelect'
import SightlyCheckbox from '../../components/SightlyCheckbox'
import TrendCard from './components/TrendCard'
import SightlyMessage from '../../components/Sightly/SightlyMessage'
import {
    fetchDemoTrendsService,
    downloadTrendsService,
    postTrendRecActionFeedbackService,
    postTrendKeywordsFeedbackService,
    postTrendQuestionFeedbackService,
    postTrendVideoFeedbackService
} from '../../services/trends'

import {
    fetchTrendsService,
    fetchTrendVideosService,
    fetchTrendArticlesService,
    trendsBlockList,
    fetchTrendScenarios,
    fetchTrendKeywordsService
} from '../../services/trends_ts'

import { brandSuccessColor } from '../../assets/jss/colorContants'
import config from '../../config'
import { MoreButton } from './components/MoreButton'
import FilterButton from './components/FilterButton'
import RsuiteLoader from 'rsuite/lib/Loader'
import { Trend, TrendVideo, TrendScenario } from '../../classes/trend'
import BlockPlacementsModal from './components/BlockPlacementsModal'
import Skeleton from 'react-loading-skeleton'
import NoBrandProfiles from '../../components/NoBrandProfiles'
import ShareModal from './components/ShareModal'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import useBrandProfiles from '../../services/brandProfile/useBrandProfiles'
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../classes/utils/utils'
import { logError } from '../../utils'
import useUser from '../../services/useUser'
import { rqKeys } from '../../ReactQueryKeyFactory'

var dayjs = require('dayjs')

const sortOptions: { id: string; label: string }[] = [
    { id: 'keywords', label: 'Most Keywords' },
    { id: 'placements', label: 'Most Placements' },
    { id: 'popularity', label: 'Popularity' }
]

const getSelectedTrends = (trends: Trend[]) => {
    let _selected: string[] = []
    for (const trend of trends) {
        if (trend.selected) {
            _selected.push(trend.trendId)
        }
    }
    return _selected
}

const getFilteredTrends = (trends: Trend[], actions: string, board: string) => {
    const filteredForVerticals = trends.filter(t => t.vertical === board)

    if (actions.includes('target') && actions.includes('monitor') && actions.includes('block')) {
        return filteredForVerticals
    }
    if (actions.includes('target') && actions.includes('monitor')) {
        return filteredForVerticals.filter(t => t.action === 'target' || t.action === 'monitor')
    }

    if (actions.includes('target') && actions.includes('block')) {
        return filteredForVerticals.filter(t => t.action === 'target' || t.action === 'block')
    }

    if (actions.includes('monitor') && actions.includes('block')) {
        return filteredForVerticals.filter(t => t.action === 'monitor' || t.action === 'block')
    }

    if (actions.includes('monitor')) {
        return filteredForVerticals.filter(t => t.action === 'monitor')
    }
    if (actions.includes('block')) {
        return filteredForVerticals.filter(t => t.action === 'block')
    }
    if (actions.includes('target')) {
        return filteredForVerticals.filter(t => t.action === 'target')
    }
    return filteredForVerticals
}

const mapStateToProps = (state: any) => {
    return {
        trendArticles: state.discover.trendArticles,
        trendVideos: state.discover.trendVideos
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        postTrendEdit: (args: any) => dispatch(postTrendEdit(args))
    }
}

interface iProps {
    postTrendEdit: Function
}

interface Time {
    hour: number
    text: string
}

const Trends = (props: iProps) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate<MyLocationGenerics>()

    const {
        data: { trends: { trendsLatestBatch } = {} }
    } = useMatch<MyLocationGenerics>()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const {
        currentBrandProfile: brandProfile,
        data: brandProfiles,
        isLoading: brandProfilesIsLoading
    } = useBrandProfiles({
        submittedOnly: true
    })

    const brandProfileId = brandProfile?.brandProfileId

    const { date, hour } = useSearch<MyLocationGenerics>()
    const [dates, setDates] = React.useState<{ date: string }[]>([
        { date: dayjs().format('YYYY-MM-DD') },
        {
            date: dayjs()
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
        },
        {
            date: dayjs()
                .subtract(2, 'day')
                .format('YYYY-MM-DD')
        }
    ])
    const [hours] = React.useState<Time[]>([
        { hour: 3, text: '6am' },
        { hour: 8, text: '11am' },
        { hour: 13, text: '4pm' }
    ])

    const [selectedTrend, setSelectedTrend] = React.useState<Trend | null>(null)
    const [vertical, setVertical] = React.useState<string>('general')
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [actions, setActions] = React.useState<string>('all')
    const [sortBy, setSortBy] = React.useState<string>('popularity')
    const [articles, setArticles] = React.useState<Trend['articles']>([])
    const [videos, setVideos] = React.useState<Trend['videos']>([])
    const [keywords, setKeywords] = React.useState<Trend['keywords']>([])
    const [trendScenarios, setTrendScenarios] = React.useState<TrendScenario[]>([])
    const [trendScenariosLoading, setTrendScenariosLoading] = React.useState<boolean>(true)

    const [trendArticlesLoading, setTrendArticlesLoading] = React.useState<boolean>(true)

    const [trendVideosLoading, setTrendVideosLoading] = React.useState<boolean>(true)

    const [trendKeywordsLoading, setTrendKeywordsLoading] = React.useState(true)

    React.useEffect(() => {
        if ((!date || !hour) && trendsLatestBatch) {
            navigate({
                search: old => ({
                    ...old,
                    date: trendsLatestBatch.date,
                    hour: trendsLatestBatch.hour
                })
            })
        }
        if (!brandProfile) return
        navigate({
            search: search => ({
                ...search,
                brandProfileId: brandProfile?.brandProfileId
            })
        })
    }, [trendsLatestBatch, brandProfile])

    const QUERY_KEY = rqKeys.trends(brandProfileId, date, hour, sortBy, config.isDemo)

    const args = {
        size: 10000,
        page: 1,
        sortBy: sortBy,
        includeStats: false,
        date: date || '',
        hour: hour || 3
    }
    const { data, isLoading: trendsIsLoading, isFetched: trendsIsFetched } = useQuery(
        QUERY_KEY,
        () =>
            config.isDemo
                ? fetchDemoTrendsService()
                : fetchTrendsService({
                      args,
                      brandProfileId: brandProfileId || 0
                  }),
        {
            enabled: !!brandProfileId && !!date && !!hour && date !== null,
            refetchOnWindowFocus: false
        }
    )

    const fullTrends: Trend[] = data

    const handleBrandProfileChange = (_brandProfileId: number) => {
        navigate({
            search: old => ({
                ...old,
                brandProfileId: _brandProfileId
            })
        })
    }

    const handleDateChange = (date: string) => {
        navigate({
            search: old => ({
                ...old,
                date
            })
        })
    }

    const handleHourChange = (hour: number) => {
        navigate({
            search: old => ({
                ...old,
                hour
            })
        })
    }

    const [downloadingTrends, setDownloadingTrends] = React.useState<boolean>(false)

    const handleDownloadTrends = async () => {
        setDownloadingTrends(true)
        let args = {
            brandProfileId: brandProfileId,
            trendIds: getSelectedTrends(fullTrends)
        }
        await downloadTrendsService(args)
        setDownloadingTrends(false)
    }

    const selectAllClick = (checked: boolean) => {
        queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
            if (prev === undefined) return []
            return prev.map(item => {
                _visibleTrends.forEach((visibleTrend: Trend) => {
                    if (item.trendId === visibleTrend.trendId) {
                        item = { ...item, selected: checked }
                    }
                })
                return item
            })
        })
    }

    const handleSelectTrend = React.useCallback(
        (bool: boolean, trendId: string) => {
            queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
                if (prev === undefined) return []
                return prev.map(item => {
                    if (item.trendId === trendId) {
                        return { ...item, selected: bool }
                    }
                    return item
                })
            })
        },
        [fullTrends]
    )

    const handleTrendNameClick = React.useCallback(
        async (trend: Trend) => {
            setSelectedTrend(trend)
            setTrendScenariosLoading(true)
            setTrendArticlesLoading(true)
            setTrendVideosLoading(true)
            setTrendKeywordsLoading(true)
            setShowModal(true)

            const args = {
                trendId: trend.trendId,
                brandProfileId: trend.brandProfileId
            }

            await Promise.all([
                (async () => {
                    const _trendScenarios = await fetchTrendScenarios(args)
                    setTrendScenarios(_trendScenarios.scenarios)

                    queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
                        if (prev === undefined) return []
                        return prev.map((t: Trend) => {
                            if (t.trendId === trend.trendId) {
                                return {
                                    ...t,
                                    action: _trendScenarios.recommendedAction
                                }
                            }
                            return t
                        })
                    })

                    setTrendScenariosLoading(false)
                })(),
                (async () => {
                    const _articles = await fetchTrendArticlesService(args)
                    setArticles(_articles)
                    setTrendArticlesLoading(false)
                })(),
                (async () => {
                    const _videos = await fetchTrendVideosService(args)
                    setVideos(_videos)
                    setTrendVideosLoading(false)
                })(),
                (async () => {
                    const _kw = await fetchTrendKeywordsService(args)
                    setKeywords(_kw)
                    setTrendKeywordsLoading(false)
                })()
            ])
        },
        [fullTrends]
    )

    const handleSaveActionEdit = React.useCallback(
        (trend: Trend, action: any) => {
            let args = {
                trendId: trend.trendId,
                brandProfileId: trend.brandProfileId,
                action
            }

            queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
                if (prev === undefined) return []
                return prev.map(t => {
                    if (t.trendId === trend.trendId) {
                        return {
                            ...t,
                            editedFromAction: t.action,
                            action: action,
                            editedBy: `${user?.firstName} ${user?.lastName}`,
                            editedDateTime: new Date().toJSON()
                        }
                    }
                    return t
                })
            })

            props.postTrendEdit(args)
        },
        [user, fullTrends]
    )
    const [showHasNonBlocked, setShowHasNonBlocked] = React.useState<boolean>(false)
    const [showBlockModal, setShowBlockModal] = React.useState<boolean>(false)
    const [showMonitorImp, setShowMonitorImp] = React.useState<boolean>(false)
    const [showSendImp, setShowSendImp] = React.useState<boolean>(false)

    const hasNonBlocked = React.useMemo(() => {
        if (!fullTrends) return false
        for (const trend of fullTrends) {
            if (trend.selected && trend.action !== 'block') {
                return true
            }
        }
        return false
    }, [fullTrends])

    const handleBlockClick = async () => {
        if (hasNonBlocked) {
            setShowHasNonBlocked(true)
        } else {
            setShowHasNonBlocked(false)
            setShowBlockModal(true)
        }
    }

    const handleImplementClick = async () => {
        if (trendsHaveMonitor()) {
            setShowMonitorImp(true)
            return
        } else {
            setShowSendImp(true)
            setShowMonitorImp(false)
        }
    }

    const trendsHaveMonitor = () => {
        if (!fullTrends) return true
        for (const trend of fullTrends?.filter((t: Trend) => t.selected)) {
            if (trend.action === 'monitor') {
                return true
            }
        }
        return false
    }

    const selectAllLabel = React.useMemo(() => {
        if (!fullTrends) return ''
        if (fullTrends.length === 0) {
            return ''
        }
        const _selected = fullTrends?.filter(t => t.selected)
        if (_selected.length === 0) {
            return ''
        }
        return _selected.length === 1 ? '1 item selected' : _selected.length + ' items selected'
    }, [fullTrends])

    const postTrendRecActionFeedback = (args: { trendId: string; feedback: string }) => {
        queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
            if (prev === undefined) return []
            return prev.map(t => {
                if (t.trendId === args.trendId) {
                    return { ...t, recommendedActionFeedback: args.feedback }
                }
                return t
            })
        })

        postTrendRecActionFeedbackService(args)
    }

    const postTrendKeywordsFeedback = (args: { trendId: string; feedback: string }) => {
        queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
            if (prev === undefined) return []
            return prev.map(t => {
                if (t.trendId === args.trendId) {
                    return { ...t, keywordsFeedback: args.feedback }
                }
                return t
            })
        })

        postTrendKeywordsFeedbackService(args)
    }

    const postTrendQuestionFeedback = (args: { trendId: string; feedback: string; question: string }) => {
        postTrendQuestionFeedbackService(args)
    }

    interface FeedbackServiceBody {
        brandProfileId: number
        videoId: string
        trendId: string
        videoRelatedToTrend?: string | null
        languageIsEnglish?: string | null
        dateRelevancyIsInvalid?: string | null
    }

    const postTrendVideoFeedback = (args: {
        type: string
        brandProfileId: number
        video: TrendVideo
        trendId: string
        feedback: string
    }) => {
        const _videos = JSON.parse(JSON.stringify(videos))

        let _body: FeedbackServiceBody = {
            brandProfileId: args.brandProfileId,
            videoId: args.video.videoId,
            trendId: args.trendId
        }

        for (const video of _videos) {
            if (video.videoId === args.video.videoId) {
                if (args.type === 'videoRelatedToTrend') {
                    video.feedback.videoRelatedToTrend = args.feedback
                    _body.videoRelatedToTrend = args.feedback
                }
                if (args.type === 'languageIsEnglish') {
                    video.feedback.languageIsEnglish = args.feedback
                    _body.languageIsEnglish = args.feedback
                }
                if (args.type === 'dateRelevancyIsInvalid') {
                    video.feedback.dateRelevancyIsInvalid = args.feedback
                    _body.dateRelevancyIsInvalid = args.feedback
                }
            }
        }
        setVideos(_videos)

        postTrendVideoFeedbackService(_body)
    }

    const handleModalClose = () => {
        setShowModal(false)
        setArticles([])
        setVideos([])
        setTrendScenarios([])
    }

    const [showBlockSuccess, setShowBlockSuccess] = React.useState<boolean>(false)
    const [showBlockError, setShowBlockError] = React.useState<string | null>(null)
    const [blocking, setBlocking] = React.useState<boolean>(false)

    const handleBlock = async ({ trendIds, ioIds }: { trendIds: string[]; ioIds: number[] }) => {
        if (brandProfileId === null || brandProfileId === undefined) {
            return
        }
        setBlocking(true)

        const result = await trendsBlockList({
            brandProfileId,
            trendIds,
            ioIds
        })

        setBlocking(false)

        if (result.status === 200) {
            setShowBlockSuccess(true)
            setShowBlockError(null)
        } else if (result.response.data) {
            logError(result)
            setShowBlockError(JSON.stringify(result.response.data))
            setShowBlockSuccess(false)
            unselectAllTrends()
            return
        }

        unselectAllTrends()
        changeTrendsToBlocked(trendIds)
    }

    const changeTrendsToBlocked = (trendIds: Trend['trendId'][]) => {
        queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
            if (prev === undefined) return []
            return prev.map(t => {
                if (trendIds.includes(t.trendId)) {
                    return {
                        ...t,
                        gAdsBlocked: true
                    }
                }

                return t
            })
        })
    }

    const unselectAllTrends = () => {
        queryClient.setQueryData(QUERY_KEY, (prev: Trend[] | undefined) => {
            if (prev === undefined) return []
            return prev.map(t => {
                return {
                    ...t,
                    selected: false
                }
            })
        })
    }

    const _visibleTrends = React.useMemo(() => {
        if (!fullTrends) return []
        return getFilteredTrends(fullTrends, actions, vertical)
    }, [fullTrends, vertical, actions])

    const isSelectAll = React.useMemo(() => {
        if (_visibleTrends.length === 0) return false
        const _selected = _visibleTrends.filter(t => t.selected)
        if (_selected.length === _visibleTrends.length) return true
        return false
    }, [_visibleTrends])

    if (brandProfiles?.length === 0 && !brandProfilesIsLoading) {
        return <NoBrandProfiles />
    }

    if (!currentAccount?.accountName || !brandProfileId) {
        return null
    }

    return (
        <div
            style={{
                position: 'relative',
                justifyContent: 'center',
                display: 'flex'
            }}
        >
            <BlockPlacementsModal
                open={showBlockModal}
                setOpen={setShowBlockModal}
                trends={fullTrends ? fullTrends.filter(t => t.selected) : []}
                accountName={currentAccount?.accountName}
                handleBlock={handleBlock}
                brandProfileId={brandProfileId}
            />

            <ShareModal
                isTrends={true}
                isOpen={showSendImp}
                setIsOpen={setShowSendImp}
                selectedTrends={fullTrends ? getSelectedTrends(fullTrends) : []}
                brandProfileId={brandProfileId}
            />

            {fullTrends && (
                <TrendModal
                    onClose={() => handleModalClose()}
                    show={showModal}
                    trend={fullTrends.filter((trend: Trend) => trend.trendId === selectedTrend?.trendId)[0]}
                    trendVideos={videos}
                    trendArticles={articles}
                    trendScenarios={trendScenarios}
                    trendKeywords={keywords}
                    setTrendScenarios={setTrendScenarios}
                    trendScenariosLoading={trendScenariosLoading}
                    trendArticlesLoading={trendArticlesLoading}
                    trendVideosLoading={trendVideosLoading}
                    trendKeywordsLoading={trendKeywordsLoading}
                    brandProfileId={brandProfileId}
                    postTrendRecActionFeedback={postTrendRecActionFeedback}
                    postTrendQuestionFeedback={postTrendQuestionFeedback}
                    postTrendVideoFeedback={postTrendVideoFeedback}
                    postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                    QUERY_KEY={QUERY_KEY}
                />
            )}

            <div style={{ width: 1016 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        style={{
                            fontSize: 24,
                            fontWeight: 600,
                            lineHeight: '32px',
                            marginBottom: 30,
                            color: '#333D47',
                            marginTop: 42
                        }}
                    >
                        Anticipation Boards
                    </div>
                </div>

                <div style={{ marginBottom: 41, display: 'flex' }}>
                    <SightlySelect
                        loading={brandProfilesIsLoading}
                        showLabelStar={true}
                        label="Select Brand Profile"
                        id="brandProfileIdDropdown"
                        labelKey="brandName"
                        valueKey="brandProfileId"
                        placeholder="Select Brand Profile"
                        width={240}
                        options={brandProfiles}
                        onChange={handleBrandProfileChange}
                        value={brandProfileId}
                    />

                    <div style={{ width: 12 }} />
                    {date && (
                        <SightlySelect
                            loading={brandProfilesIsLoading}
                            showLabelStar={true}
                            label="Select Date"
                            id="dateDropdown"
                            width={132}
                            placeholder="Select Date"
                            options={dates}
                            labelKey="date"
                            valueKey="date"
                            onChange={handleDateChange}
                            value={date}
                        />
                    )}

                    <div style={{ width: 12 }} />
                    {hour && (
                        <SightlySelect
                            loading={brandProfilesIsLoading}
                            showLabelStar={true}
                            label="Select Time"
                            id="timeDropdown"
                            width={96}
                            placeholder="Hour"
                            options={hours}
                            labelKey="text"
                            valueKey="hour"
                            onChange={handleHourChange}
                            value={hour}
                        />
                    )}
                </div>

                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '60%',
                            justifyContent: 'left',
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                height: 40,
                                paddingTop: 10,
                                paddingLeft: 16,
                                paddingRight: 16,
                                backgroundColor: 'white',
                                borderRadius: 3,
                                fontSize: 12,
                                lineHeight: '20px',
                                fontWeight: 500,
                                position: 'relative'
                            }}
                        >
                            <SightlyCheckbox
                                id="selectAllBox"
                                label={selectAllLabel}
                                checked={isSelectAll}
                                handleChange={(val: boolean) => selectAllClick(val)}
                                disabled={_visibleTrends.length === 0}
                            />
                        </div>

                        <div>
                            <SightlySelect
                                loading={brandProfilesIsLoading}
                                id="trendSort"
                                labelKey="label"
                                valueKey="id"
                                placeholder="Sort By"
                                width={162}
                                options={sortOptions}
                                onChange={(val: string) => setSortBy(val)}
                                value={sortBy}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: '40%',
                            justifyContent: 'right',
                            display: 'flex'
                        }}
                    >
                        <FilterButton
                            trends={fullTrends}
                            handleVerticalChange={(text: string) => setVertical(text)}
                            handleActionChange={(val: string) => setActions(val)}
                            getFilteredTrends={getFilteredTrends}
                            disabled={trendsIsLoading}
                        />
                        {(userCan(perms.TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ) ||
                            userCan(perms.TRENDS_DOWNLOAD)) && (
                            <div
                                style={{
                                    width: 87.94,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                {downloadingTrends ? (
                                    <RsuiteLoader style={{ alignSelf: 'center' }} />
                                ) : (
                                    <MoreButton
                                        handleBlockClick={handleBlockClick}
                                        handleImplementClick={handleImplementClick}
                                        handleDownloadClick={handleDownloadTrends}
                                        disabled={!fullTrends || getSelectedTrends(fullTrends).length < 1}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div
                    style={{
                        marginTop: 32,
                        width: '100%',
                        height: 1,
                        backgroundColor: '#D4D9D9',
                        opacity: 0.5
                    }}
                />

                {showHasNonBlocked && (
                    <SightlyMessage
                        closable
                        handleClose={() => setShowHasNonBlocked(false)}
                        backgroundColor="#E18026E5"
                        text="Warning: Can’t block a Monitor or Target action please change to Block"
                    />
                )}

                {showBlockSuccess && (
                    <SightlyMessage
                        closable
                        loading={false}
                        handleClose={() => setShowBlockSuccess(false)}
                        backgroundColor={brandSuccessColor}
                        text="Blocking Placements complete"
                    />
                )}

                {blocking && (
                    <SightlyMessage
                        loading={true}
                        closable
                        handleClose={() => setBlocking(false)}
                        backgroundColor={'#F57B7B'}
                        text="Blocking Placements in progress"
                    />
                )}

                {showBlockError && (
                    <SightlyMessage
                        closable
                        handleClose={() => setShowBlockError(null)}
                        backgroundColor={'#E18026E5'}
                        text={showBlockError}
                    />
                )}

                {showMonitorImp && (
                    <SightlyMessage
                        closable
                        handleClose={() => setShowMonitorImp(false)}
                        backgroundColor="#E18026"
                        text="Warning: Please change action to Target or Block to share implementation"
                    />
                )}

                <div
                    style={{
                        position: 'relative',
                        marginTop: 24,
                        width: 1016
                    }}
                >
                    {_visibleTrends.length < 1 && !trendsIsLoading && trendsIsFetched && (
                        <div
                            style={{
                                width: '100%',
                                paddingLeft: 300,
                                paddingRight: 300,
                                marginTop: 100,
                                textAlign: 'center',
                                fontSize: 22
                            }}
                        >
                            We currently do not have any Moments in our system for your selected filters.
                        </div>
                    )}

                    {_visibleTrends && _visibleTrends.length > 0 && (
                        <div
                            style={{
                                fontWeight: 500,
                                fontSize: 12,
                                lineHeight: '22px',
                                color: '#000000'
                            }}
                        >{`Showing ${_visibleTrends.length} Moment(s)`}</div>
                    )}

                    {trendsIsLoading ? (
                        <Skeleton height={80} count={3} />
                    ) : (
                        <div>
                            {_visibleTrends.map((trend: Trend) => {
                                return (
                                    <TrendCard
                                        key={trend.trendId}
                                        brandProfileId={brandProfileId}
                                        trend={trend}
                                        gAdsBlocked={trend.gAdsBlocked}
                                        trendId={trend.trendId}
                                        trendIsInDemo={trend.demo}
                                        actionFilter={actions}
                                        text={trend.trendName}
                                        keywordCount={trend.keywordCount}
                                        articleCount={trend.articleCount}
                                        videoCount={trend.videoCount}
                                        action={trend.action}
                                        editedBy={trend.editedBy}
                                        editedDateTime={trend.editedDateTime}
                                        editedFromAction={trend.editedFromAction}
                                        checked={trend.selected}
                                        handleSelectTrend={handleSelectTrend}
                                        handleTrendNameClick={handleTrendNameClick}
                                        handleSaveActionEdit={handleSaveActionEdit}
                                    />
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Trends)
