import React from 'react'
import useUser from './services/useUser'

function jiraHelpdesk(callback) {
    var jhdScript = document.createElement('script')
    jhdScript.type = 'text/javascript'
    jhdScript.setAttribute('data-jsd-embedded', null)
    jhdScript.setAttribute('data-key', '7a227ce3-6b6d-4b3b-99de-68f098552b4a')
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com')
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
    if (jhdScript.readyState) {
        // old IE support
        jhdScript.onreadystatechange = function() {
            if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
                jhdScript.onreadystatechange = null
                callback()
            }
        }
    } else {
        //modern browsers
        jhdScript.onload = function() {
            callback()
        }
    }
    document.getElementsByTagName('head')[0].appendChild(jhdScript)
}

const initializePendo = (user, account) => {
    window.pendo.initialize({
        visitor: {
            id: user.userId,
            roleId: user.roleId,
            roleName: user.roleName,
            email: user.email,
            company: user.company,
            createdDate: user.createdDate,
            firstName: user.firstName,
            lastName: user.lastName,
            userName: user.userName
        },
        account: {
            id: account?.accountId,
            accountLevelId: account?.accountLevelId,
            accountLevelName: account?.accountLevelName,
            accountMargin: account?.accountMargin,
            accountName: account?.accountName,
            accountTypeId: account?.accountTypeId,
            accountTypeName: account?.accountTypeName,
            parentAccountId: account?.parentAccountId,
            parentAccountName: account?.parentAccountName
        }
    })
}

const useThirdPartyInitializer = () => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const [initializedPendoAccounts, setInitializedPendoAccounts] = React.useState([])
    React.useEffect(() => {
        if (!user || initializedPendoAccounts.includes(currentAccount?.accountId) || !currentAccount?.accountId) {
            return
        }
        const userId = localStorage.getItem('userId')
        console.log(
            `initializing Pendo with userId: ${userId}, roleId: ${user.roleId}, accountId: ${currentAccount.accountId}`
        )

        initializePendo(user, currentAccount)
        setInitializedPendoAccounts(prev => {
            prev.push(currentAccount?.accountId)
            return prev
        })
    }, [user, currentAccount?.accountId])

    const [initiatedJira, setInitiatedJira] = React.useState(false)

    React.useEffect(() => {
        if (!user || user?.userType !== 'Internal' || initiatedJira) return
        console.log('initializing Jira Helpdesk widget')

        jiraHelpdesk(function() {
            var DOMContentLoaded_event = document.createEvent('Event')
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true)
            window.document.dispatchEvent(DOMContentLoaded_event)
        })
        setInitiatedJira(true)
    }, [user])
}

export default useThirdPartyInitializer
