//@ts-nocheck
import React from 'react'
import './App.css'
import { Provider } from 'react-redux'
import configureStore from './redux/store/index.js'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'rsuite/dist/styles/rsuite-default.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './components/Sightly/sightlyTagInput.css'
import 'react-loading-skeleton/dist/skeleton.css'
import RouteContainer from './RouteContainer'

const queryClient = new QueryClient()
const store = configureStore()

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Toaster position={'bottom-left'} toastOptions={{ duration: 3000 }} />
                <RouteContainer />
            </Provider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
    )
}

export default App
