import React from 'react'
import { ReactComponent as WarningIcon } from '../../../assets/img/warning-large.svg'

const MaxBoardsWarning = () => {
	return (
		<div
			style={{
				height: 420,
				justifyContent: 'center',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: 24,
				textAlign: 'center',
				alignSelf: 'center'
			}}
		>
			<WarningIcon />
			<div
				style={{
					fontWeight: 600,
					marginTop: 52,
					fontSize: 24,
					lineHeight: '32px',
					color: '#333D47',
					marginBottom: 16
				}}
			>
				Board Limit Reached
			</div>

			<div
				style={{
					fontWeight: 400,
					fontSize: 20,
					lineHeight: '30px',
					color: '#7a7a7a',
					textAlign: 'center',
					width: 407
				}}
			>
				You’ve reached your 10 anticipation board limit. Please delete a board
				to create a new one.
			</div>
		</div>
	)
}

export default MaxBoardsWarning
