import toast from 'react-hot-toast'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { userAccountAxios } from '../../axiosInstances'
import { brandProfileCompletionStatusObjValidation } from '../../schemas/brandProfiles'
import { rqKeys } from '../../ReactQueryKeyFactory'
import { api } from '../../api/api'

export enum sectionsEnum {
    'competitors' = 'competitors',
    'influencers' = 'influencers',
    'events' = 'events',
    'philanthropic' = 'philanthropic',
    'socialAndEnvironmental' = 'socialAndEnvironmental'
}

export type Question = {
    brandProfileId?: number
    keywords: string[]
    question?: string
    questionId: number
    responseText: string
}

const getBrandProfileStatus = async (brandProfileId: number) => {
    const url = `/brand-profile/${brandProfileId}/status`

    const result = await userAccountAxios.get(url)

    if (result.status === 200) {
        brandProfileCompletionStatusObjValidation.validate(result.data).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching brand profile status, see console log for more details'
            )
        })
    }
    return result.data
}

const useBrandProfile = (brandProfileId: number) => {
    const queryClient = useQueryClient()

    const { data: status, isFetched: statusIsFetched, isLoading: statusIsLoading } = useQuery(
        rqKeys.brandProfileStatusKey(brandProfileId),
        () => getBrandProfileStatus(brandProfileId),
        {
            enabled: brandProfileId !== null && brandProfileId > 0
        }
    )

    const { data: questions } = useQuery(
        rqKeys.brandProfileQuestions(brandProfileId),
        () => api.brandProfile.questions.get(brandProfileId),
        {
            enabled: brandProfileId !== null && brandProfileId > 0
        }
    )

    type args_ = {
        keywords: string[]
        questionId: number
        responseText: string
    }

    const mutateQuestions = useMutation(
        (args: args_) => {
            let url = `/brand-profile/${brandProfileId}/questions`

            const q = [
                {
                    keywords: args.keywords,
                    questionId: args.questionId,
                    responseText: args.responseText
                }
            ]

            return userAccountAxios.patch(url, q)
        },
        {
            onMutate: async (args: args_) => {
                queryClient.setQueryData(rqKeys.brandProfileQuestions(brandProfileId), (old: any) => {
                    if (!old) return []
                    return old.map((quest: Question) => {
                        if (quest.questionId === args.questionId) {
                            quest.keywords = args.keywords
                        }
                        return quest
                    })
                })
            },

            onSettled: () => {
                queryClient.invalidateQueries(rqKeys.brandProfileQuestions(brandProfileId))
            },
            onSuccess: () => {
                toast.success('Changes saved')
            }
        }
    )

    return {
        questions,
        handleQuestionKeywordsChange: mutateQuestions.mutate,
        status,
        statusIsFetched,
        statusIsLoading
    }
}

export default useBrandProfile
