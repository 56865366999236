import config from '../config.js'
import axios from 'axios'
import { Channel } from '../classes/channel'
import {
    channelsSchema,
    videosSchema,
} from '../schemas/Engage/Lists/schemas.js'
import { Video } from '../classes/video.js'
import { listBuilderAxios } from '../axiosInstances.js'

type Payload = {
    smartListId: number
    blockedKeywords: Array<string>
}

type ReturnType = {
    status: number
}

type Request = {
    cancel: Function
} | null

export const patchListKeywordsService = async (
    payload: Payload
): Promise<ReturnType> => {
    let url = `/smart-list/${payload.smartListId}`

    return await listBuilderAxios.patch(url, {
        blockedKeywords: payload.blockedKeywords,
    })
}

type ChannelsPayload = {
    sort: {
        sortColumn: string
        sortType: string
    }
    pageNumber: number
    versionId: number | string
    searchTerm: string
}

let fetchChannelsRequest: any = null
export const fetchChannelsService = async ({
    sort,
    pageNumber,
    versionId,
    searchTerm,
}: ChannelsPayload): Promise<Channel[]> => {
    if (isNaN(pageNumber)) {
        console.error('versionId and pageNumber must be numbers')
    }
    if (searchTerm !== '' && searchTerm.length < 1) {
        console.error('fetch channels service requires searchTerm')
    }
    if (!sort || !sort.sortColumn || !sort.sortType) {
        console.error('sort must contain sortColumn and sortType')
    }

    const url = `/smart-list/channel`

    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: {
            size: config.listbuilderIncrement,
            sort,
            page: pageNumber,
            versionId,
            searchTerm,
        },
    })

    if (result.status === 200) {
        channelsSchema.validate(result.data).catch((err: object) => {
            console.log(err)
            console.error(
                'we received different data from the api than expected from fetchChannels, see console log for more details'
            )
        })
    }
    return result.data
}

type VideosPayload = {
    sort: {
        sortColumn: string
        sortType: string
    }
    pageNumber: number
    versionId: number | string
    searchTerm: string
}

let fetchVideosRequest: any = null
export const fetchVideosService = async ({
    versionId,
    sort,
    pageNumber,
    searchTerm,
}: VideosPayload): Promise<Video[]> => {
    if (fetchVideosRequest) {
        fetchVideosRequest.cancel()
    }

    const url = `/smart-list/video`

    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: {
            size: config.listbuilderIncrement,
            sort,
            page: pageNumber,
            versionId,
            searchTerm,
        },
    })

    if (result && result.status === 200) {
        videosSchema.validate(result.data.slice(0, 3)).catch((err: object) => {
            console.log(err)
            console.error(
                'we received different data from the api than expected from fetchVideos, see console log for more details'
            )
        })
    }

    return result.data
}
