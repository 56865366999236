import React from 'react'
import { neutralColor } from '../../../../../assets/jss/colorContants'
import { withFormik, Form, useFormikContext } from 'formik'
import FormikInput from '../../../../../components/CustomInput/FormikInput'
import debounce from 'just-debounce-it'
import { useMatch } from '@tanstack/react-location'
import useUser from '../../../../../services/useUser'

const AutoSave = ({ debounceMs }) => {
    const formik = useFormikContext()
    const debouncedSubmit = React.useCallback(
        debounce(() => formik.submitForm(), debounceMs),
        [debounceMs, formik.submitForm]
    )

    React.useEffect(() => {
        if (formik.values.questionResponse != formik.initialValues.questionResponse) {
            debouncedSubmit()
        }
    }, [debouncedSubmit, formik.values])

    return null
}

function Question(props) {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    return (
        <div id={props.question.questionId}>
            <div
                style={{
                    fontSize: '16px',
                    marginTop: '11px'
                }}
            >
                {props.question.question}
            </div>

            <Form>
                <div>
                    <FormikInput
                        name="questionResponse"
                        formikValue={props.values.questionResponse}
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                    />
                </div>
                <AutoSave debounceMs={700} />
            </Form>

            <div style={{ color: neutralColor, height: 15 }} />
        </div>
    )
}

const FormikForm = withFormik({
    mapPropsToValues: props => {
        return {
            questionId: props.question.questionId,
            questionResponse: props.question.responseText ? props.question.responseText : ''
        }
    },
    enableReinitialize: true,
    validateOnMount: false,

    handleSubmit: (values, { props }) => {
        props.handleQuestionResponse({
            questionId: values.questionId,
            response: values.questionResponse,
            keywords: props.question.keywords
        })
    }
})(Question)

export default FormikForm
