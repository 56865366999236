import React from 'react'
import Button from 'rsuite/lib/Button'
import Table from 'rsuite/lib/Table'
import Loader from 'rsuite/lib/Loader'
import useScenarioTypes from './useScenarioTypes'
import PortalModal from '../../../components/PortalModal/PortalModal'
import Input from 'rsuite/lib/Input'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import { userAccountAxios } from '../../../axiosInstances.js'
import { rqKeys } from '../../../ReactQueryKeyFactory'

function Types(props) {
    const scenarioTypesObj = useScenarioTypes({ excludeArchived: false })

    const ActionCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Table.Cell {...props} style={{ padding: 1 }}>
                {!rowData.archived && (
                    <Button
                        appearance="link"
                        loading={props.typeArchiving === rowData.typeId}
                        onClick={() => {
                            handleArchiveTypeClick(rowData.typeId)
                        }}
                    >
                        Archive
                    </Button>
                )}
            </Table.Cell>
        )
    }

    const [adding, setAdding] = React.useState(false)
    const [newName, setNewName] = React.useState('')

    const queryClient = useQueryClient()

    const handleSubmit = async () => {
        if (newName.length < 1) {
            alert('Scenario Type Name is required')
            return
        }
        const url = '/scenarios/types'
        const res = await userAccountAxios.post(url, { typeName: newName })
        if (res.status === 200) {
            toast.success('Success')
            setAdding(false)
            setNewName('')
            queryClient.invalidateQueries(rqKeys.scenarioTypes())
        } else {
            toast.error('There was an error, please try again')
        }
    }

    const handleArchiveTypeClick = typeId => {
        scenarioTypesObj.archiveScenarioType({ typeId })
        //	props.archiveScenarioType(typeId)
    }

    return (
        <div>
            {scenarioTypesObj.scenarioTypes && scenarioTypesObj.scenarioTypes.length > 0 ? (
                <div>
                    <Button appearance="primary" onClick={() => setAdding(true)}>
                        Create Scenario Type
                    </Button>

                    <PortalModal
                        handleClose={() => setAdding(false)}
                        open={adding}
                        footerButtons={[
                            <Button onClick={() => handleSubmit()} appearance="primary">
                                Save
                            </Button>,
                            <Button onClick={() => setAdding(false)} appearance="subtle">
                                Cancel
                            </Button>
                        ]}
                    >
                        <div>
                            <h4>Add Scenario Type</h4>
                        </div>
                        <div style={{ marginTop: 48 }} className="input-label">
                            Scenario Type Name
                        </div>
                        <Input style={{ width: 500 }} value={newName} onChange={e => setNewName(e)} />
                    </PortalModal>

                    <Table
                        virtualized
                        height={500}
                        rowHeight={50}
                        data={scenarioTypesObj.scenarioTypes}
                        shouldUpdateScroll={false}
                    >
                        <Table.Column verticalAlign={'middle'} width={500}>
                            <Table.HeaderCell>Scenario Type Name</Table.HeaderCell>
                            <Table.Cell dataKey="typeName" style={{ color: 'grey' }} />
                        </Table.Column>
                        <Table.Column verticalAlign={'middle'} width={100}>
                            <Table.HeaderCell>Archived</Table.HeaderCell>
                            <Table.Cell style={{ color: 'grey' }}>
                                {rowData => {
                                    return rowData.archived ? 'True' : 'False'
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column verticalAlign={'middle'} width={80}>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>
                </div>
            ) : props.typesIsLoading ? (
                <Loader center vertical />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 200px)',
                        color: 'white'
                    }}
                >
                    <Button appearance="primary" onClick={() => setAdding(true)}>
                        Create Scenario Type
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Types
