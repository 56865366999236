import { useQuery } from '@tanstack/react-query'
import { embedUrlsObj } from '../schemas/schemas.js'
import { userAccountAxios } from '../axiosInstances.js'
import { rqKeys } from '../ReactQueryKeyFactory'

export const fetchEmbedUrlsService = async (accountId: number | undefined) => {
    if (accountId === undefined) return
    let url = `/account/${accountId}/get-embed-urls`

    const result = await userAccountAxios.get(url)

    if (result.status === 200) {
        embedUrlsObj.validate(result.data).catch(function(err) {
            console.log(err.name, err.errors)
            alert('Could not validate embed urls data')
        })
    }
    return result.data
}

export default function useEmbedUrls(accountId: number | undefined) {
    return useQuery(rqKeys.embedUrlsKey(accountId), () => fetchEmbedUrlsService(accountId), {
        enabled: !!accountId,
        refetchOnWindowFocus: false
    })
}
