import React from 'react'
import SelectPicker from 'rsuite/lib/SelectPicker'
import ControlLabel from 'rsuite/lib/ControlLabel'

const formControl = {
	paddingBottom: '10px',
	margin: '27px 0 0 0',
	position: 'relative',
	verticalAlign: 'unset'
}

export default function SelectField(props) {
	function handleChange(value) {
		if (!value) value = []
		const { onChange, name } = props
		onChange(name, value)
	}

	const { id, label, placeholder, options, value, isDisabled, error } = props

	return (
		<div style={formControl}>
			{label && (
				<ControlLabel className={props.required ? 'isRequired' : ''}>
					{label}
				</ControlLabel>
			)}

			<SelectPicker
				id={id}
				labelKey={props.optionLabel}
				valueKey={props.optionValue}
				placeholder={placeholder}
				data={options}
				value={value}
				renderMenuItem={(label, item) => {
					return <div>{label}</div>
				}}
				onChange={handleChange}
				disabled={isDisabled}
				cleanable={false}
				style={{ width: '100%' }}
				preventOverflow={true}
				searchable={!props.hideSearch}
			/>
			<div>
				<div
					id='component-helper-text'
					style={{
						color: '#f44336',
						position: 'absolute',
						bottom: '-1'
					}}>
					{error}
				</div>
			</div>
		</div>
	)
}
