import { trendsObjValidation } from '../schemas/trends'
import { listBuilderAxios } from '../axiosInstances.js'
var fileDownload = require('js-file-download')

export const downloadTrendsService = async args => {
    let { brandProfileId, trendIds } = args
    let url = `/brand-profile/${brandProfileId}/trends/download`
    let info = { trendIds }
    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        responseType: 'blob',
        data: info
    })
    fileDownload(result.data, `currentMoments.xlsx`)
}

export const downloadMomentsService = async args => {
    let {
        brandProfileId,
        trendIds,
        isSelectAll,
        boardIds,
        timeRange,
        actions,
        sortBy,
        searchTerm,
        aylienNews,
        aylienIndustries
    } = args
    const suffix = isSelectAll ? 'download-by-filter' : 'download'
    let url = `/brand-profile/${brandProfileId}/moments/${suffix}`
    let info = isSelectAll
        ? {
              boardIds,
              timeRange,
              actions,
              sortBy,
              searchTerm,
              aylienNews,
              aylienIndustries
          }
        : { momentIds: trendIds }

    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        responseType: 'blob',
        data: info
    })
    if (!result) {
        return Promise.reject('Unknown download error')
    }
    fileDownload(result.data, `currentMoments.xlsx`)
}

export const fetchDemoTrendsService = async () => {
    let url = `/trends/demo`

    try {
        const result = await listBuilderAxios.get(url)

        if (result.status === 200) {
            trendsObjValidation.validate(result.data.slice(0, 3)).catch(function(err) {
                console.log(err.name, err.errors)
                console.error(
                    'we received different data from the api than expected while fetching trends, see console log for more details'
                )
            })

            return result.data
        }
    } catch (error) {
        console.error(error)
    }
}

export const removeTrendFromDemoService = async trendId => {
    let url = `/trends/${trendId}/remove-demo`

    const response = await listBuilderAxios({
        method: 'DELETE',
        url: url
    })

    return response
}

export const addTrendToDemoService = async ({ trendId, brandProfileId }) => {
    let url = `/trends/${trendId}/save-demo`

    const response = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: {
            brandProfileId
        }
    })

    return response
}

export const implementTrends = async ({ brandProfileId, trendIds, emails, keywords, placements, message }) => {
    let url = `/brand-profile/${brandProfileId}/trends/implement`

    const response = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: {
            trendIds,
            emails,
            keywords,
            placements,
            message
        }
    })

    return response
}

export const implementMoments = async ({ brandProfileId, momentIds, emails, keywords, placements, message }) => {
    let url = `/brand-profile/${brandProfileId}/moments/implement`

    const response = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: {
            momentIds,
            emails,
            keywords,
            placements,
            message
        }
    })

    return response
}

export const postTrendRecActionFeedbackService = args => {
    const proc = async () => {
        let url = `/trends/${args.trendId}/feedback-recommended-action`
        return await listBuilderAxios.post(url, {
            feedback: args.feedback,
            brandProfileId: args.brandProfileId
        })
    }

    proc()
}

export const postTrendKeywordsFeedbackService = async args => {
    const proc = async () => {
        let url = `/trends/${args.trendId}/feedback-keywords`
        return await listBuilderAxios.post(url, {
            feedback: args.feedback,
            brandProfileId: args.brandProfileId
        })
    }

    proc()
}

export const postTrendQuestionFeedbackService = async args => {
    const proc = async () => {
        let url = `/trends/${args.trendId}/feedback-question`
        return await listBuilderAxios.post(url, {
            feedback: args.feedback,
            brandProfileId: args.brandProfileId,
            actionJustificationQuestion: args.question
        })
    }

    proc()
}

export const postTrendVideoFeedbackService = async args => {
    const proc = async () => {
        let url = `/trends/${args.trendId}/feedback-video`
        const _args = (({ trendId, ...o }) => o)(args) //one liner to remove trendId from args
        return await listBuilderAxios.post(url, _args)
    }

    proc()
}
