import { useQuery } from '@tanstack/react-query'
import { userAccountAxios } from '../axiosInstances.js'
import { rqKeys } from '../ReactQueryKeyFactory'

const getUnseenNotificationCount = async () => {
    const url = `/notifications/fetch-unseen-count`
    const { data } = await userAccountAxios.get<{ count: number }>(url)

    if (!data.count) {
        console.error('error fetching fetch-unseen-count')
    }

    return Number(data.count)
}

export default function useUnseenNotificationCount() {
    return useQuery(rqKeys.unseenNotificationCountKey(), getUnseenNotificationCount, {
        refetchInterval: 10000,
        refetchIntervalInBackground: false
    })
}
