import { Trend, TrendScenario } from '../classes/trend.js'
import {
    trendsObjValidation,
    trendVideosObjValidation,
    trendArticlesObjValidation,
    flattenedScenariosValidation,
    trendScenarioObjValidation,
    trendKeywordsObjVal
} from '../schemas/trends.js'
import { BrandProfile, TargetviewIo } from '../classes/brandProfile.js'
import { listBuilderAxios, userAccountAxios } from '../axiosInstances.js'
import { AxiosError } from 'axios'

export const NoneApplyId = 10000000

type TrendsPayload = {
    brandProfileId: number
    args: {
        size: number
        page: number
        sortBy: string
        includeStats: boolean
        date: string
        hour: number
    }
}

export const fetchTrendsService = async ({ args, brandProfileId }: TrendsPayload): Promise<Trend[]> => {
    let url = `/brand-profile/${brandProfileId}/trends`

    let result

    try {
        result = await listBuilderAxios({
            method: 'POST',
            url: url,
            data: args
        })
    } catch (error) {
        return Promise.reject(error)
    }

    if (result.status === 200) {
        trendsObjValidation.validate(result.data.trends.slice(0, 2)).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching trends, see console log for more details'
            )
        })
    }

    return result.data.trends
}

export const fetchTrendArticlesService = async ({
    trendId,
    brandProfileId,
    isMoments
}: {
    trendId: Trend['trendId']
    brandProfileId: BrandProfile['brandProfileId']
    isMoments?: boolean
}): Promise<Trend['articles']> => {
    let url = `/brand-profile/${brandProfileId}/${isMoments ? 'moments' : 'trends'}/${trendId}/articles`

    const result = await listBuilderAxios({
        method: 'GET',
        url: url
    })

    if (result.status === 200) {
        trendArticlesObjValidation.validate(result.data).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching articles, see console log for more details'
            )
        })
    }

    return result.data
}

export const fetchTrendVideosService = async ({
    trendId,
    brandProfileId,
    isMoments
}: {
    trendId: Trend['trendId']
    brandProfileId: BrandProfile['brandProfileId']
    isMoments?: boolean
}): Promise<Trend['videos']> => {
    let url = `/brand-profile/${brandProfileId}/${isMoments ? 'moments' : 'trends'}	/${trendId}/videos`

    const result = await listBuilderAxios({
        method: 'GET',
        url: url
    })

    if (result.status === 200) {
        trendVideosObjValidation.validate(result.data).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching moment videos, see console log for more details'
            )
        })
    }

    return result.data
}

export const fetchTrendKeywordsService = async ({
    trendId,
    brandProfileId,
    isMoments
}: {
    trendId: Trend['trendId']
    brandProfileId: BrandProfile['brandProfileId']
    isMoments?: boolean
}): Promise<Trend['keywords']> => {
    let url = `/brand-profile/${brandProfileId}/${isMoments ? 'moments' : 'trends'}/${trendId}/keywords`

    const result = await listBuilderAxios({
        method: 'GET',
        url: url
    })

    //TODO: delete this once api returns it
    const transformedResult = result.data.keywords.map((i: any, index: number) => {
        i.rank = index + 1
        return i
    })

    if (result.status === 200) {
        trendKeywordsObjVal.validate(transformedResult).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching moment keywords, see console log for more details'
            )
        })
    }

    //	return result.data.keywords
    return transformedResult
}

type GetTrendScenarioOptionsType = {
    brandProfileId: number
    trendId: string
}
export const getTrendScenarioOptions = async ({
    brandProfileId,
    trendId
}: GetTrendScenarioOptionsType): Promise<TrendScenario[]> => {
    const url = `/brand-profile/${brandProfileId}/answered-scenarios/${trendId}?legacy=true`
    const result = await userAccountAxios.get<TrendScenario[]>(url)
    if (result.status === 200) {
        flattenedScenariosValidation.validate(result.data).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching flattened scenarios, see console log for more details'
            )
        })
    }
    return result.data.length === 0
        ? result.data
        : result.data.concat({
              scenarioId: NoneApplyId,
              scenarioName: 'None Apply',
              scenarioResponseId: 4,
              feedback: null
          })
}

export const getMomentScenarioOptions = async ({
    brandProfileId,
    trendId
}: GetTrendScenarioOptionsType): Promise<TrendScenario[]> => {
    const url = `/brand-profile/${brandProfileId}/answered-scenarios/${trendId}?legacy=false`
    const result = await userAccountAxios.get<TrendScenario[]>(url)
    if (result.status === 200) {
        flattenedScenariosValidation.validate(result.data).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching flattened scenarios, see console log for more details'
            )
        })
    }
    return result.data.length === 0
        ? result.data
        : result.data.concat({
              scenarioId: NoneApplyId,
              scenarioName: 'None Apply',
              scenarioResponseId: 4,
              feedback: null
          })
}

// export const fetchFlattenedScenarios = async ({
//     brandProfileId
// }: {
//     brandProfileId: BrandProfile['brandProfileId']
// }): Promise<TrendScenario[]> => {
//     let url = `/brand-profile/${brandProfileId}/answered-scenarios`

//     const result = await userAccountAxios.get<TrendScenario[]>(url)

//     if (result.status === 200) {
//         flattenedScenariosValidation.validate(result.data).catch(function(err: object) {
//             console.log(err)
//             console.error(
//                 ' we received different data from the api than expected while fetching flattened scenarios, see console log for more details'
//             )
//         })
//     }

//     return result.data.length === 0
//         ? result.data
//         : result.data.concat({
//               scenarioId: NoneApplyId,
//               scenarioName: 'None Apply',
//               scenarioResponseId: 4,
//               feedback: null
//           })
// }

interface Iprops {
    brandProfileId: BrandProfile['brandProfileId']
    trendIds: Trend['trendId'][]
    ioIds: TargetviewIo['ioId'][]
}

export const trendsBlockList = async ({ brandProfileId, trendIds, ioIds }: Iprops) => {
    let url = `/brand-profile/${brandProfileId}/block-list`

    const response = await listBuilderAxios
        .post(url, {
            trendIds,
            ioIds
        })
        .catch((error: any) => {
            return error
        })

    return response
}

export const momentsBlockList = async ({ brandProfileId, trendIds, ioIds }: Iprops) => {
    let url = `/brand-profile/${brandProfileId}/moments/block-list`

    const response = await listBuilderAxios
        .post(url, {
            momentIds: trendIds,
            ioIds
        })
        .catch((error: any) => {
            return error
        })

    return response
}

interface IReturn {
    recommendedAction: string
    scenarios: TrendScenario[]
}

export const fetchTrendScenarios = async ({
    trendId,
    brandProfileId,
    isMoments
}: {
    trendId: Trend['trendId']
    brandProfileId: BrandProfile['brandProfileId']
    isMoments?: boolean
}) => {
    let url = `/brand-profile/${brandProfileId}/${isMoments ? 'moments' : 'trends'}/${trendId}/action-justification`

    const result = await listBuilderAxios.get<IReturn>(url)

    if (result.status === 200) {
        trendScenarioObjValidation.validate(result.data).catch(function(err: object) {
            console.log(err)
            console.error(
                ' we received different data from the api than expected while fetching moment scenarios, see console log for more details'
            )
        })
    }

    return result.data
}

interface ScenProps {
    brandProfileId: number
    trendId: Trend['trendId']
    scenarios: TrendScenario[]
    isMoments?: boolean
}

export const patchTrendScenarios = async ({ brandProfileId, trendId, scenarios, isMoments }: ScenProps) => {
    let url = `/brand-profile/${brandProfileId}/${isMoments ? 'moments' : 'trends'}/${trendId}/update-scenarios`

    const response = await listBuilderAxios
        .post<{ status: number }>(url, {
            scenarios: scenarios.map(s => {
                return {
                    scenarioId: s.scenarioId,
                    scenarioName: s.scenarioName,
                    scenarioResponseId: s.scenarioResponseId
                }
            })
        })
        .catch(err => {
            return { status: 500 }
        })

    return response.status
}
