import React from 'react'
import {
    patchBrandProfileOutcomes,
    fetchBrandProfileOutcomes,
    deleteBrandProfileOutcome,
    addOutcome,
    fetchOutcomeMetricOptions
} from '../../../../../redux/actions/brandProfiles'
import { connect } from 'react-redux'
import Panel from 'rsuite/lib/Panel'
import Outcome from './Outcome'
import Divider from 'rsuite/lib/Divider'
import Button from 'rsuite/lib/Button'
import { useDispatch } from 'react-redux'
import { useMatch, useSearch } from '@tanstack/react-location'
import useUser from '../../../../../services/useUser'

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit,
        outcomeMetricOptions: state.outcomeMetricOptions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOutcomeMetricOptions: () => dispatch(fetchOutcomeMetricOptions()),
        patchBrandProfileOutcomes: (outcomes, brandProfileId) =>
            dispatch(patchBrandProfileOutcomes(outcomes, brandProfileId)),
        fetchBrandProfileOutcomes: brandProfileId => dispatch(fetchBrandProfileOutcomes(brandProfileId)),
        deleteBrandProfileOutcome: args => dispatch(deleteBrandProfileOutcome(args))
    }
}

function Outcomes(props) {
    const {
        params: { brandProfileId }
    } = useMatch()
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    const dispatch = useDispatch()
    const [fetched, setFetched] = React.useState(false)
    React.useEffect(() => {
        if (!fetched) {
            props.fetchBrandProfileOutcomes(brandProfileId)
            setFetched(true)
        }
        props.fetchOutcomeMetricOptions()
    }, [])

    React.useEffect(() => {
        return () => {
            setFetched(false)
        }
    }, [])

    const handlePatch = outcome => {
        props.patchBrandProfileOutcomes(outcome, brandProfileId)
    }

    const handleAdd = () => {
        dispatch(addOutcome())
    }

    const handleDelete = outcomeId => {
        const args = {
            outcomeId,
            brandProfileId: brandProfileId
        }
        props.deleteBrandProfileOutcome(args)
    }

    return (
        <Panel
            header={<div className="sightlyPanelHeader">Outcomes</div>}
            style={{
                position: 'relative'
            }}
        >
            {props?.brandProfile?.outcomes?.map((outcome, index) => {
                return (
                    <div key={index}>
                        <Panel
                            header={
                                <div>
                                    <p style={{ width: 200 }}>{outcome.outcomeName}</p>
                                    {outcome.budgetPercentage}%
                                </div>
                            }
                            collapsible
                            defaultExpanded={index === 0}
                        >
                            <Outcome
                                viewOnly={viewOnly}
                                index={index}
                                brandProfile={props.brandProfile}
                                outcome={outcome}
                                handlePatch={handlePatch}
                                outcomeMetricOptions={props.outcomeMetricOptions}
                            />
                            {props?.brandProfile?.outcomes?.length > 1 && index > 0 && (
                                <Button
                                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                                    style={{ marginTop: 20 }}
                                    size="sm"
                                    color="red"
                                    appearance="ghost"
                                    onClick={() => handleDelete(outcome.outcomeId)}
                                >
                                    Delete this outcome
                                </Button>
                            )}
                        </Panel>
                        <Divider />
                    </div>
                )
            })}

            <Button
                size="sm"
                color="green"
                appearance="ghost"
                onClick={handleAdd}
                disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
            >
                Add another Outcome
            </Button>
        </Panel>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Outcomes)
