import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { listsObjValidation } from '../schemas/Engage/Lists/schemas.js'
import { listBuilderAxios } from '../axiosInstances.js'
import { BrandProfile } from '../classes/brandProfile.js'
import toast from 'react-hot-toast'
import { rqKeys } from '../ReactQueryKeyFactory'

interface ArchiveProps {
    smartListId: number
    archive: boolean
}

async function archiveList(payload: ArchiveProps) {
    let url = `/smart-list/${payload.smartListId}`

    const result = await listBuilderAxios.patch(url, {
        archive: payload.archive
    })
    if (result.status === 200) {
    }
}

async function getLists(accountId: number) {
    const url = `/account/${accountId}/smart-list`

    const { data } = await listBuilderAxios.get(url)

    listsObjValidation.validate(data.slice(0, 3)).catch(function(err: object) {
        console.log(err)
        console.error(
            'we received different data from the api than expected while fetching lists fetchLists(), see console log for more details'
        )
    })

    let versions = []
    for (const version of data) {
        if (version.active) {
            version.activeText = 'Active'
        } else {
            version.activeText = 'Not Active'
        }

        if (version.archived) {
            version.archivedText = 'True'
        } else {
            version.archivedText = 'False'
        }

        if (!version.uploaded) {
            version.enrichmentStatus = '-'
        } else {
            if (version.hasEnrichedData) {
                version.enrichmentStatus = 'Complete'
            } else {
                version.enrichmentStatus = 'In process'
            }
        }

        if (version.uploaded) {
            version.content = '-'
        } else {
            if (version.isVideo) {
                version.content = 'Videos'
            } else {
                version.content = 'Channels'
            }
        }

        versions.push(version)
    }

    return versions
}

export default function useLists(accountId: number, brandProfiles: BrandProfile[]) {
    const QUERY_KEY = rqKeys.listsKey(accountId)
    const queryClient = useQueryClient()

    const archiveListMutation = useMutation(archiveList, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        },
        onSuccess: () => {
            toast.success('Changes saved')
        }
    })

    const queryObj = useQuery(QUERY_KEY, () => getLists(accountId), {
        enabled: !!accountId && !!brandProfiles && brandProfiles.length > 0,
        refetchOnWindowFocus: false
    })

    return {
        ...queryObj,
        archiveList: archiveListMutation.mutate
    }
}
