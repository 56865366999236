import React from 'react'
import useWindowDimensions from '../../useWindowDimensions'
import Tabs from '../../components/Tabs/Tabs'
import useEmbedUrls from '../../services/useEmbedUrls'
import useBrandProfiles from '../../services/brandProfile/useBrandProfiles'
import NoBrandProfiles from '../../components/NoBrandProfiles'
import { useMatch } from '@tanstack/react-location'
import useUser from '../../services/useUser'

const defaultViews = ['Agency View', 'Overview']

const addIframeListener = iframeId => {
    // Update the document title using the browser API
    try {
        window.addEventListener('message', function(event) {
            if (
                document.getElementById(iframeId) &&
                event.source === document.getElementById(iframeId).contentWindow &&
                event.origin === 'https://app.sigmacomputing.com'
            ) {
                if (event.data.type === 'workbook:loaded') {
                    const sigma_iframe = document.getElementById(iframeId)
                    sigma_iframe.contentWindow.postMessage(
                        {
                            type: 'workbook:variables:update',
                            variables: { 'Profile-Name': '', 'INSERTION-ORDER-NAME': '' }
                        },
                        'https://app.sigmacomputing.com'
                    )
                }
            }
        })
    } catch (err) {}
}

const Reporting = () => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const { data: brandProfiles, isLoading, isFetched } = useBrandProfiles({ submittedOnly: true })
    const [activeTab, setActiveTab] = React.useState('')
    const [views, setViews] = React.useState(defaultViews)

    React.useEffect(() => {
        let _views = []
        if (userCan(perms.REPORTING_AGENCYVIEW_READ)) {
            _views.push('Agency View')
        }
        if (userCan(perms.REPORTING_OVERVIEW_READ)) {
            _views.push('Overview')
        }
        setViews(_views)
        setActiveTab(_views[0])
    }, [])

    React.useEffect(() => {
        if (document.getElementById('overviewIframe')) {
            addIframeListener('overviewIframe')
        }
    }, [])

    React.useEffect(() => {
        let _views = []
        if (userCan(perms.REPORTING_AGENCYVIEW_READ)) {
            _views.push('Agency View')
        }
        if (userCan(perms.REPORTING_OVERVIEW_READ)) {
            _views.push('Overview')
        }
        setViews(_views)
        setActiveTab(_views[0])
    }, [])

    const { data: embedUrls } = useEmbedUrls(currentAccount?.accountId)

    const dims = useWindowDimensions()

    if (brandProfiles && brandProfiles.length === 0 && !isLoading && isFetched) {
        return <NoBrandProfiles />
    }

    return (
        <div style={{ padding: 24 }}>
            <Tabs
                width={'100%'}
                marginRight={40}
                active={activeTab}
                onChange={active => {
                    setActiveTab(active)
                }}
            >
                {views.map(view => {
                    return <div key={view}>{view}</div>
                })}
            </Tabs>

            {activeTab === 'Agency View' && (
                <div style={{ paddingTop: 12 }}>
                    {userCan(perms.REPORTING_AGENCYVIEW_READ) && (
                        <iframe
                            id="sigma-iframe"
                            style={{
                                border: 0,
                                width: '100%',
                                height: dims.height - 56 - 48 - 24 - 24 - 24 - 12
                            }}
                            src={embedUrls?.performanceDashboardEmbedUrl}
                            title={'Agency View'}
                        >
                            <p>Your browser does not support iframes.</p>
                        </iframe>
                    )}
                </div>
            )}

            {activeTab === 'Overview' && (
                <div style={{ paddingTop: 12 }}>
                    {userCan(perms.REPORTING_OVERVIEW_READ) && (
                        <iframe
                            id="overviewIframe"
                            style={{
                                border: 0,
                                width: '100%',
                                height: dims.height - 56 - 48 - 24 - 24 - 24 - 12
                            }}
                            src={embedUrls?.reportingDashboardEmbedUrl}
                            title={'Overview'}
                        >
                            <p>Your browser does not support iframes.</p>
                        </iframe>
                    )}
                </div>
            )}
        </div>
    )
}

export default Reporting
