import React from 'react'

interface IProps {
    handleClick: Function
    tabImage: React.ReactNode
}

const Tab: Function = ({ tabImage, handleClick }: IProps) => {
    return (
        <div style={{ cursor: 'pointer', paddingLeft: 8, paddingRight: 8 }} onClick={() => handleClick()}>
            {tabImage}
        </div>
    )
}

export default Tab
