import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import React from 'react'
import { Table } from 'rsuite'

var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar)

export function logError(err, info) {
    Sentry.captureException(err, { extra: { info } })
}

export function findAccountNodeByAccountId(accountId, array) {
    for (const node of array) {
        if (node.accountId == accountId) return node
        if (node.children) {
            const child = findAccountNodeByAccountId(accountId, node.children)
            if (child) return child
        }
    }
}

export function joinClasses(...classes) {
    return classes.join(' ')
}

export function openInNewTab(url) {
    if (!url || url.length < 1) {
        console.error('url must be present')
        return
    }
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export function getCalendarDate(_date) {
    if (!_date) return ''
    return dayjs(_date).calendar()
}

export const permissionView = localStorage.getItem('permissionView') === '1'

export const removeAllExcept = (validKeys, o) => {
    return Object.keys(o).forEach(key => validKeys.includes(key) || delete o[key])
}

export const TableColumnJs = ({ children, flexGrow }) => {
    return <Table.Column flexGrow={flexGrow}>{children}</Table.Column>
}
