import { useMatch, useSearch } from '@tanstack/react-location'
import React from 'react'
import Panel from 'rsuite/lib/Panel'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useBrandProfile from '../../../../../services/brandProfile/useBrandProfile'
import QuestionComponent from './QuestionComponent'

const QuestionsSection = () => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()
    const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
    const viewOnly = viewOnlyParam || false
    const { handleQuestionKeywordsChange, questions } = useBrandProfile(Number(brandProfileId))

    return (
        <Panel header={<div className="sightlyPanelHeader">Query Questions</div>}>
            {questions &&
                questions.map((question: any) => {
                    return (
                        <QuestionComponent
                            key={question.questionId}
                            viewOnly={viewOnly}
                            question={question}
                            handleKeywordsChange={keywords => {
                                handleQuestionKeywordsChange({
                                    keywords,
                                    questionId: question.questionId,
                                    responseText: question.responseText
                                })
                            }}
                        />
                    )
                })}
        </Panel>
    )
}

export default QuestionsSection
