import React, { useState } from 'react'
import Button from 'rsuite/lib/Button'
import Form from 'rsuite/lib/Form'
import FormGroup from 'rsuite/lib/FormGroup'
import FormControl from 'rsuite/lib/FormControl'
import ControlLabel from 'rsuite/lib/ControlLabel'
import HelpBlock from 'rsuite/lib/HelpBlock'
import Schema from 'rsuite/lib/Schema'
import Table from 'rsuite/lib/Table'
import { setInitOpinionTypeAdd } from '../../../redux/actions/admin/opinions'
import { connect } from 'react-redux'
import Loader from 'rsuite/lib/Loader'
import useBrandProfileOpinionTypes from '../../../services/brandProfile/useBrandProfileOpinionTypes'
import toast from 'react-hot-toast'
import PortalModal from '../../../components/PortalModal/PortalModal'

const mapStateToProps = (state) => {
	return {
		initTypeAdd: state.admin.initOpinionTypeAdd
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setInitOpinionTypeAdd: (bool) => dispatch(setInitOpinionTypeAdd(bool))
	}
}

function Types(props) {
	let form

	const [formValues, setFormValues] = useState()

	const typesObj = useBrandProfileOpinionTypes({ excludeArchived: false })

	const { StringType } = Schema.Types
	const model = Schema.Model({
		typeName: StringType().isRequired('Type Name is required.')
	})

	const ActionCell = ({ rowData, dataKey, ...props }) => {
		return (
			<Table.Cell {...props} style={{ padding: 1 }}>
				{!rowData.archived && (
					<Button
						appearance='link'
						onClick={() => {
							handleArchiveTypeClick(rowData.typeId)
						}}>
						Archive
					</Button>
				)}
			</Table.Cell>
		)
	}

	const handleSubmit = async () => {
		if (!form.check()) {
			console.error('Form Error')
			return
		} else {
			await typesObj.createOpinionType(formValues)
			toast.success('Created!')
			props.setInitOpinionTypeAdd(false)
		}
	}

	const handleArchiveTypeClick = (typeId) => {
		typesObj.archiveOpinionType({ typeId })
	}

	return (
		<div>
			{typesObj.opinionTypes && typesObj.opinionTypes.length > 0 ? (
				<div>
					<Button
						appearance='primary'
						onClick={() => props.setInitOpinionTypeAdd(true)}>
						Create Opinion Type
					</Button>

					<PortalModal
						open={props.initTypeAdd}
						handleClose={() => props.setInitOpinionTypeAdd(false)}
						footerButtons={[
							<Button onClick={() => handleSubmit()} appearance='primary'>
								Save
							</Button>,
							<Button
								onClick={() => props.setInitOpinionTypeAdd(false)}
								appearance='subtle'>
								Cancel
							</Button>
						]}>
						<div style={{ width: 500 }}>
							<Form
								fluid
								ref={(ref) => (form = ref)}
								model={model}
								onChange={(formValue) => {
									setFormValues(formValue)
								}}>
								<h4 style={{ marginBottom: 24 }}>Add Opinion Type</h4>
								<div>
									<FormGroup>
										<ControlLabel>Type Name</ControlLabel>
										<FormControl name='typeName' />
										<HelpBlock>Required</HelpBlock>
									</FormGroup>
								</div>
							</Form>
						</div>
					</PortalModal>

					<Table
						virtualized
						height={500}
						rowHeight={50}
						data={typesObj.opinionTypes}
						shouldUpdateScroll={false}>
						<Table.Column verticalAlign={'middle'} width={500}>
							<Table.HeaderCell>Type Name</Table.HeaderCell>
							<Table.Cell dataKey='typeName' style={{ color: 'grey' }} />
						</Table.Column>
						<Table.Column verticalAlign={'middle'} width={100}>
							<Table.HeaderCell>Archived</Table.HeaderCell>
							<Table.Cell style={{ color: 'grey' }}>
								{(rowData) => {
									return rowData.archived ? 'True' : 'False'
								}}
							</Table.Cell>
						</Table.Column>
						<Table.Column verticalAlign={'middle'} width={80}>
							<Table.HeaderCell>Actions</Table.HeaderCell>
							<ActionCell />
						</Table.Column>
					</Table>
				</div>
			) : typesObj.opinionTypesIsLoading ? (
				<Loader center vertical />
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'calc(100vh - 200px)',
						color: 'white'
					}}>
					<Button
						appearance='primary'
						onClick={() => props.setInitOpinionTypeAdd(true)}>
						Create Opinion Type
					</Button>
				</div>
			)}
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Types)
