import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { userAccountAxios } from '../../../axiosInstances.js'
import toast from 'react-hot-toast'
import { accentColor, brandPinkColor } from '../../../assets/jss/colorContants.js'
import { rqKeys } from '../../../ReactQueryKeyFactory'

const getRp = async () => {
    const url = '/permission/roles'
    const res = await userAccountAxios.get(url)
    return res.data
}

function Permissions() {
    const QUERY_KEY = rqKeys.adminPermissionRoles()
    const queryClient = useQueryClient()
    const permObj = useQuery(QUERY_KEY, getRp)

    const handlePermissionChange = async ({ permissionId, roleId, isSelected }) => {
        if (!isSelected) {
            const url = `/role/${roleId}/permission/${permissionId}`
            const res = await userAccountAxios.delete(url)
            return res
        } else {
            let url = `/role/${roleId}/permission`
            return await userAccountAxios.post(url, { permissionId: permissionId })
        }
    }

    const handleChange = useMutation(handlePermissionChange, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        },
        onSuccess: () => {
            toast.success('Changes saved')
        },
        onError: () => {
            toast.error('There was an error')
        }
    })

    return (
        <div className="view">
            <h3 style={{ marginBottom: 24 }}>Permissions</h3>
            <div className="permWrapper">
                <table className="permTable">
                    <thead>
                        <tr className="permRow header-row">
                            <th className="permHeader sticky-col first-col-header">Permission Name</th>
                            <th className="permHeader sticky-col second-col">Permission Description</th>
                            {permObj &&
                                permObj.data &&
                                permObj.data[0].roles?.map(role => {
                                    return (
                                        <th className="permHeader" key={role.roleId}>
                                            {role.roleName}{' '}
                                            <div
                                                style={{
                                                    color: role.userType === 'Internal' ? accentColor : brandPinkColor
                                                }}
                                            >
                                                {role.userType}
                                            </div>
                                        </th>
                                    )
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {permObj?.data?.map(perm => {
                            return (
                                <tr className="permRow" key={perm.permissionId}>
                                    <td className="permCell sticky-col first-col">{perm.permissionName}</td>
                                    <td className="permCell sticky-col second-col">{perm.permissionDescription}</td>
                                    {perm.roles.map(role => {
                                        return (
                                            <td className="permCell" key={role.roleId}>
                                                <input
                                                    onChange={e => {
                                                        handleChange.mutate({
                                                            roleId: role.roleId,
                                                            permissionId: perm.permissionId,
                                                            isSelected: e.target.checked
                                                        })
                                                    }}
                                                    disabled={role.roleName === 'Super Admin'}
                                                    type="checkbox"
                                                    defaultChecked={role.hasPermission}
                                                />
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <style>
                {`
				td {
					text-align: center !important;
				}
				.header-row {
					position: sticky;
					top: 0;
					background-color: white;
					z-index: 9999;
				}
				permTable {
					border-collapse: collapse;
					width: 100%;
				}
				
				.permCell, .permHeader {
					border: 1px solid #dddddd;
					text-align: left;
					padding: 8px;
					word-wrap: break-word;
				}
				
				.permRow:nth-child(even) {
					background-color: #dddddd;
				}
				.view {
					margin: auto;
					width: 90vw;
					padding: 24px;
				}
				.permWrapper {
					overflow: scroll;
					white-space: nowrap;
					height: 80vh;
				}	
				thead th {
					position: -webkit-sticky; /* for Safari */
					position: sticky;
					top: 0;
				}
				tbody th {
					position: -webkit-sticky; /* for Safari */
					position: sticky;
					left: 0;
				}
				thead th:first-child {
					left: 0;
					z-index: 1;
				}
				.sticky-col {
					position: -webkit-sticky;
					position: sticky;
					background-color: white;
				}
				.first-col {	
					left: 0px;
					z-index: 9998; 
				}
				.first-col-header {
					left: 0px;
					z-index: 9999;
				}
				`}
            </style>
        </div>
    )
}

export default Permissions
