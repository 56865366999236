import { combineReducers } from 'redux'
import { adminIabCategories, adminIabCategoriesLoading } from './iabCategories'
import {
	evergreenChannels,
	evergreenChannelsLoading,
	evergreenChannelCount,
	currentEvergreenChannelsPage,
	evergreenChannelIabCategories,
	evergreenChannelsHasNextPage,
	evergreenChannelArchiving,
	evergreenChannelSaving
} from './evergreenChannels'
import {
	scenarios,
	scenariosIsLoading,
	scenarioLabels,
	scenariosLabelsIsLoading,
	scenarioSaving,
	labels,
	labelArchiving,
	labelsIsLoading,
	labelSaving,
	initLabelAdd
} from './scenarios'

import {
	opinions,
	opinionsIsLoading,
	opinionSaving,
	opinionArchiving,
	initOpinionTypeAdd,
	topics
} from './opinions'

import {
	questions,
	questionArchiving,
	questionsIsLoading,
	questionSaving,
	initQuestionAdd
} from './questions'

export default combineReducers({
	adminIabCategories,
	adminIabCategoriesLoading,
	evergreenChannels,
	evergreenChannelsLoading,
	evergreenChannelCount,
	currentEvergreenChannelsPage,
	evergreenChannelIabCategories,
	evergreenChannelsHasNextPage,
	evergreenChannelArchiving,
	evergreenChannelSaving,
	scenarios,
	scenariosIsLoading,
	scenarioLabels,
	scenariosLabelsIsLoading,
	scenarioSaving,
	labels,
	labelArchiving,
	labelsIsLoading,
	labelSaving,
	initLabelAdd,
	opinions,
	opinionsIsLoading,
	opinionSaving,
	opinionArchiving,
	initOpinionTypeAdd,
	topics,
	questions,
	questionArchiving,
	questionsIsLoading,
	questionSaving,
	initQuestionAdd
})
