import axios from 'axios'
import configureStore from './redux/store/index'
import { setAuthToken } from './redux/actions/auth'
import toast from 'react-hot-toast'
import config from './config'
import { logError } from './utils'

const userAccountAxios = axios.create({
    baseURL: config.api.userAccountUrl
})

const listBuilderAxios = axios.create({
    baseURL: config.api.listBuilderUrl
})

const interceptorRequestConfig = config => {
    const token = localStorage.getItem('token')
    if (token && !config.url.includes('authenticate')) {
        config.headers.common = { Authorization: `${token}` }
    }

    return config
}
const interceptorRequestError = error => {
    Promise.reject(error)
}

const interceptorResponse = response => {
    return response
}
const interceptorResponseError = error => {
    if (axios.isCancel(error)) {
    } else if (!error.response) {
        toast.error('Unable to connect to our API. Please try again later.')
    } else if (error.response.status === 400) {
        // these are expected errors that don't need attention from the dev team
        //(eg. trying to make block-list on a deactived google-ads accounts)
        const errorMessage =
            error?.response?.data?.Error ||
            error?.response?.data?.message ||
            error?.response?.data ||
            'An unknown error ocurred.'
        toast.error(errorMessage)
    } else if (error.response.status === 401) {
        const store = configureStore()
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        store.dispatch(setAuthToken(null))
        window.location.href = '/login'
    } else {
        logError(error, error?.response)
    }
    return Promise.reject(error)
}

userAccountAxios.interceptors.request.use(interceptorRequestConfig, interceptorRequestError)
userAccountAxios.interceptors.response.use(interceptorResponse, interceptorResponseError)

listBuilderAxios.interceptors.request.use(interceptorRequestConfig, interceptorRequestError)
listBuilderAxios.interceptors.response.use(interceptorResponse, interceptorResponseError)

export { userAccountAxios, listBuilderAxios }
