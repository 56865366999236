import React from 'react'
import Button from 'rsuite/lib/Button'
import Panel from 'rsuite/lib/Panel'
import Table from 'rsuite/lib/Table'
import PortalModal from '../../components/PortalModal/PortalModal'

const RolesInfoFullScreen = (props) => {
	let { show, handleDialog, data: rolesData, userType } = props

	return (
		<PortalModal
			open={show}
			handleClose={() => handleDialog(false)}
			footerButtons={[
				<Button
					onClick={() => handleDialog(false)}
					appearance='primary'
					style={{ marginTop: 15 }}>
					Done
				</Button>
			]}>
			<h3>Roles and Permissions</h3>
			<div style={{ width: '90vw', height: '70vh', overflow: 'auto' }}>
				{rolesData.map((role) => {
					return (
						<Panel
							header={
								<div style={{ display: 'inline-block' }}>
									<h4>{role.roleName}</h4>{' '}
									{userType === 'Internal' && <div>({role.userType})</div>}
								</div>
							}
							key={role.roleId}
							collapsible
							defaultExpanded>
							<Table
								autoHeight
								showHeader={false}
								rowHeight={45}
								data={role.permissions}>
								<Table.Column flexGrow={1}>
									<Table.HeaderCell>Permission Name</Table.HeaderCell>
									<Table.Cell dataKey='permissionName' />
								</Table.Column>
								<Table.Column flexGrow={2}>
									<Table.HeaderCell>Permission Description</Table.HeaderCell>
									<Table.Cell dataKey='permissionDescription' />
								</Table.Column>
							</Table>
						</Panel>
					)
				})}
			</div>
		</PortalModal>
	)
}

export default RolesInfoFullScreen
