import React from 'react'
import { routes } from '../routes'
import SightlyAvatar from './SightlyAvatar'
import { ReactComponent as NotificationBell } from '../assets/img/bell-regular.svg'
import NotificationsModal from './NotificationsModal'
import useUnseenNotificationCount from '../services/useUnseenNotificationCount'
import { useNavigate } from '@tanstack/react-location'
import { MyLocationGenerics } from '../classes/utils/utils'
import useUser from '../services/useUser'

export default function ProfileDropdown() {
    const unseenNotificationCount = useUnseenNotificationCount()
    const navigate = useNavigate()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const [open, setOpen] = React.useState(false)

    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            {open && (
                <NotificationsModal
                    unseenCount={unseenNotificationCount?.data ? unseenNotificationCount?.data : 0}
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}

            <div
                className="hoverActive"
                style={{
                    height: 56,
                    width: 56,
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                id="notificationBellButton"
                onClick={() => setOpen(prev => !prev)}
            >
                <div
                    className="app-icon"
                    style={{ alignSelf: 'center', margin: 4, marginTop: 12 }}
                    data-count={
                        unseenNotificationCount.data && unseenNotificationCount.data > 99
                            ? '99+'
                            : unseenNotificationCount.data
                    }
                >
                    <NotificationBell style={{ height: 24, width: 24 }} />
                </div>
            </div>

            <div
                className="hoverActive"
                style={{
                    height: 56,
                    width: 56,
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                onClick={() => navigate({ to: routes.app.settings.profile.path })}
                id="userIcon"
            >
                <div style={{ alignSelf: 'center' }}>
                    <SightlyAvatar text={user?.firstName + ' '} size="small" type={null} />
                </div>
            </div>
        </div>
    )
}
