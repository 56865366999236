import React from 'react'
import { connect } from 'react-redux'
import {
    setBrandProfileSubmitted,
    fetchBrandProfileBasic,
    submitBrandProfile,
    fetchBrandProfileCompletionStatus
} from '../../../redux/actions/brandProfiles'
import useWindowDimensions from '../../../useWindowDimensions'
import ButtonToolbar from 'rsuite/lib/ButtonToolbar'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import SightlyButton from '../../../components/SightlyButton'
import BrandProfileSteps from './BrandProfileSteps'
import BrandMentalityImage from '../../../assets/img/brand_mentality.png'

import { Outlet, useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'
import useUser from '../../../services/useUser'

const mapStateToProps = state => {
    return {
        brandProfileSubmitting: state.brandProfileSubmitting,
        brandProfileSubmitted: state.brandProfileSubmitted,
        brandProfileSaved: state.brandProfileSaved,
        brandProfileSaving: state.brandProfileSaving,
        brandProfile: state.brandProfileUnderEdit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBrandProfileSubmitted: bool => dispatch(setBrandProfileSubmitted(bool)),
        fetchBrandProfileCompletionStatus: brandProfileId =>
            dispatch(fetchBrandProfileCompletionStatus(brandProfileId)),
        fetchBrandProfileBasic: id => dispatch(fetchBrandProfileBasic(id)),
        submitBrandProfile: brandProfileId => dispatch(submitBrandProfile(brandProfileId))
    }
}

function BrandProfile(props) {
    const {
        params: { brandProfileId }
    } = useMatch()

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    const { data: brandProfiles } = useBrandProfiles({ submittedOnly: false })

    const navigate = useNavigate()

    const dims = useWindowDimensions()

    let brandProfileSubmitted = props.brandProfileSubmitted

    React.useEffect(() => {
        if (brandProfileSubmitted) {
            window.location.reload()
        }
    }, [brandProfileSubmitted])

    React.useEffect(() => {
        return () => {
            setBrandProfileSubmitted(false)
        }
    }, [])

    const [steps, setSteps] = React.useState([
        {
            name: 'brandInformation',
            humanName: 'Brand Information',
            userCanView: true,
            parentName: 'brandInformation'
        },
        {
            name: 'outcomes',
            humanName: 'Outcomes',
            userCanView: true,
            parentName: 'outcomes'
        },
        {
            name: 'queries',
            humanName: 'Queries',
            userCanView: true,
            parentName: 'queries',
            children: [
                {
                    name: 'brands',
                    humanName: 'Brands',
                    userCanView: true,
                    parentName: 'queries'
                },
                {
                    name: 'influencers',
                    humanName: 'Influencers',
                    userCanView: true,
                    parentName: 'queries'
                },
                {
                    name: 'events',
                    humanName: 'Events',
                    userCanView: true,
                    parentName: 'queries'
                },
                {
                    name: 'social',
                    humanName: 'Social Causes',
                    userCanView: true,
                    parentName: 'queries'
                },
                {
                    name: 'philanthropic',
                    humanName: 'Philanthropic Orgs',
                    userCanView: true,
                    parentName: 'queries'
                },
                {
                    name: 'questions',
                    humanName: 'Questions',
                    userCanView: true,
                    parentName: 'queries'
                }
            ]
        },
        {
            name: 'brandPositioning',
            humanName: 'Brand Positioning',
            userCanView: true,
            parentName: 'brandPositioning',
            children: [
                {
                    name: 'opinions',
                    humanName: 'Opinions',
                    userCanView: true,
                    parentName: 'brandPositioning'
                },
                {
                    name: 'scenarios',
                    humanName: 'Scenarios',
                    userCanView: true,
                    parentName: 'brandPositioning'
                }
            ]
        },
        {
            name: 'mediaResponsibility',
            parentName: 'mediaResponsibility',
            humanName: 'Media Responsibility',
            userCanView: true, //<-- this controls if user sees the STEP / circle  on the ui.
            showLock: !userCan(perms.BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY)
        },
        {
            name: 'keywordsGroup',
            parentName: 'keywordsGroups',
            humanName: 'Keywords',
            userCanView: true,

            children: [
                {
                    name: 'iabCategories',
                    humanName: 'Categories',
                    userCanView: userCan(perms.BRAND_PROFILE_CATEGORIES_READ),
                    permissionNeeded: perms.BRAND_PROFILE_CATEGORIES_READ,
                    parentName: 'keywordsGroup'
                },
                {
                    name: 'topics',
                    humanName: 'Topics',
                    userCanView: userCan(perms.BRAND_PROFILE_TOPICS_READ),
                    permissionNeeded: perms.BRAND_PROFILE_TOPICS_READ,
                    parentName: 'keywordsGroup'
                },
                {
                    name: 'news',
                    humanName: 'News',
                    userCanView: userCan(perms.BRAND_PROFILE_AYLIEN_NEWS_READ),
                    permissionNeeded: perms.BRAND_PROFILE_AYLIEN_NEWS_READ,
                    parentName: 'keywordsGroup'
                },
                {
                    name: 'industries',
                    humanName: 'Industries',
                    userCanView: userCan(perms.BRAND_PROFILE_AYLIEN_INDUSTRY_READ),
                    permissionNeeded: perms.BRAND_PROFILE_AYLIEN_INDUSTRY_READ,
                    parentName: 'keywordsGroup'
                },
                {
                    name: 'keywords',
                    humanName: 'Keywords',
                    userCanView: userCan(perms.BRAND_PROFILE_KEYWORDS_READ),
                    permissionNeeded: perms.BRAND_PROFILE_KEYWORDS_READ,
                    parentName: 'keywordsGroup'
                }
            ]
        },
        {
            name: 'targetview',
            parentName: 'targetview',
            humanName: 'TargetView IOs',
            permissionNeeded: perms.BRAND_PROFILE_IOS_READ,
            userCanView: userCan(perms.BRAND_PROFILE_IOS_READ)
        },
        {
            name: 'submit',
            parentName: 'submit',
            humanName: 'Review and Submit',
            userCanView: true
        }
    ])

    const _steps = React.useMemo(() => {
        const mainSteps = steps.filter(s => s.userCanView)
        for (const s of mainSteps) {
            if (s.children) {
                s.children = s.children.filter(c => c.userCanView)
            }
        }
        const final = mainSteps.filter(s => !s.children || s.children.length > 0)

        return final
    }, [steps])

    const activeStep = React.useMemo(() => {
        return window.location.pathname.split('/').pop()
    }, [location.current.pathname])

    const handleStepsClick = step => {
        let newSection
        _steps.forEach((element, index) => {
            if (element.name === step) {
                if (element.children) {
                    newSection = element.children[0].name
                }
            }
        })

        let newUrl =
            location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/')) +
            `/${newSection ? newSection : step}`

        navigate({
            to: newUrl,
            search: prev => {
                return {
                    ...prev
                }
            }
        })
    }

    const [basicFetched, setBasicFetched] = React.useState(false)

    React.useEffect(() => {
        if (brandProfiles && brandProfiles.length > 0 && !basicFetched) {
            props.fetchBrandProfileBasic(brandProfileId)
            setBasicFetched(true)
        }
    }, [brandProfiles, brandProfileId])

    const handleContinueClick = () => {
        const currentStep = window.location.pathname.split('/').pop()

        // find parentName
        let parentName
        for (const p of _steps) {
            if (p.name === currentStep) {
                parentName = p.parentName
            }
            if (p.children) {
                for (const child of p.children) {
                    if (child.name === currentStep) {
                        parentName = child.parentName
                    }
                }
            }
        }

        let newIndex = null
        let newSection
        _steps.forEach((element, index) => {
            if (element.name === parentName) {
                if (element.children) {
                    let indexOfCurrentChild
                    element.children.forEach((child, childIndex) => {
                        if (currentStep === child.name) {
                            indexOfCurrentChild = childIndex
                        }
                    })
                    if (indexOfCurrentChild < element.children.length - 1) {
                        newSection = element.children[indexOfCurrentChild + 1].name
                        return
                    }
                }
                newIndex = index + 1
            }
        })

        const nextStep = _steps[newIndex]?.name
        //find first child based off nextStep
        if (nextStep) {
            handleStepsClick(nextStep)
        } else {
            let newUrl =
                location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/')) +
                `/${newSection ? newSection : nextStep}`
            navigate({
                to: newUrl,
                search: prev => {
                    return {
                        ...prev
                    }
                }
            })
        }
    }

    const sectionHeight = dims.height - 200

    const handlePreviousClick = () => {
        const currentStep = window.location.pathname.split('/').pop()

        // find parentName
        let parentName
        for (const p of _steps) {
            if (p.name === currentStep) {
                parentName = p.parentName
            }
            if (p.children) {
                for (const child of p.children) {
                    if (child.name === currentStep) {
                        parentName = child.parentName
                    }
                }
            }
        }

        let newIndex = null
        let newSection
        _steps.forEach((element, index) => {
            if (element.name === parentName) {
                if (element.children) {
                    let indexOfCurrentChild
                    element.children.forEach((child, childIndex) => {
                        if (currentStep === child.name) {
                            indexOfCurrentChild = childIndex
                        }
                    })
                    if (indexOfCurrentChild > 0) {
                        newSection = element.children[indexOfCurrentChild - 1].name
                        return
                    }
                }
                newIndex = index - 1
            }
        })

        const nextStep = _steps[newIndex]?.name

        if (nextStep) {
            handleStepsClick(nextStep)
        } else {
            let newUrl =
                location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/')) +
                `/${newSection ? newSection : nextStep}`

            navigate({
                to: newUrl,
                search: prev => {
                    return {
                        ...prev
                    }
                }
            })
        }
    }

    return (
        <div className="gridContainer">
            <div className="sidebar">
                <div style={{ paddingBottom: 24 }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: props.brandProfile.submitted ? 'center' : 'space-between',
                            marginBottom: 16.71
                        }}
                    >
                        <img src={BrandMentalityImage} />

                        <div
                            style={{
                                fontWeight: 700,
                                fontSize: 14,
                                color: '#888888'
                            }}
                        >
                            {!props.brandProfile.submitted && 'Draft'}
                        </div>
                    </div>
                    <div
                        style={{
                            width: 201,
                            height: 31,
                            backgroundColor: '#333D47',
                            color: 'white',
                            fontWeight: 600,
                            fontSize: 12,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {props.brandProfile.submitted ? 'Submitted' : 'Saved'}
                    </div>
                </div>
                <BrandProfileSteps steps={_steps} activateStep={handleStepsClick} activeStep={activeStep} />

                {/* <Icon size="lg" icon="warning" style={{ color: 'orange' }} /> */}
            </div>

            <div className="main">
                <Outlet />
            </div>

            <div className="footer">
                <ButtonToolbar>
                    {activeStep !== 'brandInformation' && (
                        <SightlyButton
                            id="prevButton"
                            text="Previous"
                            handleClick={handlePreviousClick}
                            isRectangle={true}
                        />
                    )}

                    {activeStep !== 'submit' && (
                        <SightlyButton
                            id="nextButton"
                            text="Next"
                            handleClick={handleContinueClick}
                            isRectangle={true}
                        />
                    )}
                </ButtonToolbar>
            </div>

            <style>{`
  .gridContainer {
		display: grid;
   	height: 100%;	
    grid-template-columns: 265px 1fr;	
    grid-template-rows:  1fr 64px;
    grid-template-areas:
      "sidebar main"
      "sidebar footer"		
	}	
	.sidebar {
    grid-area: sidebar;
		border-right: 2px solid #f8f8f8;
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
	}	
	.footer {
    grid-area: footer;
    background-color: #FFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 72px;
		gap: 18px;
		z-index: 2;
  }	
  .main {
    grid-area: main;
    padding: 25px 38px;
    overflow-y: auto;
  }
`}</style>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfile)
