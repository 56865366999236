import {
	ADMIN_OPINIONS_IS_LOADING,
	SET_ADMIN_BRAND_OPINIONS,
	SET_ADMIN_BRAND_TOPICS,
	SET_ADMIN_BRAND_OPINION_TOPICS,
	SET_ADMIN_BRAND_OPINION_CATEGORIES,
	SET_ADMIN_BRAND_OPINION_KEYWORDS,
	OPINION_ARCHIVING,
	OPINION_TO_ARCHIVE,
	OPINION_SAVING,
	ADD_OPINION,
	SET_ADD_OPINION_TYPE,
	SET_OPINION_TYPE_TO_CREATE
} from '../../action-types/admin/opinions'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../../axiosInstances'

export function setAdminOpinionsIsLoading(bool) {
	return {
		type: ADMIN_OPINIONS_IS_LOADING,
		adminOpinionsIsLoading: bool
	}
}

export function setAdminBrandOpinions(opinions) {
	return {
		type: SET_ADMIN_BRAND_OPINIONS,
		opinions
	}
}

export function setAdminBrandTopics(topics) {
	return {
		type: SET_ADMIN_BRAND_TOPICS,
		topics
	}
}

export function setOpinionTopics(opinionId, topicIds) {
	return {
		type: SET_ADMIN_BRAND_OPINION_TOPICS,
		opinionId: opinionId,
		topicIds: topicIds
	}
}

export function setOpinionKeywords(opinionId, keywords) {
	return {
		type: SET_ADMIN_BRAND_OPINION_KEYWORDS,
		opinionId: opinionId,
		keywords: keywords
	}
}

export function setOpinionIabCategories(opinionId, iabCategoryIds) {
	return {
		type: SET_ADMIN_BRAND_OPINION_CATEGORIES,
		opinionId: opinionId,
		topicIds: iabCategoryIds
	}
}

export function setOpinionArchiving(opinionId) {
	return {
		type: OPINION_ARCHIVING,
		opinionArchiving: opinionId
	}
}

export function setOpinionToArchived(opinionId) {
	return {
		type: OPINION_TO_ARCHIVE,
		opinionId
	}
}

export function setOpinionSaving(bool) {
	return {
		type: OPINION_SAVING,
		opinionSaving: bool
	}
}

export function addOpinion(opinion) {
	return {
		type: ADD_OPINION,
		opinion
	}
}




export const createOpinion = (opinion) => {
	let url =  `/opinions`
	return (dispatch, getState) => {
		dispatch(setOpinionSaving(true))
		userAccountAxios
			.post(url, opinion)
			.then((response) => {
				dispatch(addOpinion(response.data[0]))
				dispatch(setOpinionSaving(false))
				toast.success('Opinion created!')
			})
			.catch((error) => {
				//error
			})
	}
}


export function setInitOpinionTypeAdd(bool) {
	return {
		type: SET_OPINION_TYPE_TO_CREATE,
		initTypeAdd: bool
	}
}

export function addOpinionType(opinionType) {
	return {
		type: SET_ADD_OPINION_TYPE,
		opinionType
	}
}



export const mapOpinionToKeywords = (opinionId, keywords) => {
	let url =  `/opinions/${opinionId}/keywords`
	return (dispatch, getState) => {
		userAccountAxios
			.patch(url, keywords)
			.then((response) => {
				toast.success('Keywords mapped to Opinion.')
			})
			.catch((error) => {
				console.log(error)
				toast.error(error.response.data.message)
			})
	}
}


