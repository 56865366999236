import React from 'react'
import SightlySelect from '../../../components/Sightly/SightlySelect'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import { Board } from './useBoards'
import List from 'rsuite/lib/List'
import TextTruncate from 'react-text-truncate'
import Checkbox from 'rsuite/lib/Checkbox'
import Icon from 'rsuite/lib/Icon'
import { routes } from '../../../routes'
import { ReactComponent as DropdownArrowRight } from '../../../assets/img/dropdownRight.svg'
import { Link, useNavigate } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import useUser from '../../../services/useUser'

type IProps = {
    handleBrandProfileChange: (brandProfileId: number) => void
    brandProfileId: number | null | undefined
    boards: Board[]
    handleSelectBoard: (id: number) => void
    showBackToDashboards?: boolean
    selectedBoards: number[]
    hideNewBoardButton?: boolean
}

const Sidebar = ({
    handleBrandProfileChange,
    brandProfileId,
    boards,
    handleSelectBoard,
    showBackToDashboards,
    selectedBoards,
    hideNewBoardButton
}: IProps) => {
    const navigate = useNavigate<MyLocationGenerics>()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const {
        data: brandProfiles,
        isLoading: brandProfilesIsLoading,
        isFetched: brandProfilesIsFetched
    } = useBrandProfiles({ submittedOnly: true })

    if (brandProfilesIsLoading || !brandProfilesIsFetched) return null
    return (
        <div className="sidebar">
            {showBackToDashboards && (
                <div
                    onClick={() =>
                        navigate({
                            to: routes.app.discover.boards.dashboard.path,
                            search: old => {
                                return {
                                    brandProfileId: old?.brandProfileId,
                                    accountId: old?.accountId
                                }
                            }
                        })
                    }
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 21,
                        justifyContent: 'left',
                        paddingLeft: 18,
                        width: '100%',
                        marginBottom: 24
                    }}
                >
                    <DropdownArrowRight style={{ transform: 'rotate(180deg)' }} />
                    <div style={{ fontWeight: 700, fontSize: 18, color: '#333D47' }}>Back to Dashboard</div>
                </div>
            )}
            <div className="bp">
                <SightlySelect
                    id="brandProfile"
                    onChange={(val: number) => handleBrandProfileChange(val)}
                    label="Select Brand Profile"
                    value={brandProfileId ? brandProfileId : null}
                    options={brandProfiles}
                    labelKey="brandName"
                    valueKey="brandProfileId"
                />
                {userCan(perms.BRAND_PROFILE_UPDATE) && (
                    <Link
                        to={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/brandInformation`}
                    >
                        Go to this brand profile
                    </Link>
                )}
            </div>

            {location.current.pathname !== routes.app.discover.boards.welcome.path && (
                <List hover>
                    {location.current.pathname !== routes.app.discover.boards.path &&
                        !location.current.pathname.includes('/create') &&
                        boards.map((board, index) => (
                            <List.Item
                                style={{
                                    backgroundColor: '#f8f8f8',
                                    color: '#333D47',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    width: 265 - 16,
                                    paddingLeft: 16
                                }}
                                key={index}
                                index={index}
                            >
                                <Checkbox
                                    onChange={() => handleSelectBoard(board.id)}
                                    checked={selectedBoards.includes(board.id)}
                                >
                                    <TextTruncate text={board.name} />
                                </Checkbox>
                            </List.Item>
                        ))}

                    {boards.length < 10 && !hideNewBoardButton && (
                        <List.Item
                            onClick={() =>
                                navigate({
                                    to: routes.app.discover.boards.welcome.path,
                                    search: old => {
                                        return { ...old }
                                    }
                                })
                            }
                            style={{
                                cursor: 'pointer',
                                backgroundColor: '#f8f8f8',
                                color: '#333D47',
                                fontWeight: 700,
                                fontSize: 16,
                                width: 265 - 16,
                                paddingLeft: 16
                            }}
                            key={'last'}
                            index={999999}
                        >
                            New Board
                            <Icon style={{ paddingLeft: 10 }} icon="plus-circle" />
                        </List.Item>
                    )}
                </List>
            )}

            <style>{`
  
  .sidebar {
    grid-area: sidebar;
    background-color: #F8F8F8;			
    padding-top: 22px;
    display: flex;
    flex-direction: column;
		align-items: center;
		
		
		
		
  }	
  .bp {
    padding-bottom: 32px;
    width: 233px;
  }
  
`}</style>
        </div>
    )
}

export default Sidebar
