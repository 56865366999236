import React from 'react'
import { useQuery } from '@tanstack/react-query'
import InputGroup from 'rsuite/lib/InputGroup'
import AutoComplete from 'rsuite/lib/AutoComplete'
import toast from 'react-hot-toast'
import { joinClasses } from '../../../../../utils'
import { userAccountAxios } from '../../../../../axiosInstances'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

interface IProps {
    disabled?: boolean
    handleSetWikiUrl: any
    handleSetName: any
    nameValue: string
    error?: string
}

const NameSearchField = ({ handleSetWikiUrl, handleSetName, nameValue, error }: IProps) => {
    const [wikiSearchTerm, setWikiSearchTerm] = React.useState('')

    type WikiResult = {
        name: string
        wikiUrl: string
    }

    const getWikiOptions = async (wikiSearchTerm: string): Promise<WikiResult[]> => {
        const url = `/wiki-search?searchTerm=${wikiSearchTerm}`
        const res = await userAccountAxios.get<WikiResult[]>(url)
        return res.data
    }

    const wikiObj = useQuery(rqKeys.wikiOptions(wikiSearchTerm), () => getWikiOptions(wikiSearchTerm), {
        enabled: wikiSearchTerm.length > 0
    })

    const handleNameSelect = (name: string) => {
        if (!wikiObj || !wikiObj.data) return
        try {
            const wikiUrl = wikiObj.data.filter(item => item.name === name)[0].wikiUrl
            const sanitizedWikiUrl = decodeURI(wikiUrl).replace('https://en.wikipedia.org/wiki/', '')
            handleSetWikiUrl(sanitizedWikiUrl)
        } catch (err) {
            console.error(err)
            toast.error('An unexpected error occurred. Please try again')
        }
    }

    const handleNameChange = (val: string) => {
        setWikiSearchTerm(val)
        handleSetName(val)
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className={joinClasses('input-label', 'required')}>Name</div>
            <InputGroup style={{ marginBottom: 8 }}>
                <AutoComplete
                    value={nameValue}
                    placeholder="Type Here"
                    onChange={val => handleNameChange(val)}
                    onSelect={(val: any) => handleNameSelect(val.value)}
                    data={wikiObj.data?.map(item => item.name)}
                />

                {/* <InputGroup.Button disabled style={{ backgroundColor: 'transparent' }}>
					<Icon style={{ color: accentColor }} icon='search' />
				</InputGroup.Button> */}
            </InputGroup>
            {error && (
                <div
                    style={{
                        position: 'absolute',

                        left: 0,
                        bottom: -10,
                        color: 'red',
                        fontSize: 12
                    }}
                >
                    {error}
                </div>
            )}
        </div>
    )
}

export default NameSearchField
