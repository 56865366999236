import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Account } from '../classes/account.js'
import toast from 'react-hot-toast'
import React from 'react'
import { findAccountNodeByAccountId, logError } from '../utils.js'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../classes/utils/utils.js'
import { rqKeys } from '../ReactQueryKeyFactory'
import { location } from '../RouteContainer'
import { api } from '../api/api'

const perms = {
    ASSIGNED_ACCOUNT_UPDATE: 'ASSIGNED_ACCOUNT_UPDATE',
    ACCOUNT_CREATE: 'ACCOUNT_CREATE',
    ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
    ACCOUNT_DELETE: 'ACCOUNT_DELETE',
    USER_CREATE: 'USER_CREATE',
    USER_UPDATE: 'USER_UPDATE',
    USER_DELETE: 'USER_DELETE',
    BRAND_PROFILE_CREATE: 'BRAND_PROFILE_CREATE',
    BRAND_PROFILE_UPDATE: 'BRAND_PROFILE_UPDATE',
    BRAND_PROFILE_DELETE: 'BRAND_PROFILE_DELETE',
    BRAND_PROFILE_DOWNLOAD: 'BRAND_PROFILE_DOWNLOAD',
    ACCOUNT_READ: 'ACCOUNT_READ',
    USER_READ: 'USER_READ',
    BRAND_PROFILE_READ: 'BRAND_PROFILE_READ',
    BRAND_PROFILES_PAGE_READ: 'BRAND_PROFILES_PAGE_READ',
    BRAND_PROFILE_TOPICS_READ: 'BRAND_PROFILE_TOPICS_READ',
    BRAND_PROFILE_CATEGORIES_READ: 'BRAND_PROFILE_CATEGORIES_READ',
    BRAND_PROFILE_KEYWORDS_READ: 'BRAND_PROFILE_KEYWORDS_READ',
    BRAND_PROFILE_IOS_READ: 'BRAND_PROFILE_IOS_READ',
    BRAND_PROFILE_IOS_UPDATE: 'BRAND_PROFILE_IOS_UPDATE',
    BRAND_PROFILE_AYLIEN_NEWS_READ: 'BRAND_PROFILE_AYLIEN_NEWS_READ',
    BRAND_PROFILE_AYLIEN_INDUSTRY_READ: 'BRAND_PROFILE_AYLIEN_INDUSTRY_READ',
    BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY: 'BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY',
    DISCOVER_READ: 'DISCOVER_READ',
    ENGAGE_READ: 'ENGAGE_READ',
    ADMIN_READ: 'ADMIN_READ',
    TRENDS_READ: 'TRENDS_READ', // the original trends board
    TRENDS_DOWNLOAD: 'TRENDS_DOWNLOAD',
    AYLIEN_TRENDS_READ: 'AYLIEN_TRENDS_READ', // the aylien boards page/s
    SMARTLIST_EDIT: 'SMARTLIST_EDIT',
    SMARTLIST_ARCHIVE: 'SMARTLIST_ARCHIVE',
    SMARTLIST_CREATE: 'SMARTLIST_CREATE',
    SMARTLIST_CREATE_UPLOAD: 'SMARTLIST_CREATE_UPLOAD',
    SMARTLIST_READ: 'SMARTLIST_READ',
    SMARTLIST_DOWNLOAD: 'SMARTLIST_DOWNLOAD',
    ACCOUNT_SETTINGS_READ: 'ACCOUNT_SETTINGS_READ',
    TREND_LABELS_POPUP_READ: 'TREND_LABELS_POPUP_READ',
    MEASURE_READ: 'MEASURE_READ',
    REPORTING_READ: 'REPORTING_READ',
    REPORTING_OVERVIEW_READ: 'REPORTING_OVERVIEW_READ',
    REPORTING_AGENCYVIEW_READ: 'REPORTING_AGENCYVIEW_READ',
    GARM_FEEDBACK_READ: 'GARM_FEEDBACK_READ',
    TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ: 'TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ',
    DEMO_TRENDS_EDIT: 'DEMO_TRENDS_EDIT',
    NOTIFICATIONS_READ: 'NOTIFICATIONS_READ',
    NOTIFICATION_TYPE_BP_QUESTIONS: 'NOTIFICATION_TYPE_BP_QUESTIONS',
    NOTIFICATION_TYPE_SMARTLIST_ENRICHMENT: 'NOTIFICATION_TYPE_SMARTLIST_ENRICHMENT',
    NOTIFICATION_TYPE_NEW_MOMENT_BATCHES: 'NOTIFICATION_TYPE_NEW_MOMENT_BATCHES',
    NOTIFICATION_TYPE_BP_CREATED: 'NOTIFICATION_TYPE_BP_CREATED',
    NOTIFICATION_TYPE_BP_SUBMITTED: 'NOTIFICATION_TYPE_BP_SUBMITTED',
    NOTIFICATION_TYPE_USER_CREATED: 'NOTIFICATION_TYPE_USER_CREATED',
    NOTIFICATION_TYPE_ACCOUNT_ADDED: 'NOTIFICATION_TYPE_ACCOUNT_ADDED',
    MOMENTS_INTELLIGENCE_READ: 'MOMENTS_INTELLIGENCE_READ' //The tab to the right of the reporting tab on outcomes horizontal navbar,
}

const getUser = async (userId: number) => {
    const data = await api.user.get(userId).catch(err => {
        return Promise.reject(err)
    })
    return data
}

const createAccount = async (account: Partial<Account>) => {
    api.account.create(account).then(response => {
        toast.success('Account created!')
    })
}

const deleteAccount = async (accountId: number | undefined) => {
    return api.account.delete(accountId).then(response => {
        if (response?.status !== 200) {
            toast.error(response?.data)
        } else {
            toast.success('Success')
        }
    })
}

const updateAccount = async (account: Account) => {
    try {
        await api.account.update(account)
    } catch (err) {
        logError(err)
        toast.error('Error occurred')
        return
    }
    toast.success('Changes saved')
}

export default function useUser() {
    const search = useSearch<MyLocationGenerics>()
    const queryClient = useQueryClient()
    const userId = localStorage.getItem('userId')
    const QUERY_KEY = rqKeys.userKey(Number(userId))
    const userObj = useQuery(QUERY_KEY, () => getUser(Number(userId)), {
        enabled: !!userId && userId.length > 0,
        retry: 1
    })
    const userPermissions = userObj.data?.permissions.map(p => p.permissionName)

    const createAccountMutation = useMutation(createAccount, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        }
    })

    const deleteAccountMutation = useMutation(deleteAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(QUERY_KEY).then(() => {
                window.location.reload()
            })
        }
    })

    const updateAccountMutation = useMutation(updateAccount, {
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        }
    })
    const accountIdFromParam = search.accountId

    const userAccounts = userObj?.data?.accounts

    const currentAccount = React.useMemo<Account | undefined>(() => {
        if (userObj.isLoading || userAccounts === undefined) {
            return undefined
        }
        let currentAccountId
        if (accountIdFromParam) {
            let userStillHasAccessToThisAccount = false
            let node = findAccountNodeByAccountId(accountIdFromParam, userAccounts)
            if (node) {
                userStillHasAccessToThisAccount = true
            }

            if (userStillHasAccessToThisAccount) {
                currentAccountId = accountIdFromParam
            } else {
                currentAccountId = userAccounts[0].accountId
            }
        } else {
            currentAccountId = userAccounts[0].accountId
        }
        return findAccountNodeByAccountId(currentAccountId, userAccounts)
    }, [accountIdFromParam, userObj])
    const navigate = useNavigate()

    React.useEffect(() => {
        if (currentAccount?.accountId) {
            navigate({
                hash: location.current.hash,
                search: prev => {
                    return {
                        ...prev,
                        accountId: currentAccount?.accountId
                    }
                }
            })
        }
    }, [currentAccount, location.current.hash])

    return {
        ...userObj,
        perms,
        userCan: (perm: string) => userPermissions?.includes(perm) || false,
        currentAccount,
        accounts: userObj.data?.accounts,
        user: userObj.data,
        createAccount: createAccountMutation.mutate,
        deleteAccount: deleteAccountMutation.mutate,
        updateAccount: updateAccountMutation.mutate
    }
}
