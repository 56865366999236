import React from 'react'
import { ReactComponent as KeywordsIcon } from '../../../assets/img/keywordsKey.svg'
import { ReactComponent as ArticlesLogo } from '../../../assets/img/article.svg'
import { ReactComponent as EditLogo } from '../../../assets/img/edit.svg'
import { ReactComponent as SaveLogo } from '../../../assets/img/save.svg'
import { ReactComponent as BlackYoutube } from '../../../assets/img/blackYoutube.svg'

import { accentColor, brandPinkColor, purpleSecondaryColor } from '../../../assets/jss/colorContants'
import TrendEditHistoryCard from '../components/TrendEditHistoryCard'
import SightlyAvatar from '../../../components/SightlyAvatar'
import { Trend } from '../../../classes/trend'
import TextTruncate from 'react-text-truncate'

interface ActionBoxProps {
    action: string
    selectedAction: string
    handleClick: Function
}

const ActionBox: Function = ({ action, selectedAction, handleClick }: ActionBoxProps) => {
    const _style = React.useMemo(() => {
        if (selectedAction != action) return { backgroundColor: 'white', color: 'black', cursor: 'pointer' }
        if (selectedAction === 'target')
            return {
                backgroundColor: accentColor,
                color: 'white',
                cursor: 'not-allowed'
            }
        if (selectedAction === 'monitor')
            return {
                backgroundColor: purpleSecondaryColor,
                color: 'white',
                cursor: 'not-allowed'
            }
        if (selectedAction === 'block')
            return {
                backgroundColor: brandPinkColor,
                color: 'white',
                cursor: 'not-allowed'
            }
        return { backgroundColor: 'yellow', color: 'white', cursor: 'not-allowed' }
    }, [selectedAction])

    return (
        <div
            className="unselectable"
            onClick={() => handleClick()}
            style={{
                cursor: _style.cursor,
                height: 31.6,
                paddingLeft: 24,
                paddingRight: 24,
                border: '1px solid #D4D9D9',
                backgroundColor: _style.backgroundColor,
                borderRadius: 31.6,
                textTransform: 'capitalize',
                fontSize: 12.18,
                lineHeight: '22.34px',
                fontWeight: 600,
                color: _style.color,

                marginRight: 8,
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div style={{ alignSelf: 'center' }}>{action}</div>
        </div>
    )
}

interface IProps {
    trendId: number
    brandProfileId: number
    text: string
    keywordCount: number
    articleCount: number
    videoCount: number
    handleTrendNameClick: Function
    handleActionEdit: Function
    handleSaveActionEdit: Function
    action: string
    editedBy: string
    editedDateTime: string
    editedFromAction: string
    trend: Trend
    gAdsBlocked: boolean
}

const TrendCard: Function = React.memo(
    ({
        trend,
        brandProfileId,
        trendId,
        text,
        keywordCount,
        articleCount,
        videoCount,
        action,
        editedBy,
        editedDateTime,
        editedFromAction,
        handleSaveActionEdit,
        handleTrendNameClick,
        gAdsBlocked
    }: IProps) => {
        const isEdited = React.useMemo(() => {
            return editedDateTime ? true : false
        }, [editedDateTime])

        const [isEditing, setIsEditing] = React.useState(false)
        const [showEditHistory, setShowEditHistory] = React.useState(false)
        const [componentAction, setComponentAction] = React.useState('')
        const [tempAction, setTempAction] = React.useState('')
        React.useEffect(() => {
            setComponentAction(action)
            setTempAction(action)
        }, [action])

        const handleActionClick = (_action: string) => {
            setTempAction(_action)
        }

        const handleSaveClick = (bool: boolean) => {
            setIsEditing(bool)
            if (componentAction === tempAction) return
            if (!bool) {
                handleSaveActionEdit(trend, tempAction)
                setComponentAction(tempAction)
            }
        }

        const [popupCoordinates, setPopupCoordinates] = React.useState({
            x: 0,
            y: 0
        })

        return (
            <div
                style={{
                    position: 'relative',
                    width: 1016 - 36,
                    //	height: 80,
                    padding: 16,

                    display: 'flex'
                }}
            >
                {showEditHistory && isEdited && (
                    <div
                        style={{
                            position: 'fixed',
                            zIndex: 9999999,
                            left: popupCoordinates.x - 210,
                            top: popupCoordinates.y - 200
                        }}
                    >
                        <TrendEditHistoryCard
                            editedDate={editedDateTime}
                            editorName={editedBy}
                            fromAction={editedFromAction}
                            toAction={componentAction}
                        />
                    </div>
                )}
                <div style={{ alignSelf: 'center' }}></div>
                <div id="bodyWrapper" style={{ paddingLeft: 24 }}>
                    <div
                        id="trendTitle"
                        onClick={() => handleTrendNameClick(trend)}
                        className={'sightlyLink'}
                        style={{
                            fontSize: 14,
                            lineHeight: '22px',
                            fontWeight: 700,
                            width: 600
                        }}
                    >
                        <TextTruncate text={text} line={2} />
                    </div>

                    <div className="statsContainer">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <KeywordsIcon />
                            <div
                                style={{
                                    paddingLeft: 4,
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    fontSize: '12px',
                                    color: '#696969'
                                }}
                            >
                                {keywordCount === 1 ? '1 keyword' : keywordCount + ' keywords'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ArticlesLogo />
                            <div
                                style={{
                                    paddingLeft: 4,
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    fontSize: '12px',
                                    color: '#696969'
                                }}
                            >
                                {articleCount === 1 ? '1 article' : articleCount + ' articles'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BlackYoutube style={{}} />
                            <div
                                style={{
                                    paddingLeft: 4,
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    fontSize: '12px',
                                    color: '#696969'
                                }}
                            >
                                {videoCount === 1 ? '1 video' : videoCount + ' videos'}
                            </div>
                        </div>

                        {gAdsBlocked && (
                            <div
                                style={{
                                    fontSize: '12px',
                                    color: 'white',
                                    backgroundColor: brandPinkColor,
                                    borderRadius: 3,

                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 138,
                                    justifyContent: 'center'
                                }}
                            >
                                Blocked in Google Ads
                            </div>
                        )}
                    </div>
                </div>

                <div className="actionSection">
                    {isEditing ? (
                        <div
                            style={{
                                display: 'flex',
                                marginLeft: 'auto'
                            }}
                        >
                            <ActionBox
                                action="target"
                                selectedAction={tempAction.length > 0 ? tempAction : componentAction}
                                handleClick={() => handleActionClick('target')}
                            />
                            <ActionBox
                                action="monitor"
                                selectedAction={tempAction.length > 0 ? tempAction : componentAction}
                                handleClick={() => handleActionClick('monitor')}
                            />
                            <ActionBox
                                action="block"
                                selectedAction={tempAction.length > 0 ? tempAction : componentAction}
                                handleClick={() => handleActionClick('block')}
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                cursor: 'default',
                                height: 33,
                                paddingLeft: 16,
                                paddingRight: 16,
                                backgroundColor:
                                    componentAction === 'target'
                                        ? accentColor
                                        : componentAction === 'block'
                                        ? brandPinkColor
                                        : componentAction === 'monitor'
                                        ? purpleSecondaryColor
                                        : componentAction === 'dont monitor'
                                        ? 'grey'
                                        : undefined,
                                borderRadius: 3.05,
                                textTransform: 'capitalize',
                                fontSize: 12,
                                fontWeight: 600,
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                marginLeft: 'auto'
                            }}
                        >
                            <div style={{ alignSelf: 'center' }}>{componentAction}</div>

                            {isEdited && (
                                <div
                                    onMouseOver={e => {
                                        setPopupCoordinates({ x: e.clientX, y: e.clientY })
                                        setShowEditHistory(true)
                                    }}
                                    onMouseOut={() => setShowEditHistory(false)}
                                    style={{
                                        cursor: 'default',
                                        position: 'absolute',
                                        top: -16,
                                        left: -16,
                                        zIndex: 99
                                    }}
                                >
                                    <SightlyAvatar type={null} size="small" text={editedBy} />
                                </div>
                            )}
                        </div>
                    )}
                    <div style={{ width: 20, marginLeft: 16 }}>
                        {!gAdsBlocked && (
                            <div style={{ height: 19 }}>
                                {isEditing ? (
                                    <SaveLogo
                                        id="saveTrendChangeButton"
                                        onClick={() => handleSaveClick(false)}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                ) : (
                                    <EditLogo
                                        id="initiateEditButton"
                                        onClick={() => handleSaveClick(true)}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <style>
                    {`
				.statsContainer {
					display: grid;
					grid-template-columns: 125px 125px 125px 75px 155px;
					grid-auto-flow: row;	
					padding-top: 4px;
				}			

				.actionSection {
					width: 100%;
					display: flex;
					align-items: center;
				}
				`}
                </style>
            </div>
        )
    }
)

export default TrendCard
