import React from 'react'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import LikeDislikeButton from '../../../../components/LikeDislikeButton'
import Tabs from '../../../../components/Tabs/Tabs'
import {
	purpleSecondaryColor,
	accentColor,
	brandPinkColor
} from '../../../../assets/jss/colorContants'
import { Trend } from '../../../../classes/trend'
import TextTruncate from 'react-text-truncate'

const ActionBox = ({ text }: { text: string }) => {
	const _bgColor = React.useMemo(() => {
		if (text === 'monitor') return purpleSecondaryColor
		if (text === 'target') return accentColor
		if (text === 'block') return brandPinkColor
		if (text === 'dont monitor') return 'grey'
		return 'white'
	}, [text])

	return (
		<div
			style={{
				width: 78.49,
				height: 33.15,
				borderRadius: 3.05,
				paddingTop: '5px',
				textAlign: 'center',
				backgroundColor: _bgColor,
				textTransform: 'capitalize',
				color: 'white',
				fontWeight: 600,
				fontSize: 12.18,
				lineHeight: '22.34px',
				marginTop: 8,
				marginBottom: 8
			}}
		>
			{text}
		</div>
	)
}

const _tabs = ['Brand Mentality', 'Articles', 'Videos', 'Keywords']

interface IProps {
	compTrend: Trend
	active: any
	setActive: any
	handleFeedbackRecommendedAction: any
}

const ModalHead = ({
	compTrend,
	active,
	setActive,
	handleFeedbackRecommendedAction
}: IProps) => {
	return (
		<div
			style={{
				//	backgroundColor: '#F0F3F5',
				marginBottom: 0,
				paddingLeft: 24,
				paddingTop: 24,
				paddingRight: 24,
				borderTopLeftRadius: 12,
				borderTopRightRadius: 12
			}}
		>
			<Whisper
				delayShow={400}
				delayHide={0}
				placement='bottom'
				trigger='hover'
				speaker={<Tooltip>{compTrend?.trendName}</Tooltip>}
			>
				<div
					className='maxTwoLines'
					style={{
						fontWeight: 600,
						fontSize: '24px',
						lineHeight: '32px',
						width: 890
					}}
				>
					<TextTruncate text={compTrend?.trendName} line={2} />
				</div>
			</Whisper>

			<ActionBox text={compTrend?.action} />
			<LikeDislikeButton
				textColor={null}
				handleDownClick={undefined}
				text={'What do you think about the recommended action?'}
				buttonState={compTrend?.recommendedActionFeedback}
				handleClick={handleFeedbackRecommendedAction}
			/>

			<Tabs
				//	backgroundColor='#F0F3F5'
				active={active}
				onChange={(active: string) => {
					setActive(active)
				}}
			>
				{_tabs.map((_tab, index) => {
					return (
						<div key={index} id={_tab}>
							{_tab}
						</div>
					)
				})}
			</Tabs>
		</div>
	)
}

export default ModalHead
