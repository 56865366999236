export const SET_ADMIN_SCENARIOS_IS_LOADING = 'SET_ADMIN_SCENARIOS_IS_LOADING'
export const SET_ADMIN_BRAND_SCENARIOS = 'SET_ADMIN_BRAND_SCENARIOS'
export const SET_SCENARIO_LABELS = 'SET_SCENARIO_LABELS'
export const SET_SCENARIO_TYPES = 'SET_SCENARIO_TYPES'
export const SET_SCENARIO_LABELS_IS_LOADING = 'SET_SCENARIO_LABELS_IS_LOADING'
export const SET_SCENARIO_SAVING = 'SET_SCENARIO_SAVING'
export const SET_ADD_SCENARIO = 'SET_ADD_SCENARIO'
export const SET_UPDATED_SCENARIO = 'SET_UPDATED_SCENARIO'
export const SET_ADMIN_LABELS = 'SET_ADMIN_LABELS'
export const SET_LABELS_IS_LOADING = 'SET_LABELS_IS_LOADING'
export const SET_LABEL_ARCHIVING = 'SET_LABEL_ARCHIVING'
export const SET_LABEL_TO_ARCHIVE = 'SET_LABEL_TO_ARCHIVE'
export const SET_LABEL_SAVING = 'SET_LABEL_SAVING'
export const SET_ADD_LABEL = 'SET_ADD_LABEL'
export const SET_LABEL_TO_CREATE = 'SET_LABEL_TO_CREATE'
export const SET_ADD_SCENARIO_TYPE = 'SET_ADD_SCENARIO_TYPE'
export const SET_SCENARIO_TYPE_TO_CREATE = 'SET_SCENARIO_TYPE_TO_CREATE'
