import React from 'react'
import TrendKeyword from './TrendKeyword'
import { Trend } from '../../../../classes/trend'
import Skeleton from 'react-loading-skeleton'
import LikeDislikeButton from '../../../../components/LikeDislikeButton'

interface TrendKeywordsProps {
	trendKeywords: Trend['keywords']
	trend: Trend
	trendKeywordsLoading: boolean
	compTrend: Trend
	handleKeywordsFeedback: any
}
const TrendKeywords = ({
	trendKeywords,
	trend,
	trendKeywordsLoading,
	compTrend,
	handleKeywordsFeedback
}: TrendKeywordsProps) => {
	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
		>
			{trendKeywords?.length === 0 && (
				<div
					style={{
						height: 28,
						width: 'fit-content',
						paddingRight: 8,
						borderRadius: 3,
						backgroundColor: '#F2FAFD',
						paddingTop: 3,
						paddingLeft: 8,
						fontWeight: 500,
						fontSize: 14,
						lineHeight: '22px',
						marginTop: 24
					}}
				>
					Currently we have no Keywords for this Moment.
				</div>
			)}
			{trend?.keywordCount > 10 && (
				<div
					style={{
						height: 28,
						paddingRight: 8,
						borderRadius: 3,
						backgroundColor: '#F2FAFD',
						paddingTop: 3,
						paddingLeft: 8,
						fontWeight: 500,
						fontSize: 14,
						lineHeight: '22px',
						marginTop: 24
					}}
				>
					Showing 10 of {trend.keywordCount} keywords. To see all keywords,
					please download the moment.
				</div>
			)}

			{trendKeywordsLoading && (
				<Skeleton
					style={{ marginTop: 25 + 49 }}
					width={656}
					height={43}
					count={4}
				/>
			)}

			{trendKeywords?.length > 0 && !trendKeywordsLoading && (
				<div
					id='keywordsTab'
					style={{
						marginTop: 24,
						overflowY: 'auto'
					}}
				>
					<div
						style={{
							width: 656,
							height: 49,
							paddingLeft: 28,
							paddingTop: 12,
							backgroundColor: '#F0F3F5',
							borderTopLeftRadius: 8,
							borderTopRightRadius: 8,
							fontWeight: 600,
							fontSize: 16,
							lineHeight: '24px',
							color: '#333D47',
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						Rank &nbsp; &nbsp; &nbsp; Keywords
						<div style={{ marginRight: 24, marginTop: 2 }}>
							<LikeDislikeButton
								textColor={null}
								handleDownClick={null}
								text={'What do you think about the suggested keywords?'}
								buttonState={compTrend?.keywordsFeedback}
								handleClick={handleKeywordsFeedback}
							/>
						</div>
					</div>
					{trendKeywords &&
						trendKeywords.slice(0, 10).map((keyword, index) => {
							return (
								<TrendKeyword
									key={index + keyword.rank}
									keyword={keyword}
									index={index}
								/>
							)
						})}
				</div>
			)}
		</div>
	)
}

export default TrendKeywords
