import React from 'react'

const NeedPermissionPage = ({
	permissionNeeded
}: {
	permissionNeeded: string
}) => {
	return (
		<div
			style={{
				width: '100%',
				justifyContent: 'center',
				textAlign: 'center',
				marginTop: 200
			}}
		>
			{permissionNeeded} permission is required to view this page.
		</div>
	)
}

export default NeedPermissionPage
