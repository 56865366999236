import { Link } from '@tanstack/react-location'
import React from 'react'
import toast from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'
import { Input } from 'rsuite'
import { api } from '../../../api/api'
import { accentColor } from '../../../assets/jss/colorContants'
import PortalModal from '../../../components/PortalModal/PortalModal'
import SightlyTagInput from '../../../components/Sightly/SightlyTagInput'
import SightlyButton from '../../../components/SightlyButton'
import SightlyCheckbox from '../../../components/SightlyCheckbox'
import { WarningModal } from '../../../components/WarningModal'
import { routes } from '../../../routes'
import { implementMoments, implementTrends } from '../../../services/trends'
import { rqKeys } from '../../../ReactQueryKeyFactory'

type ShareProps = {
    isOpen: boolean
    setIsOpen: Function
    selectedTrends: string[]
    brandProfileId: number
    isTrends?: boolean
}

const ShareModal = ({ isOpen, setIsOpen, selectedTrends, brandProfileId, isTrends }: ShareProps) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [emails, setEmails] = React.useState<string[]>([])
    const [isKeywordsChecked, setIsKeywordsChecked] = React.useState(true)
    const [isPlacementsChecked, setIsPlacementsChecked] = React.useState(true)

    const hasIos = useQuery(rqKeys.brandProfileIos(brandProfileId), () => api.brandProfile.ios.get(brandProfileId), {
        enabled: !!brandProfileId
    })

    const isSightlyEmail = (val: string) => {
        if (val.toLowerCase().includes('@sightly.com')) {
            return true
        } else {
            return false
        }
    }

    const handleSendClickMoments = async () => {
        if (!isKeywordsChecked && !isPlacementsChecked) {
            toast.error('Please select either Keywords or Placements')
            return
        }
        setIsLoading(true)
        const args = {
            momentIds: selectedTrends,
            brandProfileId,
            emails,
            keywords: isKeywordsChecked,
            placements: isPlacementsChecked,
            message
        }
        const res = await implementMoments(args)
        setIsLoading(false)
        if (res.status === 200) {
            toast.success(`Success: Moments Implementation Shared with ${emails.length} Users`)
        } else {
            toast.error('There was a problem sharing these moments. Please try again')
        }
        setMessage('')
        setIsOpen(false)
    }

    const handleSendClickTrends = async () => {
        if (!isKeywordsChecked && !isPlacementsChecked) {
            toast.error('Please select either Keywords or Placements')
            return
        }
        setIsLoading(true)
        const args = {
            trendIds: selectedTrends,
            brandProfileId,
            emails,
            keywords: isKeywordsChecked,
            placements: isPlacementsChecked,
            message
        }
        const res = await implementTrends(args)
        setIsLoading(false)
        if (res.status === 200) {
            toast.success(`Success: Moments Implementation Shared with ${emails.length} Users`)
        } else {
            toast.error('There was a problem sharing these moments. Please try again')
        }
        setMessage('')
        setIsOpen(false)
    }

    if (hasIos.isFetched && hasIos.data && hasIos.data.length < 1) {
        return (
            <WarningModal open={isOpen} setIsOpen={setIsOpen}>
                <div>
                    <div>Error: Please link IO to Brand Profile to share implementations</div>
                    <Link to={`${routes.app.settings.brandProfiles.path}/brandProfile/${brandProfileId}/targetview`}>
                        Update Here
                    </Link>
                </div>
            </WarningModal>
        )
    }

    return (
        <PortalModal
            open={isOpen}
            handleClose={() => setIsOpen(false)}
            footerButtons={[
                <SightlyButton
                    id="cancelShowSendButton"
                    text="Cancel"
                    type="secondary"
                    handleClick={() => setIsOpen(false)}
                />,
                <SightlyButton
                    id="sendImpButton"
                    loading={isLoading}
                    disabled={emails.length < 1 || selectedTrends.length < 1}
                    text="Send"
                    handleClick={() => (isTrends ? handleSendClickTrends() : handleSendClickMoments())}
                />
            ]}
        >
            <div style={{ width: '70vw' }}>
                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 24,
                        lineHeight: '32px',
                        color: '#333D47'
                    }}
                >
                    Share Implementation
                </div>

                <div
                    style={{
                        marginTop: 8,
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#292929'
                    }}
                >
                    Share moments with Sightly team members.
                </div>

                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: '24px',
                        marginTop: 24
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        Send as an email attachment, and&nbsp;
                        <div style={{ color: accentColor }}>log</div> &nbsp;your implementation
                    </div>
                    <div style={{ marginTop: 24 }}>To:</div>
                </div>

                <SightlyTagInput
                    setSelectedTags={(emails: string[]) => setEmails(emails)}
                    tags={emails}
                    inputIsValid={isSightlyEmail}
                />

                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: '24px',
                        marginTop: 22
                    }}
                >
                    Message:
                </div>
                <div style={{}}>
                    <Input
                        style={{ backgroundColor: '#f0f3f5' }}
                        defaultValue={''}
                        onChange={val => setMessage(val)}
                        componentClass="textarea"
                        rows={3}
                        placeholder="Type here..."
                    />
                </div>

                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: '24px',
                        marginTop: 22
                    }}
                >
                    Share (Select All That Apply):
                </div>

                <div
                    style={{
                        marginTop: 16
                    }}
                >
                    <SightlyCheckbox
                        id="keywordsBox"
                        label="Keywords"
                        size="14"
                        checked={isKeywordsChecked}
                        handleChange={(checked: boolean) => {
                            setIsKeywordsChecked(checked)
                        }}
                    />
                </div>

                <div
                    style={{
                        marginTop: 16
                    }}
                >
                    <SightlyCheckbox
                        id="impPlacements"
                        label="Placements"
                        size="14"
                        checked={isPlacementsChecked}
                        handleChange={(checked: boolean) => {
                            setIsPlacementsChecked(checked)
                        }}
                    />
                </div>
            </div>
        </PortalModal>
    )
}

export default ShareModal
