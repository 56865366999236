import React from 'react'
import chevronDown from '../../../assets/img/DropSelector.png'
import './../index.css'
import SightlyAvatar from '../../SightlyAvatar'
import ClickAwayListener from 'react-click-away-listener'
import SightlySearchBar from '../../SightlySearchBar'
import Tree from 'rsuite/lib/Tree'
import debounce from 'just-debounce-it'
import { routes } from '../../../routes'
import TextTruncate from 'react-text-truncate'
import { permissionView } from '../../../utils'
import { useNavigate } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'
import { Icon, Input, InputGroup } from 'rsuite'
import useUser from '../../../services/useUser'

const HorizontalNavAccountItem = ({ accountName }) => {
    const [open, setOpen] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState('')
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const navigate = useNavigate()
    const handleSearch = debounce(text => {
        setSearchTerm(text)
    }, 400)

    const handleAccountSelect = accountId => {
        navigate({
            // All typesafe!
            to: location.current.pathname.includes(`/app/settings/brandProfiles/brandProfile`)
                ? routes.app.settings.brandProfiles.path
                : location.current.pathname,
            search: old => ({
                accountId
            })
        })
    }

    return (
        <div
            onClick={() => {
                if (!userCan(perms.ACCOUNT_READ)) return
                setOpen(true)
            }}
            style={{
                borderRadius: 2,
                position: 'relative',
                paddingRight: 10,
                color: 'black',
                height: 56,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                marginRight: 90,
                cursor: userCan(perms.ACCOUNT_READ) ? 'pointer' : 'not-allowed'
            }}
        >
            <div
                style={{
                    width: 60,
                    textAlign: 'center'
                }}
            >
                <SightlyAvatar text={accountName} type="blue" size="big" />
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'left',
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: '24px',
                    maxWidth: 122,
                    minWidth: 122
                }}
            >
                <TextTruncate text={accountName} />
            </div>

            <div
                style={{
                    paddingLeft: 12,
                    textAlign: 'left'
                }}
            >
                <img
                    style={{
                        backgroundColor: '#F5F5F5',
                        borderRadius: 6
                    }}
                    width={24}
                    src={chevronDown}
                />
            </div>

            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 64,
                            left: 14,
                            backgroundColor: 'white',
                            zIndex: 99999,
                            //height: 500,
                            overflowY: 'auto',
                            borderRadius: 6,
                            border: '1px solid rgb(224, 224, 224)',
                            minWidth: 400,
                            padding: 8
                        }}
                    >
                        {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_READ}</div>}
                        <InputGroup>
                            <Input placeholder="Search Accounts" onChange={val => handleSearch(val)} />

                            <InputGroup.Button disabled style={{ backgroundColor: 'transparent' }}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>

                        {/* <SightlySearchBar placeholder={'Search...'} handleChange={val => handleSearch(val)} /> */}
                        <div style={{ height: 8 }} />
                        <Tree
                            showIndentLine
                            searchKeyword={searchTerm}
                            autoHeight
                            defaultValue={currentAccount?.accountId}
                            defaultExpandItemValues={
                                currentAccount?.parentAccountId ? [1, currentAccount.parentAccountId] : [1]
                            }
                            defaultExpandAll={false}
                            labelKey="accountName"
                            valueKey="accountId"
                            data={accounts}
                            onSelect={account => handleAccountSelect(account.accountId)}
                        />
                    </div>
                </ClickAwayListener>
            )}
        </div>
    )
}

export default HorizontalNavAccountItem
