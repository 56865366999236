import React from 'react'
import { ReactComponent as FilterIcon } from '../../../assets/img/filter.svg'
import SightlyButton from '../../../components/SightlyButton'
import { joinClasses } from '../../../utils'
import RadioGroup from 'rsuite/lib/RadioGroup'
import Radio from 'rsuite/lib/Radio'
import { Trend } from '../../../classes/trend'
import PortalModal from '../../../components/PortalModal/PortalModal'

interface IProps {
    handleVerticalChange: Function
    handleActionChange: Function
    trends: Trend[]
    getFilteredTrends: Function
    disabled: boolean
}

const FilterButton = ({ handleVerticalChange, handleActionChange, trends, getFilteredTrends, disabled }: IProps) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [actions, setActions] = React.useState<string[]>([])
    const [board, setBoard] = React.useState<string>('general')

    const handleSubmitClick = () => {
        handleVerticalChange(board)
        handleActionChange(actions)
        setOpen(false)
    }

    const handleActionClick = (action: string) => {
        if (actions.includes(action)) {
            setActions(prev => {
                return prev.filter(a => a !== action)
            })
        } else {
            setActions(prev => {
                return prev.concat(action)
            })
        }
    }

    const activeFilterCount = React.useMemo(() => {
        let filterCount = actions.length + 1

        return filterCount
    }, [actions])

    const filterIsActive = React.useMemo(() => {
        return activeFilterCount !== 0
    }, [activeFilterCount])

    const buttonLabel = React.useMemo(() => {
        if (activeFilterCount === 0 || !trends) {
            return 'Show All Moments'
        }

        let filteredTrends = getFilteredTrends(trends, actions, board)

        return `Show ${filteredTrends.length} Moments`
    }, [trends, activeFilterCount, actions, board])

    const stats = React.useMemo(() => {
        if (!trends) return {}
        return {
            general: trends.filter(t => t.vertical === 'general').length,
            sports: trends.filter(t => t.vertical === 'sports').length,
            business: trends.filter(t => t.vertical === 'business').length,
            entertainment: trends.filter(t => t.vertical === 'entertainment').length,
            health: trends.filter(t => t.vertical === 'health').length,
            science: trends.filter(t => t.vertical === 'science').length,
            technology: trends.filter(t => t.vertical === 'technology').length
        }
    }, [trends, board])

    const handleClear = () => {
        setActions([])
        setBoard('general')
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 32 }}>
            <PortalModal
                footerButtons={[
                    <SightlyButton id="clearTrendsButton" text="Clear" type="secondary" handleClick={handleClear} />,
                    <SightlyButton id="submitClickTrendButton" text={buttonLabel} handleClick={handleSubmitClick} />
                ]}
                open={open}
                handleClose={() => setOpen(false)}
            >
                <div style={{ width: 600 }}>
                    <h4>Filter</h4>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingTop: 16,
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: '24px',
                            color: '#7A7A7A'
                        }}
                    >
                        <div>FILTERS ({activeFilterCount})</div>

                        <div style={{ marginTop: 16 }}>Action</div>

                        <div style={{ marginTop: 8, display: 'flex' }}>
                            <div
                                className={
                                    actions.includes('target')
                                        ? 'unselectable'
                                        : joinClasses('unselectable', 'trendActionFilters')
                                }
                                onClick={() => handleActionClick('target')}
                                style={{
                                    cursor: 'pointer',
                                    height: 32,
                                    width: 54,
                                    backgroundColor: actions.includes('target') ? '#283856' : '#6E8399',
                                    borderRadius: 3,
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    fontWeight: 600,
                                    color: 'white',
                                    textAlign: 'center',
                                    paddingTop: 6
                                }}
                            >
                                Target
                            </div>

                            <div
                                className={
                                    actions.includes('block')
                                        ? 'unselectable'
                                        : joinClasses('unselectable', 'trendActionFilters')
                                }
                                onClick={() => handleActionClick('block')}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 8,
                                    height: 32,
                                    width: 49,

                                    backgroundColor: actions.includes('block') ? '#283856' : '#6E8399',
                                    borderRadius: 3,
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    fontWeight: 600,
                                    color: 'white',
                                    textAlign: 'center',
                                    paddingTop: 6
                                }}
                            >
                                Block
                            </div>

                            <div
                                className={
                                    actions.includes('monitor')
                                        ? 'unselectable'
                                        : joinClasses('unselectable', 'trendActionFilters')
                                }
                                onClick={() => handleActionClick('monitor')}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 8,
                                    height: 32,
                                    width: 62,
                                    paddingTop: 6,
                                    backgroundColor: actions.includes('monitor') ? '#283856' : '#6E8399',
                                    borderRadius: 3,
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    fontWeight: 600,
                                    color: 'white',
                                    textAlign: 'center'
                                }}
                            >
                                Monitor
                            </div>
                        </div>

                        <div style={{ marginTop: 20, marginLeft: 24 }}>
                            <RadioGroup
                                style={{ marginBottom: 30 }}
                                defaultValue={board}
                                onChange={value => {
                                    setBoard(value)
                                }}
                            >
                                <Radio value="general">{`Top Moments (${stats.general})`}</Radio>
                                <Radio value="sports">{`Sports (${stats.sports})`}</Radio>
                                <Radio value="business">{`Business (${stats.business})`}</Radio>
                                <Radio value="entertainment">{`Entertainment (${stats.entertainment})`}</Radio>
                                <Radio value="health">{`Health (${stats.health})`}</Radio>
                                <Radio value="science">{`Science (${stats.science})`}</Radio>
                                <Radio value="technology">{`Technology (${stats.technology})`}</Radio>
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </PortalModal>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    backgroundColor: filterIsActive ? '#395066' : undefined,
                    height: 40,
                    borderRadius: 6,
                    padding: 8
                }}
                id="filterButton"
                onClick={() => (disabled ? undefined : setOpen(prev => !prev))}
            >
                <FilterIcon stroke={filterIsActive ? 'white' : '#395066'} />
                <div
                    style={{
                        marginLeft: 12,
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '20px',
                        color: filterIsActive ? 'white' : '#395066'
                    }}
                >
                    {activeFilterCount > 0 ? `${activeFilterCount} Filter(s) Applied` : 'Filter'}
                </div>
            </div>
        </div>
    )
}

export default FilterButton
