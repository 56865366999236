import React from 'react'
import Table from 'rsuite/lib/Table'
import Icon from 'rsuite/lib/Icon'
import Popover from 'rsuite/lib/Popover'
import Whisper from 'rsuite/lib/Whisper'
import Dropdown from 'rsuite/lib/Dropdown'
import IconButton from 'rsuite/lib/IconButton'
import {
    downloadBrandProfile,
    setDownloadingBrandProfileId,
    setIsDownloadingBrandProfile
} from '../../redux/actions/brandProfiles.js'
import { connect } from 'react-redux'
import DownloadOptionsModal from './DownloadOptionsModal'
import { accentColor } from '../../assets/jss/colorContants.js'
import SightlyButton from '../../components/SightlyButton'
import useBrandProfiles from '../../services/brandProfile/useBrandProfiles'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { permissionView } from '../../utils.js'
import { userAccountAxios } from '../../axiosInstances.js'
import { useNavigate } from '@tanstack/react-location'
import NoBrandProfiles from '../../components/NoBrandProfiles'
import useUser from '../../services/useUser'
import { rqKeys } from '../../ReactQueryKeyFactory'

var dayjs = require('dayjs')

const date_format = 'MMMM DD, YYYY h:mm A'

const mapStateToProps = state => {
    return {
        isDownloadingBrandProfile: state.isDownloadingBrandProfile,
        downloadingBrandProfileId: state.downloadingBrandProfileId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        downloadBrandProfile: args => dispatch(downloadBrandProfile(args)),
        setDownloadingBrandProfileId: id => dispatch(setDownloadingBrandProfileId(id)),
        setIsDownloadingBrandProfile: bool => dispatch(setIsDownloadingBrandProfile(bool))
    }
}

const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group" style={{ align: 'center' }}>
            {rowData.submitted ? 'Submitted' : 'Draft'}
        </Table.Cell>
    )
}

const ActionCell = ({ rowData, dataKey, customProps, setShowModal, setBrandProfileDownloadClickedFor, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group" style={{ align: 'center', padding: 1 }}>
            <CustomWhisper
                rowData={rowData}
                customProps={customProps}
                setShowModal={setShowModal}
                setBrandProfileDownloadClickedFor={setBrandProfileDownloadClickedFor}
            >
                <IconButton size="lg" appearance="subtle" icon={<Icon icon="more" color={accentColor} />} />
            </CustomWhisper>
        </Table.Cell>
    )
}

const Menu = props => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const { cloneBrandProfile } = useBrandProfiles({ submittedOnly: false })

    let profile = props.rowData

    async function deleteBp(brandProfileId) {
        let url = `/brand-profile/${brandProfileId}/archive`
        return await userAccountAxios.patch(url)
    }

    const deleteBpMutation = useMutation(brandProfileId => deleteBp(brandProfileId), {
        onSuccess: async brandProfileId => {
            queryClient.invalidateQueries(rqKeys.brandProfilesKey(currentAccount?.accountId))
        }
    })

    const handleDeleteBrandProfileClick = brandProfileId => {
        deleteBpMutation.mutate(brandProfileId)
    }

    const handleViewBrandProfileClick = profile => {
        let url = `/app/settings/brandProfiles/brandProfile/${profile.brandProfileId}/brandInformation`
        navigate({
            to: url,
            search: prev => {
                return {
                    ...prev,
                    viewOnly: true
                }
            }
        })
    }

    const handleEditBrandProfileClick = profile => {
        let url = `/app/settings/brandProfiles/brandProfile/${profile.brandProfileId}/brandInformation`
        navigate({ to: url })
    }

    const handleEditTargetViewIosClick = profile => {
        const url = `/app/settings/brandProfiles/brandProfile/${profile.brandProfileId}/targetview`
        navigate({ to: url })
    }

    return (
        <Dropdown.Menu onSelect={props.onSelect}>
            {userCan(perms.BRAND_PROFILE_READ) && (
                <Dropdown.Item id="view" onClick={() => handleViewBrandProfileClick(profile)}>
                    View
                    {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_READ}</div>}
                </Dropdown.Item>
            )}

            {userCan(perms.BRAND_PROFILE_UPDATE) && (
                <Dropdown.Item id="edit" onClick={() => handleEditBrandProfileClick(profile)}>
                    Edit
                    {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_UPDATE}</div>}
                </Dropdown.Item>
            )}

            {userCan(perms.BRAND_PROFILE_UPDATE) && (
                <Dropdown.Item onClick={() => cloneBrandProfile.mutate({ brandProfileId: profile?.brandProfileId })}>
                    Clone
                    {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_UPDATE}</div>}
                </Dropdown.Item>
            )}

            {userCan(perms.BRAND_PROFILE_IOS_UPDATE) && (
                <Dropdown.Item onClick={() => handleEditTargetViewIosClick(profile)}>
                    Edit TV IOs
                    {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_IOS_UPDATE}</div>}
                </Dropdown.Item>
            )}

            {userCan(perms.BRAND_PROFILE_DOWNLOAD) && (
                <Dropdown.Item
                    id="download"
                    onClick={() => {
                        props.setShowModal(true)
                        props.setBrandProfileDownloadClickedFor(profile)
                    }}
                >
                    Download
                    {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_DOWNLOAD}</div>}
                </Dropdown.Item>
            )}

            {userCan(perms.BRAND_PROFILE_DELETE) && (
                <Dropdown.Item
                    onClick={() => {
                        handleDeleteBrandProfileClick(profile.brandProfileId)
                    }}
                >
                    Delete
                    {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_DELETE}</div>}
                </Dropdown.Item>
            )}
        </Dropdown.Menu>
    )
}

const MenuPopover = ({ onSelect, rowData, customProps, setShowModal, setBrandProfileDownloadClickedFor, ...rest }) => (
    <Popover {...rest} full>
        <Menu
            onSelect={onSelect}
            rowData={rowData}
            customProps={customProps}
            setShowModal={setShowModal}
            setBrandProfileDownloadClickedFor={setBrandProfileDownloadClickedFor}
        />
    </Popover>
)

let tableBody

class CustomWhisper extends React.Component {
    constructor(props) {
        super(props)
        this.handleSelectMenu = this.handleSelectMenu.bind(this)
    }
    handleSelectMenu(eventKey, event) {
        this.trigger.hide()
    }
    render() {
        return (
            <Whisper
                placement="auto"
                trigger="click"
                triggerRef={ref => {
                    this.trigger = ref
                }}
                container={() => {
                    return tableBody
                }}
                speaker={
                    <MenuPopover
                        rowData={this.props.rowData}
                        onSelect={this.handleSelectMenu}
                        customProps={this.props.customProps}
                        setShowModal={this.props.setShowModal}
                        setBrandProfileDownloadClickedFor={this.props.setBrandProfileDownloadClickedFor}
                    />
                }
            >
                {this.props.children}
            </Whisper>
        )
    }
}

function BrandProfiles(props) {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const { data: brandProfiles, isLoading, isFetched, createBrandProfile, cloneBrandProfile } = useBrandProfiles({
        submittedOnly: false
    })
    const navigate = useNavigate()

    const [showModal, setShowModal] = React.useState(false)
    const [scenarioDownloadSort, setScenarioDownloadSort] = React.useState('question')
    const [opinionDownloadSort, setOpinionDownloadSort] = React.useState('question')

    const [brandProfileDownloadClickedFor, setBrandProfileDownloadClickedFor] = React.useState(null)

    React.useEffect(() => {
        return () => {
            //clean up on unmount
            props.setIsDownloadingBrandProfile(false)
            props.setDownloadingBrandProfileId(null)
            setBrandProfileDownloadClickedFor(null)
        }
    }, [])

    const handleDownloadBrandProfileClick = () => {
        props.setIsDownloadingBrandProfile(true)
        props.setDownloadingBrandProfileId(brandProfileDownloadClickedFor.brandProfileId)
        let args = {
            brandProfileId: brandProfileDownloadClickedFor.brandProfileId,
            brandProfileName: brandProfileDownloadClickedFor.brandName,
            scenarioDownloadSort,
            opinionDownloadSort
        }
        props.downloadBrandProfile(args)
    }

    if (brandProfiles && brandProfiles.length < 1) {
        return <NoBrandProfiles />
    }

    return (
        <div style={{ padding: 24 }}>
            <DownloadOptionsModal
                showModal={showModal}
                handleCloseClick={() => setShowModal(false)}
                scenarioDownloadSort={scenarioDownloadSort}
                opinionDownloadSort={opinionDownloadSort}
                setScenarioDownloadSort={setScenarioDownloadSort}
                setOpinionDownloadSort={setOpinionDownloadSort}
                handleDownloadClick={() => handleDownloadBrandProfileClick()}
                isDownloadingBrandProfile={props.isDownloadingBrandProfile}
            />

            <div style={{ marginTop: 36 }}>
                {userCan(perms.BRAND_PROFILE_CREATE) && (
                    <>
                        <div style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
                            <SightlyButton
                                id="createNewProfile2"
                                text="Create New Profile"
                                handleClick={createBrandProfile}
                                disabled={isLoading || !isFetched}
                            />
                        </div>
                        {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_CREATE}</div>}
                    </>
                )}

                <Table
                    virtualized
                    style={{ marginTop: 48 }}
                    autoHeight
                    loading={isLoading || !isFetched}
                    data={brandProfiles}
                >
                    <Table.Column>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <StatusCell />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.Cell dataKey="brandName" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Website</Table.HeaderCell>
                        <Table.Cell dataKey="websiteUrl" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Created Date</Table.HeaderCell>
                        <Table.Cell>
                            {rowData => {
                                return dayjs(rowData.createdDate).format(date_format)
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Submitted Date</Table.HeaderCell>
                        <Table.Cell>
                            {rowData => {
                                return rowData.submittedDate ? dayjs(rowData.submittedDate).format(date_format) : '-'
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column width={90}>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                        <ActionCell
                            customProps={props}
                            setShowModal={setShowModal}
                            setBrandProfileDownloadClickedFor={setBrandProfileDownloadClickedFor}
                        />
                    </Table.Column>
                </Table>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfiles)
