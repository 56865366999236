import React from 'react'
import { neutralColor } from '../../../../../assets/jss/colorContants'
import FormGroup from 'rsuite/lib/FormGroup'
import RadioGroup from 'rsuite/lib/RadioGroup'
import Radio from 'rsuite/lib/Radio'
import useUser from '../../../../../services/useUser'
import { location } from '../../../../../RouteContainer'

export const CustomRadio = React.memo(props => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const handleChange = value => {
        props.handleScenarioSelect(props.scenario.scenarioId, value)
    }

    const focusedId = location.current.hash ? Number(location.current.hash.replace('#', '')) : false

    return (
        <div
            className={focusedId === props.scenario.scenarioId ? 'focused-item' : undefined}
            style={{
                borderBottom: '1px solid rgb(212, 217, 217, 0.3)',
                paddingTop: 16,
                paddingBottom: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
            id={props.scenario.scenarioId}
        >
            <div
                style={{
                    fontSize: 16,
                    marginLeft: 10,
                    float: 'left'
                }}
            >
                {props.scenario.scenarioName}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup controlId="radioList">
                    <RadioGroup
                        key={props.scenario.scenarioResponseId}
                        style={{ fontSize: 16 }}
                        inline
                        name="radioList"
                        defaultValue={props.scenario.scenarioResponseId}
                        onChange={value => {
                            handleChange(value)
                        }}
                    >
                        <Radio value={3} id="target" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                            Target
                        </Radio>
                        <Radio value={1} id="block" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                            Block
                        </Radio>

                        <Radio value={2} id="monitor" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}>
                            Monitor
                        </Radio>

                        <Radio
                            value={4}
                            id="dontmonitor"
                            disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.viewOnly}
                        >
                            Don't Monitor
                        </Radio>
                    </RadioGroup>
                </FormGroup>

                <div style={{ color: neutralColor, height: 15 }} />
            </div>
        </div>
    )
})
