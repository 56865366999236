import React from 'react'
import useWindowDimensions from '../../useWindowDimensions'
import useEmbedUrls from '../../services/useEmbedUrls'
import { MyLocationGenerics } from '../../classes/utils/utils'
import { useMatch } from '@tanstack/react-location'
import useUser from '../../services/useUser'

const MomentsIntelligence = () => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const { data: embedUrls } = useEmbedUrls(currentAccount?.accountId)
    const dims = useWindowDimensions()

    return (
        <div style={{ padding: 24 }}>
            <iframe
                id="momentsIntelligenceIframe"
                style={{
                    border: 0,
                    width: '100%',
                    height: dims.height - 56 - 48 - 24
                }}
                src={embedUrls?.momentsIntelligenceEmbedUrl}
                title={'Moments Intelligence'}
            >
                <p>Your browser does not support iframes.</p>
            </iframe>
        </div>
    )
}

export default MomentsIntelligence
