import React from 'react'
import Radio from 'rsuite/lib/Radio'
import RadioGroup from 'rsuite/lib/RadioGroup'
import { neutralColor } from '../../../../../assets/jss/colorContants'
import FormGroup from 'rsuite/lib/FormGroup'
import useUser from '../../../../../services/useUser'
import { location } from '../../../../../RouteContainer'

export const CustomRadio = React.memo(props => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const handleChange = value => {
        props.handleOpinionSelect(props.opinion, value)
    }

    const focusedId = location.current.hash ? Number(location.current.hash.replace('#', '')) : false

    return (
        <div
            className={focusedId === props.opinion.opinionId ? 'focused-item' : undefined}
            id={props.opinion.opinionId}
        >
            <div
                style={{
                    fontSize: '16px',
                    marginTop: '11px'
                }}
            >
                {props.opinion.question}
            </div>

            <FormGroup controlId="radioList">
                <RadioGroup
                    key={props.opinion.opinionResponseId}
                    name="radioList"
                    defaultValue={props.opinion.opinionResponseId}
                    onChange={value => {
                        handleChange(value)
                    }}
                >
                    <Radio
                        value={1}
                        id="stronglyDisagree"
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.disabled}
                    >
                        Strongly Disagree
                    </Radio>
                    <Radio value={2} id="Disagree" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.disabled}>
                        Disagree
                    </Radio>
                    <Radio value={3} id="unsure" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.disabled}>
                        Unsure
                    </Radio>
                    <Radio value={4} id="agree" disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.disabled}>
                        Agree
                    </Radio>

                    <Radio
                        value={5}
                        id="stronglyAgree"
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || props.disabled}
                    >
                        Strongly Agree
                    </Radio>
                </RadioGroup>
            </FormGroup>
            <div style={{ color: neutralColor, height: 15 }} />
        </div>
    )
})
