import React from 'react'
import useWindowDimensions from '../../../../useWindowDimensions'

const MediaResponsibility = () => {
    const dims = useWindowDimensions()
    return (
        <div style={{ padding: 24 }}>
            <h4 style={{ marginBottom: 24 }}>Media Responsibility</h4>
            <iframe
                style={{
                    border: 0,
                    width: '100%',
                    height: dims.height - 56
                }}
                src={'https://sightly.adfontesmedia.com/?keystring=0b2b5b09a62736614c519c27db5bc48d'}
                title={'Media Responsibility'}
                id="medResp"
            >
                <p>Your browser does not support iframes.</p>
            </iframe>
        </div>
    )
}

export default MediaResponsibility
