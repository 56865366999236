import React from 'react'
import useBrandProfileAylien, { aylienTypes, AylienItem } from './useBrandProfileAylien'
import Panel from 'rsuite/lib/Panel'
import Tree from 'rsuite/lib/Tree'
import InputGroup from 'rsuite/lib/InputGroup'
import Input from 'rsuite/lib/Input'
import Icon from 'rsuite/lib/Icon'
import TagPicker from 'rsuite/lib/TagPicker'
import Toggle from 'rsuite/lib/Toggle'

import Node from './Node'
import { accentColor } from '../../../../../assets/jss/colorContants'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'

const actionIdOptions = [
    {
        label: 'Target',
        id: 'target'
    },
    {
        label: 'Block',
        id: 'block'
    },
    {
        label: 'No Action',
        id: 'noaction'
    }
]
type IProps = {
    aylienType: aylienTypes
}

const Aylien = ({ aylienType }: IProps) => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()
    const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
    const viewOnly = viewOnlyParam || false
    const aylien = useBrandProfileAylien(Number(brandProfileId), aylienType)

    const handleExpand = (all: AylienItem[], expandedItem: AylienItem) => {
        aylien.populateChildren(expandedItem)
    }

    return (
        <Panel
            header={<div className="sightlyPanelHeader">{aylienType === aylienTypes.news ? 'News' : 'Industries'}</div>}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        marginBottom: 8,
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Toggle
                        onChange={val => aylien.handleSortChange(val)}
                        size="xs"
                        defaultChecked={true}
                        checkedChildren="sort a-z"
                        unCheckedChildren="sort z-a"
                        style={{ backgroundColor: accentColor }}
                    />

                    <TagPicker
                        defaultValue={[]}
                        block
                        style={{ marginLeft: 16, minWidth: 150 }}
                        preventOverflow
                        data={actionIdOptions}
                        labelKey={'label'}
                        valueKey={'id'}
                        placeholder="Filter by action"
                        onChange={val => {
                            aylien.handleFilterChange(val)
                        }}
                    />
                </div>

                <InputGroup style={{ marginBottom: 8 }}>
                    <Input
                        key={aylienType + 'search'}
                        placeholder="Search..."
                        onChange={val => aylien.handleSearch(val)}
                    />

                    <InputGroup.Button disabled style={{ backgroundColor: 'transparent' }}>
                        <Icon style={{ color: '#0092d1' }} icon="search" />
                    </InputGroup.Button>
                </InputGroup>

                <Tree
                    style={{ overflowY: 'auto' }}
                    key={`${aylien?.searchTerm}-${aylien?.sort}`}
                    data={aylien?.items}
                    labelKey={'name'}
                    valueKey={'id'}
                    onExpand={handleExpand}
                    renderTreeNode={nodeProps => {
                        return <Node disabled={viewOnly} item={nodeProps} handleClick={aylien.handleActionClick} />
                    }}
                />
            </div>
        </Panel>
    )
}

export default Aylien
