import React from 'react'

interface Keyword {
	rank: number
	keyword: string
}

const TrendKeyword = ({
	keyword,
	index
}: {
	keyword: Keyword
	index: number
}) => {
	return (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					width: 70,
					height: 43,
					backgroundColor: index % 2 === 0 ? 'white' : '#F0F3F5',
					fontWeight: 400,
					fontSize: 14,
					lineHeight: '24px',
					paddingTop: 9.5,
					color: '#000000',
					paddingLeft: 28,
					textAlign: 'left'
				}}
			>
				{keyword.rank}
			</div>

			<div
				style={{
					width: 656 - 70,
					height: 43,
					backgroundColor: index % 2 === 0 ? 'white' : '#F0F3F5',
					fontWeight: 400,
					fontSize: 14,
					lineHeight: '24px',
					paddingTop: 9.5,
					color: '#000000',
					paddingLeft: 28,
					textAlign: 'left'
				}}
			>
				{keyword.keyword}
			</div>
		</div>
	)
}

export default TrendKeyword
