import React from 'react'
import ControlLabel from 'rsuite/lib/ControlLabel'
import { Field } from 'formik'
import Input from 'rsuite/lib/Input'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import { neutralColor } from '../../assets/jss/colorContants.js'
import './customInput.css'

const formControl = {
    paddingBottom: '10px',
    margin: '27px 0 0 0',
    position: 'relative',
    verticalAlign: 'unset'
}

const formControlSlim = {
    paddingBottom: '6px',
    margin: '0px 0 0 0',
    position: 'relative',
    verticalAlign: 'unset'
}

export default function CustomInput(props) {
    const { labelText, id } = props

    const handleChange = async (val, form) => {
        await form.setFieldValue(props.name, val)
        form.validateField(props.name)
    }

    const handleFocus = event => {
        event.target.select()
    }

    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <div style={props.simple ? formControlSlim : formControl}>
                    {labelText && (
                        <ControlLabel className={props.required ? 'isRequired' : ''}>{labelText}</ControlLabel>
                    )}

                    <InputGroup>
                        {props.startAdornmentText && <InputGroup.Addon>{props.startAdornmentText}</InputGroup.Addon>}

                        <Input
                            autoFocus={props.autoFocus}
                            onFocus={handleFocus}
                            id={id}
                            value={props.formikValue}
                            onChange={val => handleChange(val, form)}
                            disabled={props.disabled}
                            style={{
                                borderColor: 'white',
                                color: props.inputColor ? props.inputColor : 'black'
                            }}
                        />

                        {!props.simple && (
                            <InputGroup.Addon style={{ backgroundColor: 'white' }}>
                                <Icon
                                    icon="check"
                                    style={{
                                        color:
                                            !form.errors[field.name] && field.value.length > 0 && !props.specialError
                                                ? 'green'
                                                : neutralColor
                                    }}
                                />
                            </InputGroup.Addon>
                        )}
                    </InputGroup>

                    {(form.errors[field.name] || props.specialError) && (
                        <div>
                            <div
                                id="component-helper-text"
                                style={{
                                    color: '#f44336',
                                    position: 'absolute',
                                    bottom: '-1'
                                }}
                            >
                                {form.errors[field.name] ? form.errors[field.name] : props.specialError}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Field>
    )
}
