export const ADMIN_OPINIONS_IS_LOADING = 'ADMIN_OPINIONS_IS_LOADING'
export const SET_ADMIN_BRAND_OPINIONS = 'SET_ADMIN_BRAND_OPINIONS'
export const SET_ADMIN_BRAND_TOPICS = 'SET_ADMIN_BRAND_TOPICS'
export const SET_ADMIN_BRAND_OPINION_TOPICS = 'SET_ADMIN_BRAND_OPINION_TOPICS'
export const SET_ADMIN_BRAND_OPINION_CATEGORIES =
	'SET_ADMIN_BRAND_OPINION_CATEGORIES'
export const SET_ADMIN_BRAND_OPINION_KEYWORDS =
	'SET_ADMIN_BRAND_OPINION_KEYWORDS'
export const OPINION_ARCHIVING = 'OPINION_ARCHIVING'
export const OPINION_TO_ARCHIVE = 'OPINION_TO_ARCHIVE'
export const OPINION_SAVING = 'OPINION_SAVING'
export const ADD_OPINION = 'ADD_OPINION'
export const SET_ADD_OPINION_TYPE = 'SET_ADD_OPINION_TYPE'
export const SET_OPINION_TYPE_TO_CREATE = 'SET_OPINION_TYPE_TO_CREATE'
