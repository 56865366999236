import React from 'react'
import DatePicker from 'rsuite/lib/DatePicker'

interface IProps {
  disabled?: boolean
  handleSet: any
  defaultValue: Date | undefined
}

const DatePickerField = ({
  disabled,
  handleSet,
  defaultValue
}: IProps) => {

  return (
    <DatePicker
      id='datepickerEvent'
      block
      defaultValue={defaultValue}
      preventOverflow
      disabled={disabled}
      cleanable={true}
      placeholder='Date of the event'
      onChange={(val: any) => {
        handleSet(val)
      }}
    />
  )
}

export default DatePickerField
