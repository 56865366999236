import {
	ADMIN_OPINIONS_IS_LOADING,
	SET_ADMIN_BRAND_OPINIONS,
	SET_ADMIN_BRAND_TOPICS,
	SET_ADMIN_BRAND_OPINION_TOPICS,
	SET_ADMIN_BRAND_OPINION_CATEGORIES,
	OPINION_TO_ARCHIVE,
	ADD_OPINION,
	OPINION_SAVING,
	OPINION_ARCHIVING,
	SET_OPINION_TYPE_TO_CREATE,
	SET_ADMIN_BRAND_OPINION_KEYWORDS
} from '../../action-types/admin/opinions'

export function opinions(state = [], action) {
	switch (action.type) {
		case SET_ADMIN_BRAND_OPINIONS:
			return action.opinions
		case OPINION_TO_ARCHIVE:
			let newState = [
				...state.map((opinion) =>
					opinion.opinionId === action.opinionId
						? { ...opinion, archived: true }
						: opinion
				)
			]
			return newState
		case ADD_OPINION:
			let stateData = []
			if (state && state.length > 0) {
				stateData = JSON.parse(JSON.stringify(state))
			}
			stateData.push(action.opinion)

			return stateData
		case SET_ADMIN_BRAND_OPINION_TOPICS:
			let newStateWithTopics = [
				...state.map((opinion) =>
					opinion.opinionId === action.opinionId
						? { ...opinion, linkedTopics: action.topicIds }
						: opinion
				)
			]
			return newStateWithTopics

		case SET_ADMIN_BRAND_OPINION_KEYWORDS:
			let newStateWithKeywords = [
				...state.map((opinion) =>
					opinion.opinionId === action.opinionId
						? { ...opinion, linkedKeywords: action.keywords }
						: opinion
				)
			]
			return newStateWithKeywords

		case SET_ADMIN_BRAND_OPINION_CATEGORIES:
			let newStateWithCategories = [
				...state.map((opinion) =>
					opinion.opinionId === action.opinionId
						? { ...opinion, linkedIabCategories: action.iabCategoryIds }
						: opinion
				)
			]
			return newStateWithCategories
		default:
			return state
	}
}

export function opinionsIsLoading(state = true, action) {
	switch (action.type) {
		case ADMIN_OPINIONS_IS_LOADING:
			return action.adminOpinionsIsLoading
		default:
			return state
	}
}

export function opinionSaving(state = false, action) {
	switch (action.type) {
		case OPINION_SAVING:
			return action.opinionSaving
		default:
			return state
	}
}

export function opinionArchiving(state = '', action) {
	switch (action.type) {
		case OPINION_ARCHIVING:
			return action.opinionArchiving
		default:
			return state
	}
}





export function initOpinionTypeAdd(state = false, action) {
	switch (action.type) {
		case SET_OPINION_TYPE_TO_CREATE:
			return action.initTypeAdd
		default:
			return state
	}
}

export function topics(state = [], action) {
	switch (action.type) {
		case SET_ADMIN_BRAND_TOPICS:
			return action.topics
		default:
			return state
	}
}
