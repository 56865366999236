const local = {
    environment: 'local',
    api: {
        userAccountUrl: 'http://localhost:4000',
        listBuilderUrl: 'http://localhost:4200'
    }
}

const staging = {
    environment: 'staging',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-staging-zu7blp7gxa-uw.a.run.app',
        listBuilderUrl: 'https://api-cont-intell-list-builder-staging-zu7blp7gxa-uw.a.run.app'
    }
}

const development = {
    environment: 'development',
    api: {
        userAccountUrl: 'https://sightly-development-outcome-gcr-user-account-ffwjoxalpq-uw.a.run.app',
        listBuilderUrl: 'https://sightly-development-outcome-gcr-list-builder-ffwjoxalpq-uw.a.run.app'
    }
}

const demo = {
    environment: 'demo',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-demo-zu7blp7gxa-uw.a.run.app',
        listBuilderUrl: 'https://api-cont-intell-list-builder-demo-zu7blp7gxa-uw.a.run.app'
    }
}

const production = {
    environment: 'production',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-prod-zu7blp7gxa-uw.a.run.app',
        listBuilderUrl: 'https://api-cont-intell-list-builder-prod-zu7blp7gxa-uw.a.run.app'
    }
}

var config = production
switch (process.env.REACT_APP_STAGE) {
    case 'local':
        config = local
        break
    case 'staging':
        config = staging
        break
    case 'demo':
        config = demo
        break
    case 'development':
        config = development
        break
    default:
        config = production
}

const isDemo = window.location.href.includes('demo-outcomes')
const listbuilderIncrement = 10
const urlParameters = {
    brandProfileId: 'brandProfileId',
    boardIds: 'boardIds',
    timeRange: 'timeRange'
}

export default {
    isDemo,
    listbuilderIncrement,
    urlParameters,
    ...config
}
