import React from 'react'
import { Controller } from 'react-hook-form'
import Input from 'rsuite/lib/Input'
import InputGroup from 'rsuite/lib/InputGroup'

interface IProps {
	id: string
	label?: string
	labelColor?: string
	placeholder?: string
	disabled?: boolean
	name: string
	register: any
	error?: string
	width?: string | number
	autoFocus?: boolean
	control: any
	prefix?: string
	textarea?: boolean
}

const SightlyInputForm = ({
	name,
	label,
	labelColor,
	placeholder,
	disabled = undefined,
	id,
	control,
	error,
	width,
	autoFocus,
	prefix,
	textarea
}: IProps) => {
	return (
		<div
			style={{
				textAlign: 'left',
				position: 'relative',
				width: width ? width : undefined
			}}
		>
			{label && (
				<div
					className='unselectable'
					style={{
						whiteSpace: 'nowrap',
						fontSize: 16,
						fontWeight: 500,
						lineHeight: '16px',
						color: labelColor ? labelColor : '#696969',
						marginBottom: 8
					}}
				>
					{label}
				</div>
			)}

			<Controller
				name={name}
				control={control}
				rules={{ required: true }}
				render={({ field }) => (
					<InputGroup>
						{prefix && <InputGroup.Addon>{prefix}</InputGroup.Addon>}

						<Input
							componentClass={textarea ? 'textarea' : 'input'}
							rows={textarea && 3}
							id={id}
							{...field}
							disabled={disabled}
							autoFocus={autoFocus}
							placeholder={placeholder ? placeholder : 'Type Here'}
						/>
					</InputGroup>
				)}
			/>

			{error && (
				<div
					style={{
						position: 'absolute',

						left: 0,
						color: 'red',
						fontSize: 12
					}}
				>
					{error}
				</div>
			)}
		</div>
	)
}

export default SightlyInputForm
