import React from 'react'
import { ReactComponent as Close } from '../../assets/img/closeIcon.svg'

interface IProps {
	handleClose: Function
	text: string
	backgroundColor: string
	customButton: React.ReactNode
	closable: boolean
	loading: boolean
}

const SightlyMessage: Function = ({
	handleClose,
	text,
	backgroundColor,
	customButton,
	closable,
	loading
}: IProps) => {
	if (closable && !handleClose) {
		console.error(
			'SightlyMessage component must supply handleClose function if closable = true'
		)
	}

	return (
		<div
			style={{
				marginTop: 24,
				paddingTop: 12,
				paddingBottom: 12,
				borderRadius: 6,
				backgroundColor: backgroundColor,
				display: 'inline-flex',
				color: '#FFF',
				fontWeight: 600,
				fontSize: 14,
				paddingRight: 24,
				lineHeight: '22px',
				minWidth: 634
			}}
		>
			{closable && (
				<div
					onClick={() => handleClose()}
					style={{
						cursor: 'pointer',
						height: 16,
						width: 16,
						alignSelf: 'center',
						marginLeft: 28,
						position: 'relative'
					}}
				>
					{loading && (
						<div style={{ position: 'absolute', top: -7, left: -7 }}>
							<div className='spinning-loader'></div>
						</div>
					)}

					{loading === false && (
						<div style={{ position: 'absolute', top: -7, left: -7 }}>
							<div className='stationary-loader'></div>
						</div>
					)}

					<Close
						style={{
							height: 16,
							width: 16
						}}
					/>
				</div>
			)}
			<div
				style={{
					alignSelf: 'center',
					marginLeft: 22
				}}
			>
				{text}
			</div>
			{customButton && (
				<div style={{ marginLeft: 44, alignSelf: 'center' }}>
					{customButton}
				</div>
			)}
		</div>
	)
}

export default SightlyMessage
