import React from 'react'
import SightlyCheckbox from '../components/SightlyCheckbox'
import SightlySelect from '../components/Sightly/SightlySelect'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import { User, EmailFreqEnum, NotifPrefsEnum, NotificationAccount } from '../classes/user'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../axiosInstances'
import { rqKeys } from '../ReactQueryKeyFactory'
import { useMatch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../classes/utils/utils'
import { logError } from '../utils'
import useUser from '../services/useUser'

type FrequencyOption = {
    id: EmailFreqEnum
    name: string
    value: 0 | 1 | 7 | 30
}

const frequencyOptions: FrequencyOption[] = [
    { id: 1, name: 'Immediately', value: 0 },
    { id: 2, name: 'Daily', value: 1 },
    { id: 3, name: 'Weekly', value: 7 },
    { id: 4, name: 'Monthly', value: 30 }
]

const NotifTypeComponent = ({
    name,
    description,
    web,
    email,
    frequencyId,
    handleChangeEmail,
    handleChangeWeb,
    handleChangeFreq
}: {
    name: string
    description: string
    web: boolean
    email: boolean
    frequencyId: EmailFreqEnum
    handleChangeEmail: (val: boolean) => void
    handleChangeWeb: (val: boolean) => void
    handleChangeFreq: (val: EmailFreqEnum) => void
}) => {
    return (
        <div style={{ marginBottom: 23.48, display: 'flex' }}>
            <div style={{ width: 298 + 102 }}>
                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 17.39,
                        lineHeight: '27.33px',
                        color: '#292929'
                    }}
                >
                    {name}
                </div>

                <div
                    style={{
                        fontWeight: 500,
                        fontSize: 12.42,
                        lineHeight: '18.63px',
                        color: '#6E8399',
                        marginTop: 1.57
                    }}
                >
                    {description}
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 400
                }}
            >
                <div style={{ marginLeft: 7 }}>
                    <SightlyCheckbox
                        id={'web' + name.replace(/\s/gm, '')}
                        checked={web}
                        handleChange={(val: boolean) => handleChangeWeb(val)}
                        size="17"
                    />
                </div>
                <div style={{ marginLeft: 67 }}>
                    <SightlyCheckbox
                        id={'email' + name.replace(/\s/gm, '')}
                        checked={email}
                        handleChange={(val: boolean) => handleChangeEmail(val)}
                        size="17"
                    />
                </div>

                {email && (
                    <div style={{ marginLeft: 43 }}>
                        <SightlySelect
                            width={180}
                            id={`frequencyOptionsSelect${name.replace(/\s/gm, '')}`}
                            options={frequencyOptions}
                            value={frequencyId}
                            labelKey="name"
                            valueKey="id"
                            onChange={(val: EmailFreqEnum) => handleChangeFreq(val)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const NotificationPreferences = () => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const QUERY_KEY = rqKeys.userKey(Number(user?.userId))
    const userAccounts = user?.accounts

    type UserPrefs = {
        accountIds: number[] | undefined
        prefs: User['notificationPreferences'] | undefined
    }

    const queryClient = useQueryClient()

    const mutatePrefs = useMutation(
        (prefs: UserPrefs) => {
            const payload = {
                accountIds: prefs.accountIds,
                notificationPreferences: prefs.prefs
            }

            const url = `/notification-preferences`
            return userAccountAxios.patch(url, payload)
        },
        {
            onMutate: args => {
                queryClient.setQueryData(QUERY_KEY, (old: any) => {
                    old.notificationPreferences = args.prefs
                    old.notificationAccounts = args?.accountIds?.map((accountId: number) => {
                        return { accountId, accountName: 'test' }
                    })
                    return old
                })
            },
            onError: () => {
                toast.error('An unexpected error ocurred. Please try again.')
            },
            onSuccess: () => {
                toast.success('Success, your changes were saved')
            }
        }
    )

    const handleChange = (property: NotifPrefsEnum, value: any) => {
        const prefs = JSON.parse(JSON.stringify(user?.notificationPreferences))
        prefs[property] = value

        const fin = {
            accountIds: user?.notificationAccounts.map((a: NotificationAccount) => {
                return a.accountId
            }),
            prefs: prefs
        }

        mutatePrefs.mutate(fin)
    }

    const handleSelectAccount = (accountIds: number[]) => {
        const prefs = {
            prefs: user?.notificationPreferences,
            accountIds: accountIds
        }

        mutatePrefs.mutate(prefs)
    }

    return (
        <div
            style={{
                marginTop: 36,
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div style={{ width: 1015 }}>
                <div
                    style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '24px',
                        color: '#000000'
                    }}
                >
                    Notifications
                </div>

                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 24,
                        lineHeight: '32px',
                        color: '#333D47',
                        marginTop: 37
                    }}
                >
                    Choose when and how to be notified
                </div>

                <div
                    style={{
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: '18px',
                        color: '#6E8399',
                        marginTop: 9
                    }}
                >
                    Select the platform and email notifications you’d like to receive
                </div>

                <div
                    style={{
                        height: 1,
                        opacity: 0.5,
                        width: '100%',
                        backgroundColor: '#D4D9D9',
                        marginTop: 16
                    }}
                />

                <div
                    style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '24px',
                        color: '#000000',
                        marginTop: 11
                    }}
                >
                    Accounts
                </div>

                <div
                    style={{
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: '18px',
                        color: '#6E8399',
                        marginTop: 8,
                        marginBottom: 16
                    }}
                >
                    Select the accounts you want to be notified about
                </div>

                <CheckTreePicker
                    id="notifAccountsPicker"
                    virtualized
                    value={user?.notificationAccounts?.map((a: NotificationAccount) => {
                        return a.accountId
                    })}
                    data={userAccounts || []}
                    labelKey={'accountName'}
                    valueKey={'accountId'}
                    onChange={handleSelectAccount}
                    cascade={true}
                    preventOverflow={true}
                    style={{
                        width: 488,
                        borderRadius: 3
                    }}
                    placeholder="Select Accounts"
                    defaultExpandAll={true}
                />

                <div
                    style={{
                        height: 1,
                        opacity: 0.5,
                        width: '100%',
                        backgroundColor: '#D4D9D9',
                        marginTop: 16
                    }}
                />

                <div
                    style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '24px',
                        color: '#000000',
                        marginTop: 16
                    }}
                >
                    General
                </div>

                <div
                    style={{
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: '18px',
                        color: '#6E8399',
                        marginTop: 8
                    }}
                >
                    Manage your platform and email notifications
                </div>

                <div
                    style={{
                        display: 'flex',
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '25.5px',
                        marginTop: 16,
                        marginLeft: 298 + 102,
                        marginBottom: 14.5,
                        color: '#292929'
                    }}
                >
                    <div>Platform</div>
                    <div style={{ marginLeft: 27.82 }}>Email</div>
                    <div style={{ marginLeft: 27.82 }}>Email Frequency</div>
                </div>

                {userCan(perms.NOTIFICATION_TYPE_BP_QUESTIONS) && (
                    <NotifTypeComponent
                        name="Brand Profile Questions"
                        description="Notify me about new questions in my brand profile"
                        web={user ? user.notificationPreferences?.questionsWeb : false}
                        email={user ? user.notificationPreferences?.questionsEmail : false}
                        frequencyId={user ? user.notificationPreferences.questionsFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['questionsWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['questionsEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['questionsFrequency'], val)}
                    />
                )}

                {userCan(perms.NOTIFICATION_TYPE_SMARTLIST_ENRICHMENT) && (
                    <NotifTypeComponent
                        name="Smartlist Enrichment"
                        description="Notify me when my SmartList is ready to edit and view"
                        web={user ? user.notificationPreferences.enrichmentWeb : false}
                        email={user ? user.notificationPreferences.enrichmentEmail : false}
                        frequencyId={user ? user.notificationPreferences.enrichmentFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['enrichmentWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['enrichmentEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['enrichmentFrequency'], val)}
                    />
                )}

                {userCan(perms.NOTIFICATION_TYPE_NEW_MOMENT_BATCHES) && (
                    <NotifTypeComponent
                        name="Moments"
                        description="Notify me about new moments available to review"
                        web={user ? user.notificationPreferences.newTrendsWeb : false}
                        email={user ? user.notificationPreferences.newTrendsEmail : false}
                        frequencyId={user ? user.notificationPreferences.newTrendsFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['newTrendsWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['newTrendsEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['newTrendsFrequency'], val)}
                    />
                )}

                {userCan(perms.NOTIFICATION_TYPE_BP_CREATED) && (
                    <NotifTypeComponent
                        name="New Brand Profile Created"
                        description="Notify me about new brand profiles created"
                        web={user ? user.notificationPreferences.brandProfileCreatedWeb : false}
                        email={user ? user.notificationPreferences.brandProfileCreatedEmail : false}
                        frequencyId={user ? user.notificationPreferences.brandProfileCreatedFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['brandProfileCreatedWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['brandProfileCreatedEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['brandProfileCreatedFrequency'], val)}
                    />
                )}

                {userCan(perms.NOTIFICATION_TYPE_BP_SUBMITTED) && (
                    <NotifTypeComponent
                        name="Brand Profile Submitted"
                        description="Notify me about submitted brand profiles"
                        web={user ? user.notificationPreferences.brandProfileSubmittedWeb : false}
                        email={user ? user.notificationPreferences.brandProfileSubmittedEmail : false}
                        frequencyId={user ? user.notificationPreferences.brandProfileSubmittedFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['brandProfileSubmittedWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['brandProfileSubmittedEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['brandProfileSubmittedFrequency'], val)}
                    />
                )}

                {userCan(perms.NOTIFICATION_TYPE_USER_CREATED) && (
                    <NotifTypeComponent
                        name="New User Created"
                        description="Notify me about new users created"
                        web={user ? user.notificationPreferences.userCreatedWeb : false}
                        email={user ? user.notificationPreferences.userCreatedEmail : false}
                        frequencyId={user ? user.notificationPreferences.userCreatedFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['userCreatedWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['userCreatedEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['userCreatedFrequency'], val)}
                    />
                )}

                {userCan(perms.NOTIFICATION_TYPE_ACCOUNT_ADDED) && (
                    <NotifTypeComponent
                        name="New Account Created"
                        description="Notify me about new accounts created"
                        web={user ? user.notificationPreferences.accountCreatedWeb : false}
                        email={user ? user.notificationPreferences.accountCreatedEmail : false}
                        frequencyId={user ? user.notificationPreferences.accountCreatedFrequency : 1}
                        handleChangeWeb={val => handleChange(NotifPrefsEnum['accountCreatedWeb'], val)}
                        handleChangeEmail={val => handleChange(NotifPrefsEnum['accountCreatedEmail'], val)}
                        handleChangeFreq={val => handleChange(NotifPrefsEnum['accountCreatedFrequency'], val)}
                    />
                )}
            </div>
        </div>
    )
}

export default NotificationPreferences
