import React, { useState } from 'react'
import { connect } from 'react-redux'
import { changePassword } from '../redux/actions/auth'
import { routes } from '../routes'
import toast from 'react-hot-toast'
import * as v from '../validations'
import CustomPassword from '../components/CustomPasswordRequirements/CustomPasswordRequirements.js'
import CustomPasswordMatchChecker from '../components/CustomPasswordRequirements/CustomPasswordMatchChecker'
import SightlyButton from '../components/SightlyButton'
import Input from 'rsuite/lib/Input'
import { Navigate, useMatch } from '@tanstack/react-location'

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        alert: state.alert,
        updatingPassword: state.updatingPassword
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (password, userId, token) => dispatch(changePassword(password, userId, token))
    }
}

function PasswordChange(props) {
    const {
        params: { userId, token }
    } = useMatch()
    //let userId = props.match.params.userId

    //let token = props.match.params.token

    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')

    async function postChangePassword() {
        if (password === password_confirmation) {
            props.changePassword(password, userId, token)
        } else {
            toast.error('Passwords do not match.')
        }
    }

    const passwordIsValid = () => {
        let isValid = true
        for (var prop of v.invalidPasswordObject(password)) {
            if (!prop.satisfied) {
                isValid = false
                break
            }
        }
        if (password === password_confirmation) return isValid
        else return false
    }

    if (props.isLoggedIn) {
        return <Navigate to={routes.app.settings.brandProfiles.path} />
    }

    return (
        <div
            style={{
                backgroundColor: '#F0F3F5',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    marginTop: 134,
                    maxWidth: 526,
                    textAlign: 'center',
                    flex: 1,
                    height: '100%'
                }}
            >
                <div className="input-label">New Password</div>
                <Input id="newPasswordInput" value={password} onChange={e => setPassword(e)} />
                <div style={{ marginTop: 24 }} className="input-label">
                    Confirm New Password
                </div>
                <Input
                    id="confirmPasswordInput"
                    value={password_confirmation}
                    onChange={e => setPasswordConfirmation(e)}
                    style={{ marginBottom: 24 }}
                />

                <CustomPassword password={password} />
                <div style={{ height: 6 }} />
                <CustomPasswordMatchChecker password={password} password_confirmation={password_confirmation} />
                <div style={{ height: 24 }} />

                <SightlyButton
                    id="changePasswordButton"
                    block
                    handleClick={postChangePassword}
                    text={props.updatingPassword ? 'Updating...' : 'Change Password'}
                    disabled={!passwordIsValid() || props.updatingPassword}
                />
            </div>
        </div>
    )
}

const ChangePassword = connect(mapStateToProps, mapDispatchToProps)(PasswordChange)

export default ChangePassword
