import React from 'react'
import { routes } from '../../../routes'
import ProfileDropdown from '../../../components/ProfileDropdown'
import { sightlyBlue } from '../../../assets/jss/colorContants'
import './../index.css'
import config from '../../../config'
import HorizontalNavAccountItem from './HorizontalNavAccountItem'
import { useLocation, useNavigate } from '@tanstack/react-location'
import useUser from '../../../services/useUser'

const verticals = {
    profile: 'profile',
    accountOverview: 'accountOverview',
    outcomes: 'outcomes',
    admin: 'admin'
}

function HorizontalNavbar() {
    const navigate = useNavigate()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const location = useLocation()

    const activeVertical = React.useMemo(() => {
        if (!location || !location.current) return ''
        if (
            location.current.pathname.includes('/app/settings/profile') ||
            location.current.pathname.includes(routes.app.settings.notificationPreferences.path)
        ) {
            return verticals.profile
        }
        if (location.current.pathname.includes('/app/settings')) {
            return verticals.accountOverview
        }
        if (
            location.current.pathname.includes(routes.app.discover.trends.path) ||
            location.current.pathname.includes(routes.app.discover.boards.path) ||
            location.current.pathname.includes('lists') ||
            location.current.pathname.includes(routes.app.discover.measure.path) ||
            location.current.pathname.includes(routes.app.discover.reporting.path) ||
            location.current.pathname.includes(routes.app.discover.momentsIntelligence.path)
        ) {
            return verticals.outcomes
        }
        if (location.current.pathname.includes('/admin')) {
            return verticals.admin
        }
    }, [location.current.pathname])

    const _tabs = [
        {
            route: routes.app.discover.trends.path,
            label: 'Anticipation Tool',
            perm: perms.TRENDS_READ,
            section: verticals.outcomes
        },
        {
            route: routes.app.discover.boards.path,
            label: 'Boards',
            perm: perms.AYLIEN_TRENDS_READ,
            section: verticals.outcomes
        },
        {
            route: routes.app.engage.lists.lists.path,
            label: 'SmartLists',
            perm: perms.SMARTLIST_READ,
            section: verticals.outcomes
        },
        {
            route: routes.app.discover.reporting.path,
            label: 'Reporting',
            perm: perms.REPORTING_READ,
            section: verticals.outcomes,
            prodOnly: true
        },
        {
            route: routes.app.discover.momentsIntelligence.path,
            label: 'Moments Intelligence',
            perm: perms.MOMENTS_INTELLIGENCE_READ,
            section: verticals.outcomes
        },
        {
            route: routes.app.discover.measure.path,
            label: 'Measure',
            perm: perms.MEASURE_READ,
            section: verticals.outcomes,
            demoOnly: true
        },

        {
            route: routes.app.settings.brandProfiles.path,
            label: 'Brand Profiles',
            perm: perms.BRAND_PROFILES_PAGE_READ,
            section: verticals.accountOverview
        },
        {
            route: routes.app.settings.account.path,
            label: 'Account Details',
            perm: perms.ACCOUNT_READ,
            section: verticals.accountOverview
        },

        {
            route: routes.app.settings.users.path,
            label: 'Users',
            perm: perms.USER_READ,
            section: verticals.accountOverview
        },
        {
            route: routes.admin.roles.path,
            label: 'Roles',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.admin.permissions.path,
            label: 'Permissions',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.admin.scenarios.path,
            label: 'Scenarios',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.admin.opinions.path,
            label: 'Opinions',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.admin.questions.path,
            label: 'Questions',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.admin.iabCategories.path,
            label: 'IAB Categories',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.admin.evergreenChannels.path,
            label: 'Evergreen Channels',
            perm: perms.ADMIN_READ,
            section: verticals.admin
        },
        {
            route: routes.app.settings.profile.path,
            label: 'Profile',
            perm: true,
            section: verticals.profile
        },
        {
            route: routes.app.settings.notificationPreferences.path,
            label: 'Notifications',
            perm: perms.NOTIFICATIONS_READ,
            section: verticals.profile
        }
    ]

    const tabs = React.useMemo(() => {
        return config.isDemo
            ? _tabs.filter(t => !t.prodOnly && (userCan(t.perm) || t.perm === true) && t.section === activeVertical)
            : _tabs.filter(t => !t.demoOnly && (userCan(t.perm) || t.perm === true) && t.section === activeVertical)
    }, [activeVertical, userCan, config.isDemo])

    return (
        <div style={{ backgroundColor: sightlyBlue }}>
            <div
                style={{
                    height: 56,
                    backgroundColor: 'white',
                    borderTopLeftRadius: 12,
                    display: 'flex',
                    paddingLeft: 24,
                    paddingRight: 24
                }}
            >
                <HorizontalNavAccountItem accountName={currentAccount?.accountName} />

                {tabs.map(_tab => {
                    return (
                        <div
                            className={
                                location.current.pathname.includes(_tab.route) ? 'Tabs__Tab--active' : 'Tabs__Tab'
                            }
                            key={_tab.route}
                            onClick={() =>
                                navigate({
                                    to: _tab.route
                                })
                            }
                        >
                            {_tab.label}
                        </div>
                    )
                })}

                <div
                    style={{
                        marginLeft: 'auto'
                    }}
                >
                    <ProfileDropdown />
                </div>
            </div>
            <div style={{ width: '100%', height: 1, backgroundColor: '#f2f2f5' }} />
        </div>
    )
}

export default HorizontalNavbar
