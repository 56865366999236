/* eslint-disable semi, indent, no-mixed-operators, no-underscore-dangle */
import React from 'react'
import Button from 'rsuite/lib/Button'
import debounce from 'just-debounce-it'
import Icon from 'rsuite/lib/Icon'
import IconButton from 'rsuite/lib/IconButton'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import { filteredRolesPermissionsInfo } from './userUtils'
import RolesInfoFullScreen from './RolesInfoFullScreen.js'
import FormGroup from 'rsuite/lib/FormGroup'
import Input from 'rsuite/lib/Input'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import SelectPicker from 'rsuite/lib/SelectPicker'
import { isValidEmail } from '../../validations'
import toast from 'react-hot-toast'
import { joinClasses } from '../../utils'
import { userAccountAxios } from '../../axiosInstances'
import useRoles from '../../services/useRoles'
import { useMatch } from '@tanstack/react-location'
import useUser from '../../services/useUser'

const createUser = async user => {
    user.userName = 'placeholder'
    user.phoneNumber = '123123123'
    delete user.userId
    delete user.internal
    const url = `/user/invite`
    return await userAccountAxios.post(url, user)
}

function CreateUser() {
    const { data: roles } = useRoles()

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const [openDialog, setOpenDialog] = React.useState(false)
    const handleDialog = value => {
        setOpenDialog(value)
    }

    const [userIsAdding, setUserIsAdding] = React.useState(false)

    const handleInviteUserClick = async () => {
        setUserIsAdding(true)
        if (!formObject.email.toLowerCase().includes('sightly.com') && formObject.accounts.includes(1)) {
            alert(
                'Currently we are unable to add external users to the Sightly account. Please select another account and try again.'
            )

            setUserIsAdding(false)

            return
        }

        let accountsToLink = []
        for (const account of formObject.accounts) {
            accountsToLink.push({ accountId: account })
        }

        let type = formObject.email.toLowerCase().includes('sightly.com') ? 'Internal' : 'External'

        let newUser = {
            firstName: formObject.firstName,
            lastName: formObject.lastName,
            company: formObject.company,
            email: formObject.email,
            userType: type,
            roleId: formObject.roleId,
            accounts: accountsToLink
        }

        const response = await createUser(newUser).catch(err => {
            setUserIsAdding(false)
            return
        })
        if (response?.status === 200) {
            toast.success('User invited!')
        }

        setUserIsAdding(false)
    }

    const [formObject, setFormObject] = React.useState({
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
        accounts: []
    })

    const isClean = React.useMemo(() => {
        if (formObject.firstName?.length < 1) {
            return false
        }
        if (!isValidEmail(formObject.email)) {
            return false
        }
        if (formObject.lastName?.length < 1) {
            return false
        }
        if (formObject.accounts?.length < 1) {
            return false
        }
        if (formObject.roleId?.length < 1) {
            return false
        }
        if (formObject.company?.length < 1) {
            return false
        }

        return true
    }, [formObject])

    const handleChange = debounce((property, value) => {
        switch (property) {
            case 'company':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        company: value
                    }
                    return newState
                })
                break

            case 'firstName':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        firstName: value
                    }
                    return newState
                })
                break

            case 'lastName':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        lastName: value
                    }
                    return newState
                })
                break

            case 'email':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        email: value
                    }
                    return newState
                })
                break

            case 'roleId':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        roleId: value
                    }
                    return newState
                })
                break

            case 'accounts':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        accounts: value
                    }
                    return newState
                })
                break

            default:
                break
        }
    }, 100)

    const { email } = formObject
    const roleOptions = React.useMemo(() => {
        if (!roles || !user) return []

        if (!email.toLowerCase().includes('@sightly.com')) {
            return roles.filter(role => role.userType === 'External')
        }

        if (user.userType === 'External') {
            return roles.filter(role => role.userType === 'External')
        }

        if (user.roleId === 1) {
            return roles
        }

        return roles.filter(role => role.roleId != 1 && role.userType === 'Internal')
    }, [roles, user, email])

    React.useEffect(() => {
        handleChange('roleId', '')
    }, [email])

    return (
        <>
            <div style={{ width: 500, margin: 'auto' }}>
                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Company
                    </div>
                    <Input onChange={val => handleChange('company', val)} />
                </FormGroup>

                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Email
                    </div>
                    <Input onChange={val => handleChange('email', val)} />
                </FormGroup>

                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        First Name
                    </div>
                    <Input onChange={val => handleChange('firstName', val)} />
                </FormGroup>
                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Last Name
                    </div>
                    <Input onChange={val => handleChange('lastName', val)} />
                </FormGroup>

                <FormGroup style={{ marginTop: 27 }}>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Account
                    </div>
                    <CheckTreePicker
                        block
                        virtualized={true}
                        defaultExpandAll
                        data={accounts}
                        labelKey={'accountName'}
                        valueKey={'accountId'}
                        onChange={val => handleChange('accounts', val)}
                        cascade={true}
                        preventOverflow={true}
                    />
                </FormGroup>

                <FormGroup>
                    <div className={joinClasses('input-label', 'isRequired')} style={{ marginTop: 24 }}>
                        Role
                    </div>
                    <div
                        style={{
                            position: 'relative'
                        }}
                    >
                        <SelectPicker
                            disabled={formObject.email.length < 1}
                            id={'role'}
                            labelKey={'roleNameExtended'}
                            valueKey={'roleId'}
                            placeholder={'Role'}
                            value={formObject.roleId}
                            data={roleOptions}
                            renderMenuItem={(label, item) => {
                                return <div>{label}</div>
                            }}
                            onChange={val => handleChange('roleId', val)}
                            cleanable={false}
                            style={{ width: '100%' }}
                            preventOverflow={true}
                        />

                        <div style={{ position: 'absolute', right: -60, top: -10 }}>
                            <Whisper
                                delayShow={700}
                                delayHide={0}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>More about Roles/Permissions</Tooltip>}
                            >
                                <IconButton
                                    icon={<Icon icon="info" />}
                                    circle
                                    size="md"
                                    appearance="ghost"
                                    onClick={() => {
                                        handleDialog(true)
                                    }}
                                    style={{ margin: '10px' }}
                                />
                            </Whisper>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup style={{ marginTop: 27 }}>
                    <Button disabled={!isClean} onClick={handleInviteUserClick} loading={userIsAdding}>
                        Invite User
                    </Button>
                </FormGroup>
            </div>

            <RolesInfoFullScreen
                show={openDialog}
                title="Roles and Permissions"
                handleDialog={value => {
                    handleDialog(value)
                }}
                data={filteredRolesPermissionsInfo(user && user.userType, roles)}
                userType={user && user.userType}
            />
        </>
    )
}

export default CreateUser
