import React from 'react'
import Input from 'rsuite/lib/Input'
import IconButton from 'rsuite/lib/IconButton'
import TagGroup from 'rsuite/lib/TagGroup'
import Tag from 'rsuite/lib/Tag'
import Icon from 'rsuite/lib/Icon'
import InputGroup from 'rsuite/lib/InputGroup'
import toast from 'react-hot-toast'

//change item  to item.keyword

export default class DynamicTag extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            typing: false,
            inputValue: '',
            tags: [],
            setTags: false
        }
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputConfirm = this.handleInputConfirm.bind(this)
    }

    componentWillReceiveProps(newProps) {
        if (!this.state.setTags && newProps?.items?.length > 0) {
            this.setState({ setTags: true, tags: newProps.items })
        }
    }

    handleButtonClick() {
        this.setState(
            {
                typing: true
            },
            () => {
                this.input.focus()
            }
        )
    }
    handleInputChange(inputValue) {
        this.setState({ inputValue })
    }
    handleInputConfirm() {
        const { inputValue, tags } = this.state
        const nextTags = inputValue ? [...tags, { keyword: inputValue, archived: false }] : tags
        this.setState({
            tags: nextTags,
            typing: false,
            inputValue: ''
        })
        this.props.saveChanges(nextTags)
    }
    handleTagRemove(tag) {
        if (this.props.disregardLeafNode && this.props.items.filter(t => !t.archived).length === 1) {
            toast.error('You cannot remove the last search term while "Remove Original IAB Term" is checked.')
            return
        }
        const { tags } = this.state

        for (const _tag of tags) {
            if (tag.keyword === _tag.keyword) {
                _tag.archived = true
            }
        }
        this.setState({
            tags
        })
        this.props.saveChanges(tags)
    }
    renderInput() {
        const { typing, inputValue } = this.state

        if (typing) {
            return (
                <div style={{ display: 'inline-block' }}>
                    <InputGroup
                        size="xs"
                        style={{
                            width: 100,
                            //	display: 'inline-block',
                            margin: '10px'
                        }}
                    >
                        <Input
                            className="tag-input"
                            inputRef={ref => {
                                this.input = ref
                            }}
                            size="xs"
                            style={{
                                width: 70
                            }}
                            value={inputValue}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm}
                            onPressEnter={this.handleInputConfirm}
                            disabled={this.props.disabled}
                        />
                        <InputGroup.Button
                            appearance="link"
                            size="xs"
                            onClick={this.handleInputConfirm}
                            disabled={this.props.disabled}
                        >
                            <Icon icon="save" />
                        </InputGroup.Button>
                    </InputGroup>
                </div>
            )
        }

        return (
            <IconButton
                style={{
                    display: 'inline-block',
                    margin: '10px'
                }}
                onClick={this.handleButtonClick}
                icon={<Icon icon="plus" />}
                appearance="ghost"
                size="xs"
                disabled={this.props.disabled}
            >
                {' '}
                Click to add
            </IconButton>
        )
    }
    render() {
        const { tags } = this.state
        return (
            <TagGroup style={{ margin: 0 }} id={this.props.id + 'tagGroup'}>
                {tags
                    .filter(kw => !kw.archived)
                    .map((item, index) => (
                        <Tag
                            style={{
                                backgroundColor: this.props.backgroundColor,
                                color: this.props.textColor
                            }}
                            key={index + this.props.id}
                            closable={
                                !(
                                    this.props.disregardLeafNode &&
                                    this.props.items.filter(t => !t.archived).length === 1
                                )
                            }
                            onClose={() => {
                                this.handleTagRemove(item)
                            }}
                        >
                            {item.keyword}
                        </Tag>
                    ))}
                {this.renderInput()}
            </TagGroup>
        )
    }
}
