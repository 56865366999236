import {
	SET_ADMIN_IAB_CATEGORIES,
	SET_ADMIN_IAB_CATEGORIES_LOADING
} from '../../action-types/admin/iabCategories'
import * as Yup from 'yup'

import toast from 'react-hot-toast'
import { userAccountAxios } from '../../../axiosInstances'

export const adminIabCategoriesObj = Yup.array()
	.min(1, 'should have at least one iab cat')
	.of(
		Yup.object().shape({
			disregardLeafNode: Yup.bool().required(),
			iabCategoryId: Yup.string().required(),
			iabCategoryName: Yup.string().required(),
			keywords: Yup.array()
				.of(
					Yup.object().shape({
						keyword: Yup.string().required(),
						archived: Yup.bool().required()
					})
				)
				.defined()
		})
	)

export function setAdminIabCategories(adminIabCategories) {
	return {
		type: SET_ADMIN_IAB_CATEGORIES,
		adminIabCategories
	}
}

export function setAdminIabCategoriesLoading(adminIabCategoriesLoading) {
	return {
		type: SET_ADMIN_IAB_CATEGORIES_LOADING,
		adminIabCategoriesLoading
	}
}

export function fetchAdminIabCategories() {
	let url =  `/iab-categories/leaf-nodes`
	return async (dispatch) => {
		dispatch(setAdminIabCategoriesLoading(true))
		try {
			const result = await userAccountAxios.get(url)
			if (result.status === 200) {
				let iabCategories = result.data

				adminIabCategoriesObj.validate(iabCategories).catch(function (err) {
					console.log(err.name, err.errors)
					console.error(
						'We received different API data than expected while fetching admin Iab Category keywords, see the console log for more details.'
					)
				})

				dispatch(setAdminIabCategories(iabCategories))
				dispatch(setAdminIabCategoriesLoading(false))
			}
		} catch (error) {
			console.error(error)
			dispatch(setAdminIabCategoriesLoading(false))
		}
	}
}

export const mapIabCategoryToKeywords = (iabCategoryId, keywords) => {
	let url =  `/iab-categories/${iabCategoryId}/keywords`
	return async (dispatch, getState) => {
		userAccountAxios
			.patch(url, keywords)
			.then((response) => {
				toast.success('Success', { duration: 1000 })
			})
			.catch((error) => {
				console.log(error)
				toast.error(error.response.data.message)
			})
	}
}

export const disregardIabCategoryLeafNode = (iabCategoryId, checked, index) => {
	let url =  `/iab-categories/${iabCategoryId}/disregard-leaf-node`
	return async (dispatch, getState) => {
		userAccountAxios
			.patch(url)
			.then((response) => {
				toast.success('Success', { duration: 1000 })
			})
			.catch((error) => {
				console.log(error)
				toast.error(error.response.data.message)
			})
	}
}
