import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from 'rsuite/lib/Button'
import { resetPassword } from '../redux/actions/auth.js'
import { isEmailError } from '../validations'
import Form from 'rsuite/lib/Form'
import FormGroup from 'rsuite/lib/FormGroup'
import ControlLabel from 'rsuite/lib/ControlLabel'
import logo from '../assets/img/sightly-logo.svg'
import { accentColor } from '../assets/jss/colorContants.js'
import Input from 'rsuite/lib/Input'
import { Link, useNavigate } from '@tanstack/react-location'

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        resettingPassword: state.resettingPassword,
        alert: state.alert
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: email => dispatch(resetPassword(email))
    }
}

function PasswordReset(props) {
    const [email, setEmail] = useState('')
    async function postResetPassword() {
        props.resetPassword(email)
    }

    return (
        <div style={{ width: 400, margin: 'auto' }}>
            <Form>
                <FormGroup style={{ textAlign: 'center' }}>
                    <img
                        src={logo}
                        alt="logo"
                        style={{
                            width: '130px',
                            verticalAlign: 'middle',
                            border: '0',
                            margin: '90px 0px 60px 0px'
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <Input type="email" value={email} onChange={e => setEmail(e)} />
                </FormGroup>

                <FormGroup>
                    <Button
                        block
                        loading={props.resettingPassword}
                        disabled={!email || isEmailError(email)}
                        onClick={postResetPassword}
                    >
                        Reset Password
                    </Button>
                </FormGroup>

                <FormGroup>
                    <Link to="/login">&larr; Login</Link>
                </FormGroup>
            </Form>
        </div>
    )
}

const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(PasswordReset)

export default ResetPassword
