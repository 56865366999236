import {
	SET_ADMIN_SCENARIOS_IS_LOADING,
	SET_ADMIN_BRAND_SCENARIOS,
	SET_SCENARIO_LABELS,
	SET_SCENARIO_LABELS_IS_LOADING,
	SET_SCENARIO_SAVING,
	SET_ADD_SCENARIO,
	SET_UPDATED_SCENARIO,
	SET_ADMIN_LABELS,
	SET_LABELS_IS_LOADING,
	SET_LABEL_ARCHIVING,
	SET_LABEL_TO_ARCHIVE,
	SET_LABEL_SAVING,
	SET_ADD_LABEL,
	SET_LABEL_TO_CREATE
} from '../../action-types/admin/scenarios'
import { brandScenarioObjValidation } from '../../../schemas/schemas'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../../axiosInstances'

export function setAdminScenariosIsLoading(bool) {
	return {
		type: SET_ADMIN_SCENARIOS_IS_LOADING,
		adminScenariosIsLoading: bool
	}
}

export function setScenariosLabelsIsLoading(bool) {
	return {
		type: SET_SCENARIO_LABELS_IS_LOADING,
		scenariosLabelsIsLoading: bool
	}
}

export function setAdminBrandScenarios(scenarios) {
	return {
		type: SET_ADMIN_BRAND_SCENARIOS,
		scenarios
	}
}

export function setScenarioLabels(scenarioLabels) {
	return {
		type: SET_SCENARIO_LABELS,
		scenarioLabels
	}
}

export function setScenarioSaving(bool) {
	return {
		type: SET_SCENARIO_SAVING,
		scenarioSaving: bool
	}
}

export function addScenario(scenario) {
	return {
		type: SET_ADD_SCENARIO,
		scenario
	}
}

export function setUpdatedScenario(scenario) {
	return {
		type: SET_UPDATED_SCENARIO,
		scenario
	}
}

export const updateScenario = (scenarioId, scenario) => {
	let url = `/scenarios/${scenarioId}`
	return (dispatch, getState) => {
		userAccountAxios
			.patch(url, scenario)
			.then((response) => {
				dispatch(setUpdatedScenario(scenario))
				toast.success('Scenario updated!')
			})
			.catch((error) => {
				//error
			})
	}
}

export const createScenario = (scenario) => {
	let url = `/scenarios`
	return (dispatch, getState) => {
		dispatch(setScenarioSaving(true))
		userAccountAxios
			.post(url, scenario)
			.then((response) => {
				if (response.status == 200) {
					dispatch(addScenario(response.data[0]))
					dispatch(setScenarioSaving(false))
					toast.success('Scenario created!')
				} else if (response.response.status === 403) {
					toast.error(response.response.data.message)
					dispatch(setScenarioSaving(false))
				}
			})
			.catch((error) => {
				toast.error(error?.response?.data)
				dispatch(setScenarioSaving(false))
			})
	}
}

export function fetchAdminBrandScenarios() {
	let url = `/scenarios`
	return async (dispatch) => {
		dispatch(setAdminScenariosIsLoading(true))
		try {
			const result = await userAccountAxios.get(url)
			if (result.status === 200) {
				let scenarios = result.data

				brandScenarioObjValidation.validate(scenarios).catch(function (err) {
					console.log(err.name, err.errors)
					console.error(
						'We received different API data than expected, see the console log for more details.'
					)
				})

				dispatch(setAdminBrandScenarios(scenarios))
				dispatch(setAdminScenariosIsLoading(false))
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export function fetchAdminBrandScenarioLabels() {
	let url = `/scenarios/labels`
	return async (dispatch) => {
		dispatch(setScenariosLabelsIsLoading(true))
		try {
			const result = await userAccountAxios.get(url)
			if (result.status === 200) {
				let scenarioLabels = result.data
				dispatch(setScenarioLabels(scenarioLabels))
				dispatch(setScenariosLabelsIsLoading(false))
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export function setAdminLabels(labels) {
	return {
		type: SET_ADMIN_LABELS,
		labels
	}
}

export function setLabelArchiving(labelId) {
	return {
		type: SET_LABEL_ARCHIVING,
		labelArchiving: labelId
	}
}

export function setLabelToArchived(labelId) {
	return {
		type: SET_LABEL_TO_ARCHIVE,
		labelId
	}
}

export function setInitLabelAdd(bool) {
	return {
		type: SET_LABEL_TO_CREATE,
		initLabelAdd: bool
	}
}

export function setLabelSaving(bool) {
	return {
		type: SET_LABEL_SAVING,
		labelSaving: bool
	}
}

export function addLabel(label) {
	return {
		type: SET_ADD_LABEL,
		label
	}
}

export function setLabelsIsLoading(bool) {
	return {
		type: SET_LABELS_IS_LOADING,
		labelsIsLoading: bool
	}
}

export function setLabels(labels) {
	return {
		type: SET_ADMIN_LABELS,
		labels
	}
}

export function fetchAdminLabels() {
	let url = `/scenarios/labels`
	return async (dispatch) => {
		dispatch(setLabelsIsLoading(true))
		try {
			const result = await userAccountAxios.get(url)
			if (result.status === 200) {
				let labels = result.data
				dispatch(setLabels(labels))
				dispatch(setLabelsIsLoading(false))
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export const createLabel = (label) => {
	let url = `/scenarios/labels`
	return (dispatch, getState) => {
		dispatch(setLabelSaving(true))
		userAccountAxios
			.post(url, label)
			.then((response) => {
				dispatch(addLabel(response.data[0]))
				dispatch(setLabelSaving(false))
				toast.success('Label Created')
				dispatch(setInitLabelAdd(false))
			})
			.catch((error) => {
				toast.error(error.response.data.message)
			})
	}
}

export const archiveLabel = (labelId) => {
	let url = `/scenarios/labels/${labelId}`
	return (dispatch) => {
		dispatch(setLabelArchiving(labelId))
		userAccountAxios
			.delete(url)
			.then((response) => {
				dispatch(setLabelToArchived(labelId))
				dispatch(setLabelArchiving(''))
				toast.success('Label Archived')
			})
			.catch((error) => {
				toast.error(error.response.data.message)
				console.error(error)
			})
	}
}
