import {
    HAS_BRAND_PROFILES,
    SET_BRAND_PROFILE_LOADING,
    SET_BRAND_PROFILE_SAVING,
    SET_BRAND_PROFILE_SAVED,
    SCENARIOS_IS_LOADING,
    SET_BRAND_PROFILE_UNDER_EDIT,
    SET_BRAND_PROFILE_IAB_CATEGORIES,
    SET_BRAND_PROFILE_OUTCOMES,
    SET_BRAND_PROFILE_TOPICS,
    SET_BRAND_PROFILE_BASIC_INFO,
    SET_BRAND_PROFILE_IOS,
    SET_DOWNLOADING_BRAND_PROFILE_ID,
    SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING,
    SET_IS_DOWNLOADING_BRAND_PROFILE,
    SET_BRAND_PROFILE_SUBMITTING,
    SET_BRAND_PROFILE_SUBMITTED,
    SET_BRAND_PROFILE_TOPICS_LOADING,
    ADD_OUTCOME,
    SET_OUTCOME_METRIC_OPTIONS
} from '../action-types/brandProfiles'
import toast from 'react-hot-toast'
import {
    basicInfoObjValidation,
    topicsObjValidation,
    iabCategoriesObjValidation,
    outcomesObjValidation,
    brandProfileCompletionStatusObjValidation,
    targetviewIos,
    outcomeMetricOptionsObj
} from '../../schemas/brandProfiles'
import { initialOutcome } from '../../views/BrandProfiles/BrandProfile/components/Outcomes/constants'
import { userAccountAxios } from '../../axiosInstances'
import { api } from '../../api/api'
import { logError } from '../../utils'

var fileDownload = require('js-file-download')

export function fetchBrandProfileBasic(brandProfileId) {
    return async (dispatch, getState) => {
        let url = `/brand-profile/${brandProfileId}/basic`
        try {
            const result = await userAccountAxios.get(url)
            if (result.status === 200) {
                if (result.data.length < 1) {
                    alert('This brand profile does not exist.')
                    window.location.href = '/app/settings/brandProfiles'
                    return
                }
                basicInfoObjValidation.validate(result.data).catch(function(err) {
                    console.error(err.name, err.errors)
                    console.error(
                        ' we received different data from the api than expected while fetching brand profile basic info, see console log for more details'
                    )
                })

                let brandProfile = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))
                brandProfile.submitted = result.data.submitted
                brandProfile.brandProfileId = result.data.brandProfileId
                brandProfile.brandName = result.data.brandName
                brandProfile.websiteUrl = result.data.websiteUrl
                brandProfile.aylienIndustryId = result.data.aylienIndustryId
                brandProfile.twitterProfileUrl = result.data.twitterProfileUrl

                dispatch(setBrandProfileUnderEdit(brandProfile))
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export const patchBrandProfileKeywords = ({ keywords, brandProfileId }) => {
    return async (dispatch, getState) => {
        let url = `/brand-profile/${brandProfileId}/keywords`
        await userAccountAxios.patch(url, keywords)
        toast.success('Changes saved')
    }
}

export function fetchBrandProfileOutcomes(brandProfileId) {
    let url = `/brand-profile/${brandProfileId}/outcomes`
    return async dispatch => {
        try {
            const result = await userAccountAxios.get(url)
            if (result.status === 200) {
                if (result.data.length > 0) {
                    outcomesObjValidation.validate(result.data).catch(function(err) {
                        console.log(err.name, err.errors)
                        console.error(
                            ' we received different data from the api than expected while fetching brand profile outcomes, see console log for more details'
                        )
                    })
                    for (const o of result.data) {
                        delete o.brandProfileId
                    }
                }
                if (result.data.length < 1) {
                    result.data[0] = JSON.parse(JSON.stringify(initialOutcome))
                }
                dispatch(setBrandProfileOutcomes(result.data))
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export function fetchBrandProfileCompletionStatus(brandProfileId) {
    let url = `/brand-profile/${brandProfileId}/status`
    return async (dispatch, getState) => {
        dispatch(setBrandProfileLoading(true))
        try {
            const result = await userAccountAxios.get(url)

            if (result.status === 200) {
                brandProfileCompletionStatusObjValidation.validate(result.data).catch(function(err) {
                    console.log(err.name, err.errors)
                    console.error(
                        ' we received different data from the api than expected while fetching brand profile status, see console log for more details'
                    )
                })

                let bp = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))

                bp.complete = result.data.complete
                bp.opinionIncompleteCount = result.data.opinionIncompleteCount
                bp.outcomeIncompleteCount = result.data.outcomeIncompleteCount
                bp.questionIncompleteCount = result.data.questionIncompleteCount
                bp.scenarioIncompleteCount = result.data.scenarioIncompleteCount
                bp.brandProfileNameComplete = result.data.brandProfileNameComplete
                bp.twitterComplete = result.data.twitterComplete
                bp.websiteComplete = result.data.websiteComplete
                bp.queriesComplete = result.data.queriesComplete

                dispatch(setBrandProfileUnderEdit(bp))
                dispatch(setBrandProfileLoading(false))
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export function fetchBrandProfileIabCategories(args) {
    let brandProfileId = args.brandProfileId
    let url = `/brand-profile/${brandProfileId}/iab-categories`
    return async dispatch => {
        dispatch(setBrandProfileIabCategoriesLoading(true))
        const result = await userAccountAxios.post(url, args)
        if (result.status === 200) {
            iabCategoriesObjValidation.validate(result.data.slice(0, 3)).catch(function(err) {
                console.log(err.name, err.errors)
                console.error(
                    ' we received different data from the api than expected while fetching brand profile  categories, see console log for more details'
                )
            })

            dispatch(setBrandProfileIabCategories(result.data))
            dispatch(setBrandProfileIabCategoriesLoading(false))
        } else {
            dispatch(setBrandProfileIabCategoriesLoading(false))
        }
    }
}

export function fetchBrandProfileTopics(args) {
    let url = `/brand-profile/${args.brandProfileId}/topics`
    return async dispatch => {
        dispatch(setBrandProfileTopicsLoading(true))
        try {
            const result = await userAccountAxios.post(url, args)
            if (result.status === 200) {
                topicsObjValidation.validate(result.data.slice(0, 3)).catch(function(err) {
                    console.log(err.name, err.errors)
                    console.error(
                        ' we received different data from the api than expected while fetching brand profile topics, see console log for more details'
                    )
                })
                dispatch(setBrandProfileTopics(result.data))
                dispatch(setBrandProfileTopicsLoading(false))
            }
        } catch (error) {
            console.error(error)
            dispatch(setBrandProfileTopicsLoading(false))
        }
    }
}

export function fetchBrandProfileTargetviewInsertionOrders(brandProfileId) {
    return async dispatch => {
        try {
            const ios = await api.brandProfile.ios.get(brandProfileId)
            const formattedResult = (ios && ios.map(io => io.ioId)) || []
            dispatch(setBrandProfileTargetviewInsertionOrders(formattedResult || []))
        } catch (error) {
            logError(error)
            console.error(error)
        }
    }
}

export function fetchOutcomeMetricOptions() {
    let url = `/brand-profile/metrics-options`
    return async dispatch => {
        try {
            const result = await userAccountAxios.get(url)
            if (result.status === 200) {
                outcomeMetricOptionsObj.validate(result.data).catch(function(err) {
                    console.log(err.name, err.errors)
                    console.error(
                        'we received different data from the api than expected while fetching brand profile outcome metrics, see console log for more details'
                    )
                })
                dispatch(setOutcomeMetricOptions(result.data))
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export function setOutcomeMetricOptions(outcomeMetricOptions) {
    return {
        type: SET_OUTCOME_METRIC_OPTIONS,
        outcomeMetricOptions
    }
}

export function setBrandProfileUnderEdit(brandProfileUnderEdit) {
    return {
        type: SET_BRAND_PROFILE_UNDER_EDIT,
        brandProfileUnderEdit
    }
}

export function addOutcome() {
    return {
        type: ADD_OUTCOME,
        action: 'adding a blank outcome'
    }
}

export function setBrandProfileSubmitting(brandProfileSubmitting) {
    return {
        type: SET_BRAND_PROFILE_SUBMITTING,
        brandProfileSubmitting
    }
}

export function setBrandProfileBasicInfo(basicInfo) {
    return {
        type: SET_BRAND_PROFILE_BASIC_INFO,
        basicInfo
    }
}

export function setBrandProfileIabCategories(iabCategories) {
    return {
        type: SET_BRAND_PROFILE_IAB_CATEGORIES,
        iabCategories
    }
}

export function setBrandProfileIabCategoriesLoading(iabCategoriesLoading) {
    return {
        type: SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING,
        iabCategoriesLoading
    }
}

export function setBrandProfileOutcomes(outcomes) {
    return {
        type: SET_BRAND_PROFILE_OUTCOMES,
        outcomes
    }
}

export function setBrandProfileTopics(topics) {
    return {
        type: SET_BRAND_PROFILE_TOPICS,
        topics
    }
}

export function setBrandProfileTopicsLoading(topicsLoading) {
    return {
        type: SET_BRAND_PROFILE_TOPICS_LOADING,
        topicsLoading
    }
}

export function setBrandProfileTargetviewInsertionOrders(ios) {
    return {
        type: SET_BRAND_PROFILE_IOS,
        ios
    }
}

export const patchBrandProfileBasicInfo = brandProfile => {
    return async (dispatch, getState) => {
        dispatch(setBrandProfileSaving(true))
        const url = `/brand-profile/${brandProfile.brandProfileId}`
        delete brandProfile.brandProfileId
        const result = await userAccountAxios.patch(url, brandProfile)
        if (result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const patchBrandProfileOutcomes = (_outcome, brandProfileId) => {
    if (_outcome.primaryMetricValue === '') {
        _outcome.primaryMetricValue = null
    }
    if (_outcome.secondaryMetricValue === '') {
        _outcome.secondaryMetricValue = null
    }
    if (_outcome.tertiaryMetricValue === '') {
        _outcome.tertiaryMetricValue = null
    }

    return async (dispatch, getState) => {
        let bp = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))
        let newOutcomes = bp.outcomes.map(outcome => {
            if (outcome.outcomeId === _outcome.outcomeId) {
                outcome = _outcome
            }
            return outcome
        })

        bp.outcomes = newOutcomes

        dispatch(setBrandProfileUnderEdit(bp))
        dispatch(setBrandProfileSaving(true))
        let url = `/brand-profile/${brandProfileId}/outcomes`
        const result = await userAccountAxios.patch(url, bp.outcomes)
        if (result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const deleteBrandProfileOutcome = args => {
    const { brandProfileId, outcomeId } = args
    return async (dispatch, getState) => {
        let bp = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))
        let newOutcomes = bp.outcomes.filter(o => o.outcomeId !== outcomeId)
        bp.outcomes = newOutcomes
        dispatch(setBrandProfileUnderEdit(bp))
        dispatch(setBrandProfileSaving(true))
        let url = `/brand-profile/${brandProfileId}/outcomes`
        const result = await userAccountAxios.patch(url, bp.outcomes)
        if (result.status === 200) {
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const submitBrandProfile = brandProfileId => {
    return async (dispatch, getState) => {
        dispatch(setBrandProfileSubmitting(true))
        let url = `/brand-profile/${brandProfileId}/submit`
        const result = await userAccountAxios.patch(url)
        if (result.status === 200) {
            let brandProfile = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))
            brandProfile.submitted = true
            dispatch(setBrandProfileUnderEdit(brandProfile))
            dispatch(setBrandProfileSubmitting(false))
            dispatch(setBrandProfileSubmitted(true))
        }
    }
}

export const patchBrandProfileIabCategories = data => {
    let brandProfileId = data.brandProfileId
    let iabCategories = data.iabCategories

    let url = `/brand-profile/${brandProfileId}/iab-categories`
    return async dispatch => {
        dispatch(setBrandProfileSaving(true))

        const result = await userAccountAxios.patch(url, iabCategories)
        if (result.status === 201 || result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const patchBrandProfileTopics = data => {
    let brandProfileId = data.brandProfileId
    let topics = data.topics
    let url = `/brand-profile/${brandProfileId}/topics`
    return async dispatch => {
        dispatch(setBrandProfileSaving(true))
        const result = await userAccountAxios.patch(url, topics)
        if (result.status === 201 || result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const patchBrandProfileTargetviewInsertionOrders = ({ ios, brandProfileId }) => {
    return async dispatch => {
        dispatch(setBrandProfileSaving(true))

        let url = `/brand-profile/${brandProfileId}/ios`
        const result = await userAccountAxios.patch(url, ios)
        if (result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const deleteBrandProfile = brandProfileId => {
    let url = `/brand-profile/${brandProfileId}/archive`

    return (dispatch, getState) => {
        userAccountAxios
            .patch(url)
            .then(response => {})
            .catch(error => {
                console.error(error)
            })
    }
}

export function downloadBrandProfile(payload) {
    let brandProfileId = payload.brandProfileId
    let brandProfileName = payload.brandProfileName
    let url = `/brand-profile/${brandProfileId}/download`
    if (payload.scenarioDownloadSort === 'response' && payload.opinionDownloadSort != 'response') {
        url = `/brand-profile/${brandProfileId}/download?scenarioOrderBy=response`
    }

    if (payload.scenarioDownloadSort != 'response' && payload.opinionDownloadSort === 'response') {
        url = `/brand-profile/${brandProfileId}/download?opinionOrderBy=response`
    }

    if (payload.scenarioDownloadSort === 'response' && payload.opinionDownloadSort === 'response') {
        url = `/brand-profile/${brandProfileId}/download?opinionOrderBy=response&scenarioOrderBy=response`
    }

    return dispatch => {
        userAccountAxios
            .get(url, { responseType: 'blob' })
            .then(response => {
                if (response.data) {
                    fileDownload(response.data, `${brandProfileName}.zip`)
                } else {
                    toast.error('We were unable to download this brand profile')
                }

                dispatch(setIsDownloadingBrandProfile(false))
                dispatch(setDownloadingBrandProfileId(null))
            })
            .catch(error => {
                dispatch(setIsDownloadingBrandProfile(false))
                dispatch(setDownloadingBrandProfileId(null))
                console.error('download brand profile error', error)
            })
    }
}

export function setBrandProfileLoading(bool) {
    return {
        type: SET_BRAND_PROFILE_LOADING,
        brandProfileLoading: bool
    }
}

export function setBrandProfileSaving(bool) {
    return {
        type: SET_BRAND_PROFILE_SAVING,
        brandProfileSaving: bool
    }
}
export function setBrandProfileSaved(bool) {
    return {
        type: SET_BRAND_PROFILE_SAVED,
        brandProfileSaved: bool
    }
}

export function setBrandProfileSubmitted(bool) {
    return {
        type: SET_BRAND_PROFILE_SUBMITTED,
        brandProfileSubmitted: bool
    }
}

export function setScenariosIsLoading(bool) {
    return {
        type: SCENARIOS_IS_LOADING,
        scenariosIsLoading: bool
    }
}

export function hasBrandProfiles(bool) {
    return {
        type: HAS_BRAND_PROFILES,
        hasBrandProfiles: bool
    }
}

export function setIsDownloadingBrandProfile(isDownloadingBrandProfile) {
    return {
        type: SET_IS_DOWNLOADING_BRAND_PROFILE,
        isDownloadingBrandProfile
    }
}

export function setDownloadingBrandProfileId(downloadingBrandProfileId) {
    return {
        type: SET_DOWNLOADING_BRAND_PROFILE_ID,
        downloadingBrandProfileId
    }
}
