import React from 'react'
import { accentColor, brandPinkColor, purpleSecondaryColor } from '../../../../../assets/jss/colorContants'
import { ReactComponent as CommentsIcon } from '../../../../../assets/img/comments-youtube_16x16.svg'
import { ReactComponent as ViewsIcon } from '../../../../../assets/img/views.svg'
import { ReactComponent as ThumbsUpIcon } from '../../../../../assets/img/thumbs-up-youtube_18x16.svg'
import numeral from 'numeral'
import { Video } from '../../../../../classes/video'
import youtubePlayVideoIcon from '../../../../../assets/img/video-play-youtube_24x17.png'
import ActionButton from './ActionButton'
import GarmFeedback from './GarmFeedback'
import useWindowDimensions from '../../../../../useWindowDimensions'
import TextTruncate from 'react-text-truncate'
import PortalModal from '../../../../../components/PortalModal/PortalModal'
import SightlyButton from '../../../../../components/SightlyButton'
import { useMatch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useUser from '../../../../../services/useUser'
var dayjs = require('dayjs')
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar)

interface cat {
    text: string
    color: string
}

const Cat: Function = ({ text, color }: cat) => {
    return (
        <div
            style={{
                marginBottom: 4,
                height: 24,
                borderRadius: 3,
                padding: '2px 8px 2px 8px',
                backgroundColor: color,
                color: 'white',
                fontWeight: 600,
                fontSize: 12,
                lineHeight: '20px',
                marginRight: 4
            }}
        >
            {text}
        </div>
    )
}

interface iProps {
    video: Video
    isFromViewClick: boolean
}

const VideoComponent: Function = ({ video, isFromViewClick }: iProps) => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const initialVideo = new Video(
        video.actionId,
        video.categoryName,
        video.channelName,
        video.published,
        video.description,
        video.id,
        video.name,
        video.thumbnail,
        video.views,
        video.likes,
        video.comments,
        video.versionId,
        video.topTargetCategories,
        video.topMonitorCategories,
        video.topBlockCategories,
        video.garmFeedbackIds
    )

    const dims = useWindowDimensions()
    const [compVideo, setCompVideo] = React.useState<Video>(initialVideo)
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [showIframe, setShowIframe] = React.useState<boolean>(false)

    const toggleLines: Function = (event: React.MouseEvent) => {
        event.preventDefault()
        setExpanded(prev => !prev)
    }

    const handleChangeAction: Function = (actionId: number) => {
        if (isFromViewClick) {
            return
        }
        if (actionId === compVideo.actionId) {
            return
        }
        setCompVideo(compVideo.changeAction(actionId))
    }

    const handleNameClick = () => {
        setShowIframe(true)
    }

    return (
        <div
            style={{
                width: '100%',
                padding: 16,
                display: 'flex'
            }}
        >
            <PortalModal
                footerButtons={[
                    <SightlyButton id="closeModalButton" text="Close" handleClick={() => setShowIframe(false)} />
                ]}
                open={showIframe}
                handleClose={() => setShowIframe(false)}
            >
                <div
                    style={{
                        width: dims.width - 200,
                        padding: 30
                    }}
                >
                    {showIframe && (
                        <iframe
                            style={{ width: '100%', height: '70vh', border: 0 }}
                            src={`https://www.youtube.com/embed/${video.id}`}
                            title="Video"
                        >
                            <p>Your browser does not support iframes.</p>
                        </iframe>
                    )}
                </div>
            </PortalModal>

            <GarmFeedback video={compVideo} showModal={showModal} handleClose={() => setShowModal(false)} />
            <div
                id="videoThumbnail"
                onClick={() => setShowIframe(true)}
                style={{
                    cursor: 'pointer',
                    height: 64,
                    width: 100,
                    marginRight: 8,
                    position: 'relative'
                }}
            >
                <img style={{ height: 64, width: 100, borderRadius: 6 }} src={compVideo.thumbnail} />
                <div style={{ zIndex: 99, position: 'absolute', top: 2, left: 3 }}>
                    <img style={{ height: 17, width: 24 }} src={youtubePlayVideoIcon} />
                </div>
            </div>

            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                    <div
                        id="name"
                        onClick={() => handleNameClick()}
                        style={{
                            cursor: 'pointer',
                            fontWeight: 600,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#333D47',
                            width: 640 - 45
                        }}
                    >
                        <TextTruncate text={compVideo.name} />
                    </div>

                    <div
                        style={{
                            marginLeft: 'auto',
                            display: 'flex',
                            fontWeight: 600,
                            fontSize: 12
                        }}
                    >
                        <ActionButton
                            id="targetButton"
                            handleChangeAction={() => handleChangeAction(1)}
                            disabled={isFromViewClick}
                            active={compVideo.actionId === 1}
                            activeColor={accentColor}
                            text="Target"
                        />
                        <ActionButton
                            id="monitorButton"
                            handleChangeAction={() => handleChangeAction(3)}
                            disabled={isFromViewClick}
                            active={compVideo.actionId === 3}
                            activeColor={purpleSecondaryColor}
                            text="Monitor"
                        />
                        <ActionButton
                            id="blockButton"
                            handleChangeAction={() => handleChangeAction(2)}
                            disabled={isFromViewClick}
                            active={compVideo.actionId === 2}
                            activeColor={brandPinkColor}
                            text="Block"
                        />
                    </div>
                </div>
                <div
                    id="channelDescription"
                    style={{
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '22px',
                        color: '#696969',
                        marginBottom: 8,
                        width: 640 - 45
                    }}
                >
                    <TextTruncate
                        textTruncateChild={
                            <span>
                                {!expanded && (
                                    <span
                                        style={{ color: accentColor }}
                                        className="sightlyLink"
                                        onClick={e => toggleLines(e)}
                                    >
                                        more
                                    </span>
                                )}
                            </span>
                        }
                        line={expanded ? 4 : 1}
                        text={compVideo.description}
                    />

                    {expanded && (
                        <span style={{ color: accentColor }} className="sightlyLink" onClick={e => toggleLines(e)}>
                            less
                        </span>
                    )}
                </div>

                {compVideo.topTargetCategories.length +
                    compVideo.topMonitorCategories.length +
                    compVideo.topBlockCategories.length >
                    0 && (
                    <div
                        id="categoryContainer"
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            marginBottom: 4,
                            maxWidth: 623
                        }}
                    >
                        {compVideo.topTargetCategories.map((cat, index) => {
                            return <Cat key={index} text={cat} color={accentColor} />
                        })}
                        {compVideo.topMonitorCategories.map((cat, index) => {
                            return <Cat key={index} text={cat} color={purpleSecondaryColor} />
                        })}
                        {compVideo.topBlockCategories.map((cat, index) => {
                            return <Cat key={index} text={cat} color={brandPinkColor} />
                        })}
                    </div>
                )}

                <div id="statsContainer" style={{ display: 'flex' }}>
                    <div
                        id="videoViewsIcon"
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <ViewsIcon
                            style={{
                                color: '#7F868D',
                                alignSelf: 'center',
                                height: 16,
                                width: 16
                            }}
                        />
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: '20px',
                                marginLeft: 8,
                                color: '#333D47'
                            }}
                        >
                            {numeral(compVideo.views).format('0a')}
                        </div>
                    </div>

                    <div
                        id="videoLikesIcon"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 16
                        }}
                    >
                        <ThumbsUpIcon
                            style={{
                                color: '#7F868D',
                                alignSelf: 'center',
                                height: 16,
                                width: 16
                            }}
                        />
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: '20px',
                                marginLeft: 8,
                                color: '#333D47'
                            }}
                        >
                            {numeral(video.likes).format('0a')}
                        </div>
                    </div>

                    <div id="videoCommentsIcon" style={{ display: 'flex', alignItems: 'center', marginLeft: 16 }}>
                        <CommentsIcon
                            style={{
                                color: '#7F868D',
                                alignSelf: 'center',
                                height: 16,
                                width: 16
                            }}
                        />
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: '20px',
                                marginLeft: 8,
                                color: '#333D47'
                            }}
                        >
                            {compVideo.comments > 999
                                ? numeral(compVideo.comments).format('0a')
                                : numeral(compVideo.comments).format('0,0')}
                        </div>
                    </div>

                    <div
                        style={{
                            marginLeft: 16,
                            display: 'flex',
                            fontSize: 12,
                            lineHeight: '20px',
                            fontWeight: 500
                        }}
                    >
                        Uploaded:&nbsp;
                        <div style={{ fontWeight: 600, marginRight: 16 }}>
                            {dayjs(compVideo.published).format('MMM DD, YYYY')}
                        </div>
                    </div>

                    {userCan(perms.GARM_FEEDBACK_READ) && (
                        <div style={{ marginLeft: 'auto' }}>
                            <div
                                onClick={() => setShowModal(true)}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    color: '#1DA1F2'
                                }}
                            >
                                Does this video violate GARM?
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VideoComponent
