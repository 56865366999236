import React from 'react'
import { routes } from './routes.js'
import MyLogin from './pages/Login'
import ResetPassword from './pages/ResetPassword.js'
import ChangePassword from './pages/ChangePassword.js'
import { Link, Outlet, ReactLocation, Route, Router, useSearch } from '@tanstack/react-location'
import ProtectedRoute from './layouts/ProtectedRoute.js'
import Dashboard from './views/Discover/Boards/Dashboard'
import Welcome from './views/Discover/Boards/Welcome'
import Custom from './views/Discover/Boards/Custom'
import General from './views/Discover/Boards/General'
import AylienBoard from './views/Discover/Boards/AylienBoard'
import AuthRoute from './layouts/AuthRoute.js'
import BasicInfo from './views/BrandProfiles/BrandProfile/components/BasicInfo.js'
import Questions from './views/BrandProfiles/BrandProfile/components/Questions/Questions.js'
import Outcomes from './views/BrandProfiles/BrandProfile/components/Outcomes/Outcomes.js'
import QueryTypeOneSection from './views/BrandProfiles/BrandProfile/components/Queries/QueryTypeOneSection'
import { typeOneSections } from './views/BrandProfiles/BrandProfile/components/Queries/constants'
import SocialSection from './views/BrandProfiles/BrandProfile/components/Queries/SocialSection'
import QuestionsSection from './views/BrandProfiles/BrandProfile/components/Queries/QuestionsSection'
import EventSection from './views/BrandProfiles/BrandProfile/components/Queries/EventSection'
import Opinions from './views/BrandProfiles/BrandProfile/components/Opinions/Opinions.js'
import Scenarios from './views/BrandProfiles/BrandProfile/components/Scenarios/Scenarios.js'
import MediaResponsibility from './views/BrandProfiles/BrandProfile/components/MediaResponsibility'
import IabCategories from './views/BrandProfiles/BrandProfile/components/IabCategories/IabCategories.js'
import Topics from './views/BrandProfiles/BrandProfile/components/Topics.js'
import Aylien from './views/BrandProfiles/BrandProfile/components/Aylien/Aylien'
import { aylienTypes } from './views/BrandProfiles/BrandProfile/components/Aylien/useBrandProfileAylien'
import Keywords from './views/BrandProfiles/BrandProfile/components/Keywords/Keywords.js'
import Targetview from './views/BrandProfiles/BrandProfile/components/Targetview/Targetview.js'
import Review from './views/BrandProfiles/BrandProfile/components/Review/Review.js'
import { api } from './api/api'
import { MyLocationGenerics } from './classes/utils/utils.js'
import { perms } from './staticData/permissions'
import { LoadingPage } from './components/LoadingPage'
import { useQuery } from '@tanstack/react-query'
import PortalModal from './components/PortalModal/PortalModal'
import SightlyButton from './components/SightlyButton'
import { ReactComponent as WarningIcon } from './assets/img/warning-large.svg'
import { userAccountAxios } from './axiosInstances.js'
import { rqKeys } from './ReactQueryKeyFactory'

const AylienNews = () => {
    return <Aylien key={aylienTypes.news} aylienType={aylienTypes.news} />
}
const AylienIndustries = () => {
    return <Aylien key={aylienTypes.industries} aylienType={aylienTypes.industries} />
}

export const location = new ReactLocation<MyLocationGenerics>()

const RouteContainer = () => {
    const rlRoutes: Route<MyLocationGenerics>[] = [
        {
            path: '/',
            element: <MyLogin />
        },
        {
            path: '/login',
            element: <MyLogin />
        },
        {
            path: '/resetPassword',
            element: <ResetPassword />
        },
        {
            path: routes.changePassword.path,
            element: <ChangePassword />
        },
        {
            path: '/admin',
            element: (
                <div>
                    <p>This route was moved to /app/admin</p>
                    <Link to={'/app/admin/roles'}>Click here to go there now</Link>
                </div>
            )
        },
        {
            path: 'app',
            element: <AuthRoute />,
            searchFilters: [
                search => ({
                    ...search,
                    accountId: search.accountId
                })
            ],
            children: [
                {
                    path: 'admin',
                    children: [
                        {
                            path: 'scenarios',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.scenarios.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'labels',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.scenarios.labels.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'types',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.scenarios.types.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'create',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.scenarios.create.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'edit',
                                    children: [
                                        {
                                            path: ':scenarioId',
                                            element: (
                                                <ProtectedRoute
                                                    component={routes.admin.scenarios.edit.component}
                                                    permissionsNeeded={[perms.ADMIN_READ]}
                                                />
                                            )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: 'opinions',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.opinions.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'types',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.opinions.types.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'create',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.opinions.create.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'edit',
                                    children: [
                                        {
                                            path: ':opinionId',
                                            element: (
                                                <ProtectedRoute
                                                    component={routes.admin.opinions.edit.component}
                                                    permissionsNeeded={[perms.ADMIN_READ]}
                                                />
                                            )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: 'questions',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.questions.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                }
                            ]
                        },
                        {
                            path: 'permissions',
                            element: (
                                <ProtectedRoute
                                    component={routes.admin.permissions.component}
                                    permissionsNeeded={[perms.ADMIN_READ]}
                                />
                            )
                        },
                        {
                            path: 'roles',
                            element: (
                                <ProtectedRoute
                                    component={routes.admin.roles.component}
                                    permissionsNeeded={[perms.ADMIN_READ]}
                                />
                            )
                        },
                        {
                            path: 'iabCategories',
                            element: (
                                <ProtectedRoute
                                    component={routes.admin.iabCategories.component}
                                    permissionsNeeded={[perms.ADMIN_READ]}
                                />
                            )
                        },
                        {
                            path: 'evergreenChannels',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.evergreenChannels.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'create',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.admin.evergreenChannels.create.component}
                                            permissionsNeeded={[perms.ADMIN_READ]}
                                        />
                                    )
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'discover',
                    children: [
                        {
                            path: 'moments',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.discover.trends.component}
                                    permissionsNeeded={[perms.TRENDS_READ]}
                                />
                            ),
                            pendingMs: 1,
                            pendingMinMs: 200,
                            pendingElement: <LoadingPage message="Loading page data..." />,
                            loader: async () => {
                                const trendsLatestBatch = await api.trends.getLatestBatch()

                                return {
                                    trends: {
                                        trendsLatestBatch
                                    }
                                }
                            }
                        },
                        {
                            path: 'boards',
                            // element: <BoardsLoading />,
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={Dashboard}
                                            permissionsNeeded={[perms.AYLIEN_TRENDS_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'dashboard',
                                    element: (
                                        <ProtectedRoute
                                            component={Dashboard}
                                            permissionsNeeded={[perms.AYLIEN_TRENDS_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'welcome',
                                    element: (
                                        <ProtectedRoute
                                            component={Welcome}
                                            permissionsNeeded={[perms.AYLIEN_TRENDS_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'board',
                                    element: (
                                        <ProtectedRoute
                                            component={AylienBoard}
                                            permissionsNeeded={[perms.AYLIEN_TRENDS_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'create',
                                    children: [
                                        {
                                            path: 'custom',
                                            element: (
                                                <ProtectedRoute
                                                    component={Custom}
                                                    permissionsNeeded={[perms.AYLIEN_TRENDS_READ]}
                                                />
                                            )
                                        },
                                        {
                                            path: 'general',
                                            element: (
                                                <ProtectedRoute
                                                    component={General}
                                                    permissionsNeeded={[perms.AYLIEN_TRENDS_READ]}
                                                />
                                            )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: 'measure',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.discover.measure.component}
                                    permissionsNeeded={[perms.MEASURE_READ]}
                                />
                            )
                        },
                        {
                            path: 'reporting',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.discover.reporting.component}
                                    permissionsNeeded={[perms.REPORTING_READ]}
                                />
                            )
                        },
                        {
                            path: 'momentIntelligence',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.discover.momentsIntelligence.component}
                                    permissionsNeeded={[perms.MOMENTS_INTELLIGENCE_READ]}
                                />
                            )
                        }
                    ]
                },
                {
                    path: 'settings',
                    children: [
                        {
                            path: 'users',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.app.settings.users.component}
                                            permissionsNeeded={[perms.USER_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'create',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.app.settings.users.create.component}
                                            permissionsNeeded={[perms.USER_CREATE]}
                                        />
                                    )
                                },
                                {
                                    path: 'edit',
                                    children: [
                                        {
                                            path: ':userId',
                                            element: (
                                                <ProtectedRoute
                                                    component={routes.app.settings.users.edit.component}
                                                    permissionsNeeded={[perms.USER_READ]}
                                                />
                                            )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: 'profile',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.settings.profile.component}
                                    permissionsNeeded={[]}
                                />
                            )
                        },
                        {
                            path: 'notificationPreferences',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.settings.notificationPreferences.component}
                                    permissionsNeeded={[perms.NOTIFICATIONS_READ]}
                                />
                            )
                        },
                        {
                            path: 'account',
                            element: (
                                <ProtectedRoute
                                    component={routes.app.settings.account.component}
                                    permissionsNeeded={[perms.ACCOUNT_READ]}
                                />
                            )
                        },
                        {
                            path: 'brandProfiles',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.app.settings.brandProfiles.component}
                                            permissionsNeeded={[perms.BRAND_PROFILES_PAGE_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'brandProfile',
                                    children: [
                                        {
                                            path: ':brandProfileId',
                                            element: (
                                                <ProtectedRoute
                                                    component={routes.app.settings.brandProfiles.brandProfile.component}
                                                    permissionsNeeded={[perms.BRAND_PROFILE_READ]}
                                                />
                                            ),
                                            children: [
                                                {
                                                    path: 'brandInformation',
                                                    element: (
                                                        <>
                                                            <BasicInfo />
                                                            <Questions />
                                                        </>
                                                    )
                                                },
                                                {
                                                    path: 'outcomes',
                                                    element: <Outcomes />
                                                },
                                                {
                                                    path: 'brands',
                                                    element: (
                                                        <QueryTypeOneSection
                                                            key="1"
                                                            queryType={typeOneSections.competitors}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'influencers',
                                                    element: (
                                                        <QueryTypeOneSection
                                                            key="2"
                                                            queryType={typeOneSections.influencers}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'events',
                                                    element: <EventSection />
                                                },
                                                {
                                                    path: 'philanthropic',
                                                    element: (
                                                        <QueryTypeOneSection
                                                            key="4"
                                                            queryType={typeOneSections.philanthropic}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'social',
                                                    element: <SocialSection />
                                                },
                                                {
                                                    path: 'questions',
                                                    element: <QuestionsSection />
                                                },
                                                {
                                                    path: 'opinions',
                                                    element: <Opinions />
                                                },
                                                {
                                                    path: 'scenarios',
                                                    element: <Scenarios />
                                                },
                                                {
                                                    path: 'mediaResponsibility',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={MediaResponsibility}
                                                            permissionsNeeded={[
                                                                perms.BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY
                                                            ]}
                                                        />
                                                    )
                                                },

                                                {
                                                    path: 'iabCategories',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={IabCategories}
                                                            permissionsNeeded={[perms.BRAND_PROFILE_CATEGORIES_READ]}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'topics',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={Topics}
                                                            permissionsNeeded={[perms.BRAND_PROFILE_TOPICS_READ]}
                                                        />
                                                    )
                                                },

                                                {
                                                    path: 'news',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={AylienNews}
                                                            permissionsNeeded={[perms.BRAND_PROFILE_AYLIEN_NEWS_READ]}
                                                        />
                                                    )
                                                },

                                                {
                                                    path: 'industries',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={AylienIndustries}
                                                            permissionsNeeded={[
                                                                perms.BRAND_PROFILE_AYLIEN_INDUSTRY_READ
                                                            ]}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'keywords',
                                                    element: <Keywords />
                                                },
                                                {
                                                    path: 'targetview',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={Targetview}
                                                            permissionsNeeded={[perms.BRAND_PROFILE_IOS_READ]}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'submit',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={Review}
                                                            permissionsNeeded={[perms.BRAND_PROFILE_UPDATE]}
                                                        />
                                                    )
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'engage',
                    children: [
                        {
                            path: 'lists',
                            children: [
                                {
                                    path: '/',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.app.engage.lists.lists.component}
                                            permissionsNeeded={[perms.ENGAGE_READ]}
                                        />
                                    )
                                },
                                {
                                    path: 'createList',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.app.engage.lists.createList.component}
                                            permissionsNeeded={[perms.ENGAGE_READ, perms.SMARTLIST_CREATE]}
                                        />
                                    )
                                },
                                {
                                    path: 'uploadList',
                                    element: (
                                        <ProtectedRoute
                                            component={routes.app.engage.lists.uploadList.component}
                                            permissionsNeeded={[perms.ENGAGE_READ, perms.SMARTLIST_CREATE_UPLOAD]}
                                        />
                                    )
                                },
                                {
                                    path: 'channelListBuilder',
                                    children: [
                                        {
                                            path: ':versionId',
                                            children: [
                                                {
                                                    path: 'view',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={
                                                                routes.app.engage.lists.channelListBuilder.component
                                                            }
                                                            permissionsNeeded={[perms.SMARTLIST_READ]}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'edit',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={
                                                                routes.app.engage.lists.channelListBuilder.component
                                                            }
                                                            permissionsNeeded={[perms.SMARTLIST_EDIT]}
                                                        />
                                                    )
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    path: 'videoListBuilder',
                                    children: [
                                        {
                                            path: ':versionId',
                                            children: [
                                                {
                                                    path: 'view',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={
                                                                routes.app.engage.lists.videoListBuilder.component
                                                            }
                                                            permissionsNeeded={[perms.SMARTLIST_READ]}
                                                        />
                                                    )
                                                },
                                                {
                                                    path: 'edit',
                                                    element: (
                                                        <ProtectedRoute
                                                            component={
                                                                routes.app.engage.lists.videoListBuilder.component
                                                            }
                                                            permissionsNeeded={[perms.SMARTLIST_EDIT]}
                                                        />
                                                    )
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

    const getApiVersion = async () => {
        const url = '/api-version'
        const res = await userAccountAxios.get(url)
        return res?.data?.versionId
    }

    const [showModal, setShowModal] = React.useState(false)
    const { data: apiVersion } = useQuery(rqKeys.apiVersionKey(), getApiVersion, {
        refetchInterval: 10000
    })

    const versionFromLocalStorage = localStorage.getItem('apiVersion')

    React.useEffect(() => {
        if (!apiVersion) return

        if (!versionFromLocalStorage) {
            localStorage.setItem('apiVersion', apiVersion)
            return
        }
        if (apiVersion != versionFromLocalStorage) {
            localStorage.setItem('apiVersion', apiVersion)
            setShowModal(true)
        }
    }, [apiVersion, versionFromLocalStorage])

    return (
        <>
            <PortalModal
                cannotClose
                centerFooterButtons
                footerButtons={[]}
                handleClose={() => setShowModal(false)}
                open={showModal}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 48,
                        padding: 48
                    }}
                >
                    <WarningIcon />
                    <div style={{ fontWeight: 600, color: '#333d47', fontSize: 24 }}>This site has been updated</div>
                    <div style={{ fontWeight: 500, color: '#7a7a7a', fontSize: 20 }}>
                        Please refresh your page to apply the updates.
                    </div>
                    <SightlyButton id="closeRefreshModal" handleClick={() => window.location.reload()} text="Refresh" />
                </div>
            </PortalModal>

            <Router location={location} routes={rlRoutes} />
        </>
    )
}

export default RouteContainer
