import React, { ReactNode } from 'react'
import { Item, ItemTypes, HandleSelectProps } from './useBoardCreator'
import Pills from './Pills'

type IProps = {
    data: Item[]
    count: number
    handleSelectItem: (p: HandleSelectProps) => void
    selectedCount: number
    itemType: ItemTypes
    WarningMessage: ReactNode
    boardType: 'custom' | 'general'
    searchTerm: string
    MAX_ALLOWED_CUSTOM_ITEMS?: number
}

const PillsGroup = ({
    data,
    count,
    handleSelectItem,
    selectedCount,
    itemType,
    WarningMessage,
    boardType,
    searchTerm,
    MAX_ALLOWED_CUSTOM_ITEMS
}: IProps) => {
    if (boardType === 'custom' && !MAX_ALLOWED_CUSTOM_ITEMS) {
        console.error('boardType of custom requires MAX_ALLOWED_CUSTOM_ITEMS property')
    }
    return (
        <>
            {count === 0 && WarningMessage}

            {data
                .filter(g => g.items.length > 0)
                .map(group => (
                    <Pills
                        searchTerm={searchTerm}
                        key={group.name}
                        group={group}
                        handleSelect={(id: number) =>
                            handleSelectItem({
                                itemType,
                                name: group.name,
                                id
                            })
                        }
                        maxReached={
                            boardType === 'custom' ? selectedCount === MAX_ALLOWED_CUSTOM_ITEMS : selectedCount === 1
                        }
                    />
                ))}
        </>
    )
}

export default PillsGroup
