import React from 'react'
import TreePicker from 'rsuite/lib/TreePicker'
import ControlLabel from 'rsuite/lib/ControlLabel'
import FormGroup from 'rsuite/lib/FormGroup'
import Form from 'rsuite/lib/Form'
import Icon from 'rsuite/lib/Icon'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import { useNavigate } from '@tanstack/react-location'
import { location } from '../RouteContainer'
import useUser from '../services/useUser'

function SimplePopover(props) {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const navigate = useNavigate()

    const handleSelect = (accountId, e) => {
        navigate({
            to: location.current.pathname,
            search: prev => {
                return {
                    ...prev,
                    accountId
                }
            }
        })
        window.location.reload()
    }

    return (
        <div style={{ marginTop: '30px', marginBottom: '15px' }}>
            <Form>
                <FormGroup style={{ position: 'relative' }}>
                    <ControlLabel>Active Account</ControlLabel>

                    <TreePicker
                        placement={'bottomEnd'}
                        defaultExpandAll
                        data={accounts}
                        // style={{ width: '100%' }}
                        name="accounts"
                        labelKey="accountName"
                        valueKey="accountId"
                        value={currentAccount?.accountId}
                        onChange={(e, v) => handleSelect(e, v)}
                        cleanable={false}
                        menuStyle={{ zIndex: 999999, overflowY: 'auto' }}
                        searchable={props.searchable}
                        preventOverflow
                    />
                    <div style={{ position: 'absolute', top: 30, right: -30 }}>
                        <Whisper
                            delayShow={700}
                            delayHide={0}
                            placement="topStart"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    Your Active Account is what drives which brand profiles, smart lists, and users you
                                    see on this site.
                                </Tooltip>
                            }
                        >
                            <Icon size="lg" icon="question" />
                        </Whisper>
                    </div>
                </FormGroup>
            </Form>
        </div>
    )
}

export default SimplePopover
