import React from 'react'
import Icon from 'rsuite/lib/Icon'
import * as v from '../../validations'

export default function CustomPassword(props) {
	return (
		<div style={{ width: 300 }}>
			{v.invalidPasswordObject(props.password).map((prop, key) => {
				return (
					<div
						style={{ display: 'flex', paddingLeft: 0, paddingTop: 6 }}
						key={key}>
						<Icon
							icon='check-circle'
							style={{
								color: prop.satisfied ? 'green' : undefined,
								marginRight: 24
							}}
						/>

						<div>{prop.text}</div>
					</div>
				)
			})}
		</div>
	)
}
