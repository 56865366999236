import React from 'react'
import SightlyAvatar from '../../../components/SightlyAvatar'
import {
	brandPinkColor,
	accentColor,
	purpleSecondaryColor
} from '../../../assets/jss/colorContants'
import { ReactComponent as RightArrow } from '../../../assets/img/rightArrow.svg'
var dayjs = require('dayjs')
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar)

interface IProps {
	editorName: string
	editedDate: string
	fromAction: string
	toAction: string
}

const TrendEditHistoryCard: Function = ({
	editedDate,
	editorName,
	fromAction,
	toAction
}: IProps) => {
	return (
		<div
			style={{
				width: 264,
				height: 161,
				backgroundColor: 'white',
				borderRadius: 6,
				boxShadow:
					'var(--ds-overlay,0 4px 8px -2px rgba(9,30,66,0.7),0 0 1px rgba(9,30,66,0.7))'
			}}
		>
			<div style={{ position: 'absolute', top: 20, left: 16 }}>
				<SightlyAvatar type={null} size='big' text={editorName} />
			</div>
			<div
				style={{
					fontWeight: 600,
					lineHeight: '22px',
					fontSize: 14,
					position: 'absolute',
					top: 21,
					left: 74
				}}
			>
				{editorName}
			</div>

			<div
				style={{
					fontWeight: 500,
					fontSize: 12,
					lineHeight: '16px',
					position: 'absolute',
					top: 43,
					left: 74,
					color: '#696969'
				}}
			>
				{dayjs(editedDate).calendar()}
			</div>

			<div
				style={{
					fontWeight: 400,
					fontSize: 14,
					lineHeight: '18.76px',
					color: '#A3A3A3',
					position: 'absolute',
					top: 69,
					left: 16,
					width: 211
				}}
			>
				The recommended action was edited by {editorName.split(' ')[0]}
			</div>

			<div
				style={{
					width: 40.36,
					height: '20px',
					textTransform: 'capitalize',
					backgroundColor:
						fromAction === 'target'
							? accentColor
							: fromAction === 'monitor'
							? purpleSecondaryColor
							: brandPinkColor,
					textAlign: 'center',
					position: 'absolute',
					color: 'white',
					fontWeight: 600,
					fontSize: 7.5,
					lineHeight: '13.75px',
					borderRadius: '1.88px',
					paddingTop: 3.12,
					left: 16,
					top: 117
				}}
			>
				{fromAction}
			</div>

			<RightArrow
				style={{
					height: 20,
					width: 20,
					position: 'absolute',
					left: 64.62,
					top: 117
				}}
			/>

			<div
				style={{
					width: 43.75,
					height: 20,
					textTransform: 'capitalize',
					backgroundColor:
						toAction === 'target'
							? accentColor
							: toAction === 'monitor'
							? purpleSecondaryColor
							: brandPinkColor,
					textAlign: 'center',
					position: 'absolute',
					color: 'white',
					fontWeight: 600,
					fontSize: 7.5,
					lineHeight: '13.75px',
					borderRadius: '1.88px',
					paddingTop: 3.12,
					left: 94.62,
					top: 117
				}}
			>
				{toAction}
			</div>
		</div>
	)
}

export default TrendEditHistoryCard
