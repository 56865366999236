import React from 'react'
import CreatableTagPicker from '../../../../../components/CreatableTagPicker'
import { location } from '../../../../../RouteContainer'
import { Question } from '../../../../../services/brandProfile/useBrandProfile'
import useUser from '../../../../../services/useUser'

type IProps = {
    question: Question
    handleKeywordsChange: (keywords: string[]) => void
    viewOnly: boolean
}
const QuestionComponent = ({ question, handleKeywordsChange, viewOnly }: IProps) => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const focusedId = location.current.hash ? Number(location.current.hash.replace('#', '')) : false

    return (
        <div
            className={focusedId === question.questionId ? 'focused-item' : undefined}
            id={String(question?.questionId)}
            style={{
                minHeight: 88,
                borderBottom: '1px solid lightgrey',
                padding: 24,
                display: 'flex'
            }}
        >
            <div style={{ alignSelf: 'center' }}>
                <h6>Our Question:</h6>
                <div style={{ width: 400, marginBottom: 24 }}>{question.question}</div>
                <h6>You answered:</h6>
                <div style={{ width: 400 }}>{question.responseText}</div>
            </div>

            <div style={{ paddingLeft: 24 }}>
                <h6>Associated Keywords:</h6>
                <CreatableTagPicker
                    id={question.questionId}
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                    backgroundColor="#4CAF50"
                    textColor="white"
                    items={question.keywords}
                    saveChanges={handleKeywordsChange}
                />
            </div>
        </div>
    )
}

export default QuestionComponent
