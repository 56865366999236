import React from 'react'
import SightlyButton from '../../../components/SightlyButton'
import PortalModal from '../../../components/PortalModal/PortalModal'

type IProps = {
	handleClose: () => void
	handleCancel: () => void
	handleDelete: () => void
	open: boolean
}

const DeleteBoardModal = ({
	handleClose,
	handleCancel,
	handleDelete,
	open
}: IProps) => {
	return (
		<PortalModal
			open={open}
			handleClose={handleClose}
			footerButtons={[
				<SightlyButton
					id='cancel'
					handleClick={() => handleCancel()}
					type='secondary'
					text='Cancel'
				/>,
				<SightlyButton
					id='delete'
					text='Delete'
					handleClick={() => handleDelete()}
					type='cancel'
				/>
			]}>
			<h4>Delete Board</h4>
			<div style={{ width: 600 }}>
				Are you sure you want to deactivate your board? All of your data will be
				permanently removed. This action cannot be undone.
			</div>
		</PortalModal>
	)
}

export default DeleteBoardModal
