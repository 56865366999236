import React from 'react'
import SightlyButton from '../../../components/SightlyButton'
import PortalModal from '../../../components/PortalModal/PortalModal'
import Input from 'rsuite/lib/Input'

type IProps = {
	handleClose: () => void
	handleCancel: () => void
	handleRename: (val: string) => void
	open: boolean
	name: string
}

const RenamingModal = ({
	handleClose,
	handleCancel,
	handleRename,
	open,
	name
}: IProps) => {
	const [newName, setNewName] = React.useState<string>(name)
	return (
		<PortalModal
			handleClose={handleClose}
			open={open}
			footerButtons={[
				<SightlyButton
					id='rename'
					text='Ok'
					handleClick={() => {
						handleRename(newName)
					}}
					type='primary'
				/>,
				<SightlyButton
					id='cancel'
					handleClick={() => handleCancel()}
					type='secondary'
					text='Cancel'
				/>
			]}>
			<h4 style={{ marginBottom: 30, width: 376 }}>Rename Board</h4>
			<Input
				id='name'
				onChange={(val: string) => setNewName(val)}
				placeholder='Enter Board Name'
			/>
		</PortalModal>
	)
}

export default RenamingModal
