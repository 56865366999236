import EditUser from './views/Users/EditUser'
import CreateUser from './views/Users/CreateUser'
import Lists from './views/Engage/Lists/Lists.js'
import Users from './views/Users/Users'
import BrandProfiles from './views/BrandProfiles/BrandProfiles.js'
import UserProfile from './views/UserProfile/UserProfile.js'
import Account from './views/Account/Account'
import UploadList from './views/Engage/Lists/UploadList'
import CreateList from './views/Engage/Lists/CreateList'
import ResetPassword from './pages/ResetPassword'
import ChangePassword from './pages/ChangePassword'
import Login from './pages/Login'
import Scenarios from './views/BrandProfiles/Admin/Scenarios.js'
import CreateScenario from './views/BrandProfiles/Admin/CreateScenario.js'
import Labels from './views/BrandProfiles/Admin/Labels.js'
import ScenarioTypes from './views/BrandProfiles/Admin/ScenarioTypes.js'
import Opinions from './views/BrandProfiles/Admin/Opinions.js'
import OpinionEdit from './views/BrandProfiles/Admin/OpinionEdit'
import ScenarioEdit from './views/BrandProfiles/Admin/ScenarioEdit'
import CreateOpinion from './views/BrandProfiles/Admin/CreateOpinion.js'
import OpinionTypes from './views/BrandProfiles/Admin/OpinionTypes.js'
import Questions from './views/BrandProfiles/Admin/Questions.js'
import Measure from './views/Discover/Measure'
import Reporting from './views/Discover/Reporting'
import Permissions from './views/BrandProfiles/Admin/Permissions.js'
import Roles from './views/BrandProfiles/Admin/Roles.js'
import BrandProfile from './views/BrandProfiles/BrandProfile/BrandProfile'
import Trends from './views/Discover/Trends.tsx'
import IabCategories from './views/BrandProfiles/Admin/IabCategories'
import EvergreenChannels from './views/BrandProfiles/Admin/EvergreenChannels'
import CreateEvergreenChannels from './views/BrandProfiles/Admin/CreateEvergreenChannels'
import ChannelListBuilder from './views/Engage/Lists/ListBuilder/ChannelListBuilder'
import VideoListBuilder from './views/Engage/Lists/ListBuilder/VideoListBuilder'
import ListBuilderPlaceholder from './views/Engage/Lists/ListBuilder/components/ListBuilderPlaceholder'
import NotificationPreferences from './views/NotificationPreferences'
import MomentsIntelligence from './views/Discover/MomentsIntelligence'
import Dashboard from './views/Discover/Boards/Dashboard'
import Welcome from './views/Discover/Boards/Welcome'
import Custom from './views/Discover/Boards/Custom'
import General from './views/Discover/Boards/General'
import AylienBoard from './views/Discover/Boards/AylienBoard'

export const routes = {
    login: {
        path: '/login',
        component: Login,
        name: 'Log In'
    },
    resetPassword: {
        path: '/resetPassword',
        component: ResetPassword,
        name: 'Reset Password'
    },
    changePassword: {
        path: '/changePassword/:userId/:token',
        component: ChangePassword,
        name: 'Change Password'
    },
    app: {
        path: '/app',
        engage: {
            lists: {
                lists: {
                    path: '/app/engage/lists',
                    component: Lists,
                    name: 'SmartLists'
                },
                uploadList: {
                    path: '/app/engage/lists/uploadList',
                    component: UploadList,
                    name: 'Upload List'
                },
                createList: {
                    path: '/app/engage/lists/createList',
                    component: CreateList,
                    name: 'Create List'
                },
                listBuilder: {
                    path: '/app/engage/lists/listBuilder/:versionId',
                    name: 'List Builder Placeholder',
                    component: ListBuilderPlaceholder
                },

                channelListBuilder: {
                    path: '/app/engage/lists/channelListBuilder/:versionId/:viewOnly',
                    name: 'List Builder',
                    component: ChannelListBuilder
                },
                videoListBuilder: {
                    path: '/app/engage/lists/videoListBuilder/:versionId/:viewOnly',
                    name: 'List Builder',
                    component: VideoListBuilder
                }
            }
        },
        discover: {
            trends: {
                path: '/app/discover/moments',
                name: 'Trends',
                component: Trends
            },
            boards: {
                path: '/app/discover/boards',
                name: 'Boards',
                dashboard: {
                    path: '/app/discover/boards/dashboard',
                    component: Dashboard
                },
                welcome: {
                    path: '/app/discover/boards/welcome',
                    component: Welcome
                },
                custom: {
                    path: '/app/discover/boards/create/custom',
                    component: Custom
                },
                general: {
                    path: '/app/discover/boards/create/general',
                    component: General
                },
                board: {
                    path: '/app/discover/boards/board',
                    component: AylienBoard
                }
            },
            measure: {
                path: '/app/discover/measure',
                name: 'Measure',
                component: Measure
            },
            reporting: {
                path: '/app/discover/reporting',
                name: 'Reporting',
                component: Reporting
            },
            momentsIntelligence: {
                path: '/app/discover/momentIntelligence',
                name: 'Moments Intelligence',
                component: MomentsIntelligence
            }
        },
        settings: {
            profile: {
                path: '/app/settings/profile',
                name: 'Profile',
                component: UserProfile
            },
            notificationPreferences: {
                path: '/app/settings/notificationPreferences',
                name: 'Notification Preferences',
                component: NotificationPreferences
            },
            account: {
                path: '/app/settings/account',
                name: 'Account',
                component: Account
            },
            users: {
                path: '/app/settings/users',
                component: Users,
                edit: {
                    path: '/app/settings/users/edit/:user',
                    name: 'Edit',
                    component: EditUser
                },
                create: {
                    path: '/app/settings/users/create',
                    name: 'Create',
                    component: CreateUser
                }
            },
            brandProfiles: {
                path: '/app/settings/brandProfiles',
                name: 'Brand Profiles',
                component: BrandProfiles,
                brandProfile: {
                    path: '/app/settings/brandProfiles/brandProfile/:brandProfileId',
                    name: 'Brand Profile',
                    component: BrandProfile
                }
            }
        }
    },
    admin: {
        path: '/admin',
        scenarios: {
            path: '/app/admin/scenarios',
            name: 'Brand Profiles Scenarios',
            component: Scenarios,
            create: {
                path: '/app/admin/scenarios/create',
                name: 'Create',
                component: CreateScenario
            },
            edit: {
                path: '/app/admin/scenarios/edit/:scenarioId',
                name: 'Edit',
                component: ScenarioEdit
            },
            labels: {
                path: '/app/admin/scenarios/labels',
                name: 'Labels',
                component: Labels
            },
            types: {
                path: '/app/admin/scenarios/types',
                name: 'Scenario Types',
                component: ScenarioTypes
            }
        },
        opinions: {
            path: '/app/admin/opinions',
            name: 'Brand Profiles Opinions',
            component: Opinions,
            create: {
                path: '/app/admin/opinions/create',
                name: 'Create',
                component: CreateOpinion
            },
            edit: {
                path: '/app/admin/opinions/edit/:opinionId',
                name: 'Edit',
                component: OpinionEdit
            },
            types: {
                path: '/app/admin/opinions/types',
                name: 'Opinion Types',
                component: OpinionTypes
            }
        },
        questions: {
            path: '/app/admin/questions',
            name: 'Brand Profiles Questions',
            component: Questions
        },
        permissions: {
            path: '/app/admin/permissions',
            name: 'Permissions',
            component: Permissions
        },
        roles: {
            path: '/app/admin/roles',
            name: 'Roles',
            component: Roles
        },
        iabCategories: {
            path: '/app/admin/iabCategories',
            name: 'IAB Categories',
            component: IabCategories
        },
        evergreenChannels: {
            path: '/app/admin/evergreenChannels',
            name: 'Evergreen Channels',
            component: EvergreenChannels,
            create: {
                path: '/app/admin/evergreenChannels/create',
                name: 'Create',
                component: CreateEvergreenChannels
            }
        }
    }
}
