import {
    SET_AUTH_TOKEN,
    SET_LOGGED_IN,
    SET_LOGGING_IN,
    SET_UPDATING_PASSWORD,
    SET_RESETTING_PASSWORD
} from '../action-types/auth'
import { userLoginValidation } from '../../schemas/schemas'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../axiosInstances'

export function setAuthToken(payload) {
    return { type: SET_AUTH_TOKEN, payload }
}

export function setLoggedIn(payload) {
    return { type: SET_LOGGED_IN, payload }
}

export function setLoggingIn(payload) {
    return { type: SET_LOGGING_IN, payload }
}

export function login(credentials) {
    let url = '/authenticate'
    return async dispatch => {
        dispatch(setLoggingIn(true))

        const result = await userAccountAxios.post(url, {
            username: credentials.username,
            password: credentials.password
        })
        if (!result.status) {
            return
        }
        try {
            if (!result.data.jwt) {
                dispatch(setLoggingIn(false))
                toast.error(result.data.message)
                return
            }

            if (result.status === 200) {
                const token = result.data.jwt
                const user = result.data.user
                userLoginValidation.validate(user).catch(function(err) {
                    console.log(err.name, err.errors)
                    console.error('error on userLoginValidation')
                })              
                dispatch(setAuthToken(token))
                localStorage.setItem('token', token)
                localStorage.setItem('userId', user.userId)
                dispatch(setLoggedIn(true))
                dispatch(setLoggingIn(false))
            }
        } catch (error) {
            dispatch(setLoggingIn(false))
            console.error('auth error: ' + error)
        }
    }
}

export function resetPassword(email) {
    let url = '/reset-password'
    return async dispatch => {
        dispatch(setResettingPassword(true))
        try {
            const result = await userAccountAxios.post(url, {
                email: email
            })

            dispatch(setResettingPassword(false))

            if (result?.status === 200) {
                toast.success('Reset password email sent. Check Your email.')
            }
        } catch (error) {
            dispatch(setResettingPassword(false))
            console.log('catch', error)
            //	toast.error(error.response)
        }
    }
}

export function setUpdatingPassword(bool) {
    return {
        type: SET_UPDATING_PASSWORD,
        updatingPassword: bool
    }
}

export function setResettingPassword(bool) {
    return {
        type: SET_RESETTING_PASSWORD,
        resettingPassword: bool
    }
}

export function changePassword(password, userId, token) {
    let url = `/update-password/${userId}/${token}`
    return async dispatch => {
        dispatch(setUpdatingPassword(true))
        try {
            const result = await userAccountAxios.post(url, {
                password: password
            })
            dispatch(setUpdatingPassword(false))
            if (result.status === 200) {
                toast.success('Password reset. Redirecting you to login page now...')
                setTimeout(() => {
                    window.location.href = '/login'
                }, 2000)
            } else {
                toast.error(result.response.data.Error)
            }
        } catch (error) {
            dispatch(setUpdatingPassword(false))

            toast.error('An error occurred while updating your password. Please try again later.')
        }
    }
}
