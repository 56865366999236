import React from 'react'
import { ReactComponent as CheckboxChecked } from './../assets/img/checkbox_checked.svg'
import { ReactComponent as CheckboxUnchecked } from './../assets/img/checkbox_unchecked.svg'

import { ReactComponent as CheckboxCheckedSmall } from './../assets/img/checkbox_checked_small.svg'
import { ReactComponent as CheckboxUncheckedSmall } from './../assets/img/checkbox_unchecked_small.svg'

import { ReactComponent as CheckboxChecked14 } from './../assets/img/checkbox_checked14.svg'
import { ReactComponent as CheckboxUnchecked14 } from './../assets/img/checkbox_unchecked14.svg'

import { ReactComponent as CheckboxChecked17 } from './../assets/img/checkbox_checked_17x17.svg'
import { ReactComponent as CheckboxUnchecked17 } from './../assets/img/checkbox_unchecked_17x17.svg'

interface IProps {
	id: string
	checked: boolean
	handleChange: Function
	label?: string
	disabled?: boolean
	size?: '14' | '17' | 'small'
	defaultChecked?: boolean
}

const SightlyCheckbox = ({
	id,
	checked,
	handleChange,
	label,
	disabled,
	size,
	defaultChecked
}: IProps) => {
	const [componentChecked, setComponentChecked] = React.useState(false)

	React.useEffect(() => {
		setComponentChecked(defaultChecked ? defaultChecked : checked)
	}, [checked, defaultChecked])

	const handleComponentChange = (bool: boolean) => {
		if (disabled) return
		if (defaultChecked !== undefined) {
			setComponentChecked(bool)
		}

		handleChange(bool)
	}

	if (size === '14') {
		return (
			<div style={{ display: 'flex' }}>
				{componentChecked ? (
					<CheckboxChecked14
						id={id}
						onClick={() => handleComponentChange(false)}
						style={{
							cursor: disabled ? 'not-allowed' : 'pointer',
							alignSelf: 'center',
							width: 16
						}}
					/>
				) : (
					<CheckboxUnchecked14
						id={id}
						onClick={() => handleComponentChange(true)}
						style={{
							width: 16,
							alignSelf: 'center',
							cursor: disabled ? 'not-allowed' : 'pointer'
						}}
					/>
				)}

				{label && (
					<div
						className='unselectable'
						style={{
							marginLeft: 8,
							fontWeight: 600,
							fontSize: 14,
							lineHeight: '22px',
							color: '#333D47'
						}}
					>
						{label}
					</div>
				)}
			</div>
		)
	}

	if (size === '17') {
		return (
			<div style={{ display: 'flex' }}>
				{componentChecked ? (
					<CheckboxChecked17
						id={id}
						onClick={() => handleComponentChange(false)}
						style={{
							cursor: disabled ? 'not-allowed' : 'pointer',
							alignSelf: 'center',
							width: 17
						}}
					/>
				) : (
					<CheckboxUnchecked17
						id={id}
						onClick={() => handleComponentChange(true)}
						style={{
							width: 17,
							alignSelf: 'center',
							cursor: disabled ? 'not-allowed' : 'pointer'
						}}
					/>
				)}

				{label && (
					<div
						className='unselectable'
						style={{
							marginLeft: 8,
							fontWeight: 600,
							fontSize: 14,
							lineHeight: '22px',
							color: '#333D47'
						}}
					>
						{label}
					</div>
				)}
			</div>
		)
	}

	if (size === 'small') {
		return (
			<div style={{ display: 'flex' }}>
				{componentChecked ? (
					<CheckboxCheckedSmall
						id={id}
						onClick={() => handleComponentChange(false)}
						style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
					/>
				) : (
					<CheckboxUncheckedSmall
						id={id}
						onClick={() => handleComponentChange(true)}
						style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
					/>
				)}

				{label && (
					<div
						className='unselectable'
						style={{ marginLeft: 12, color: '#333D47' }}
					>
						{label}
					</div>
				)}
			</div>
		)
	}

	return (
		<div style={{ display: 'flex' }}>
			{componentChecked ? (
				<CheckboxChecked
					id={id}
					onClick={() => handleComponentChange(false)}
					style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
				/>
			) : (
				<CheckboxUnchecked
					id={id}
					onClick={() => handleComponentChange(true)}
					style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
				/>
			)}

			{label && (
				<div
					className='unselectable'
					style={{ marginLeft: 12, color: '#333D47' }}
				>
					{label}
				</div>
			)}
		</div>
	)
}

export default SightlyCheckbox
