import React from 'react'
import { ReactComponent as DotIcon } from '../../../assets/img/dot.svg'
import { SightlyDropdownItem } from '../../../components/SightlyDropdownItem'
import ClickAwayListener from 'react-click-away-listener'
import { useMatch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import useUser from '../../../services/useUser'

interface DropdownItem {
    id: string
    label: string
    enabled: boolean
}

export const MoreButton = ({
    handleDownloadClick,
    handleImplementClick,
    disabled,
    handleBlockClick,
    hideShare
}: {
    handleDownloadClick: Function
    handleImplementClick: Function
    handleBlockClick: Function
    disabled: boolean
    hideShare?: boolean
}) => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const [open, setOpen] = React.useState<boolean>(false)
    const items: DropdownItem[] = [
        {
            id: 'download',
            label: 'Download',
            enabled: userCan(perms.TRENDS_DOWNLOAD)
        },
        {
            id: 'share',
            label: 'Share Implementation',
            enabled: userCan(perms.TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ)
        },
        {
            id: 'block',
            label: 'Block Placements',
            enabled: true
        }
    ]
    const handleClick = (id: DropdownItem['id']) => {
        setOpen(false)
        if (id === 'download') {
            handleDownloadClick()
        }
        if (id === 'share') {
            handleImplementClick()
        }
        if (id === 'block') {
            handleBlockClick()
        }
    }
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div style={{ position: 'relative' }}>
                <div
                    className="unselectable"
                    id="moreButton"
                    onClick={() => (disabled ? null : setOpen(prev => !prev))}
                    style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        height: 40,
                        alignSelf: 'right',
                        justifySelf: 'right',
                        display: 'flex',
                        paddingLeft: 11,
                        paddingRight: 11,
                        borderRadius: 3,
                        backgroundColor: open ? '#395066' : undefined
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 24,
                            paddingLeft: 3,
                            paddingRight: 3,
                            height: 40
                        }}
                    >
                        <DotIcon fill={open ? 'white' : '#395066'} />
                        <DotIcon fill={open ? 'white' : '#395066'} />
                        <DotIcon fill={open ? 'white' : '#395066'} />
                    </div>

                    <div
                        style={{
                            marginLeft: 8,
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: '20px',
                            color: open ? 'white' : '#395066',
                            alignSelf: 'center'
                        }}
                    >
                        More
                    </div>
                </div>

                {open && (
                    <div
                        id="moreDropdown"
                        style={{
                            overflowY: 'auto',
                            zIndex: 999999,
                            backgroundColor: '#F5F5F5',
                            position: 'absolute',
                            top: 48,
                            right: 0
                        }}
                    >
                        {items.map((option, index) => {
                            if (hideShare && option.id === 'share') {
                                return null
                            }
                            return (
                                <SightlyDropdownItem
                                    id={option.label}
                                    backgroundColor={null}
                                    isSelected={false}
                                    key={index}
                                    value={option.id}
                                    label={option.label}
                                    handleClick={() => handleClick(option.id)}
                                    minWidth={240}
                                    disabled={!option.enabled}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    )
}
