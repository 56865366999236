import * as Yup from 'yup'

export const trendsObjValidation = Yup.array().of(
    Yup.object().shape({
        gAdsBlocked: Yup.boolean().required(),
        demo: Yup.boolean().required(),
        brandProfileId: Yup.number().required(),
        articleCount: Yup.number().required(),
        vertical: Yup.string().required(),
        videoCount: Yup.number().required(),
        keywordCount: Yup.number().required(),
        feedback: Yup.string().defined(),
        action: Yup.string().required(),
        editedBy: Yup.string().defined(),
        editedDateTime: Yup.string().defined(),
        editedFromAction: Yup.string().defined(),
        originalRecommendedAction: Yup.string().defined(),
        keywordsFeedback: Yup.string().defined(),
        recommendedActionFeedback: Yup.string().defined(),
        trendName: Yup.string().required(),
        trendId: Yup.string().required()
    })
)

export const trendKeywordsObjVal = Yup.array()
    .of(
        Yup.object().shape({
            keyword: Yup.string().required(),
            rank: Yup.number().required()
        })
    )
    .defined()

export const trendArticlesObjValidation = Yup.array().of(
    Yup.object().shape({
        description: Yup.string(),
        images: Yup.array().of(Yup.string()),
        source: Yup.string(),
        title: Yup.string(),
        url: Yup.string()
    })
)

export const trendScenarioObjValidation = Yup.object().shape({
    recommendedAction: Yup.string().required(),
    scenarios: Yup.array().of(
        Yup.object().shape({
            scenarioId: Yup.number().required(),
            scenarioName: Yup.string().required(),
            feedback: Yup.string().defined(),
            scenarioResponseId: Yup.number().required()
        })
    )
})

export const flattenedScenariosValidation = Yup.array().of(
    Yup.object().shape({
        scenarioId: Yup.number().required(),
        scenarioName: Yup.string().required()
    })
)

export const trendVideosObjValidation = Yup.array().of(
    Yup.object().shape({
        channelId: Yup.string().required(),
        channelTitle: Yup.string().required(),
        trendId: Yup.string().required(),
        feedback: Yup.object().required(),
        videoDescription: Yup.string().defined(),
        videoId: Yup.string().required(),
        videoTitle: Yup.string().required()
    })
)

export const trendsLatestBatchObjValidation = Yup.object().shape({
    date: Yup.date().required(),
    hour: Yup.number().required()
})
