import React from 'react'

interface iProps {
	text: string
	size: 'small' | 'big' | 'xl'
	type: 'blue' | 'pink' | null
}

const SightlyAvatar = ({ text, size, type }: iProps) => {
	const label = React.useMemo(() => {
		try {
			if (!text || text === ' ') return ''
			const wordArray = text.split(' ')
			const first = wordArray[0].charAt(0)
			let second = ''
			if (wordArray[1]) {
				second = wordArray[1].charAt(0)
			}
			return first + second
		} catch {
			return ''
		}
	}, [text])

	const _size = React.useMemo(() => {
		if (size === 'big') {
			return 40
		} else if (size === 'xl') {
			return 120
		}
		return 32
	}, [size])

	const _fontSize = React.useMemo(() => {
		if (size === 'xl') return 59
		return 16
	}, [size])

	return (
		<div
			className='unselectable'
			style={{
				display: 'flex',
				alignItems: 'center',
				width: _size,
				color: '#FFFFFF',
				height: _size,
				borderRadius: _size,
				fontWeight: 600,
				fontSize: _fontSize,
				justifyContent: 'center',
				textTransform: 'uppercase',
				backgroundImage:
					type === 'blue'
						? 'linear-gradient(to right, #26AAE1, #7B61FF)'
						: type === 'pink'
						? 'linear-gradient(to bottom right, #EE7731, #E8178A)'
						: 'linear-gradient(to bottom right, #353D46, rgba(38, 170, 225, 1))'
			}}
		>
			{label}
		</div>
	)
}

export default SightlyAvatar
