import {
    SET_ADMIN_QUESTIONS,
    SET_QUESTIONS_IS_LOADING,
    SET_QUESTION_ARCHIVING,
    SET_QUESTION_TO_ARCHIVE,
    SET_QUESTION_SAVING,
    SET_ADD_QUESTION,
    SET_QUESTION_TO_CREATE
} from '../../action-types/admin/questions'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../../axiosInstances'
import { logError } from '../../../utils'

export function setAdminQuestions(questions) {
    return {
        type: SET_ADMIN_QUESTIONS,
        questions
    }
}

export function setQuestionArchiving(questionId) {
    return {
        type: SET_QUESTION_ARCHIVING,
        questionArchiving: questionId
    }
}

export function setQuestionToArchived(questionId) {
    return {
        type: SET_QUESTION_TO_ARCHIVE,
        questionId
    }
}

export function setInitQuestionAdd(bool) {
    return {
        type: SET_QUESTION_TO_CREATE,
        initQuestionAdd: bool
    }
}

export function setQuestionSaving(bool) {
    return {
        type: SET_QUESTION_SAVING,
        questionSaving: bool
    }
}

export function addQuestion(question) {
    return {
        type: SET_ADD_QUESTION,
        question
    }
}

export function setQuestionsIsLoading(bool) {
    return {
        type: SET_QUESTIONS_IS_LOADING,
        questionsIsLoading: bool
    }
}

export function setQuestions(questions) {
    return {
        type: SET_ADMIN_QUESTIONS,
        questions
    }
}

export function fetchAdminQuestions() {
    let url = `/questions`
    return async dispatch => {
        dispatch(setQuestionsIsLoading(true))
        try {
            const result = await userAccountAxios.get(url)
            if (result.status === 200) {
                let questions = result.data
                dispatch(setQuestions(questions))
                dispatch(setQuestionsIsLoading(false))
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export const createQuestion = question => {
    let url = `/questions`
    return (dispatch, getState) => {
        dispatch(setQuestionSaving(true))
        userAccountAxios
            .post(url, question)
            .then(response => {
                dispatch(addQuestion(response.data[0]))
                dispatch(setQuestionSaving(false))
                toast.success('Question Created')
                dispatch(setInitQuestionAdd(false))
                window.location.reload()
            })
            .catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

export const archiveQuestion = questionId => {
    let url = `/questions/${questionId}`
    return dispatch => {
        dispatch(setQuestionArchiving(questionId))
        userAccountAxios
            .delete(url)
            .then(response => {
                dispatch(setQuestionToArchived(questionId))
                dispatch(setQuestionArchiving(''))
                toast.success('Question Archived')
                window.location.reload()
            })
            .catch(error => {
                toast.error('Error archiving question. See console for more details')
                logError(error)
                console.error(error)
            })
    }
}
