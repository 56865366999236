import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Panel from 'rsuite/lib/Panel'
import { ReactComponent as DropdownArrowRight } from '../../../assets/img/dropdownRight.svg'
import { routes } from '../../../routes'
import toast from 'react-hot-toast'
import TagPicker from 'rsuite/lib/TagPicker'
import Toggle from 'rsuite/lib/Toggle'
import Loader from 'react-loader-spinner'
import { accentColor, brandPinkColor, brandSuccessColor } from '../../../assets/jss/colorContants'
import { userAccountAxios } from '../../../axiosInstances'
import useScenarioTypes from './useScenarioTypes'
import { useMatch, useNavigate } from '@tanstack/react-location'
import { adminScenarioValidation, brandScenarioObjValidation } from '../../../schemas/schemas'
import { logError } from '../../../utils'
import { SelectPicker } from 'rsuite'
import { rqKeys } from '../../../ReactQueryKeyFactory'
import { MyLocationGenerics } from '../../../classes/utils/utils'

interface Scenario {
    scenarioId: number
    archived: boolean
    labelIds: number[]
    labels: string[]
    scenarioName: string
    scdenarioTypeIds: number[]
}

const fetchScenario = async (_scenarioId: number) => {
    let url = `/scenarios/${_scenarioId}`
    const result = await userAccountAxios.get(url)
    adminScenarioValidation.validate(result.data).catch(function(err) {
        logError(err)
        console.log(err.name, err.errors)
        console.error(
            ' we received different data from the api than expected while fetching admin scenario, see console log for more details'
        )
    })
    return result.data
}

const fetchDataSegments = async () => {
    const url = `/scenarios/data-segments`
    const result = await userAccountAxios.get(url)
    return result.data
}

const archiveScenario = async (scenarioId: number) => {
    let url = `/scenarios/${scenarioId}`
    const res = await userAccountAxios.delete(url)
    toast.success('Changes saved')
}

const fetchLabels = async () => {
    let url = `/scenarios/labels`
    const result = await userAccountAxios.get(url)
    let scenarioLabels = result.data
    return scenarioLabels
}

const ScenarioEdit = (props: any) => {
    const {
        params: { scenarioId }
    } = useMatch<MyLocationGenerics>()
    const navigate = useNavigate()
    const scenarioQueryKey = rqKeys.scenario(Number(scenarioId))
    const queryClient = useQueryClient()
    const { data: scenario, isLoading: scenarioIsLoading, isError } = useQuery(
        scenarioQueryKey,
        () => fetchScenario(Number(scenarioId)),
        {
            enabled: !!scenarioId,
            onError: (err: Error) => {
                console.error('error fetching scenario', err.message)
            }
        }
    )

    const dataSegmentsObj = useQuery(rqKeys.scenarioDataSegments(), () => fetchDataSegments())
    const scenarioTypesObj = useScenarioTypes({ excludeArchived: true })
    const { data: scenarioLabels } = useQuery(rqKeys.scenarioLabels(), fetchLabels)

    const handleArchivedChanged = async (archived: boolean) => {
        if (!scenario) return
        if (archived === true) {
            await archiveScenario(scenario?.scenarioId)
        } else {
        }
        queryClient.invalidateQueries(scenarioQueryKey)
    }

    const updateScenario = async (scenarioId: number, scenario: Partial<Scenario>) => {
        let url = `/scenarios/${scenarioId}`
        const res = await userAccountAxios.patch(url, scenario)
        return res.data
    }

    const labelsChange = async (labelId: number, e: any, scenario: Scenario) => {
        const scenarioObj = {
            scenarioName: scenario.scenarioName,
            labelId: labelId
        }
        await updateScenario(scenario.scenarioId, scenarioObj)
        queryClient.invalidateQueries(scenarioQueryKey)
        toast.success('Changes saved')
    }

    const mapScenarioToScenarioTypes = (scenarioId: number, scenarioTypes: number[]) => {
        if (scenarioTypes.length === 0) {
            alert('Unable to process. A scenario must belong to at least one scenario type.')
            window.location.reload()
            return
        }
        const url = `/scenarios/${scenarioId}/scenario-type`

        userAccountAxios
            .patch(url, scenarioTypes)
            .then(response => {
                queryClient.invalidateQueries(scenarioQueryKey)
                toast.success('Changes saved')
            })
            .catch(error => {
                alert(
                    'We were unable to perform this map due to: ' +
                        error?.response?.data?.error +
                        '. Click ok to reload the page.'
                )
                window.location.reload()
            })
    }

    const mapScenarioToDataSegments = (scenarioId: number, dataSegmentIds: number[]) => {
        let url = `/scenarios/${scenarioId}/data-segments`

        userAccountAxios
            .patch(url, dataSegmentIds)
            .then(response => {
                queryClient.invalidateQueries(scenarioQueryKey)
                toast.success('Changes saved')
            })
            .catch(error => {
                alert(
                    'We were unable to perform this map due to: ' +
                        error?.response?.data?.error +
                        '. Click ok to reload the page.'
                )
                window.location.reload()
            })
    }

    if (isError) {
        return <div>There was a problem loading this scenario</div>
    }

    if (!scenario || scenarioIsLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '100vh'
                }}
            >
                <Loader type="Circles" color={accentColor} />
            </div>
        )
    }

    return (
        <div style={{ padding: 24 }}>
            <div
                onClick={() => navigate({ to: routes.admin.scenarios.path })}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 21,
                    justifyContent: 'left',
                    paddingLeft: 18,
                    width: '100%',
                    marginBottom: 24
                }}
            >
                <DropdownArrowRight style={{ transform: 'rotate(180deg)' }} />
                <div style={{ fontWeight: 700, fontSize: 18, color: '#333D47' }}>Back to Scenarios</div>
            </div>

            <Panel header={<h3>{scenario?.scenarioName}</h3>}>
                <Toggle
                    disabled={scenario.archived}
                    onChange={handleArchivedChanged}
                    size="xs"
                    defaultChecked={scenario.archived}
                    checkedChildren="Archived"
                    unCheckedChildren="Active"
                    style={{
                        backgroundColor: scenario.archived ? brandPinkColor : brandSuccessColor,
                        marginBottom: 48
                    }}
                />

                <h4 style={{ marginTop: 48 }}>Label</h4>
                <SelectPicker
                    id="label"
                    cleanable={false}
                    data={scenarioLabels?.filter((labels: any) => !labels.archived)}
                    labelKey="labelName"
                    valueKey="labelId"
                    defaultValue={scenario.labelId}
                    onChange={(v, e) => labelsChange(v, e, scenario)}
                    preventOverflow={true}
                />

                <h4 style={{ marginTop: 48 }}>Scenario Types</h4>
                <TagPicker
                    id="scenarioTypes"
                    cleanable={false}
                    data={scenarioTypesObj.scenarioTypes}
                    labelKey="typeName"
                    valueKey="typeId"
                    defaultValue={scenario.scenarioTypeIds}
                    onChange={(v, e) => {
                        mapScenarioToScenarioTypes(scenario.scenarioId, v)
                    }}
                    style={{ paddingBottom: 2 }}
                    preventOverflow={true}
                />

                <h4 style={{ marginTop: 48 }}>Data Segments</h4>
                <TagPicker
                    id="dataSegments"
                    cleanable={false}
                    data={dataSegmentsObj.data}
                    labelKey="typeName"
                    valueKey="dataSegmentId"
                    defaultValue={scenario.dataSegmentIds}
                    onChange={(v, e) => {
                        mapScenarioToDataSegments(scenario.scenarioId, v)
                    }}
                    style={{ paddingBottom: 2 }}
                    preventOverflow={true}
                />
            </Panel>
        </div>
    )
}

export default ScenarioEdit
