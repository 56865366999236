import { Account } from './account'

interface Permission {
    permissionId: number
    permissionName: string
}

export interface NotificationAccount {
    accountId: number
    accountName: string
}

export enum EmailFreqEnum {
    'immediately' = 1,
    'daily' = 2,
    'weekly' = 3,
    'monthly' = 4
}

export enum NotifPrefsEnum {
    'questionsWeb' = 'questionsWeb',
    'questionsEmail' = 'questionsEmail',
    'questionsFrequency' = 'questionsFrequency',
    'enrichmentWeb' = 'enrichmentWeb',
    'enrichmentEmail' = 'enrichmentEmail',
    'enrichmentFrequency' = 'enrichmentFrequency',
    'newTrendsWeb' = 'newTrendsWeb',
    'newTrendsEmail' = 'newTrendsEmail',
    'newTrendsFrequency' = 'newTrendsFrequency',

    'accountCreatedEmail' = 'accountCreatedEmail',
    'accountCreatedFrequency' = 'accountCreatedFrequency',
    'accountCreatedWeb' = 'accountCreatedWeb',
    'brandProfileCreatedEmail' = 'brandProfileCreatedEmail',
    'brandProfileCreatedFrequency' = 'brandProfileCreatedFrequency',
    'brandProfileCreatedWeb' = 'brandProfileCreatedWeb',
    'brandProfileSubmittedEmail' = 'brandProfileSubmittedEmail',
    'brandProfileSubmittedFrequency' = 'brandProfileSubmittedFrequency',
    'brandProfileSubmittedWeb' = 'brandProfileSubmittedWeb',

    'userCreatedEmail' = 'userCreatedEmail',
    'userCreatedFrequency' = 'userCreatedFrequency',
    'userCreatedWeb' = 'userCreatedWeb'
}

export interface NotifPrefs {
    questionsWeb: boolean
    questionsEmail: boolean
    questionsFrequency: number

    enrichmentWeb: boolean
    enrichmentEmail: boolean
    enrichmentFrequency: number

    newTrendsWeb: boolean
    newTrendsEmail: boolean
    newTrendsFrequency: number

    accountCreatedEmail: boolean
    accountCreatedFrequency: number
    accountCreatedWeb: boolean
    brandProfileCreatedEmail: boolean
    brandProfileCreatedFrequency: number
    brandProfileCreatedWeb: boolean
    brandProfileSubmittedEmail: boolean
    brandProfileSubmittedFrequency: number
    brandProfileSubmittedWeb: boolean

    userCreatedEmail: boolean
    userCreatedFrequency: number
    userCreatedWeb: boolean
}

export interface User {
    userId: number
    firstName: string
    lastName: string
    email: string
    company: string
    userType: string
    roleId: number
    roleName: string
    permissions: Permission[]
    notificationAccounts: NotificationAccount[]
    notificationPreferences: NotifPrefs
    accounts: Account[]
}
