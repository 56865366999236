import * as Yup from 'yup'

export const accountObjValidation = Yup.object().shape({
    accountId: Yup.number().required(),
    accountLevelId: Yup.number().required(),
    accountLevelName: Yup.string().required(),
    accountMargin: Yup.number().required(),
    accountName: Yup.string().required(),
    accountTypeId: Yup.number().required(),
    accountTypeName: Yup.string().required(),
    contactEmail: Yup.string().required(),
    contactName: Yup.string().required(),
    children: Yup.array().of(
        Yup.object().shape({
            accountId: Yup.number().required(),
            accountLevelId: Yup.number().required(),
            accountLevelName: Yup.string().required(),
            accountMargin: Yup.number().required(),
            accountName: Yup.string().required(),
            accountTypeId: Yup.number().required(),
            accountTypeName: Yup.string().required(),
            contactEmail: Yup.string().required(),
            contactName: Yup.string().required(),
            children: Yup.array().nullable()
        })
    )
})

export const rolesAndPermissionsObjValidation = Yup.array()
    .min(1, 'No roles associated with account')
    .of(
        Yup.object().shape({
            roleId: Yup.number().required(),
            roleName: Yup.string().required(),
            roleDescription: Yup.string().required(),
            userType: Yup.string().required(),
            default: Yup.bool()
                .defined()
                .nullable(),
            permissions: Yup.array()
                .of(
                    Yup.object().shape({
                        permissionId: Yup.number().defined(),
                        permissionName: Yup.string().defined(),
                        permissionDescription: Yup.string().defined(),
                        moduleName: Yup.string().defined()
                    })
                )
                .defined()
        })
    )

const notifPrefs = Yup.object().shape({
    questionsWeb: Yup.boolean().required(),
    questionsEmail: Yup.boolean().required(),
    questionsFrequency: Yup.number().required(),

    enrichmentWeb: Yup.boolean().required(),
    enrichmentEmail: Yup.boolean().required(),
    enrichmentFrequency: Yup.number().required(),

    newTrendsWeb: Yup.boolean().required(),
    newTrendsEmail: Yup.boolean().required(),
    newTrendsFrequency: Yup.number().required(),

    accountCreatedEmail: Yup.boolean().required(),
    accountCreatedFrequency: Yup.number().required(),
    accountCreatedWeb: Yup.boolean().required(),
    brandProfileCreatedEmail: Yup.boolean().required(),
    brandProfileCreatedFrequency: Yup.number().required(),
    brandProfileCreatedWeb: Yup.boolean().required(),
    brandProfileSubmittedEmail: Yup.boolean().required(),
    brandProfileSubmittedFrequency: Yup.number().required(),
    brandProfileSubmittedWeb: Yup.boolean().required(),

    userCreatedEmail: Yup.boolean().required(),
    userCreatedFrequency: Yup.number().required(),
    userCreatedWeb: Yup.boolean().required()
})

export const userObjValidation = Yup.object().shape({
    userId: Yup.number().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    company: Yup.string().required(),
    userType: Yup.string().required(),
    roles: Yup.array(),
    accounts: Yup.array().of(accountObjValidation),
    notificationAccounts: Yup.array().of(
        Yup.object().shape({
            accountId: Yup.number().required(),
            accountName: Yup.string().required()
        })
    ),
    notificationPreferences: notifPrefs
})

export const userLoginValidation = Yup.object().shape({
    userId: Yup.number().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    company: Yup.string().required(),
    userType: Yup.string().required(),
    roles: Yup.array(),
    accounts: Yup.array(),
    permissions: Yup.array().of(
        Yup.object().shape({
            permissionId: Yup.number().required(),
            permissionName: Yup.string().required()
        })
    )
})

export const usersObjVal = Yup.array().of(
    Yup.object().shape({
        company: Yup.string().defined(),
        email: Yup.string().defined(),
        firstName: Yup.string().defined(),
        lastName: Yup.string().defined(),
        phoneNumber: Yup.string().defined(),
        roleId: Yup.number().defined(),
        userId: Yup.number().defined(),
        userName: Yup.string().defined(),
        userType: Yup.string().defined()
    })
)

export const brandScenarioObjValidation = Yup.array().of(
    Yup.object().shape({
        archived: Yup.bool().required(),
        scenarioId: Yup.number().required(),
        scenarioName: Yup.string().required()
    })
)

export const adminScenarioValidation = Yup.object().shape({
    archived: Yup.bool().required(),
    scenarioId: Yup.number().required(),
    scenarioName: Yup.string().required(),
    dataSegmentIds: Yup.array()
        .of(Yup.number())
        .defined(),
    scenarioTypeIds: Yup.array()
        .of(Yup.number())
        .defined(),
    labelId: Yup.number().defined()
})

export const brandOpinionObjValidation = Yup.array().of(
    Yup.object().shape({
        archived: Yup.bool().required(),
        opinionId: Yup.number().required(),
        question: Yup.string().required(),
        //opinionType: Yup.string().required(),
        opinionTypeIds: Yup.array().of(Yup.number()),
        opinionResponseId: Yup.string()
    })
)

export const brandTopicObjValidation = Yup.array().of(
    Yup.object().shape({
        children: Yup.array().of(
            Yup.object().shape({
                parentIds: Yup.array().required(),
                topicId: Yup.number().required(),
                topicName: Yup.string().required(),
                topicResponseId: Yup.number().required()
            })
        ),
        parentIds: Yup.array().required(),
        topicId: Yup.number().required(),
        topicName: Yup.string().required(),
        topicResponseId: Yup.number().required()
    })
)

export const aylienAdminVal = Yup.array().of(
    Yup.object().shape({
        id: Yup.number().required(),
        taxonomyCode: Yup.string().required(),
        taxonomyLevel: Yup.number().required(),
        taxonomyName: Yup.string().required()
    })
)

export const embedUrlsObj = Yup.object().shape({
    brandMentalityDashboardEmbedUrl: Yup.string().required(),
    performanceDashboardEmbedUrl: Yup.string().required(),
    performanceDashboardEmbedUrlDemo: Yup.string().required(),
    reportingDashboardEmbedUrl: Yup.string().required(),
    momentsIntelligenceEmbedUrl: Yup.string()
})
