import React from 'react'
import Table from 'rsuite/lib/Table'
import IconButton from 'rsuite/lib/IconButton'
import Dropdown from 'rsuite/lib/Dropdown'
import Whisper from 'rsuite/lib/Whisper'
import Icon from 'rsuite/lib/Icon'
import Popover from 'rsuite/lib/Popover'
import { accentColor } from '../../assets/jss/colorContants.js'
import SightlyButton from '../../components/SightlyButton'
import { routes } from '../../routes.js'
import useUsers from '../../services/useUsers'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../axiosInstances.js'
import PortalModal from '../../components/PortalModal/PortalModal'
import { useNavigate } from '@tanstack/react-location'
import useUser from '../../services/useUser'
import { rqKeys } from '../../ReactQueryKeyFactory'

const ActionCell = ({ rowData, dataKey, customProps, setShowModal, handleDeleteUserClick, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group" style={{ align: 'center', padding: 1 }}>
            <CustomWhisper
                rowData={rowData}
                customProps={customProps}
                setShowModal={setShowModal}
                handleDeleteUserClick={handleDeleteUserClick}
            >
                <IconButton size="lg" appearance="subtle" icon={<Icon icon="more" color={accentColor} />} />
            </CustomWhisper>
        </Table.Cell>
    )
}

const Menu = props => {
    const { accounts, currentAccount, perms, userCan } = useUser()
    const navigate = useNavigate()
    let user = props.rowData

    const handleEditUserClick = user => {
        let url = `/app/settings/users/edit/${user.userId}`
        navigate({ to: url })
    }

    return (
        <Dropdown.Menu onSelect={props.onSelect}>
            {userCan(perms.USER_UPDATE) && (
                <Dropdown.Item onClick={() => handleEditUserClick(user)}>Edit</Dropdown.Item>
            )}

            {userCan(perms.USER_DELETE) && (
                <Dropdown.Item
                    onClick={() => {
                        props.handleDeleteUserClick(user)
                    }}
                >
                    Delete
                </Dropdown.Item>
            )}
        </Dropdown.Menu>
    )
}

const MenuPopover = ({ onSelect, rowData, customProps, setShowModal, handleDeleteUserClick, ...rest }) => (
    <Popover {...rest} full>
        <Menu
            onSelect={onSelect}
            rowData={rowData}
            customProps={customProps}
            setShowModal={setShowModal}
            handleDeleteUserClick={handleDeleteUserClick}
        />
    </Popover>
)

let tableBody

class CustomWhisper extends React.Component {
    constructor(props) {
        super(props)
        this.handleSelectMenu = this.handleSelectMenu.bind(this)
    }
    handleSelectMenu(eventKey, event) {
        this.trigger.hide()
    }
    render() {
        return (
            <Whisper
                placement="topEnd"
                trigger="click"
                triggerRef={ref => {
                    this.trigger = ref
                }}
                container={() => {
                    return tableBody
                }}
                speaker={
                    <MenuPopover
                        rowData={this.props.rowData}
                        onSelect={this.handleSelectMenu}
                        customProps={this.props.customProps}
                        setShowModal={this.props.setShowModal}
                        handleDeleteUserClick={this.props.handleDeleteUserClick}
                    />
                }
            >
                {this.props.children}
            </Whisper>
        )
    }
}

function Users(props) {
    const { data: users, isLoading } = useUsers()

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [showModal, setShowModal] = React.useState(false)
    const [deleteUserAlertIsOpen, setDeleteUserAlertIsOpen] = React.useState(false)
    const [userToDelete, setUserToDelete] = React.useState({})

    const handleDeleteUserClick = user => {
        handleOpenDeleteUserAlert(user)
        setUserToDelete(user)
    }

    const handleCloseDeleteUserAlert = () => {
        setDeleteUserAlertIsOpen(false)
        setUserToDelete({})
    }

    const handleOpenDeleteUserAlert = () => {
        setDeleteUserAlertIsOpen(true)
    }

    const handleDeleteUser = async () => {
        setDeleteUserAlertIsOpen(false)
        const url = `/user/${userToDelete.userId}`
        await userAccountAxios.delete(url)
        queryClient.invalidateQueries(rqKeys.usersKeys(currentAccount?.accountId))
        setUserToDelete({})
        toast.success('Success')
    }

    if (props?.users?.length < 1) {
        return (
            <div
                style={{
                    height: '70vh',
                    textAlign: 'left',
                    padding: '200px 400px 400px 400px'
                }}
            >
                <h3 style={{ color: 'black' }}>
                    {'This account'} has no users directly linked to it; however, users linked to any of its parent
                    accounts will have access to this account.
                </h3>
            </div>
        )
    }

    return (
        <div style={{ padding: 24 }}>
            <PortalModal
                open={deleteUserAlertIsOpen}
                handleClose={() => undefined}
                footerButtons={[
                    <SightlyButton id="yes" handleClick={handleDeleteUser} text="Yes, delete" />,
                    <SightlyButton type="cancel" id="cancel" handleClick={handleCloseDeleteUserAlert} text="Cancel" />
                ]}
            >
                <h4>Are you sure you want to delete this user?</h4>
            </PortalModal>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end'
                }}
            >
                {userCan(perms.USER_CREATE) && (
                    <SightlyButton
                        id="createUserButton"
                        text="Create New User"
                        handleClick={() => navigate({ to: routes.app.settings.users.create.path })}
                    />
                )}
            </div>
            <div>
                <Table autoHeight loading={isLoading} data={users}>
                    <Table.Column>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="userId" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>First Name</Table.HeaderCell>
                        <Table.Cell dataKey="firstName" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Last Name</Table.HeaderCell>
                        <Table.Cell dataKey="lastName" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.Cell dataKey="company" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.Cell dataKey="email" />
                    </Table.Column>

                    <Table.Column width={90}>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                        <ActionCell
                            customProps={props}
                            setShowModal={setShowModal}
                            handleDeleteUserClick={handleDeleteUserClick}
                        />
                    </Table.Column>
                </Table>
            </div>
        </div>
    )
}

export default Users
