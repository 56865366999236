import React from 'react'
import ReactDom from 'react-dom'
import { ReactComponent as CloseIcon } from '../assets/img/close_blue_16x16.svg'
import { ReactComponent as SettingsIcon } from '../assets/img/blue_settings_16x16.svg'
import { ReactComponent as NoNotifications } from '../assets/img/noNotifications.svg'
import { accentColor } from '../assets/jss/colorContants'
import { routes } from '../routes'
import ClickAwayListener from 'react-click-away-listener'
import { User } from '../classes/user'
import { Unarray } from '../classes/utils/utils'
import SightlyAvatar from './SightlyAvatar'
import useIntersectionObserver from '../hooks/useIntersectionObserver'
import { Notification } from '../classes/notification'
import Skeleton from 'react-loading-skeleton'
import NotificationComponent from './NotificationComponent'
import { useQueryClient } from '@tanstack/react-query'
import { notificationsObjValidation } from '../schemas/notifications'
import { userAccountAxios } from '../axiosInstances'
import { useNavigate } from '@tanstack/react-location'
import useUser from '../services/useUser'
import { rqKeys } from '../ReactQueryKeyFactory'

interface IProps {
    open: boolean
    onClose: () => void
    unseenCount: number
}

const NotificationsModal = ({ open, onClose, unseenCount }: IProps) => {
    const navigate = useNavigate()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const modalRoot = document.getElementById('modalPortal') as HTMLElement

    const settingsClicked = () => {
        onClose()
        navigate({ to: routes.app.settings.notificationPreferences.path })
    }

    const [page, setPage] = React.useState(1)
    const [notifs, setNotifs] = React.useState<Notification[]>([])
    const [hasNextPage, setHasNextPage] = React.useState(false)

    React.useEffect(() => {
        getUserNotifications(1)
    }, [])

    const [isLoading, setIsLoading] = React.useState(true)

    const [isFetched, setIsFetched] = React.useState(false)

    const getUserNotifications = async (page: number) => {
        setIsLoading(true)
        const url = `/notifications?page=${page}`
        const { data } = await userAccountAxios.get<Notification[]>(url)

        //	notifications
        notificationsObjValidation.validate(data).catch(function(err) {
            console.error(err)
        })

        if (data.length < 10) {
            setHasNextPage(false)
        } else {
            setHasNextPage(true)
        }
        setNotifs((prev: Notification[]) => {
            return prev.concat(data)
        })
        setIsLoading(false)
        setIsFetched(true)
    }

    const [fetchingMore, setFetchingMore] = React.useState(false)
    const fetchNextPage = async () => {
        setFetchingMore(true)
        await getUserNotifications(page + 1)
        setPage(prev => prev + 1)
        setFetchingMore(false)
    }

    const loadMoreButtonRef: HTMLButtonElement | any = React.useRef()

    useIntersectionObserver({
        root: null,
        target: loadMoreButtonRef,
        onIntersect: hasNextPage ? fetchNextPage : undefined,
        enabled: !!hasNextPage
    })

    const queryClient = useQueryClient()
    const handleMarkUnread = async (notificationId: number) => {
        setNotifs((prev: Notification[]) => {
            return prev.map((notif: Notification) => {
                if (notif.notificationId === notificationId) {
                    notif.seen = false
                }
                return notif
            })
        })
        const url = `/notifications/mark-as-unseen`
        const res = await userAccountAxios.patch(url, {
            notificationIds: [notificationId]
        })

        queryClient.invalidateQueries(rqKeys.unseenNotificationCountKey())
    }

    const handleMarkRead = async (notificationId: number) => {
        setNotifs((prev: Notification[]) => {
            return prev.map((notif: Notification) => {
                if (notif.notificationId === notificationId) {
                    notif.seen = true
                }
                return notif
            })
        })
        const url = `/notifications/mark-as-seen`
        const res = await userAccountAxios.patch(url, {
            notificationIds: [notificationId]
        })

        queryClient.invalidateQueries(rqKeys.unseenNotificationCountKey())
    }

    const handleDelete = async (notificationId: number) => {
        setNotifs((prev: Notification[]) => {
            return prev.filter((n: Notification) => n.notificationId !== notificationId)
        })

        const url = `/notifications/mark-as-deleted`
        const res = await userAccountAxios.patch(url, {
            notificationIds: [notificationId]
        })

        queryClient.invalidateQueries(rqKeys.unseenNotificationCountKey())
    }

    const markAllAsRead = async () => {
        setNotifs((prev: Notification[]) => {
            return prev.map((n: Notification) => {
                n.seen = true
                return n
            })
        })

        const url = `/notifications/mark-all-as-seen`
        const res = await userAccountAxios.patch(url)
        queryClient.invalidateQueries(rqKeys.unseenNotificationCountKey())
    }

    return ReactDom.createPortal(
        <ClickAwayListener onClickAway={onClose} id="notifModal">
            <div
                style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    position: 'fixed',
                    right: 24 + 32 + 16,
                    top: 52,
                    zIndex: 9999,
                    width: 600,
                    height: '90%',
                    borderRadius: 12,
                    backgroundColor: 'white',
                    paddingTop: 16,
                    paddingBottom: 16,
                    boxShadow: 'var(--ds-overlay,0 4px 8px -2px rgba(9,30,66,0.7),0 0 1px rgba(9,30,66,0.7))'
                }}
            >
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 24,
                            paddingRight: 24
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 700,
                                fontSize: 16,
                                lineHeight: '24px',
                                color: 'black',
                                marginRight: 'auto',
                                alignSelf: 'center'
                            }}
                        >
                            Notifications
                        </div>
                        <div style={{ marginLeft: 'auto', display: 'flex' }}>
                            <div
                                id="markAllAsReadButton"
                                onClick={markAllAsRead}
                                className="unselectable"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 12,
                                    lineHeight: '22px',
                                    fontWeight: 500,
                                    color: accentColor,
                                    alignSelf: 'center'
                                }}
                            >
                                Mark all as read
                            </div>
                            <SettingsIcon
                                id="settingsButton"
                                onClick={settingsClicked}
                                style={{
                                    alignSelf: 'center',
                                    cursor: 'pointer',
                                    marginLeft: 16
                                }}
                            />
                            <CloseIcon
                                id="closeModalButton"
                                style={{
                                    alignSelf: 'center',
                                    cursor: 'pointer',
                                    marginLeft: 16
                                }}
                                onClick={onClose}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            marginTop: 8,
                            paddingLeft: 24,
                            paddingRight: 24
                        }}
                    >
                        {user?.notificationAccounts
                            ?.slice(0, 12)
                            .map((account: Unarray<User['notificationAccounts']>) => {
                                return (
                                    <div key={account.accountId} style={{ marginRight: 4 }}>
                                        <SightlyAvatar type="blue" text={account.accountName} size="big" />
                                    </div>
                                )
                            })}
                    </div>

                    <div style={{ marginTop: 24 }}>
                        {notifs.map((notif: Notification) => {
                            return (
                                <NotificationComponent
                                    key={notif.notificationId}
                                    notif={notif}
                                    handleMarkRead={() => handleMarkRead(notif.notificationId)}
                                    handleMarkUnread={() => handleMarkUnread(notif.notificationId)}
                                    handleDelete={() => handleDelete(notif.notificationId)}
                                    onClose={onClose}
                                />
                            )
                        })}

                        {isLoading &&
                            (unseenCount === 0 ? (
                                <Skeleton count={1} height={143} />
                            ) : (
                                <Skeleton count={unseenCount > 9 ? 9 : unseenCount} height={143} />
                            ))}

                        {!hasNextPage && isFetched && notifs.length < 1 && (
                            <>
                                <NoNotifications style={{ marginTop: 64, marginLeft: 169 }} />
                                <div
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 700,
                                        lineHeight: '28px',
                                        color: '#333D47',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        marginTop: 16
                                    }}
                                >
                                    You’ve read all your notifications
                                </div>
                            </>
                        )}

                        {fetchingMore && (
                            <div
                                style={{
                                    height: 104,
                                    position: 'relative',
                                    borderTop: '1px solid #D4D9D9'
                                }}
                            >
                                <Skeleton count={6} />
                            </div>
                        )}
                        {hasNextPage && (
                            <button
                                id="fetchNextPageButton"
                                style={{
                                    backgroundColor: accentColor,
                                    marginBottom: 24,
                                    marginTop: 16,
                                    color: 'white',
                                    marginLeft: 24,
                                    borderRadius: 5
                                }}
                                onClick={fetchNextPage}
                                disabled={fetchingMore}
                            >
                                Load More
                            </button>

                            // <div ref={loadMoreButtonRef}></div>
                        )}
                    </div>
                </div>
            </div>
        </ClickAwayListener>,
        modalRoot
    )
}

export default NotificationsModal
