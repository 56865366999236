import React from 'react'
import { Trend, TrendScenario } from '../../../classes/trend'
import { BrandProfile } from '../../../classes/brandProfile'
import { getMomentScenarioOptions, patchTrendScenarios, fetchTrendScenarios } from '../../../services/trends_ts'
import toast from 'react-hot-toast'
import PortalModal from '../../../components/PortalModal/PortalModal'
import TrendScenarios from '../components/ModalComponents/TrendScenarios'
import ModalHead from '../components/ModalComponents/ModalHead'
import ModalFooter from '../components/ModalComponents/ModalFooter'
import TrendArticles from '../components/ModalComponents/TrendArticles'
import TrendVideos from '../components/ModalComponents/TrendVideos'
import TrendKeywords from '../components/ModalComponents/TrendKeywords'

interface iProps {
    trend: Trend
    trendArticles: Trend['articles']
    trendVideos: Trend['videos']
    trendScenarios: TrendScenario[]
    trendKeywords: Trend['keywords']
    setTrendScenarios: Function
    trendScenariosLoading: boolean
    trendArticlesLoading: boolean
    trendVideosLoading: boolean
    trendKeywordsLoading: boolean
    show: boolean
    onClose: Function
    postTrendRecActionFeedback: Function
    postTrendKeywordsFeedback: Function
    postTrendQuestionFeedback: Function
    postTrendVideoFeedback: Function
    brandProfileId: BrandProfile['brandProfileId'] | null
    updateTrendScenarios: Function
}

const MomentModal = ({
    updateTrendScenarios,
    trend,
    trendArticles,
    trendVideos,
    trendKeywords,
    trendScenarios,
    setTrendScenarios,
    show,
    onClose,
    brandProfileId,
    postTrendRecActionFeedback,
    postTrendKeywordsFeedback,
    postTrendQuestionFeedback,
    postTrendVideoFeedback,
    trendScenariosLoading,
    trendArticlesLoading,
    trendKeywordsLoading,
    trendVideosLoading
}: iProps) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false)

    const [active, setActive] = React.useState('0')
    const [compTrend, setCompTrend] = React.useState<Trend>(trend)
    React.useEffect(() => {
        setCompTrend({ ...trend })
    }, [trend])

    const [scenarios, setScenarios] = React.useState<TrendScenario[]>([])

    React.useEffect(() => {
        if (!brandProfileId || !trend) return

        const proc = async () => {
            const _scenarios = await getMomentScenarioOptions({ brandProfileId, trendId: trend?.trendId })
            setScenarios(_scenarios)
        }

        proc()
    }, [brandProfileId])

    const handleFeedbackRecommendedAction = (feedback: string) => {
        let args = {
            trendId: trend.trendId,
            feedback,
            brandProfileId: brandProfileId
        }
        postTrendRecActionFeedback(args)
    }

    const handleKeywordsFeedback = (feedback: string) => {
        let args = {
            trendId: trend.trendId,
            feedback,
            brandProfileId: brandProfileId
        }
        postTrendKeywordsFeedback(args)
    }

    const handleFeedbackQuestion = (feedback: string, question: string) => {
        let args = {
            trendId: trend.trendId,
            feedback,
            question,
            brandProfileId: brandProfileId
        }
        postTrendQuestionFeedback(args)
    }

    const handleVideoFeedback = (type: string, feedback: string, video: string) => {
        let args = {
            trendId: trend.trendId,
            brandProfileId: brandProfileId,
            feedback: feedback === 'disagree',
            type,
            video
        }

        postTrendVideoFeedback(args)
    }

    const handleFooterNav = (type: 'prev' | 'next') => {
        if (type === 'prev') {
            if (active !== '0') {
                setActive(active => String(Number(active) - 1))
            }
        } else {
            if (active !== '3') {
                setActive(active => String(Number(active) + 1))
            }
        }
    }

    const handleDeleteTrendScenario = (scenarioId: TrendScenario['scenarioId']) => {
        setTrendScenarios((prev: TrendScenario[]) => {
            return prev.filter(s => s.scenarioId !== scenarioId)
        })
        setHasUnsavedChanges(true)
    }

    const [adding, setAdding] = React.useState<boolean>(false)

    const handleAddTrendScenario = (scenario: TrendScenario) => {
        setAdding(false)

        setTrendScenarios((prev: TrendScenario[]) => {
            return prev.concat(scenario)
        })
        setHasUnsavedChanges(true)
    }

    const [patchingScenarios, setPatchingScenarios] = React.useState<boolean>(false)
    const handlePatchTrendScenarios = async () => {
        if (!brandProfileId) {
            return
        }
        setHasUnsavedChanges(false)
        setPatchingScenarios(true)

        const args = {
            brandProfileId,
            trendId: trend.trendId,
            isMoments: true,
            scenarios: trendScenarios.map(s => {
                delete s.feedback
                return s
            })
        }

        const result = await patchTrendScenarios(args)

        const _args = {
            trendId: trend.trendId,
            brandProfileId: trend.brandProfileId,
            isMoments: true
        }

        const _trendScenarios = await fetchTrendScenarios(_args)

        setCompTrend(prev => {
            return {
                ...prev,
                action: _trendScenarios.recommendedAction
            }
        })

        if (result === 200) {
            toast.success('Success')
        } else {
            toast.error('An unexpected error occurred.')
        }
        setPatchingScenarios(false)
        updateTrendScenarios({
            trendId: trend.trendId,
            recommendedAction: _trendScenarios.recommendedAction
        })
    }

    const handleClose = () => {
        onClose()
        setAdding(false)
        setHasUnsavedChanges(false)
    }

    return (
        <PortalModal
            open={show}
            handleClose={handleClose}
            hasClose={true}
            footerButtons={[
                <ModalFooter
                    active={active}
                    handleFooterNav={handleFooterNav}
                    trendScenarios={trendScenarios}
                    hasUnsavedChanges={hasUnsavedChanges}
                    patchingScenarios={patchingScenarios}
                    handlePatchTrendScenarios={handlePatchTrendScenarios}
                />
            ]}
        >
            <div>
                <ModalHead
                    compTrend={compTrend}
                    active={active}
                    setActive={setActive}
                    handleFeedbackRecommendedAction={handleFeedbackRecommendedAction}
                />

                <div style={{ height: '60vh', maxWidth: '80vw', overflowY: 'auto' }}>
                    {active === '0' && (
                        <div>
                            <TrendScenarios
                                trendScenariosLoading={trendScenariosLoading}
                                trendScenarios={trendScenarios}
                                handleFeedbackQuestion={handleFeedbackQuestion}
                                handleDeleteTrendScenario={handleDeleteTrendScenario}
                                adding={adding}
                                handleAddTrendScenario={handleAddTrendScenario}
                                setAdding={setAdding}
                                scenarios={scenarios}
                            />
                        </div>
                    )}

                    {active === '1' && (
                        <div>
                            <TrendArticles
                                compTrend={compTrend}
                                trendArticlesLoading={trendArticlesLoading}
                                trendArticles={trendArticles}
                            />
                        </div>
                    )}

                    {active === '2' && (
                        <TrendVideos
                            trendVideos={trendVideos}
                            compTrend={compTrend}
                            trendVideosLoading={trendVideosLoading}
                            handleVideoFeedback={handleVideoFeedback}
                        />
                    )}

                    {active === '3' && (
                        <TrendKeywords
                            trendKeywords={trendKeywords}
                            trend={trend}
                            compTrend={compTrend}
                            trendKeywordsLoading={trendKeywordsLoading}
                            handleKeywordsFeedback={handleKeywordsFeedback}
                        />
                    )}
                </div>
            </div>
        </PortalModal>
    )
}

export default MomentModal
