import toast from 'react-hot-toast'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { userAccountAxios } from '../../../../../axiosInstances'
import { scenariosObjValidation } from '../../../../../schemas/brandProfiles'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

const getBrandProfileScenarios = async (brandProfileId: number) => {
    let url = `/brand-profile/${brandProfileId}/scenarios`

    try {
        const result = await userAccountAxios.get(url)
        if (result.status === 200) {
            scenariosObjValidation.validate(result.data.slice(0, 3)).catch(function(err) {
                console.log(err.name, err.errors)
                console.error(
                    ' we received different data from the api than expected while fetching brand profile scenarios, see console log for more details'
                )
            })
        }
        return result.data
    } catch (error) {
        console.error(error)
    }
}

export const patchBrandProfileScenarios = async (data: any) => {
    let brandProfileId = data.brandProfileId
    const scenarios = data.scenarios
    const url = `/brand-profile/${brandProfileId}/scenarios`
    await userAccountAxios.patch(url, scenarios)
}

const useScenarios = (brandProfileId: number) => {
    const queryClient = useQueryClient()
    const QUERY_KEY = rqKeys.brandProfileScenarios(brandProfileId)
    const { data: scenarios, isLoading } = useQuery(QUERY_KEY, () => getBrandProfileScenarios(brandProfileId), {
        enabled: !!brandProfileId
    })

    const mutateScenarios = useMutation(
        (args: any) => {
            return patchBrandProfileScenarios(args)
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(QUERY_KEY)
            },
            onSuccess(data, variables, context) {
                toast.success('Changes saved')
            }
        }
    )

    return {
        scenarios,
        scenariosIsLoading: isLoading,
        handleScenarioChange: mutateScenarios.mutate
    }
}

export default useScenarios
