import React from 'react'
import Icon from 'rsuite/lib/Icon'

export default function CustomPasswordMatchChecker({
	password,
	password_confirmation
}) {
	return (
		<div style={{ display: 'flex', width: 300, paddingLeft: 0, paddingTop: 0 }}>
			<Icon
				icon='check-circle'
				style={{
					color:
						password === password_confirmation && password.length > 0
							? 'green'
							: undefined,
					marginRight: 24
				}}
			/>

			<div>Passwords Match</div>
		</div>
	)
}
