import React from 'react'
import PortalModal from '../../../../../components/PortalModal/PortalModal'
import SightlyButton from '../../../../../components/SightlyButton'
import SightlySelectForm from '../../../../../components/Sightly/SightlySelectForm'
import SightlyInputForm from '../../../../../components/Sightly/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { socialObjValidation } from '../../../../../schemas/brandProfiles'
import toast from 'react-hot-toast'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { accentColor } from '../../../../../assets/jss/colorContants'
import { Social } from './SocialSection'
import Checkbox from 'rsuite/lib/Checkbox'
import { userAccountAxios } from '../../../../../axiosInstances'
import { useMatch } from '@tanstack/react-location'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

enum fields {
    'name' = 'name',
    'id' = 'id',
    'wikiUrl' = 'wikiUrl',
    'description' = 'description',
    'causeIsNotListed' = 'causeIsNotListed',
    'relationshipId' = 'relationshipId',
    'aylienId' = 'aylienId'
}

type IProps = {
    item: Social
    open: boolean
    handleClose: () => void
    disabled?: boolean
    type: 'edit' | 'add'
}

type AylienDropdownItem = {
    id: number
    aylienTaxonomyCode: string
    aylienTaxonomyName: string
    aylienTaxonomyDescription: string
}

const getDropdownItems = async (): Promise<AylienDropdownItem[]> => {
    const url = '/brand-profile/SNEDropdown'
    const res = await userAccountAxios.get(url)
    return res.data
}

const SocialModal = ({ item, open, handleClose, disabled, type }: IProps) => {
    const {
        params: { brandProfileId }
    } = useMatch()
    const queryClient = useQueryClient()
    const [isSaving, setIsSaving] = React.useState(false)

    const dropdownItems = useQuery(rqKeys.socialAylien(), getDropdownItems)

    const { register, handleSubmit, reset, control, formState, setValue, watch, getValues } = useForm<Social>({
        defaultValues: item,
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(socialObjValidation)
    })

    const _handleClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: Partial<Social>) => {
        const items = queryClient.getQueryData<Social[]>(['social', brandProfileId])
        if (data.name === null) {
            console.error('data.name cannot be null')
            return
        }
        const threshold = type === 'edit' ? 1 : 0
        const nameIsDuplicate =
            items &&
            items.filter(item => data.name && item?.name?.toLowerCase() === data.name.toLowerCase()).length > threshold
        if (nameIsDuplicate) {
            toast.error('This name is already used. Please pick a different name and try again.')
            return
        }
        setIsSaving(true)
        delete data.id
        delete data.relationshipName

        const _args: any = {
            ...data
        }

        if (!data.causeIsNotListed) {
            const aylienItem = dropdownItems.data && dropdownItems.data.filter(i => i.id === data.aylienId)[0]
            if (!aylienItem) {
                console.error('aylienItem cannot be undefined')
                return
            }
            if (aylienItem?.aylienTaxonomyCode.includes('ay.')) {
                _args.aylienNewsId = aylienItem.id
                _args.aylienIndustriesId = null
            } else {
                _args.aylienIndustriesId = aylienItem.id
                _args.aylienNewsId = null
            }
            _args.name = aylienItem.aylienTaxonomyName
        } else {
            _args.aylienIndustriesId = null
            _args.aylienNewsId = null
        }
        delete _args.aylienId
        if (!_args.aylienIndustriesId) {
            _args.aylienIndustriesId = null
        }
        if (!_args.aylienNewsId) {
            _args.aylienNewsId = null
        }

        try {
            if (type === 'edit') {
                const url = `/brand-profile/${brandProfileId}/socialAndEnvironmental/${item.id}`
                await userAccountAxios.patch(url, _args)
                toast.success('Saved')
            } else {
                const url = `/brand-profile/${brandProfileId}/socialAndEnvironmental`
                await userAccountAxios.post(url, _args)
                toast.success('Added')
                reset()
            }
            queryClient.invalidateQueries(rqKeys.brandProfileSocial(Number(brandProfileId)))
        } catch (err) {
            toast.error('Error')
            setIsSaving(false)
        }
        setIsSaving(false)
    }

    const errors = formState.errors

    React.useEffect(() => {
        if (item && type === 'edit') {
            reset(item)
        }
    }, [item])

    const watchCauseIsNotListed = watch(fields.causeIsNotListed)

    const watchName = watch(fields.name)

    React.useEffect(() => {
        if (!watchName) {
            return
        }
        const item = dropdownItems?.data?.filter(i => i.aylienTaxonomyName === watchName)[0]
        if (!item) {
            return
        }
        setValue(fields.description, item.aylienTaxonomyDescription as never, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [watchName])

    const watchAylienId = watch(fields.aylienId)

    React.useEffect(() => {
        if (!watchAylienId) {
            return
        }
        if (watchCauseIsNotListed) {
            return
        }
        const item = dropdownItems?.data?.filter(i => i.id === watchAylienId)[0]
        if (!item) {
            return
        }
        setValue(fields.name, item.aylienTaxonomyName as never, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [watchAylienId])

    React.useEffect(() => {
        if (watchCauseIsNotListed) {
            setValue(fields.aylienId, null as never, {
                shouldValidate: true,
                shouldDirty: true
            })
        } else {
        }
    }, [watchCauseIsNotListed])

    React.useEffect(() => {
        var year = new Date().getFullYear()
        if (!document || document === null) {
            return
        }
        document.getElementById('date')?.setAttribute('min', year + '-01-01')
        document.getElementById('date')?.setAttribute('max', year + '-12-31')
    }, [])

    return (
        <PortalModal
            hasClose={true}
            handleClose={_handleClose}
            open={open}
            footerButtons={[
                <SightlyButton
                    id="rename"
                    loading={isSaving}
                    disabled={!formState.isValid || !formState.isDirty}
                    text={type === 'edit' ? 'Save' : 'Add'}
                    handleClick={handleSubmit(onSubmit)}
                    type="primary"
                />
            ]}
        >
            <h4 style={{ marginBottom: 30, width: 376 }}>
                {type === 'edit' ? `Edit Social Issue` : `Add New Social Issue`}
            </h4>

            <div style={{ width: 450, justifyContent: 'center', marginBottom: 24 }}>
                Please select any social or environmental causes that are relevant to your brand from the dropdown
                provided. If you don’t see your cause in the list, please provide some additional information in the
                optional fields below.
            </div>

            <div className="form-container">
                <div>
                    {watchCauseIsNotListed ? (
                        <SightlyInputForm
                            control={control}
                            error={errors?.name?.message}
                            name={fields.name}
                            register={register}
                            id="nameField"
                            label="Name"
                            disabled={disabled}
                        />
                    ) : (
                        <SightlySelectForm
                            label="Name"
                            id="name"
                            labelKey="aylienTaxonomyName"
                            valueKey="id"
                            name={fields.aylienId}
                            options={dropdownItems.data}
                            control={control}
                            disabled={disabled}
                        />
                    )}

                    <Checkbox
                        style={{ marginTop: 8 }}
                        checked={watchCauseIsNotListed}
                        onChange={(val: any, checked: boolean) => {
                            setValue(fields.name, '' as never, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                            setValue(fields.causeIsNotListed, checked as never, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                        }}
                    >
                        My cause is not listed
                    </Checkbox>
                </div>

                <SightlyInputForm
                    control={control}
                    error={errors?.description?.message}
                    name={fields.description}
                    register={register}
                    id="description"
                    label="Description"
                    disabled={disabled}
                    textarea
                />

                <SightlyInputForm
                    control={control}
                    error={errors?.wikiUrl?.message}
                    name={fields.wikiUrl}
                    register={register}
                    id="WikipediaLink"
                    label="Wikipedia Link"
                    disabled={disabled}
                    prefix="en.wikipedia.org/wiki/"
                />

                <div>
                    <SightlySelectForm
                        label="Relationship"
                        id="relationship"
                        labelKey="name"
                        valueKey="id"
                        name={fields.relationshipId}
                        options={[
                            { id: 1, name: 'Issue I want to associate with' },
                            { id: 2, name: 'Issue I want to avoid' }
                        ]}
                        control={control}
                        disabled={disabled}
                    />
                </div>

                {type === 'add' && formState.isDirty && (
                    <div
                        onClick={() => reset()}
                        className="sightlyLink"
                        style={{
                            position: 'absolute',
                            bottom: 24,
                            left: 24,
                            color: accentColor
                        }}
                    >
                        Clear
                    </div>
                )}
            </div>

            <style>{`
				.form-container {
					display: grid;
					gap: 24px;				
					grid-template-columns: repeat(auto-fit);			
				}		
			`}</style>
        </PortalModal>
    )
}

export default SocialModal
