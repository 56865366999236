import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { competitorsObjValidation, influencersObjValidation } from '../../../../../schemas/brandProfiles'
import Table from 'rsuite/lib/Table'
import SightlyButton from '../../../../../components/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '../../../../../assets/jss/colorContants'
import { CustomWhisper } from '../../../../../components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import QueryTypeOneModal from './QueryTypeOneModal'
import Panel from 'rsuite/lib/Panel'
import { userAccountAxios } from '../../../../../axiosInstances'
import { typeOneSections } from './constants'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useUser from '../../../../../services/useUser'
import { TableColumnJs } from '../../../../../utils'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

export type SectionTypeOne = {
    id: number
    name: string
    twitterProfileUrl: string
    websiteUrl?: string
    wikiUrl: string
    relationshipId: number
    relationshipName?: string
}

const getItems = async (brandProfileId: number, queryType: typeOneSections) => {
    let url = `/brand-profile/${brandProfileId}/${queryType}`

    const result = await userAccountAxios.get<SectionTypeOne[]>(url)

    if (result.status === 200) {
        if (result.data.length > 0) {
            const validation =
                queryType === typeOneSections.influencers ? influencersObjValidation : competitorsObjValidation
            validation.validate(result.data).catch(function(err) {
                console.error(err)
            })
        }
    }

    return result.data
}

type QueryProps = {
    queryType: typeOneSections
}

const QueryTypeOneSection = ({ queryType }: QueryProps) => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()
    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const queryClient = useQueryClient()

    const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
    const viewOnly = viewOnlyParam || false
    const QUERY_KEY = rqKeys.brandProfileQuery(queryType, Number(brandProfileId))
    const { data: items, isLoading, isFetched } = useQuery(
        QUERY_KEY,
        () => getItems(Number(brandProfileId), queryType),
        { enabled: !!brandProfileId, refetchOnWindowFocus: false }
    )

    const [editing, setEditing] = React.useState(false)
    const [editingId, setEditingId] = React.useState<number | null>(null)

    const [adding, setAdding] = React.useState(false)

    const editingCompetitor = React.useMemo(() => {
        if (!editingId || !isFetched || !items || !editing) return null

        const item = items.filter((c: SectionTypeOne) => c.id === editingId)[0]

        if (!item) return null
        return item
    }, [editingId, editing, items])

    const handleDelete = async (id: number) => {
        const url = `/brand-profile/${brandProfileId}/${queryType}/${id}`
        try {
            await userAccountAxios.delete(url)
            toast.success('Deleted')
        } catch (err) {
            toast.error('Error')
        }
        queryClient.invalidateQueries(QUERY_KEY)
    }

    const descriptionText = React.useMemo(() => {
        if (queryType === typeOneSections.competitors) {
            return `	Please enter your brand's competitors and aspirational brands.
			Aspirational brands need not be competitors, or even in the same
			industry as your brand.`
        }
        if (queryType === typeOneSections.philanthropic) {
            return `Please enter any philanthropic or charitable organizations that are relevant to your brand. Ex: Red Cross Society, Wounded Warrior Project, etc.`
        }
        if (queryType === typeOneSections.influencers) {
            return `Please enter any celebrities, influencers, and activists who are
			relevant to your brand.`
        }
    }, [queryType])

    const headerText = React.useMemo(() => {
        if (queryType === typeOneSections.competitors) {
            return `Brands`
        }

        if (queryType === typeOneSections.philanthropic) {
            return `Philanthropic`
        }
        if (queryType === typeOneSections.influencers) {
            return `Influencers`
        }
    }, [queryType])

    return (
        <Panel header={<div className="sightlyPanelHeader">{headerText}</div>}>
            {!!editingCompetitor && (
                <QueryTypeOneModal
                    key="edit"
                    type="edit"
                    open={editing}
                    handleClose={() => {
                        setEditing(false)
                        setEditingId(null)
                    }}
                    item={editingCompetitor}
                    queryType={queryType}
                />
            )}

            <QueryTypeOneModal
                key="add"
                type="add"
                open={adding}
                handleClose={() => setAdding(false)}
                item={
                    queryType === typeOneSections.influencers
                        ? {
                              id: 456456546456456564,
                              name: '',
                              wikiUrl: '',
                              twitterProfileUrl: '',
                              relationshipId: 1
                          }
                        : {
                              id: 456456546456456564,
                              name: '',
                              wikiUrl: '',
                              twitterProfileUrl: '',
                              websiteUrl: '',
                              relationshipId: 1
                          }
                }
                queryType={queryType}
            />

            <div className="descriptionText">{descriptionText}</div>
            {!viewOnly && items && items.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <SightlyButton
                        disabled={!userCan(perms.BRAND_PROFILE_UPDATE)}
                        handleClick={() => setAdding(true)}
                        id="addMorebutton"
                        text="Create New"
                    />
                </div>
            )}

            {isFetched && items && items.length < 1 ? (
                <SightlyButton
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                    handleClick={() => setAdding(true)}
                    id="addMorebutton"
                    type="secondary"
                    text="Add +"
                />
            ) : (
                isFetched && (
                    <Table loading={isLoading} autoHeight data={items}>
                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell style={{ display: 'flex', alignItems: 'center' }} dataKey="relationshipName" />
                        </TableColumnJs>

                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell>
                                {(item: SectionTypeOne) => {
                                    return <>{item.name}</>
                                }}
                            </Table.Cell>
                        </TableColumnJs>

                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Twitter</Table.HeaderCell>
                            <Table.Cell dataKey="twitterProfileUrl" />
                        </TableColumnJs>

                        {queryType !== typeOneSections.influencers && (
                            <TableColumnJs flexGrow={1}>
                                <Table.HeaderCell>Website</Table.HeaderCell>
                                <Table.Cell dataKey="websiteUrl" />
                            </TableColumnJs>
                        )}

                        <TableColumnJs flexGrow={1}>
                            <Table.HeaderCell>Wiki</Table.HeaderCell>
                            <Table.Cell dataKey="wikiUrl" />
                        </TableColumnJs>

                        {!viewOnly && (
                            <TableColumnJs flexGrow={undefined}>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                    {(item: SectionTypeOne) => {
                                        return (
                                            <CustomWhisper
                                                dropdownItems={[
                                                    <Dropdown.Item
                                                        key={1}
                                                        onClick={() => {
                                                            setEditingId(item.id)
                                                            setEditing(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>,
                                                    <Dropdown.Item key={2} onClick={() => handleDelete(item.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                ]}
                                            >
                                                <IconButton
                                                    size="lg"
                                                    appearance="subtle"
                                                    icon={<Icon icon="more" color={accentColor} />}
                                                />
                                            </CustomWhisper>
                                        )
                                    }}
                                </Table.Cell>
                            </TableColumnJs>
                        )}
                    </Table>
                )
            )}
            <style>{`
				.descriptionText {
					color: #a3a3a3;
					font-size: 14px;
					margin-top: 24px;
					margin-bottom: 24px;
					font-weight: 500;									
				}		
			`}</style>
        </Panel>
    )
}

export default QueryTypeOneSection
