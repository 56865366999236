import React from 'react'
import toast from 'react-hot-toast'
import { ReactComponent as ThumbsUp } from '../assets/img/thumbs-up-solid.svg'
import { ReactComponent as ThumbsDown } from '../assets/img/thumbs-down-solid.svg'
import { ReactComponent as ThumbsUpOutline } from '../assets/img/thumbs-up-regular.svg'
import { ReactComponent as ThumbsDownOutline } from '../assets/img/thumbs-down-regular.svg'

const FeedbackButton = ({
	buttonState,
	handleClick,
	text,
	textColor,
	handleDownClick
}) => {
	const [componentState, setComponentState] = React.useState(null)

	React.useEffect(() => {
		setComponentState(buttonState)
	}, [buttonState])

	const handleButtonClick = (type) => {
		if (componentState === type) {
			type = null
		}

		if (type === 'dislike' && handleDownClick) {
			handleDownClick()
		}
		setComponentState(type)
		handleClick(type)

		toast.success('Thanks for the feedback!', { duration: 1000 })
	}

	return (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					fontWeight: 500,
					fontSize: 12,
					lineHeight: '20px',
					color: textColor ? textColor : '#333D47'
				}}
			>
				{text}
			</div>
			<div style={{ marginLeft: 9.99, marginTop: 2 }}>
				{componentState === 'like' ? (
					<ThumbsUp
						style={{ height: 16, width: 16, cursor: 'pointer' }}
						onClick={() => handleButtonClick('like')}
					/>
				) : (
					<ThumbsUpOutline
						style={{ height: 16, width: 16, cursor: 'pointer' }}
						onClick={() => handleButtonClick('like')}
					/>
				)}
			</div>

			<div style={{ marginLeft: 9.99, marginTop: 2 }}>
				{componentState === 'dislike' ? (
					<ThumbsDown
						style={{ height: 16, width: 16, cursor: 'pointer' }}
						onClick={() => handleButtonClick('dislike')}
					/>
				) : (
					<ThumbsDownOutline
						style={{ height: 16, width: 16, cursor: 'pointer' }}
						onClick={() => handleButtonClick('dislike')}
					/>
				)}
			</div>
		</div>
	)
}

export default FeedbackButton
