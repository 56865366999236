import React from 'react'
import { accentColor } from '../assets/jss/colorContants'
import { Notification, NotifUrl } from '../classes/notification'
import SightlyAvatar from './SightlyAvatar'
import { getCalendarDate } from '../utils'
import { SightlyDropdownItem } from './SightlyDropdownItem'
import ClickAwayListener from 'react-click-away-listener'
import { routes } from '../routes'
import TextTruncate from 'react-text-truncate'
import { useNavigate } from '@tanstack/react-location'

interface IProps {
    notif: Notification
    handleMarkUnread: Function
    handleMarkRead: Function
    handleDelete: Function
    onClose: () => void
}

const NotificationComponent = ({ notif, handleMarkUnread, handleMarkRead, handleDelete, onClose }: IProps) => {
    const navigate = useNavigate()
    const [menuIsOpen, setMenuIsOpen] = React.useState(false)

    const _handleDelete = () => {
        setMenuIsOpen(false)
        handleDelete()
    }

    const _handleTurnOffType = () => {
        setMenuIsOpen(false)
        navigate({ to: routes.app.settings.notificationPreferences.path })
    }

    const handleGoToUrl = async (url: string) => {
        await handleMarkRead()
        window.location.href = url
    }

    const isMulti = React.useMemo(() => {
        return notif.notificationTypeId === 1 || notif.notificationTypeId === 2 || notif.notificationTypeId === 3
    }, [notif])

    return (
        <div
            className="sightlyNotification"
            style={{
                //		height: 104,
                position: 'relative',
                borderTop: '1px solid rgba(212, 217, 217, .5)'
            }}
            key={notif.notificationId}
        >
            <div style={{ display: 'flex', paddingTop: 16, paddingBottom: 16 }}>
                <div style={{ marginLeft: 24 }}>
                    <SightlyAvatar type="blue" text={notif.accountName} size="big" />
                </div>

                <div style={{ marginLeft: 16 }}>
                    <div
                        id="notifBody"
                        onClick={() => (isMulti ? undefined : handleGoToUrl(notif.urls[0].url))}
                        className={isMulti ? undefined : 'sightlyLink'}
                        style={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: 'black',
                            width: 437
                            //cursor: 'pointer'
                        }}
                    >
                        <TextTruncate line={2} text={notif.body} />
                    </div>

                    {isMulti && (
                        <div style={{ marginTop: 8 }}>
                            {notif.urls.map((url: NotifUrl, index: number) => {
                                return (
                                    <div key={index} style={{ display: 'flex', color: 'black' }}>
                                        <div
                                            style={{
                                                alignSelf: 'center',
                                                height: 5,
                                                width: 5,
                                                backgroundColor: 'black',
                                                borderRadius: 45,
                                                marginLeft: 8
                                            }}
                                        />
                                        <div
                                            id="notifName"
                                            onClick={() => handleGoToUrl(url.url)}
                                            className="sightlyLink"
                                            style={{
                                                alignSelf: 'center',
                                                marginLeft: 8,
                                                fontWeight: 600,
                                                fontSize: 16,
                                                lineHeight: '24px'
                                            }}
                                        >
                                            {url.title}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    <div
                        style={{
                            marginTop: 12,
                            fontWeight: 500,
                            fontSize: 12,
                            lineHeight: '20px',
                            color: '#696969',
                            cursor: 'default'
                        }}
                    >
                        {getCalendarDate(notif.createdDate)}
                    </div>
                </div>

                <div style={{ marginRight: 16, alignSelf: 'center' }}>
                    {!notif.seen && (
                        <div
                            id="markReadButton"
                            onClick={() => handleMarkRead()}
                            style={{
                                cursor: 'pointer',
                                height: 16,
                                width: 16,
                                backgroundColor: 'white',
                                borderRadius: 45,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    height: 7,
                                    width: 7,
                                    backgroundColor: accentColor,
                                    borderRadius: 45,
                                    alignSelf: 'center'
                                }}
                            />
                        </div>
                    )}

                    {notif.seen && (
                        <div
                            id="markUnreadButton"
                            onClick={() => handleMarkUnread()}
                            style={{
                                cursor: 'pointer',
                                height: 16,
                                width: 16,
                                backgroundColor: 'white',
                                borderRadius: 45,
                                border: '1px solid #C4C4C4',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        ></div>
                    )}
                </div>

                <div
                    className="hoverWhite"
                    id="menuToggleButton"
                    onClick={() => setMenuIsOpen(prev => !prev)}
                    style={{
                        marginLeft: 'auto',
                        marginRight: 10,
                        padding: 8,
                        alignSelf: 'center',
                        cursor: 'pointer',
                        borderRadius: 6,
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            height: 4,
                            width: 4,
                            borderRadius: 45,
                            backgroundColor: '#C4C4C4'
                        }}
                    />
                    <div
                        style={{
                            height: 4,
                            width: 4,
                            borderRadius: 45,
                            backgroundColor: '#C4C4C4',
                            marginTop: 4
                        }}
                    />
                    <div
                        style={{
                            height: 4,
                            width: 4,
                            borderRadius: 45,
                            backgroundColor: '#C4C4C4',
                            marginTop: 4
                        }}
                    />

                    {menuIsOpen && (
                        <div
                            style={{
                                position: 'absolute',
                                right: 25,
                                top: 32,
                                zIndex: 99999
                            }}
                        >
                            <ClickAwayListener id="menuOpenItem" onClickAway={() => setMenuIsOpen(false)}>
                                <>
                                    <SightlyDropdownItem
                                        id="deleteDropdownItem"
                                        isSelected={false}
                                        key={0}
                                        value={null}
                                        label={'Delete Notification'}
                                        handleClick={_handleDelete}
                                        minWidth={240}
                                        backgroundColor={null}
                                        disabled={false}
                                    />
                                    <SightlyDropdownItem
                                        id="turnOffDropdownItem"
                                        isSelected={false}
                                        key={1}
                                        value={null}
                                        label={'Turn off this notification type'}
                                        handleClick={_handleTurnOffType}
                                        minWidth={240}
                                        backgroundColor={null}
                                        disabled={false}
                                    />
                                </>
                            </ClickAwayListener>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NotificationComponent
