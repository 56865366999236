import React from 'react'
import Message from 'rsuite/lib/Message'
import { connect } from 'react-redux'
import {
    fetchBrandProfileBasic,
    fetchBrandProfileCompletionStatus,
    submitBrandProfile
} from '../../../../../redux/actions/brandProfiles'
import ConfirmSubmit from './ConfirmSubmit'
import SightlyButton from '../../../../../components/SightlyButton'
import { useMatch, useNavigate } from '@tanstack/react-location'
import { brandProfileLoading } from '../../../../../redux/reducers/brandProfiles'
import { LoadingPage } from '../../../../../components/LoadingPage'

const mapDispatchToProps = dispatch => {
    return {
        fetchBrandProfileCompletionStatus: brandProfileId =>
            dispatch(fetchBrandProfileCompletionStatus(brandProfileId)),
        submitBrandProfile: brandProfileId => dispatch(submitBrandProfile(brandProfileId)),
        fetchBrandProfileBasic: id => dispatch(fetchBrandProfileBasic(id))
    }
}

const mapStateToProps = state => {
    return {
        brandProfileSubmitting: state.brandProfileSubmitting,
        brandProfileSubmitted: state.brandProfileSubmitted,
        brandProfileLoading: state.brandProfileLoading,
        brandProfile: state.brandProfileUnderEdit
    }
}

const Review = props => {
    const {
        params: { brandProfileId }
    } = useMatch()

    const navigate = useNavigate()

    const [basicFetched, setBasicFetched] = React.useState(false)

    React.useEffect(() => {
        if (brandProfileId && !basicFetched) {
            props.fetchBrandProfileBasic(brandProfileId)
            setBasicFetched(true)
        }
    }, [brandProfileId])

    const [fetched, setFetched] = React.useState(false)
    const goToBrandInformationSection = () => {
        let currentUrl = window.location.pathname
        let newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/')) + `/brandInformation`
        navigate({ to: newUrl })
    }

    React.useEffect(() => {
        if (!fetched && props.brandProfile.brandProfileId !== '') {
            props.fetchBrandProfileCompletionStatus(props.brandProfile.brandProfileId)
            setFetched(true)
        }
    })

    if (props.brandProfileLoading) {
        return <LoadingPage message="Fetching status..." />
    }

    if (props.brandProfile.submitted) {
        return <Message showIcon type="success" title="Success!" description="This brand profile is submitted." />
    }

    if (
        !props.brandProfile.brandProfileNameComplete ||
        !props.brandProfile.twitterComplete ||
        !props.brandProfile.websiteComplete
    ) {
        return (
            <Message
                showIcon
                type="warning"
                title="We need more info!"
                description={
                    <p style={{ fontSize: 16 }}>
                        Please complete all of the required fields in the{' '}
                        <a onClick={goToBrandInformationSection} style={{ cursor: 'pointer' }}>
                            Brand Information section
                        </a>{' '}
                        before submitting.
                    </p>
                }
            />
        )
    }

    return (
        <div
            style={{
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 24
            }}
        >
            {!props.brandProfile.complete ? (
                <Message
                    showIcon
                    type="warning"
                    title="We need more info!"
                    description={<ConfirmSubmit brandProfile={props.brandProfile} />}
                />
            ) : (
                <Message
                    showIcon
                    type="success"
                    title="Ready to submit!"
                    description={'Your brand profile is ready to submit'}
                />
            )}
            <div style={{ width: 200 }}>
                <SightlyButton
                    block
                    id={'submitBrandProfileButton'}
                    text="Submit"
                    loading={props.brandProfileSubmitting}
                    handleClick={() => props.submitBrandProfile(brandProfileId)}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Review)
