import React from 'react'
import { connect } from 'react-redux'
import Loader from 'rsuite/lib/Loader'
import Uploader from 'rsuite/lib/Uploader'
import Panel from '../../../components/CustomPanel'
import Button from 'rsuite/lib/Button'
import Whisper from 'rsuite/lib/Whisper'
import debounce from 'just-debounce-it'
import Tooltip from 'rsuite/lib/Tooltip'
import { targetTypes } from './constants'
import { setUploadedList } from '../../../redux/actions/engage/lists'
import { uploadedListObjValidation } from '../../../schemas/Engage/Lists/schemas'
import toast from 'react-hot-toast'
import SightlyAlert from '../../../components/SightlyAlert'
import { accentColor } from '../../../assets/jss/colorContants'
import { ReactComponent as WarningImage } from '../../../assets/img/warning.svg'
import numeral from 'numeral'
import { routes } from '../../../routes'
import { postUploadedListService } from '../../../services/lists'
import Input from 'rsuite/lib/Input'
import SelectPicker from 'rsuite/lib/SelectPicker'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import SightlyButton from '../../../components/SightlyButton'
import config from '../../../config'
import { useMatch, useNavigate } from '@tanstack/react-location'
import useUser from '../../../services/useUser'
const parseExcelUrl = config.api.listBuilderUrl + '/parse-excel'

const mapStateToProps = state => {
    return {
        uploadedList: state.engage.uploadedList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUploadedList: list => dispatch(setUploadedList(list))
    }
}

const objectives = [
    { objectiveId: 1, objectiveName: 'Reach & Awareness' },
    { objectiveId: 2, objectiveName: 'Branding' },
    { objectiveId: 3, objectiveName: 'Conversions' }
]

function UploadList(props) {
    const navigate = useNavigate()
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const currentAccountId = currentAccount?.accountId
    const [showSuccessPopup, setShowSuccessPopup] = React.useState(false)
    const [showWarningPopup, setShowWarningPopup] = React.useState(false)
    const [postingList, setPostingList] = React.useState(false)

    const { data: brandProfiles, isLoading: brandProfilesIsLoading } = useBrandProfiles({
        submittedOnly: true
    })

    const filteredBrandProfiles = React.useMemo(() => {
        if (!brandProfiles) {
            return []
        }
        return brandProfiles.filter(bp => bp.submitted && !bp.archived)
    }, [brandProfiles])

    React.useEffect(() => {
        return () => {
            setShowSuccessPopup(false)
            setShowWarningPopup(false)
        }
    }, [])

    const [compVersion, setCompVersion] = React.useState({})

    const handleUploadSuccess = data => {
        props.setUploadedList(data)
    }

    const handleRemoveFile = () => {
        decrementUploadedCount()
        handleChange('uploadedList', [])
    }

    const [uploadedCount, setUploadedCount] = React.useState(0)
    const incrementUploadedCount = () => {
        setUploadedCount(uploadedCount + 1)
    }

    const decrementUploadedCount = () => {
        setUploadedCount(uploadedCount - 1)
    }

    const handleDownloadTemplateClick = () => {
        window.location.href =
            'https://storage.googleapis.com/sightlyoutcomeintelligence_templates/SmartList_Upload_Template.xlsx'
    }

    const [formObject, setFormObject] = React.useState({
        objectiveId: objectives[0].objectiveId,
        targetTypeId: targetTypes[3].targetTypeId,
        brandProfileId: null,
        name: '',
        uploadedList: []
    })

    const isClean = React.useMemo(() => {
        if (formObject.name.length < 1) return false
        if (formObject.uploadedList.length < 1) return false
        return true
    }, [formObject])

    React.useEffect(() => {
        if (filteredBrandProfiles.length > 0) {
            handleChangeExecute('brandProfileId', filteredBrandProfiles[0].brandProfileId)
        }
    }, [filteredBrandProfiles])

    const handleChange = debounce((property, value) => {
        handleChangeExecute(property, value)
    }, 500)

    const handleChangeExecute = (property, value) => {
        switch (property) {
            case 'name':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        name: value
                    }
                    return newState
                })
                break

            case 'objectiveId':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        objectiveId: value
                    }
                    return newState
                })
                break

            case 'targetTypeId':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        targetTypeId: value
                    }
                    return newState
                })
                break

            case 'brandProfileId':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        brandProfileId: value
                    }
                    return newState
                })
                break

            case 'uploadedList':
                setFormObject(prevState => {
                    let newState = {
                        ...prevState,
                        uploadedList: value
                    }
                    return newState
                })
                break

            default:
                break
        }
    }

    const handleSubmit = async () => {
        setPostingList(true)
        const list = {
            smartListName: formObject.name,
            objectiveId: formObject.objectiveId,
            smartListData: formObject.uploadedList,
            targetTypeId: formObject.targetTypeId
        }

        let data = {
            list,
            brandProfileId: formObject.brandProfileId,
            accountId: currentAccountId
        }

        const response = await postUploadedListService(data)

        response.data.successCount =
            Number(response.data.uploadedChannelCount) + Number(response.data.uploadedVideoCount)
        setCompVersion(response.data)
        if (response.data.invalidIds.length > 0) {
            setShowWarningPopup(true)
        } else if (response.data.invalidIds.length < 1) {
            setShowSuccessPopup(true)
        }

        setPostingList(false)
    }

    const headerColor1 = '#00A694'
    const headerColor2 = accentColor

    if (brandProfilesIsLoading) {
        return <Loader vertical center />
    }

    return (
        <div style={{ padding: 24 }}>
            <SightlyAlert
                headerColor={`linear-gradient(to right,  ${headerColor1} 0%,${headerColor2} 100%)`}
                text="We’ll send you an email once the list
			has been enriched and you can return to download it. Typically, this
			process can take up to three hours, but may take longer."
                headerText={numeral(compVersion?.successCount).format('0,0') + ' IDs uploaded successfully'}
                topHeaderText="Upload Successful"
                closeButtonText="Return to SmartLists"
                show={showSuccessPopup}
                setShow={setShowSuccessPopup}
                onClose={() => navigate({ to: routes.app.engage.lists.lists.path })}
            />
            <SightlyAlert
                headerColor="#EE7731"
                headerIcon={<WarningImage />}
                text="We will remove the invalid IDs and continue processing the remainder of your upload. The invalid IDs will be available in the downloaded list."
                headerText={'Oops, there were ' + compVersion?.invalidIds?.length + ' invalid IDs found'}
                topHeaderText="Invalid IDs"
                closeButtonText="Continue"
                show={showWarningPopup}
                setShow={setShowWarningPopup}
                onClose={() => setShowSuccessPopup(true)}
            />

            <Panel
                header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Upload a list</div>

                        <Whisper
                            delayShow={700}
                            delayHide={0}
                            trigger="hover"
                            placement="bottom"
                            speaker={<Tooltip>The list can be either channels or videos</Tooltip>}
                        >
                            <Button
                                appearance="link"
                                style={{ margin: 0, padding: 0 }}
                                onClick={handleDownloadTemplateClick}
                            >
                                Click to download template
                            </Button>
                        </Whisper>
                    </div>
                }
                bordered
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="isRequired" style={{ marginTop: 24 }}>
                        SmartList Name
                    </div>
                    <Input onChange={val => handleChange('name', val)} />
                    <div style={{ marginTop: 24 }}> Objective</div>
                    <SelectPicker
                        preventOverflow
                        labelKey={'objectiveName'}
                        valueKey={'objectiveId'}
                        placeholder={'Select'}
                        data={objectives}
                        defaultValue={formObject.objectiveId}
                        onChange={val => {
                            handleChange('objectiveId', val)
                        }}
                        cleanable={false}
                        block
                        searchable={false}
                    />
                    <div style={{ marginTop: 24 }}>Target Type</div>
                    <SelectPicker
                        preventOverflow
                        labelKey={'targetTypeName'}
                        valueKey={'targetTypeId'}
                        placeholder={'Select'}
                        data={targetTypes}
                        defaultValue={formObject.targetTypeId}
                        onChange={val => {
                            handleChange('targetTypeId', val)
                        }}
                        cleanable={false}
                        block
                        searchable={false}
                    />
                    <div style={{ marginTop: 24 }}>Brand Profile</div>
                    <SelectPicker
                        preventOverflow
                        labelKey={'brandName'}
                        valueKey={'brandProfileId'}
                        placeholder={'Select'}
                        value={formObject.brandProfileId}
                        data={filteredBrandProfiles}
                        onChange={val => {
                            handleChangeExecute('brandProfileId', val)
                        }}
                        cleanable={false}
                        block
                        searchable={false}
                    />
                    <div style={{ marginTop: 24 }}> </div>
                    <div>
                        <p className="isRequired">Pick a file</p>
                        <Uploader
                            disabled={uploadedCount > 0}
                            listType="text"
                            accept=".xlsx, .xls, .csv"
                            action={parseExcelUrl}
                            headers={{ Authorization: localStorage.getItem('token') }}
                            multiple={false}
                            draggable
                            onError={err => console.log('err consolelog:', err)}
                            onSuccess={val => {
                                uploadedListObjValidation
                                    .validate(val)
                                    .then(data => {
                                        handleChange('uploadedList', val)
                                        handleUploadSuccess(val)
                                    })
                                    .catch(err => {
                                        toast.error(err.errors.join(', ') + '. Please fix your file and try again', {
                                            duration: 5000
                                        })
                                    })

                                incrementUploadedCount()
                            }}
                            onRemove={() => handleRemoveFile()}
                        >
                            <div
                                style={{
                                    height: 150,
                                    width: '100%',
                                    display: 'flex',
                                    color: 'grey',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Click or Drag files to this area to upload
                            </div>
                        </Uploader>
                    </div>
                </div>
                <div style={{ marginTop: 24 }} />
                <SightlyButton loading={postingList} disabled={!isClean} handleClick={handleSubmit} text="Submit" />
            </Panel>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadList)
