import React from 'react'
import { CustomRadio } from './CustomRadio'
import { connect } from 'react-redux'
import Panel from 'rsuite/lib/Panel'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import Skeleton from 'react-loading-skeleton'
import useScenarios from './useScenarios'
import { useMatch, useSearch } from '@tanstack/react-location'

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

function Scenarios(props) {
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false

    const { scenarios, scenariosIsLoading, handleScenarioChange } = useScenarios(brandProfileId)

    const _handleScenarioSelect = (scenarioId, scenarioResponseId) => {
        let data = {
            scenarioId: scenarioId,
            scenarioResponseId: scenarioResponseId
        }

        let params = {
            scenarios: [data],
            brandProfileId: props.brandProfile.brandProfileId
        }
        handleScenarioChange(params)
    }

    const handleScenarioSelect = React.useCallback(
        (scenarioId, scenarioResponseId) => {
            _handleScenarioSelect(scenarioId, scenarioResponseId)
        },
        [props.brandProfile]
    )

    return (
        <Panel header={<div className="sightlyPanelHeader">Scenarios</div>}>
            <div
                style={{
                    fontWeight: 500,
                    color: '#a3a3a3',
                    fontSize: 14,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                Note: These selections work in tandem with your existing campaign targeting. For example, media would
                only target a user in content related to this scenario if the user watching fits within the audience
                parameters for the campaign.
            </div>
            <div style={{ height: 30 }} />
            <div style={{ position: 'relative' }}>
                {scenariosIsLoading && <Skeleton height={57} count={20} width={'100%'} />}

                {scenarios &&
                    scenarios.length > 0 &&
                    scenarios.map((scenarioType, index) => {
                        const expandedByDefault =
                            !!window.location.hash &&
                            scenarioType?.scenarios?.filter(o => o.scenarioId == window.location.hash.replace('#', ''))
                                .length > 0
                        return (
                            <div key={index}>
                                <Panel
                                    collapsible
                                    defaultExpanded={expandedByDefault}
                                    header={
                                        <div style={{ fontSize: 20, fontWeight: 700 }}>
                                            {scenarioType.scenarioTypeName}
                                        </div>
                                    }
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            justifyContent: 'end'
                                        }}
                                    >
                                        <Whisper
                                            delayShow={700}
                                            delayHide={0}
                                            trigger="hover"
                                            placement="auto"
                                            speaker={
                                                <Tooltip>
                                                    <div style={{ textAlign: 'left' }}>
                                                        Target: Activate on moments related to this scenario
                                                    </div>
                                                    <br />
                                                    <div style={{ textAlign: 'left' }}>
                                                        Monitor: Receive notifications about moments related to this
                                                        scenario and decide accordingly. Based on other targeting
                                                        tactics, you may serve on moments related to this scenario until
                                                        further action is taken
                                                    </div>
                                                    <br />
                                                    <div style={{ textAlign: 'left' }}>
                                                        Don’t Monitor: Do not receive notifications about moments
                                                        related to this scenario; no further action required. Based on
                                                        other targeting tactics, you may serve on moments related to
                                                        this scenario
                                                    </div>
                                                    <br />
                                                    <div style={{ textAlign: 'left' }}>
                                                        Block: Block all moments related to this scenario
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                id="whatDoOptionsMean"
                                                style={{ display: 'flex', justifyContent: 'end' }}
                                            >
                                                <div style={{ flex: 1 }} />
                                                <div>What do these options mean?</div>
                                            </div>
                                        </Whisper>
                                    </div>
                                    <br />
                                    {scenarioType.scenarios.map((scenario, scenIndex) => {
                                        return (
                                            <CustomRadio
                                                viewOnly={viewOnly}
                                                key={scenIndex}
                                                handleScenarioSelect={handleScenarioSelect}
                                                scenario={scenario}
                                            />
                                        )
                                    })}
                                    <div style={{ height: 48 }} />
                                </Panel>
                            </div>
                        )
                    })}
            </div>
        </Panel>
    )
}

export default connect(mapStateToProps, null)(Scenarios)
