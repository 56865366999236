import React from 'react'
import { ReactComponent as SearchIcon } from '../assets/img/search.svg'
import ClickAwayListener from 'react-click-away-listener'

const SightlySearchBar = (props) => {
	const [val, setVal] = React.useState('')
	const [active, setActive] = React.useState(false)
	const handleClickAway = () => {
		setActive(false)
		if (val === '') {
			setVal('')
		}
	}
	const handleChange = (val) => {
		setVal(val)
		props.handleChange(val)
	}
	return (
		<ClickAwayListener onClickAway={() => handleClickAway()}>
			<div
				id={props.id}
				onClick={() => setActive(true)}
				style={{
					backgroundColor: '#F0F3F5',
					zIndex: 99,
					position: 'relative',
					height: 40,
					borderRadius: 3,
					cursor: 'pointer'
				}}
			>
				{!val && !active && (
					<div
						className='unselectable'
						style={{
							color: '#BDC2C7',
							position: 'absolute',
							fontWeight: 600,
							fontSize: 12,
							lineHeight: '20px',
							top: 10,
							left: 40
						}}
					>
						{props.placeholder}
					</div>
				)}

				<SearchIcon style={{ position: 'absolute', top: 12, left: 12 }} />
				{(active || val) && (
					<input
						value={val}
						onChange={(e) => handleChange(e.target.value)}
						autoFocus={active}
						style={{
							outline: 'none',
							backgroundColor: '#F0F3F5',
							position: 'absolute',
							fontWeight: 600,
							fontSize: 12,
							lineHeight: '20px',
							border: 0,
							left: 40,
							width: 200,
							height: 40
						}}
					/>
				)}
			</div>
		</ClickAwayListener>
	)
}

export default SightlySearchBar
