import React, { ReactElement } from 'react'
import { Version } from '../../../../../classes/version'
import FiltersSideBar from './FiltersSideBar'
import DownloadModal from '../../components/DownloadModal'
import dayjs from 'dayjs'
import SightlyToggle from '../../../../../components/Sightly/SightlyToggle'
import Loader from 'react-loader-spinner'
import { accentColor } from '../../../../../assets/jss/colorContants'
import numeral from 'numeral'
import SightlyButton from '../../../../../components/SightlyButton'
import { routes } from '../../../../../routes'
import SightlySearchBar from '../../../../../components/SightlySearchBar'
import { downloadExcelListService } from '../../../../../services/lists'
import useOnScreen from '../../../../BrandProfiles/BrandProfile/useOnScreen'
import { getCalendarDate } from '../../../../../utils'
import TextTruncate from 'react-text-truncate'
import InputPicker from 'rsuite/lib/InputPicker'
import SightlyCreatableTagPicker from '../../../../../components/Sightly/SightlyCreatableTagPicker'
import Radio from 'rsuite/lib/Radio'
import RadioGroup from 'rsuite/lib/RadioGroup'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import { ReactComponent as Info } from '../../../../../assets/img/info_12x12.svg'
import { Link, useMatch, useNavigate } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils/utils'
import useUser from '../../../../../services/useUser'

interface CustomRadioProps {
    label: string
    value: number
    tooltipText: ReactElement
}

const FiltersCustomRadio = ({ label, value, tooltipText }: CustomRadioProps) => {
    return (
        <Radio value={value}>
            <div
                style={{
                    position: 'relative',
                    fontWeight: 600,
                    fontSize: 14,
                    color: 'rgb(51, 61, 71)',
                    marginLeft: -3
                }}
            >
                {label}
                <div style={{ position: 'absolute', right: 54, top: 0 }}>
                    <Whisper delayHide={0} placement="top" trigger="hover" speaker={<Tooltip>{tooltipText}</Tooltip>}>
                        <Info style={{ marginLeft: 12 }} />
                    </Whisper>
                </div>
            </div>
        </Radio>
    )
}

interface SortOption {
    id: string
    label: string
}
interface ListFrameProps {
    isVideo: boolean
    version: Version
    handleSensitivityChange: Function
    handleBlockedKeywords: Function
    handleFiltersSaved: Function
    handleChannelsToggle: Function
    contentIsLoading: boolean
    children: JSX.Element[]
    handleSearch: Function
    setCurrentSort: (val: 'videos' | 'views' | 'subscribers') => void
    isFromViewClick: boolean
    handleBottomReached: Function
    hasNextPage: boolean
    sortOptions: SortOption[]
}

const ListFrame = ({
    isVideo,
    version,
    handleBlockedKeywords,
    handleSensitivityChange,
    handleFiltersSaved,
    handleChannelsToggle,
    contentIsLoading,
    children,
    handleSearch,
    setCurrentSort,
    isFromViewClick,
    handleBottomReached,
    hasNextPage,
    sortOptions
}: ListFrameProps) => {
    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const bottomRef = React.useRef(null)
    const bottomReached = useOnScreen(bottomRef)
    const [isDownloading, setIsDownloading] = React.useState<boolean>(false)
    const [showDownloadModal, setShowDownloadModal] = React.useState<boolean>(false)

    const itemCount: number = React.useMemo(() => {
        if (version.uploaded) {
            if (isVideo) {
                return version.stats.videoCount
            } else {
                return version.stats.channelCount
            }
        } else {
            return version.stats.itemCount
        }
    }, [version])

    React.useEffect(() => {
        if (!version.versionId || !bottomReached || !hasNextPage || contentIsLoading) {
            return
        }
        handleBottomReached()
    }, [bottomReached])

    const handleDownloadClick = async () => {
        if (version.uploaded) {
            setIsDownloading(true)
            let payload = {
                versionId: version.versionId,
                smartListName: version.smartListName
            }

            await downloadExcelListService(payload)
            setIsDownloading(false)
        } else {
            setShowDownloadModal(true)
        }
    }

    const noVisibleItems = React.useMemo(() => {
        if (contentIsLoading) return false
        if (isVideo) {
            return version?.videos?.length === 0
        } else {
            return version?.channels?.length === 0
        }
    }, [version, contentIsLoading])

    return (
        <div
            style={{
                display: 'flex',
                position: 'relative'
            }}
        >
            {contentIsLoading && (
                <div
                    style={{
                        cursor: 'not-allowed',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        opacity: 0.2,
                        zIndex: 99
                    }}
                ></div>
            )}
            {!version.versionId && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        height: 700,
                        width: '100%',
                        backgroundColor: 'white',
                        zIndex: 999
                    }}
                >
                    <Loader type="Circles" color={accentColor} />
                </div>
            )}
            {!isFromViewClick && version.versionId && (
                <div>
                    <FiltersSideBar
                        isVideo={isVideo}
                        handleSensitivityChange={handleSensitivityChange}
                        handleFiltersSaved={handleFiltersSaved}
                        version={version}
                    />
                </div>
            )}
            <div
                style={{
                    justifyContent: 'center',
                    width: '100%',
                    display: 'flex',
                    padding: 24
                }}
            >
                <div style={{ width: 1016, marginLeft: 12, marginRight: 12 }}>
                    <DownloadModal
                        versionId={version.versionId}
                        show={showDownloadModal}
                        setShow={() => setShowDownloadModal(false)}
                    />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 24
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div
                                style={{
                                    fontWeight: 600,
                                    lineHeight: '32px',
                                    fontSize: 24,
                                    color: '#333D47'
                                }}
                            >
                                {version.smartListName}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 24
                                }}
                            >
                                <Link to={routes.app.engage.lists.lists.path}>Back to Smartlists</Link>

                                {userCan(perms.SMARTLIST_DOWNLOAD) && (
                                    <SightlyButton
                                        id="listBuilderDownloadButton"
                                        text="Download"
                                        loading={isDownloading}
                                        handleClick={() => handleDownloadClick()}
                                    />
                                )}
                            </div>
                        </div>

                        <div style={{ display: 'block' }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: '#C4C4C4',
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 3,
                                    color: 'white',
                                    borderRadius: 3,
                                    height: 28,
                                    lineHeight: '22px',
                                    fontSize: 14,
                                    fontWeight: 600
                                }}
                            >
                                {version.objectiveName}
                            </div>
                        </div>

                        <div
                            id="details"
                            style={{
                                lineHeight: '22px',
                                fontSize: 14,
                                display: 'flex',
                                marginTop: 16,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <div style={{ color: '#696969', fontWeight: 500 }}>Brand Profile:&nbsp;</div>
                            <div
                                style={{
                                    color: '#333D47',
                                    fontWeight: 600,
                                    width: 268 - 92.89 - 24
                                }}
                            >
                                <TextTruncate text={version.brandProfileName} />
                            </div>

                            <div style={{ color: '#696969', fontWeight: 500, marginLeft: 16 }}>Created:&nbsp;</div>
                            <div
                                style={{
                                    color: '#333D47',
                                    fontWeight: 600
                                }}
                            >
                                {dayjs(version.createdDate).format('MMM D, YYYY')}
                            </div>

                            <div style={{ color: '#696969', fontWeight: 500, marginLeft: 16 }}>Updated:&nbsp;</div>
                            <div
                                style={{
                                    color: '#333D47',
                                    fontWeight: 600
                                }}
                            >
                                {getCalendarDate(version.updatedDate)}
                            </div>

                            <div style={{ color: '#696969', fontWeight: 500, marginLeft: 16 }}>
                                {isVideo ? 'Videos:' : 'Channels:'}&nbsp;
                            </div>

                            <div
                                style={{
                                    color: '#333D47',
                                    fontWeight: 600
                                }}
                            >
                                <div>{numeral(itemCount).format()}</div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            {!isVideo && !version?.uploaded && !isFromViewClick && version.versionId && (
                                <div style={{ width: 250 }}>
                                    <div className="input-label">Block Sensitivity</div>
                                    <RadioGroup
                                        defaultValue={version?.blockVeto ? version?.blockVeto : 1}
                                        onChange={value => {
                                            handleSensitivityChange(value)
                                        }}
                                    >
                                        <FiltersCustomRadio
                                            value={1}
                                            label="Default"
                                            tooltipText={
                                                <>
                                                    Categories are <strong>weighted equally</strong> to determine the
                                                    recommended action.
                                                </>
                                            }
                                        />

                                        <FiltersCustomRadio
                                            value={-0.3}
                                            label="Sensitive"
                                            tooltipText={
                                                <>
                                                    Channels with <strong>one or more</strong> blocked categories may be
                                                    blocked.
                                                </>
                                            }
                                        />

                                        <FiltersCustomRadio
                                            value={-7}
                                            label="More Sensitive"
                                            tooltipText={
                                                <>
                                                    Channels with <strong>one or more</strong> blocked categories are
                                                    likely to be blocked.
                                                </>
                                            }
                                        />

                                        <FiltersCustomRadio
                                            value={-99999}
                                            label="Extreme"
                                            tooltipText={
                                                <>
                                                    Channels with <strong>even one blocked category</strong> will be
                                                    blocked.
                                                </>
                                            }
                                        />
                                    </RadioGroup>
                                </div>
                            )}

                            {!isFromViewClick && version.versionId && (
                                <div>
                                    <div className="input-label">Blocked Keywords</div>
                                    <SightlyCreatableTagPicker
                                        id="filterSideBarTagPicker"
                                        handleChange={handleBlockedKeywords}
                                        disabled={!userCan(perms.SMARTLIST_EDIT) || isFromViewClick || contentIsLoading}
                                        defaultItems={version?.blockedKeywords}
                                    />
                                </div>
                            )}
                        </div>

                        <div style={{ marginBottom: 16, display: 'flex' }}>
                            {version.uploaded && (
                                <div style={{ marginRight: 16 }}>
                                    <SightlyToggle
                                        leftCheckedByDefault={!isVideo}
                                        rightText="Videos"
                                        leftText="Channels"
                                        onChange={handleChannelsToggle}
                                    />
                                </div>
                            )}

                            <InputPicker
                                preventOverflow
                                disabled={false}
                                cleanable={false}
                                id="trendSort"
                                labelKey="label"
                                valueKey="id"
                                placeholder="Sort By"
                                block
                                data={sortOptions}
                                defaultValue={'views'}
                                onChange={setCurrentSort}
                                style={{ borderRadius: 3 }}
                            />

                            <div style={{ width: 351, marginLeft: 'auto' }}>
                                <SightlySearchBar
                                    id="lbSearch"
                                    placeholder={`Search by ${isVideo ? 'video' : 'channel'} title or description`}
                                    handleChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="sightly-divider" />

                    <div>
                        {noVisibleItems ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 72,
                                    fontSize: 18,
                                    fontWeight: 600
                                }}
                            >
                                {`No ${isVideo ? 'videos' : 'channels'} found for your current filters`}
                            </div>
                        ) : (
                            <>{children}</>
                        )}
                    </div>

                    <div id="bottom" ref={bottomRef} />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            height: 150
                        }}
                    >
                        {contentIsLoading && (
                            <div
                                style={{
                                    height: '100px',
                                    alignSelf: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Loader type="Circles" color={accentColor} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListFrame
