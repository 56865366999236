import React from 'react'
import { Trend, TrendScenario } from '../../../classes/trend'
import MomentCard from './MomentCard'
import { useMoments } from './useMoments'
import Loader from 'react-loader-spinner'
import { accentColor, brandPinkColor, brandSuccessColor } from '../../../assets/jss/colorContants'
import SightlySelect from '../../../components/Sightly/SightlySelect'
import Checkbox from 'rsuite/lib/Checkbox'
import Input from 'rsuite/lib/Input'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import MomentModal from './MomentModal'
import {
    fetchTrendScenarios,
    fetchTrendArticlesService,
    fetchTrendKeywordsService,
    fetchTrendVideosService,
    momentsBlockList
} from '../../../services/trends_ts'
import useBoards from './useBoards'
import Sidebar from './Sidebar'
import { downloadMomentsService } from '../../../services/trends'
import SightlyMessage from '../../../components/Sightly/SightlyMessage'
import BlockPlacementsModal from '../components/BlockPlacementsModal'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import useBrandProfiles from '../../../services/brandProfile/useBrandProfiles'
import TablePagination from 'rsuite/lib/Table/TablePagination'
import IconButton from 'rsuite/lib/IconButton'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import PortalModal from '../../../components/PortalModal/PortalModal'
import ShareModal from '../components/ShareModal'
import toast from 'react-hot-toast'
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import NoBrandProfiles from '../../../components/NoBrandProfiles'
import { MyLocationGenerics } from '../../../classes/utils/utils'
import { location } from '../../../RouteContainer'
import debounce from 'just-debounce-it'
import { logError } from '../../../utils'
import useUser from '../../../services/useUser'

const sortOptions: { id: string; label: string }[] = [
    { id: 'keywords', label: 'Most Keywords' },
    { id: 'placements', label: 'Most Placements' },
    { id: 'popularity', label: 'Popularity' }
]

const timeRangeOptions = [
    { id: 1, label: 'Today' },
    { id: 2, label: 'Yesterday' },
    { id: 3, label: 'This Week' },
    { id: 4, label: 'Last Week' },
    { id: 5, label: 'This Month' },
    { id: 6, label: 'Last Month' }
]

const actionOptions = [
    { id: 'Target', label: 'Target' },
    { id: 'Monitor', label: 'Monitor' },
    { id: 'Block', label: 'Block' }
]

const AylienBoard = () => {
    const navigate = useNavigate()

    const { data: brandProfiles, currentBrandProfile, isLoading: brandProfilesIsLoading } = useBrandProfiles({
        submittedOnly: true
    })
    const { brandProfileId, boardIds, timeRange = 1, actions, sortBy = 'keywords', searchTerm = '' } = useSearch<
        MyLocationGenerics
    >()

    const boardsObj = useBoards()

    const { accounts, user, currentAccount, perms, userCan } = useUser()
    const [showModal, setShowModal] = React.useState(false)
    const [selectedTrend, setSelectedTrend] = React.useState<Trend | null>(null)
    const [articles, setArticles] = React.useState<Trend['articles']>([])
    const [keywords, setKeywords] = React.useState<Trend['keywords']>([])
    const [trendScenarios, setTrendScenarios] = React.useState<TrendScenario[]>([])
    const [showMonitorImp, setShowMonitorImp] = React.useState<boolean>(false)
    const [trendScenariosLoading, setTrendScenariosLoading] = React.useState<boolean>(true)
    const [trendArticlesLoading, setTrendArticlesLoading] = React.useState<boolean>(true)
    const [trendVideosLoading, setTrendVideosLoading] = React.useState<boolean>(true)
    const [trendKeywordsLoading, setTrendKeywordsLoading] = React.useState(true)

    const handleSearch = debounce((text: string) => {
        navigate({
            search: prev => {
                return {
                    ...prev,
                    searchTerm: text
                }
            }
        })
    }, 500)

    const {
        moments,
        handleSaveActionEdit,
        handleSelectTrend,
        momentsIsLoading,
        setSortBy,
        setActions,
        handleSelectAllOnPage,
        selectedItems,
        postTrendRecActionFeedback,
        postTrendQuestionFeedback,
        postTrendVideoFeedback,
        postTrendKeywordsFeedback,
        videos,
        setVideos,
        updateTrendScenarios,
        aylienNewsOptions,
        aylienIndustriesOptions,
        setAylienNews,
        setAylienIndustries,
        aylienIndustries,
        aylienNews,
        changeTrendsToBlocked,
        setPage,
        page,
        totalResultCount,
        hasNextPage,
        requestedPageSize,
        selectedTrends
    } = useMoments({ brandProfileId, boardIds: boardIds || [], timeRange, searchTerm, sortBy, actions })

    React.useEffect(() => {
        handleSelectAllOnPage(false)
    }, [actions, timeRange, aylienIndustries, aylienNews])

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: search => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
        navigate({
            search: search => ({
                ...search,
                timeRange: search?.timeRange ?? 1,
                searchTerm: search?.searchTerm ?? '',
                boardIds: search?.boardIds ?? [],
                actions: search?.actions ?? ['Target', 'Monitor', 'Block'],
                sortBy: search?.sortBy ?? 'keywords'
            })
        })
    }, [currentBrandProfile, location.current.search])

    const _handleSelectTrend = React.useCallback((bool: any, trend: any) => {
        handleSelectTrend(trend)
    }, [])

    const handleTrendNameClick = React.useCallback(async (trend: Trend) => {
        setSelectedTrend(trend)
        setTrendScenariosLoading(true)
        setTrendArticlesLoading(true)
        setTrendVideosLoading(true)
        setTrendKeywordsLoading(true)
        setShowModal(true)

        const args = {
            trendId: trend.trendId,
            brandProfileId: trend.brandProfileId,
            isMoments: true
        }

        await Promise.all([
            (async () => {
                const _trendScenarios = await fetchTrendScenarios(args)
                setTrendScenarios(_trendScenarios.scenarios)
                updateTrendScenarios({
                    trendId: trend.trendId,
                    recommendedAction: _trendScenarios.recommendedAction
                })
                setTrendScenariosLoading(false)
            })(),
            (async () => {
                const _articles = await fetchTrendArticlesService(args)
                setArticles(_articles)
                setTrendArticlesLoading(false)
            })(),
            (async () => {
                const _videos = await fetchTrendVideosService(args)

                setVideos(_videos)
                setTrendVideosLoading(false)
            })(),
            (async () => {
                const _kw = await fetchTrendKeywordsService(args)
                setKeywords(_kw)
                setTrendKeywordsLoading(false)
            })()
        ])
    }, [])

    const trendsHaveMonitor = () => {
        for (const trend of selectedTrends) {
            if (trend.action === 'monitor') {
                return true
            }
        }
        return false
    }

    const [shareIsOpen, setShareIsOpen] = React.useState(false)

    const handleImplementClick = async () => {
        if (trendsHaveMonitor()) {
            setShowMonitorImp(true)
            return
        } else {
            setShareIsOpen(true)
            setShowMonitorImp(false)
        }
    }

    const _handleSaveActionEdit = (trend: Trend, action: any) => {
        if (!brandProfileId) return
        handleSaveActionEdit({ trendId: trend.trendId, brandProfileId, action })
    }

    const _handleTimeRangeChange = (val: number) => {
        navigate({
            search: old => {
                return {
                    ...old,
                    timeRange: val
                }
            }
        })
    }

    const handleModalClose = () => {
        setShowModal(false)
        setArticles([])
        setVideos([])
        setTrendScenarios([])
    }

    const [downloadingTrends, setDownloadingTrends] = React.useState(false)

    const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)

    const handleDownloadTrends = async (all?: boolean) => {
        setDownloadingTrends(true)
        let args
        if (all) {
            if (totalResultCount > 1000) {
                setDownloadingTrends(false)
                setShowTooManyTrendsError(true)
                return
            }
            args = {
                isSelectAll: true,
                brandProfileId: brandProfileId,
                boardIds,
                timeRange,
                actions,
                sortBy,
                searchTerm,
                aylienNews,
                aylienIndustries
            }
        } else {
            if (selectedItems.length > 1000) {
                setDownloadingTrends(false)
                setShowTooManyTrendsError(true)
                return
            }
            args = {
                brandProfileId: brandProfileId,
                trendIds: selectedItems,
                isMoments: true
            }
        }
        try {
            await downloadMomentsService(args)
        } catch (err) {
            toast.error('There was an error downloading your moments.')
            setDownloadingTrends(false)
        }

        setDownloadingTrends(false)
    }

    const hasNonBlocked = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action !== 'block' && selectedItems.includes(trend.trendId)) {
                return true
            }
        }
        return false
    }, [moments, selectedItems])

    const [showHasNonBlocked, setShowHasNonBlocked] = React.useState<boolean>(false)
    const [showBlockModal, setShowBlockModal] = React.useState<boolean>(false)

    const handleBlockClick = async () => {
        if (hasNonBlocked) {
            setShowHasNonBlocked(true)
        } else {
            setShowHasNonBlocked(false)
            setShowBlockModal(true)
        }
    }

    const [showBlockSuccess, setShowBlockSuccess] = React.useState<boolean>(false)
    const [showBlockError, setShowBlockError] = React.useState<string | null>(null)
    const [blocking, setBlocking] = React.useState<boolean>(false)

    const handleBlock = async ({ trendIds, ioIds }: { trendIds: string[]; ioIds: number[] }) => {
        if (brandProfileId === null || brandProfileId === undefined) {
            return
        }
        setBlocking(true)

        const result = await momentsBlockList({ brandProfileId, trendIds, ioIds })
        setBlocking(false)

        if (result.status === 200) {
            setShowBlockSuccess(true)
            setShowBlockError(null)
        } else if (result.response.data) {
            logError(result)
            setShowBlockError(JSON.stringify(result.response.data))
            setShowBlockSuccess(false)
            handleSelectAllOnPage(false)
            return
        }

        handleSelectAllOnPage(false)
        changeTrendsToBlocked({ trendIds })
    }

    const allMomentsOnPageAreSelected = React.useMemo(() => {
        if (selectedItems.length === 0) {
            return false
        }

        const notIncludedInSelectedItems = moments?.map(m => m.trendId)?.filter(id => !selectedItems.includes(id))
        if (notIncludedInSelectedItems === undefined) {
            return true
        }
        if (notIncludedInSelectedItems.length > 0) {
            return false
        }
        return true
    }, [moments, selectedItems])

    const firstShown = page === 1 ? 1 : (page - 1) * requestedPageSize + 1
    const lastShown = firstShown + requestedPageSize > totalResultCount ? totalResultCount : page * requestedPageSize
    const disableActions = selectedItems.length === 0

    if (brandProfilesIsLoading) {
        return null
    }

    if (brandProfiles?.length === 0) {
        return <NoBrandProfiles />
    }

    return (
        <div className="gridContainer">
            <Sidebar
                handleBrandProfileChange={(brandProfileId: number) => {
                    navigate({
                        search: old => {
                            delete old?.boardIds
                            return {
                                ...old,
                                brandProfileId
                            }
                        }
                    })

                    handleSelectAllOnPage(false)
                }}
                brandProfileId={brandProfileId}
                handleSelectBoard={boardsObj.handleSelectBoard}
                boards={boardsObj?.boards || []}
                showBackToDashboards={(boardsObj?.boards && boardsObj.boards.length > 0) || false}
                selectedBoards={boardIds || []}
            />

            <div className="header-aylien">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 1016,
                        paddingRight: 10
                    }}
                >
                    <div style={{ fontWeight: 600, fontSize: 24, color: '#333D47' }}>Moments</div>

                    <InputGroup style={{ width: 494 }}>
                        <Input
                            key={searchTerm}
                            defaultValue={searchTerm}
                            placeholder="Search moments"
                            onChange={val => handleSearch(val)}
                        />

                        <InputGroup.Button disabled style={{ backgroundColor: 'transparent' }}>
                            <Icon icon="search" />
                        </InputGroup.Button>
                    </InputGroup>
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: 16,
                        alignItems: 'center',
                        width: 1016,
                        paddingRight: 10
                    }}
                >
                    <CheckTreePicker
                        id="actionDropdown"
                        style={{ width: 162 }}
                        preventOverflow
                        data={actionOptions}
                        labelKey="label"
                        valueKey="id"
                        onChange={_actions => _actions.length > 0 && setActions(_actions)}
                        value={actions}
                        cleanable={false}
                    />

                    <SightlySelect
                        id="dateDropdown"
                        width={162}
                        options={timeRangeOptions}
                        labelKey="label"
                        valueKey="id"
                        onChange={_handleTimeRangeChange}
                        value={timeRange as number}
                    />

                    <CheckTreePicker
                        style={{ width: 162 }}
                        menuStyle={{ maxWidth: '90vw' }}
                        preventOverflow
                        data={aylienNewsOptions}
                        labelKey="taxonomyPath"
                        valueKey="taxonomyCode"
                        placeholder="News"
                        onChange={setAylienNews}
                        value={aylienNews}
                    />

                    <CheckTreePicker
                        style={{ width: 162 }}
                        menuStyle={{ maxWidth: '90vw' }}
                        preventOverflow
                        data={aylienIndustriesOptions}
                        labelKey="taxonomyPath"
                        valueKey="taxonomyCode"
                        placeholder="Industries"
                        onChange={setAylienIndustries}
                        value={aylienIndustries}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: 8,
                        alignItems: 'center',
                        width: 1016,
                        paddingRight: 10,
                        marginLeft: -10
                    }}
                >
                    <Checkbox
                        indeterminate={selectedItems.length > 0 && !allMomentsOnPageAreSelected}
                        checked={allMomentsOnPageAreSelected}
                        onChange={(val, checked) => {
                            handleSelectAllOnPage(checked)
                        }}
                    >
                        {selectedItems.length > 0 ? (
                            <div style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
                                {selectedItems.length} item(s) selected
                            </div>
                        ) : (
                            ''
                        )}
                    </Checkbox>

                    <div
                        style={{
                            width: 1,
                            height: 16,
                            backgroundColor: '#D4D9D9',
                            opacity: 0.5
                        }}
                    />
                    <Whisper
                        placement="bottom"
                        trigger={'hover'}
                        speaker={
                            <Tooltip>
                                {disableActions
                                    ? 'Select at least one moment to create a blocklist'
                                    : 'Create a blocklist'}
                            </Tooltip>
                        }
                    >
                        <IconButton
                            onClick={handleBlockClick}
                            icon={<Icon style={{ color: brandPinkColor }} icon="ban" />}
                            appearance="ghost"
                            size="xs"
                            disabled={disableActions}
                            id="clickToAddButton"
                        >
                            Block Selected
                        </IconButton>
                    </Whisper>

                    {userCan(perms.TRENDS_DOWNLOAD) && totalResultCount > 0 && (
                        <IconButton
                            onClick={() => handleDownloadTrends(false)}
                            icon={<Icon icon="download" />}
                            appearance="ghost"
                            size="xs"
                            id="clickToAddButton"
                            disabled={selectedItems.length < 1}
                        >
                            Download Selected
                        </IconButton>
                    )}

                    {userCan(perms.TRENDS_DOWNLOAD) && totalResultCount > 0 && (
                        <IconButton
                            onClick={() => handleDownloadTrends(true)}
                            icon={<Icon icon="download" />}
                            appearance="ghost"
                            size="xs"
                            id="clickToAddButton"
                            disabled={totalResultCount < 1}
                        >
                            Download All {totalResultCount}
                        </IconButton>
                    )}

                    {userCan(perms.TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ) && totalResultCount > 0 && (
                        <IconButton
                            onClick={handleImplementClick}
                            icon={<Icon icon="share" />}
                            appearance="ghost"
                            size="xs"
                            id="shareMomentsButton"
                            disabled={selectedItems.length < 1}
                        >
                            Share
                        </IconButton>
                    )}
                    <div
                        style={{
                            width: 1,
                            height: 16,
                            backgroundColor: '#D4D9D9',
                            opacity: 0.5
                        }}
                    />

                    <SightlySelect
                        id="trendSort"
                        labelKey="label"
                        valueKey="id"
                        placeholder="Sort By"
                        width={162}
                        options={sortOptions}
                        onChange={(val: string) => setSortBy(val)}
                        value={sortBy}
                    />

                    {!momentsIsLoading && moments && moments.length > 0 && (
                        <div
                            style={{
                                marginLeft: 'auto',
                                fontSize: 12,
                                fontWeight: 500,
                                color: 'black'
                            }}
                        >{`Showing ${firstShown} - ${lastShown} of ${totalResultCount}`}</div>
                    )}
                </div>
            </div>
            <div className="main">
                {brandProfileId && (
                    <ShareModal
                        isOpen={shareIsOpen}
                        setIsOpen={setShareIsOpen}
                        selectedTrends={selectedItems}
                        brandProfileId={brandProfileId}
                    />
                )}

                <PortalModal
                    hasClose
                    open={showTooManyTrendsError}
                    handleClose={() => setShowTooManyTrendsError(false)}
                    footerButtons={[]}
                >
                    <div
                        style={{
                            width: 500,
                            padding: 24,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 48
                        }}
                    >
                        <h4>Unable to download</h4>
                        <div>
                            Our system can only handle downloading 1,000 moments maximum. Please add more filters and
                            try again.
                        </div>
                    </div>
                </PortalModal>
                <PortalModal handleClose={() => console.log('close')} footerButtons={[]} open={downloadingTrends}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 24,
                            width: 400,
                            height: 200
                        }}
                    >
                        <h4>Downloading...</h4>
                        <Loader color={accentColor} type="Circles" />
                    </div>
                </PortalModal>
                <div style={{ width: 1016 }}>
                    {showHasNonBlocked && (
                        <SightlyMessage
                            closable
                            handleClose={() => setShowHasNonBlocked(false)}
                            backgroundColor="#E18026E5"
                            text="Warning: Can’t block a Monitor, Target, or Dont Monitor action, please change to Block"
                        />
                    )}

                    {showBlockSuccess && (
                        <SightlyMessage
                            closable
                            loading={false}
                            handleClose={() => setShowBlockSuccess(false)}
                            backgroundColor={brandSuccessColor}
                            text="Blocking Placements complete"
                        />
                    )}

                    {blocking && (
                        <SightlyMessage
                            loading={true}
                            closable
                            handleClose={() => setBlocking(false)}
                            backgroundColor={'#F57B7B'}
                            text="Blocking Placements in progress"
                        />
                    )}

                    {showBlockError && (
                        <SightlyMessage
                            closable
                            handleClose={() => setShowBlockError(null)}
                            backgroundColor={'#E18026E5'}
                            text={showBlockError}
                        />
                    )}

                    {momentsIsLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh',
                                width: 1016
                            }}
                        >
                            <Loader color={accentColor} type="Circles" />
                        </div>
                    ) : (
                        <>
                            <>
                                {!momentsIsLoading && moments && moments.length < 1 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <h4>There are no moments for your current filters</h4>
                                    </div>
                                )}
                            </>

                            {showMonitorImp && (
                                <SightlyMessage
                                    closable
                                    handleClose={() => setShowMonitorImp(false)}
                                    backgroundColor="#E18026"
                                    text="Warning: Please change action to Target or Block to share implementation"
                                />
                            )}

                            {moments &&
                                moments?.map((trend: Trend) => {
                                    return (
                                        <div
                                            key={trend.trendId}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 1016,
                                                borderBottom: '1px solid rgb(212, 217, 217, 0.5)'
                                            }}
                                        >
                                            <Checkbox
                                                checked={selectedItems.includes(trend.trendId)}
                                                onChange={(val: string, bool: boolean) => {
                                                    _handleSelectTrend(true, trend)
                                                }}
                                            />
                                            <MomentCard
                                                key={trend.trendId}
                                                brandProfileId={brandProfileId}
                                                trend={trend}
                                                gAdsBlocked={trend.gAdsBlocked}
                                                trendId={trend.trendId}
                                                actionFilter={'all'}
                                                text={trend.trendName}
                                                keywordCount={trend.keywordCount}
                                                articleCount={trend.articleCount}
                                                videoCount={trend.videoCount}
                                                action={trend.action}
                                                editedBy={trend.editedBy}
                                                editedDateTime={trend.editedDateTime}
                                                editedFromAction={trend.editedFromAction}
                                                handleTrendNameClick={handleTrendNameClick}
                                                handleSaveActionEdit={_handleSaveActionEdit}
                                            />
                                        </div>
                                    )
                                })}
                        </>
                    )}
                    <div style={{ height: 24 }} />
                    {brandProfileId && (
                        <>
                            {moments &&
                                moments.filter((trend: Trend) => trend.trendId === selectedTrend?.trendId)[0] !==
                                    undefined && (
                                    <MomentModal
                                        onClose={() => handleModalClose()}
                                        show={showModal}
                                        trend={
                                            moments &&
                                            moments.filter(
                                                (trend: Trend) => trend.trendId === selectedTrend?.trendId
                                            )[0]
                                        }
                                        trendVideos={videos}
                                        trendArticles={articles}
                                        trendScenarios={trendScenarios}
                                        trendKeywords={keywords}
                                        setTrendScenarios={setTrendScenarios}
                                        trendScenariosLoading={trendScenariosLoading}
                                        trendArticlesLoading={trendArticlesLoading}
                                        trendVideosLoading={trendVideosLoading}
                                        trendKeywordsLoading={trendKeywordsLoading}
                                        brandProfileId={brandProfileId}
                                        postTrendRecActionFeedback={postTrendRecActionFeedback}
                                        postTrendQuestionFeedback={postTrendQuestionFeedback}
                                        postTrendVideoFeedback={postTrendVideoFeedback}
                                        postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                                        updateTrendScenarios={updateTrendScenarios}
                                    />
                                )}
                        </>
                    )}
                </div>
            </div>

            <div className="footer-aylien">
                <TablePagination
                    lengthMenu={[
                        {
                            value: 20,
                            label: 20
                        }
                    ]}
                    next={hasNextPage}
                    total={totalResultCount}
                    renderTotal={() => <div />}
                    renderLengthMenu={() => <div />}
                    activePage={page}
                    displayLength={20}
                    onChangePage={setPage}
                />
            </div>

            <style>{`
        .gridContainer {
          display: grid;
          height: 100%;	
          grid-template-columns: 265px 1fr;	
          grid-template-rows:  0fr 1fr 1fr;
          grid-template-areas:
					  "sidebar header-aylien"
            "sidebar main"
            "sidebar main"	
						"sidebar footer-aylien"	
        }
				.footer-aylien {
					grid-area: footer-aylien;
					box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.3);
					padding-right: 72px;
				}
				.header-aylien {
					grid-area: header-aylien;		
					box-shadow: 2px 2px 4px rgba(0,0,0, 0.3); 
					padding: 25px 0px;
					display: flex;
					align-items: center;
					flex-direction: column;
					gap: 18px;
				}
        .main {
          grid-area: main;
          padding: 25px 38px;
          overflow-y: auto;
          justify-content: center;
          display: flex;  
          min-width: 1016px;    
					height: 100%;
					
        }  
        .total {
          font-size: 12px;
          font-weight: 500;
          color: black;
          display:flex;
          justify-content: left;
        }   
				.aylien-board-select-all-info {
					background-color: #EEEEEE;
					height: 34px;
					border-radius: 8px;
					display: flex;
					gap: 10px;
					width: 100%;
					justify-content: center;
					align-items: center;
				}
			       
      `}</style>

            {brandProfileId && (
                <BlockPlacementsModal
                    open={showBlockModal}
                    setOpen={setShowBlockModal}
                    brandProfileId={brandProfileId}
                    trends={moments ? moments.filter((m: Trend) => selectedItems.includes(m.trendId)) : []}
                    accountName={currentAccount?.accountName || ''}
                    handleBlock={handleBlock}
                />
            )}
        </div>
    )
}

export default AylienBoard
