import { userAccountAxios } from '../../axiosInstances.js'

type Payload = {
    urlToCheck: string
}

type ReturnType = boolean

export const urlExists = async (urlToCheck: Payload): Promise<ReturnType> => {
    const url = '/brand-profile/url-exists'

    const response = await userAccountAxios
        .post(url, {
            urlToCheck
        })
        .catch(() => {
            return {
                data: false
            }
        })

    return response.data === true
}
