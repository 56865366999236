import React from 'react'
import { sightlyBlue } from '../../../assets/jss/colorContants'
import { ReactComponent as Logo } from '../../../assets/img/sightly-logo-white.svg'
import { ReactComponent as OutcomeImage } from '../../../assets/img/outcomes.svg'
import { ReactComponent as AccountOverviewImage } from '../../../assets/img/accountOverview.svg'
import { ReactComponent as AdminImage } from '../../../assets/img/admin.svg'
import { ReactComponent as ProfileSettingsImage } from '../../../assets/img/profileSettings.svg'
import { ReactComponent as LogoutImage } from '../../../assets/img/logout.svg'
import Tab from './Tab.tsx'
import { routes } from '../../../routes'
import { permissionView } from '../../../utils'
import { useNavigate } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'
import useUser from '../../../services/useUser'

const Navbar = () => {
    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        window.location.href = routes.login.path
    }

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    const outcomesVisible = React.useMemo(() => {
        if (
            userCan(perms.TRENDS_READ) ||
            userCan(perms.SMARTLIST_READ) ||
            userCan(perms.REPORTING_READ) ||
            userCan(perms.MOMENTS_INTELLIGENCE_READ)
        ) {
            return true
        }
        return false
    }, [
        userCan(perms.TRENDS_READ),
        userCan(perms.SMARTLIST_READ),
        userCan(perms.REPORTING_READ),
        userCan(perms.MOMENTS_INTELLIGENCE_READ)
    ])

    const navigate = useNavigate()

    const routeIsActive = myRoutes => {
        for (const r of myRoutes) {
            if (location.current.pathname.includes(r)) {
                return true
            }
        }
        return false
    }

    return (
        <div
            id="sidenav"
            style={{
                flex: 1,
                minWidth: 80,
                maxWidth: 80,
                backgroundColor: sightlyBlue,
                height: '100vh',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Logo
                style={{
                    flex: 1,
                    width: 32,
                    marginTop: 14,
                    marginLeft: 24,
                    marginBottom: 16
                }}
                fill="white"
                opacity={1}
            />

            {outcomesVisible && (
                <Tab
                    handleClick={() => {
                        if (userCan(perms.TRENDS_READ)) {
                            navigate({
                                to: routes.app.discover.trends.path
                            })
                        } else {
                            navigate({
                                to: routes.app.engage.lists.lists.path
                            })
                        }
                    }}
                    tabImage={
                        <OutcomeImage
                            className={'opacityOneHover'}
                            fill="white"
                            opacity={routeIsActive(['/app/discover', '/app/engage']) ? 1 : 0.5}
                        />
                    }
                />
            )}

            {userCan(perms.ACCOUNT_SETTINGS_READ) && (
                <Tab
                    handleClick={() => {
                        navigate({
                            to: routes.app.settings.brandProfiles.path
                        })
                    }}
                    tabImage={
                        <>
                            <AccountOverviewImage
                                className={'opacityOneHover'}
                                fill="white"
                                opacity={
                                    routeIsActive([
                                        '/app/settings/brandProfiles',
                                        '/app/settings/account',
                                        '/app/settings/users'
                                    ])
                                        ? 1
                                        : 0.5
                                }
                            />
                            {permissionView && <div style={{ color: 'red' }}>`ACCOUNT_SETTINGS_READ`</div>}
                        </>
                    }
                />
            )}

            {userCan(perms.ADMIN_READ) && (
                <Tab
                    handleClick={() => {
                        navigate({
                            to: routes.admin.roles.path
                        })
                    }}
                    tabImage={
                        <>
                            <AdminImage
                                className={'opacityOneHover'}
                                fill="white"
                                opacity={routeIsActive(['/admin']) ? 1 : 0.5}
                            />
                            {permissionView && <div style={{ color: 'red' }}>{perms.ADMIN_READ}</div>}
                        </>
                    }
                />
            )}

            <Tab
                handleClick={() => {
                    navigate({
                        to: routes.app.settings.profile.path
                    })
                }}
                tabImage={
                    <ProfileSettingsImage
                        className={'opacityOneHover'}
                        fill="white"
                        opacity={
                            routeIsActive(['/app/settings/profile', '/app/settings/notificationPreferences']) ? 1 : 0.5
                        }
                    />
                }
            />

            <Tab
                handleClick={() => handleLogout()}
                tabImage={<LogoutImage className="opacityOneHover" fill="white" opacity={0.5} />}
            />
        </div>
    )
}

export default Navbar
