import React from 'react'
import FormikInput from '../../../../components/CustomInput/FormikInput'
import FormikSelect from '../../../../components/CustomSelect/FormikSelect'
import { withFormik, Form, useFormikContext } from 'formik'
import debounce from 'just-debounce-it'
import {
    patchBrandProfileBasicInfo,
    fetchBrandProfileBasic,
    setBrandProfileBasicInfo
} from '../../../../redux/actions/brandProfiles'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import Panel from 'rsuite/lib/Panel'
import { useQuery } from '@tanstack/react-query'
import { userAccountAxios } from '../../../../axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import useUser from '../../../../services/useUser'
import { LoadingPage } from '../../../../components/LoadingPage'
import { rqKeys } from '../../../../ReactQueryKeyFactory'

const urlRegex = require('url-regex')

export const schemaValidation = Yup.object().shape({
    brandName: Yup.string()
        .required('Required')
        .min(2, 'Must be greater than 1 character')
        .max(50, 'Must be less than 50 characters'),
    aylienIndustryId: Yup.mixed().required('Required'),
    websiteUrl: Yup.string().test('urlTest', 'Valid URL required', basicInfoWebsiteUrl => {
        if (!basicInfoWebsiteUrl) {
            return true
        } else {
            return urlRegex({ exact: true, strict: false }).test(basicInfoWebsiteUrl)
        }
    }),
    twitterProfileUrl: Yup.string()
        .min(2, 'Must be greater than 1 character')
        .max(50, 'Must be less than 30 characters')
})

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        patchBrandProfileBasicInfo: data => dispatch(patchBrandProfileBasicInfo(data)),
        fetchBrandProfileBasic: brandProfileId => dispatch(fetchBrandProfileBasic(brandProfileId)),
        setBrandProfileBasicInfo: basicInfo => dispatch(setBrandProfileBasicInfo(basicInfo))
    }
}

const AutoSave = ({ debounceMs }) => {
    const formik = useFormikContext()
    const debouncedSubmit = React.useCallback(
        debounce(() => formik.submitForm(), debounceMs),
        [debounceMs, formik.submitForm]
    )

    React.useEffect(() => {
        if (formik.dirty) {
            debouncedSubmit()
        }
    }, [debouncedSubmit, formik.values])

    return null
}

const getAylienIndustries = async () => {
    const url = `/brand-profile/aylien-industries`
    const res = await userAccountAxios.get(url)
    return res.data
}

function BasicInfo(props) {
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly } = useSearch()

    const { accounts, user, currentAccount, perms, userCan } = useUser()

    let fetchBrandProfileBasic = props.fetchBrandProfileBasic
    const [fetched, setFetched] = React.useState(false)
    React.useEffect(() => {
        if (!fetched && !!brandProfileId) {
            fetchBrandProfileBasic(brandProfileId)
            setFetched(true)
        }
    }, [brandProfileId])

    React.useEffect(() => {
        return () => {
            setFetched(false)
        }
    }, [])

    const { data: aylienIndustries } = useQuery(rqKeys.aylienIndBasicInfo(), getAylienIndustries)
    //usePrompt('you sure you want to leave?', true)
    if (!props?.brandProfile?.brandName) {
        return <LoadingPage message="Loading brand profile..." />
    }
    return (
        <Panel
            header={<div className="sightlyPanelHeader">Brand Information</div>}
            style={{
                position: 'relative'
            }}
        >
            <Form>
                <FormikInput
                    required
                    name="brandName"
                    labelText="Brand Profile Name"
                    formikValue={props.values.brandName}
                    autoFocus={true}
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                />

                <FormikInput
                    required
                    name="websiteUrl"
                    labelText="Website"
                    formikValue={props.values.websiteUrl}
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                />

                <FormikSelect
                    required
                    id="aylienIndustryId"
                    name="aylienIndustryId"
                    label="Industry Vertical"
                    placeholder={''}
                    optionLabel="taxonomyPath"
                    optionValue="id"
                    options={aylienIndustries}
                    value={props.values.aylienIndustryId}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    validateField={props.validateField}
                    validateForm={props.validateForm}
                    touched={props.touched.aylienIndustryId}
                    error={props.errors.aylienIndustryId}
                    isDisabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                />

                <FormikInput
                    required
                    name="twitterProfileUrl"
                    labelText="Twitter Profile"
                    formikValue={props.values.twitterProfileUrl}
                    startAdornmentText={'twitter.com/'}
                    disabled={!userCan(perms.BRAND_PROFILE_UPDATE) || viewOnly}
                />

                <AutoSave debounceMs={700} />
            </Form>
        </Panel>
    )
}

const FormikForm = withFormik({
    mapPropsToValues: props => {
        return {
            brandName: props.brandProfile.brandName,
            websiteUrl: props.brandProfile.websiteUrl,
            aylienIndustryId: props.brandProfile.aylienIndustryId,
            twitterProfileUrl: props.brandProfile.twitterProfileUrl,
            brandProfileId: props.brandProfile.brandProfileId
        }
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema: schemaValidation,
    handleSubmit: (values, { props }) => {
        props.setBrandProfileBasicInfo(values)
        props.patchBrandProfileBasicInfo(values)
    }
})(BasicInfo)

export default connect(mapStateToProps, mapDispatchToProps)(FormikForm)
