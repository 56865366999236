import { combineReducers } from 'redux'
import { authToken, isLoggedIn, alert, loggingIn, updatingPassword, resettingPassword } from './auth'
import {
    hasBrandProfiles,
    brandProfileLoading,
    brandProfileSaving,
    brandProfileSubmitting,
    brandProfileIabCategoriesLoading,
    brandProfileSubmitted,
    brandProfileSaved,
    scenariosIsLoading,
    brandProfileUnderEdit,
    isDownloadingBrandProfile,
    downloadingBrandProfileId,
    brandProfileTopicsLoading,
    outcomeMetricOptions
} from './brandProfiles'

import admin from './admin/index'
import engage from './engage/index'
import discover from './discover/index'
import thirdParty from './ThirdParty/index'

export default combineReducers({
    authToken,
    isLoggedIn,
    loggingIn,
    alert,
    brandProfileSubmitting,
    brandProfileSubmitted,
    brandProfileUnderEdit,
    brandProfileLoading,
    brandProfileIabCategoriesLoading,
    brandProfileTopicsLoading,
    isDownloadingBrandProfile,
    downloadingBrandProfileId,
    hasBrandProfiles,
    brandProfileSaving,
    brandProfileSaved,
    updatingPassword,
    resettingPassword,
    scenariosIsLoading,
    admin,
    engage,
    discover,
    thirdParty,
    outcomeMetricOptions
})
