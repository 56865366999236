import React from 'react'
import { TrendArticle } from '../../../../classes/trend'
import { useSelector } from 'react-redux'
import twitterLogo from '../../../../assets/img/twitterLogo.png'
import Paragraph from 'rsuite/lib/Placeholder/PlaceholderParagraph'
import { ReactComponent as LinkIcon } from '../../../../assets/img/hyperlink.svg'

interface iProps {
	article: TrendArticle
	isFirst: boolean
}

const TrendArticleComponent = ({ article, isFirst }: iProps) => {
	const getHostnameFromRegex = (url: string) => {
		const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
		return matches && matches[1]
	}

	const trendsIsLoading = useSelector(
		(state: any) => state.discover.trendsIsLoading
	)
	const isTwitter = article.url.includes('twitter')

	const _imageUrl = React.useMemo(() => {
		if (isTwitter) return twitterLogo
		if (article.images && article.images[0]) return article.images[0]
		if (!article.images || !article.images[0]) return ''
		return ''
	}, [article])

	if (trendsIsLoading && !isFirst) {
		return (
			<div style={{ height: 77 }}>
				<Paragraph style={{ marginTop: 30 }} graph='image' />
			</div>
		)
	}

	if (trendsIsLoading && isFirst) {
		return (
			<div style={{ height: 213 }}>
				<Paragraph style={{ marginTop: 30 }} graph='image' />
			</div>
		)
	}

	if (isFirst) {
		return (
			<div
				onClick={() => window.open(article.url, '_blank')?.focus()}
				style={{
					cursor: 'pointer',
					height: 183 + 30,
					width: 700,
					marginTop: 15,
					marginBottom: 15,
					borderBottom: '1px solid rgb(212, 217, 217, 0.5)',
					display: 'flex',
					paddingLeft: 20
				}}
			>
				<div style={{ width: 274 + 24, paddingRight: 24 }}>
					{_imageUrl.length > 0 ? (
						<img
							style={{ width: 274, height: 183, borderRadius: 2.066 }}
							src={_imageUrl}
						/>
					) : (
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								color: '#6E8399',
								paddingTop: 40,
								textAlign: 'center'
							}}
						>
							IMAGE NOT AVAILABLE
						</div>
					)}
				</div>
				<div style={{ flex: 1, width: 335 }}>
					<div
						style={{
							width: 335,
							fontWeight: 600,
							fontSize: 16,
							lineHeight: '24px',
							color: '#333D47',
							textAlign: 'left'
						}}
					>
						{article.title === 'Access Denied'
							? 'Title Unavailable'
							: article.title}
					</div>

					<div style={{ display: 'flex', paddingBottom: 5, marginTop: 16 }}>
						<div
							style={{
								fontWeight: 600,
								fontSize: 12,
								lineHeight: '20px',
								color: '#333D47',
								textAlign: 'left'
							}}
						>
							{isTwitter
								? 'twitter.com'
								: article.url
								? getHostnameFromRegex(article.url)
								: ''}{' '}
							-
						</div>
						<div style={{ marginTop: 2, marginLeft: 3.5 }}>
							<LinkIcon />
						</div>
					</div>

					<div
						className='maxThreeLines'
						style={{
							width: 335,
							fontWeight: 400,
							fontSize: 12,
							marginTop: 16,
							lineHeight: '17.52px',
							color: '#000000',
							textAlign: 'left'
						}}
					>
						{article.description}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div
			onClick={() => window.open(article.url, '_blank')?.focus()}
			style={{
				cursor: 'pointer',
				height: 77,
				marginTop: 8,
				borderBottom: '1px solid rgb(212, 217, 217, 0.5)',
				display: 'flex',
				width: 700
			}}
		>
			<div style={{ width: 116 }}>
				{_imageUrl.length > 0 ? (
					<img
						style={{ width: 100, height: 72, borderRadius: 3 }}
						src={_imageUrl}
					/>
				) : (
					<div
						style={{
							fontSize: 12,
							fontWeight: 500,
							color: '#6E8399',
							paddingTop: 15,
							textAlign: 'center'
						}}
					>
						IMAGE NOT AVAILABLE
					</div>
				)}
			</div>
			<div style={{ flex: 1 }}>
				<div
					style={{
						fontWeight: 600,
						fontSize: 16,
						lineHeight: '24px',
						color: '#333D47',
						textAlign: 'left'
					}}
				>
					{article.title === 'Access Denied'
						? 'Title Unavailable'
						: article.title}
				</div>

				<div style={{ display: 'flex', paddingBottom: 5 }}>
					<div
						style={{
							fontWeight: 600,
							fontSize: 12,
							lineHeight: '20px',
							color: '#333D47',
							textAlign: 'left'
						}}
					>
						{isTwitter
							? 'twitter.com'
							: article.url
							? getHostnameFromRegex(article.url)
							: ''}{' '}
						-
					</div>
					<div style={{ marginTop: 2, marginLeft: 3.5 }}>
						<LinkIcon />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TrendArticleComponent
