import {
    SET_AUTH_TOKEN,
    SET_LOGGED_IN,
    SET_ALERT,
    SET_LOGGING_IN,
    SET_UPDATING_PASSWORD,
    SET_RESETTING_PASSWORD
} from '../action-types/auth'

export function authToken(state = null, action) {
    switch (action.type) {
        case SET_AUTH_TOKEN:
            return action.payload
        default:
            return state
    }
}

export function isLoggedIn(state = false, action) {
    switch (action.type) {
        case SET_LOGGED_IN:
            return action.payload
        default:
            return state
    }
}

export function loggingIn(state = false, action) {
    switch (action.type) {
        case SET_LOGGING_IN:
            return action.payload
        default:
            return state
    }
}

export function updatingPassword(state = false, action) {
    switch (action.type) {
        case SET_UPDATING_PASSWORD:
            return action.updatingPassword
        default:
            return state
    }
}

export function resettingPassword(state = false, action) {
    switch (action.type) {
        case SET_RESETTING_PASSWORD:
            return action.resettingPassword
        default:
            return state
    }
}

export function alert(state = false, action) {
    switch (action.type) {
        case SET_ALERT:
            return action.payload
        default:
            return state
    }
}
