import React from 'react'
import LikeDislikeButton from '../../../../components/LikeDislikeButton'
import {
	accentColor,
	purpleSecondaryColor,
	brandPinkColor
} from '../../../../assets/jss/colorContants'
import {
	TrendScenario,
	ScenarioResponseEnum,
	getScenarioResponse
} from '../../../../classes/trend'
import { NoneApplyId } from '../../../../services/trends_ts'

interface iProps {
	scenario: TrendScenario
	index: number
	handleFeedbackQuestion: Function
	handleDeleteTrendScenario: Function
}

const TrendScenarioComponent = ({
	scenario,
	index,
	handleFeedbackQuestion,
	handleDeleteTrendScenario
}: iProps) => {
	const _bgColor = React.useMemo(() => {
		if (scenario.scenarioResponseId === ScenarioResponseEnum.target.id)
			return accentColor
		if (scenario.scenarioResponseId === ScenarioResponseEnum.monitor.id)
			return purpleSecondaryColor
		if (scenario.scenarioResponseId === ScenarioResponseEnum.block.id)
			return brandPinkColor
		if (scenario.scenarioResponseId === ScenarioResponseEnum.dontmonitor.id)
			return 'lightgrey'
		return 'white'
	}, [scenario])

	const [showRemoveOption, setShowRemoveOption] = React.useState<boolean>(false)

	return (
		<div
			style={{
				height: 100,
				margin: '10px 0px',
				marginLeft: 10,
				position: 'relative',
				display: 'flex',
				borderBottom: '1px solid rgb(212, 217, 217, 0.5)'
			}}
		>
			<div
				style={{
					fontWeight: 700,
					width: 63,
					fontSize: 16,
					lineHeight: '19.36px',
					color: '#5F7284',
					paddingTop: 3
				}}
			>
				Q{index}
			</div>

			<div
				style={{
					fontWeight: 600,
					width: 600,
					fontSize: 16,
					lineHeight: '24px',
					color: '#333D47'
				}}
			>
				{scenario.scenarioName}
			</div>
			{scenario.scenarioId != NoneApplyId && (
				<>
					<div
						style={{
							marginTop: 24,
							marginLeft: 65,
							fontWeight: 600,
							width: 87.5,
							height: 40,
							backgroundColor: _bgColor,
							fontSize:
								scenario.scenarioResponseId ===
								ScenarioResponseEnum.dontmonitor.id
									? 10
									: 15,
							lineHeight: '27.5px',
							color: 'white',
							textTransform: 'capitalize',
							borderRadius: 3.75,
							paddingTop: 6.25,
							textAlign: 'center'
						}}
					>
						{scenario.scenarioResponseId === ScenarioResponseEnum.dontmonitor.id
							? `Do not Monitor`
							: getScenarioResponse(scenario.scenarioResponseId)}
					</div>

					<div
						style={{
							position: 'absolute',
							bottom: 16,
							left: 63,
							display: 'flex',
							height: 20,
							alignContent: 'center'
						}}
					>
						<LikeDislikeButton
							text={'Is this scenario relevant to the moment?'}
							textColor={'#2E4052'}
							buttonState={scenario.feedback}
							handleClick={(feedback: string) =>
								handleFeedbackQuestion(feedback, scenario.scenarioName)
							}
							handleDownClick={() => setShowRemoveOption(true)}
						/>
						{showRemoveOption && (
							<div
								onClick={() => {
									setShowRemoveOption(false)
									handleDeleteTrendScenario()
								}}
								className='unselectable'
								style={{
									cursor: 'pointer',
									marginLeft: 10,
									fontWeight: 700,
									fontSize: 12,
									lineHeight: '20px',
									color: '#FF0000'
								}}
							>
								Remove Scenario
							</div>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default TrendScenarioComponent
